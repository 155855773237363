import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { MatDividerModule, MatIconModule, MatProgressSpinnerModule } from "@angular/material"
import { FroalaEditorModule, FroalaViewModule } from "angular-froala-wysiwyg"

import { ModelsRoutingModule } from "./models-routing.module"
import { SharedModule } from "../shared/shared.module"
import { ModelDetailPageComponent } from "./pages/model-detail-page/model-detail-page.component"
import { ModelDetailPictorialsComponent } from "./components/model-detail-pictorials/model-detail-pictorials.component"
import { ModelDescriptionComponent } from "./components/model-description/model-description.component"
import { BoardModule } from "../board/board.module"
import { ModelMetaFormComponent } from "./components/model-meta-form/model-meta-form.component"

@NgModule({
  declarations: [
    ModelDetailPageComponent,
    ModelDescriptionComponent,
    ModelDetailPictorialsComponent,
    ModelMetaFormComponent,
  ],
  imports: [
    SharedModule,
    CommonModule,
    ModelsRoutingModule,
    MatIconModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    MatProgressSpinnerModule,
    MatDividerModule,
    BoardModule,
  ],
  exports: [],
})
export class ModelsModule {}
