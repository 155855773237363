import { Component, OnInit } from "@angular/core"
import { MembershipService } from "../../services/membership.service"

import { CookieService } from "angular2-cookie/core"
import { tap } from "rxjs/operators"
import { Pagination } from "src/app/shared/types/pagination.type"
import { Util } from "src/app/shared/utils/utils"

@Component({
  selector: "app-membership-list-media",
  templateUrl: "./membership-list-media.component.html",
  styleUrls: ["./membership-list-media.component.scss"],
})
export class MembershipListMediaComponent implements OnInit {
  paginatedResult$
  langFlag

  page: number = 1
  pageUrl: string

  constructor(private membershipService: MembershipService, private cookieService: CookieService) {}

  ngOnInit() {
    if (this.cookieService.get("lang") !== "KOR") {
      this.langFlag = "ENG"
    } else {
      this.langFlag = "KOR"
    }

    this.getPaginatedResult()
  }

  getPaginatedResult() {
    this.paginatedResult$ = this.membershipService
      .getListPaginated({ perspective: "contributor" })
      .pipe(
        tap((data: Pagination<any>) => {
          this.pageUrl = data.next || data.previous
        }),
      )
  }

  getPaginatedResultByUrl(pageNo: number) {
    if (this.pageUrl) {
      const url = Util.getPaginationUrl(this.pageUrl, pageNo)

      this.page = pageNo
      this.paginatedResult$ = this.membershipService.getListPaginatedByUrl(url).pipe(
        tap((data: Pagination<any>) => {
          this.pageUrl = data.next || data.previous
        }),
      )
    }
  }
}
