import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { TranslateService } from "@ngx-translate/core"
import { PictorialService } from "src/app/board/services/pictorial.service"
import { Comment } from "src/app/board/types/comment.type"

@Component({
  selector: "app-pictorial-comment-detail",
  templateUrl: "./pictorial-comment-detail.component.html",
  styleUrls: ["./pictorial-comment-detail.component.scss"],
})
export class PictorialCommentDetailComponent implements OnInit {
  @Input() comment: Comment
  @Input() writable: boolean = false
  @Output() onDelete = new EventEmitter<number>()

  showReplies: boolean = false
  replies: Comment[]

  constructor(
    private pictorialService: PictorialService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {}

  onClickReplies() {
    this.showReplies = !this.showReplies
    if (this.showReplies) {
      this.fetchReplies()
    }
  }

  async fetchReplies() {
    const replies = await this.pictorialService
      .getComments({
        pictorialId: this.comment.pictorial_id,
        parentId: this.comment.id,
      })
      .toPromise()
    this.replies = (replies as unknown as Comment[]).reverse()
  }

  async deleteReply(id: number) {
    if (!confirm(this.translateService.instant("ALERT_CONFIRM.DELETE_COMMENT_CONFIRM"))) {
      return
    }
    this.replies = this.replies.filter((reply) => reply.id !== id)
    await this.pictorialService.deleteComment(id).toPromise()
  }

  onClickDelete() {
    this.onDelete.emit(this.comment.id)
  }

  async postReply(content: string) {
    const body = {
      pictorialId: this.comment.pictorial_id,
      content: content,
      creatorId: this.comment.creator,
      parentId: this.comment.id,
    }
    const reply = await this.pictorialService.postComment(body).toPromise()
    this.replies = this.replies.concat(reply)
  }

  get replyText() {
    const n = this.comment.children.length
    if (this.showReplies) {
      return this.translateService.instant("BUTTONS.CLOSE")
    } else if (n === 0) {
      return this.translateService.instant("BUTTONS.REPLY")
    } else if (n === 1) {
      return this.translateService.instant("COMMENT_DETAIL.SHOW_ONE_REPLY")
    } else {
      return this.translateService.instant("COMMENT_DETAIL.SHOW_N_REPLIES", {
        n: n,
      })
    }
  }

  get isChild() {
    return this.comment.parent
  }

  get canDelete() {
    return this.comment.permissions.includes("delete")
  }
}
