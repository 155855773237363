import { Pipe, PipeTransform } from "@angular/core"

type unit = "bytes" | "KB" | "MB" | "GB" | "TB" | "PB"
type unitPrecisionMap = {
  [u in unit]: number
}

const defaultPrecisionMap: unitPrecisionMap = {
  bytes: 0,
  KB: 0,
  MB: 1,
  GB: 1,
  TB: 2,
  PB: 2,
}

// kb기반 데이터를 mb, gb, tb, pb 단위로 변환
@Pipe({
  name: "fileSize",
})
export class FileSizePipe implements PipeTransform {
  private readonly units: unit[] = ["KB", "MB", "GB", "TB", "PB"]
  transform(kb: number = 0) {
    if (isNaN(parseFloat(String(kb))) || !isFinite(kb)) return "?"

    let unitIndex = 0

    while (kb >= 1024) {
      kb /= 1024
      unitIndex++
    }

    const unit = this.units[unitIndex - 1]

    return `${kb.toFixed(defaultPrecisionMap[unit])} ${unit}`
  }
}
