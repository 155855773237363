import { NgModule } from "@angular/core"
import { RouterModule, Routes } from "@angular/router"
import { CreatorDetailPageComponent } from "./creator/pages/creator-detail-page/creator-detail-page.component"
import { CreatorPhotobookPageComponent } from "./creator/pages/creator-photobook-page/creator-photobook-page.component"
import { NotFoundPageComponent } from "./website/pages/not-found-page/not-found-page.component"


const routes: Routes = [
  { path: "", pathMatch: "full", redirectTo: "/" },
  {
    path: "404",
    component: NotFoundPageComponent,
  },
  {
    path: ":id",
    component: CreatorDetailPageComponent,
  },
  {
    path: ":id/models",
    component: CreatorDetailPageComponent,
  },
  {
    path: ":id/models/:mid",
    component: CreatorDetailPageComponent,
  },
  {
    path: ":id/pictorials/:pid",
    component: CreatorPhotobookPageComponent,
  },
  {
    path: ":id/post/:pid",
    component: CreatorDetailPageComponent,
  },
  { path: "**", redirectTo: "/404" },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      onSameUrlNavigation: "reload",
      scrollPositionRestoration: "disabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
