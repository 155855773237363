import { NgModule } from "@angular/core"
import {
  MatButtonModule,
  MatCardModule,
  MatDialogModule,
  MatIconModule,
  MatSliderModule,
} from "@angular/material"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import { CountUpModule } from "countup.js-angular2"
import { SharedModule } from "../shared/shared.module"
import { ImageDialogComponent } from "./components/image-dialog/image-dialog.component"
import { CollectTermsPageComponent } from "./pages/collect-terms-page/collect-terms-page.component"
import { ContentPolicyPageComponent } from "./pages/content-policy-page/content-policy-page.component"

import { LandingPageComponent } from "./pages/landing-page/landing-page.component"
import { NotFoundPageComponent } from "./pages/not-found-page/not-found-page.component"
import { ProvideTermsPageComponent } from "./pages/provide-terms-page/provide-terms-page.component"
import { ServicePlanPageComponent } from "./pages/service-plan-page/service-plan-page.component"
import { WebsiteRoutingModule } from "./website-routing.module"
import { CommunityGuidelinePageComponent } from "./pages/community-guideline-page/community-guideline-page.component"
import { BenefitGuidelinePageComponent } from "./pages/benefit-guideline-page/benefit-guideline-page.component"
import { CopyrightPageComponent } from "./pages/copyright-page/copyright-page.component"
import { SecurityPageComponent } from "./pages/security-page/security-page.component"
import { CookiePageComponent } from "./pages/cookie-page/cookie-page.component"
import { AgeverificationPageComponent } from "./pages/ageverification-page/ageverification-page.component"
import { LandingFirstCollaborationComponent } from "./components/landing/landing-first-collaboration/landing-first-collaboration.component"
import { LandingSecondCommunicationComponent } from "./components/landing/landing-second-communication/landing-second-communication.component"
import { LandingThirdLeeheeComponent } from "./components/landing/landing-third-leehee/landing-third-leehee.component"
import { LandingForthEnterMembermeComponent } from "./components/landing/landing-forth-enter-memberme/landing-forth-enter-memberme.component"
import { LandingFifthPictorialComponent } from "./components/landing/landing-fifth-pictorial/landing-fifth-pictorial.component"
import { LandingSixthProfitComponent } from "./components/landing/landing-sixth-profit/landing-sixth-profit.component"
import { LandingEighthCommunityComponent } from "./components/landing/landing-eighth-community/landing-eighth-community.component"
import { LandingNinthWithMembermeComponent } from "./components/landing/landing-ninth-with-memberme/landing-ninth-with-memberme.component"
import { LandingTenthCreatorComponent } from "./components/landing/landing-tenth-creator/landing-tenth-creator.component"
import { LandingSecurityComponent } from "./components/landing/landing-security/landing-security.component"
import { LandingRecommendedModelsComponent } from "./components/landing/landing-recommended-models/landing-recommended-models.component"
import { DeleteAccountRequestPageComponent } from "./pages/delete-account-request-page/delete-account-request-page.component"
import { AngularSvgIconModule } from "angular-svg-icon"

@NgModule({
  imports: [
    SharedModule,
    BrowserAnimationsModule,
    MatSliderModule,
    WebsiteRoutingModule,
    MatDialogModule,
    CountUpModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    AngularSvgIconModule,
  ],
  declarations: [
    LandingPageComponent,

    CollectTermsPageComponent,
    ProvideTermsPageComponent,
    NotFoundPageComponent,
    ContentPolicyPageComponent,
    ServicePlanPageComponent,
    ImageDialogComponent,
    CommunityGuidelinePageComponent,
    BenefitGuidelinePageComponent,
    CopyrightPageComponent,
    SecurityPageComponent,
    CookiePageComponent,
    AgeverificationPageComponent,
    NotFoundPageComponent,
    LandingFirstCollaborationComponent,
    LandingSecondCommunicationComponent,
    LandingThirdLeeheeComponent,
    LandingForthEnterMembermeComponent,
    LandingFifthPictorialComponent,
    LandingSixthProfitComponent,
    LandingEighthCommunityComponent,
    LandingNinthWithMembermeComponent,
    LandingTenthCreatorComponent,
    LandingSecurityComponent,
    LandingRecommendedModelsComponent,
    DeleteAccountRequestPageComponent,
  ],
  entryComponents: [ImageDialogComponent],
})
export class WebsiteModule {}
