import { Component, Input, OnInit } from "@angular/core"
import * as moment from "moment"

import { Post } from "src/app/board/types/post.type"
import { Creator } from "src/app/creator/types"
import { MessageType } from "../../types/message.type"
import { Router } from "@angular/router"
import { Pictorial } from "src/app/board/types/pictorial.type"
import { MatDialog } from "@angular/material"
import { PurchasePermission } from "src/app/creator/pages/creator-photobook-page/type"
import { PictorialBuyModalComponent } from "src/app/payment/pages/pictorial-buy-modal/pictorial-buy-modal.component"
import { User } from "../../../account/types/user.type"
import { UserService } from "../../../account/services/user.service"
import { PictorialDetailPageService } from "src/app/board/pages/pictorial-detail-page/pictorial-detail-page.service"

@Component({
  selector: "app-chat-content-viewer",
  templateUrl: "./chat-content-viewer.component.html",
  styleUrls: ["./chat-content-viewer.component.scss"],
  providers: [PictorialDetailPageService],
})
export class ChatContentViewerComponent implements OnInit {
  @Input() data: any
  @Input() messageId: number
  @Input() type: MessageType

  isLocked = false
  isPastPoll = false
  creator: Creator
  isBlockedUser = false

  pictorialId: string
  pictorial: Pictorial

  messageType = MessageType

  isLoading = true
  user: User

  constructor(
    private router: Router,
    private pictorialDetailService: PictorialDetailPageService,
    private userService: UserService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.user = this.userService.getUser()
    if (this.type === MessageType.pictorial) {
      this.pictorialId = (this.data as Post).pictorial_id
      this.pictorialDetailService.init(this.pictorialId)
    }
    this.setCreator()
    this.setIsLocked()
    this.setIsPastPoll()
    this.setIsBlockedUser()

    if (this.type === MessageType.post) {
      this.isLoading = false
    }
  }

  setCreator() {
    if (this.type == "post") {
      this.creator = (this.data as Post).creator
    }
  }

  setIsLocked() {
    if (this.type == "post") {
      this.isLocked = !(this.data as Post).permissions.includes("view")
    }
  }

  setIsPastPoll() {
    if (this.type === "post" && (this.data as Post).polls && (this.data as Post).polls.length) {
      this.isPastPoll =
        moment.duration(moment((this.data as Post).polls[0].end_date).diff(moment())).asDays() < -1
    }
  }

  setIsBlockedUser() {
    if (
      this.creator &&
      this.creator.membership &&
      this.creator.membership.member_management &&
      this.creator.membership.member_management.is_blocked_user
    ) {
      this.isBlockedUser = true
    }
  }

  loadFinished(event: boolean) {
    this.isLoading = !event
  }
}
