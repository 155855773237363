import { Component, Input, OnInit } from "@angular/core"
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router"
import { Subscription } from "rxjs"
import { UserService } from "src/app/account/services/user.service"
import { Breakpoint } from "src/app/shared/constants/constants"
import { SnackbarService } from "src/app/shared/services/snackbar.service"
import { Pagination } from "src/app/shared/types/pagination.type"
import { Util } from "src/app/shared/utils/utils"
import { PostMessagesService } from "../../services/post-messages.service"
import { Message } from "../../types/message.type"
import { InboxPageType } from "../../types/url-param.type"

import { Post } from "src/app/board/types/post.type"
import { map } from "rxjs/operators"
import { PictorialDataService } from "../../services/pictorial-data.service"
import { PostDataService } from "src/app/board/services/post-data.service"
import { CountryRestrictionModalService } from "src/app/shared/services/country-restiction-modal.service"
import { BreakpointObserverService } from "src/app/shared/services/breakpoint-observer.service"

@Component({
  selector: "app-message-history-list",
  templateUrl: "./message-history-list.component.html",
  styleUrls: ["./message-history-list.component.scss"],
})
export class MessageHistoryListComponent implements OnInit {
  @Input() messageCreatedByMe: boolean = false

  messages: Message[] = []
  productSentByMe: Post[] = []
  idSetWillRemove: Set<number> = new Set<number>()

  totalPage: number = 1
  totalItems: number = 0
  currentPage: number = 1

  isLoading: boolean
  isFetchingIp: boolean
  pageType: InboxPageType

  isMobile: boolean
  isLogin: boolean
  isRemoveMode: boolean = false

  routerSubscription: Subscription

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserverService,
    private postMessageService: PostMessagesService,
    private pictorialService: PictorialDataService,
    private postDataService: PostDataService,
    private userService: UserService,
    private snackbarService: SnackbarService,
    private countryRestrictionService: CountryRestrictionModalService,
  ) {}

  ngOnInit() {
    this.init()

    const breakPointQueryStr = this.breakpointObserver.maxWidth(Breakpoint.md)
    this.breakpointObserver.observe([breakPointQueryStr]).subscribe((result) => {
      this.isMobile = result.matches
    })

    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.init()
      }
    })

    this.countryRestrictionService.isFetchingIpEvent.subscribe((isFetching: boolean) => {
      this.isFetchingIp = isFetching
    })
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe()
  }

  init() {
    const params = this.route.snapshot.paramMap
    const type = params.get("type")

    if (!Object.values(InboxPageType).includes(type as InboxPageType) && !this.messageCreatedByMe) {
      this.router.navigate(["/404"], { skipLocationChange: true })
      return
    }

    if (this.pageType == undefined) {
      this.pageType = type as InboxPageType
    }

    if (this.pageType != (type as InboxPageType)) {
      this.currentPage = 1
      this.pageType = type as InboxPageType
    }

    const queryParams = this.route.snapshot.queryParams
    const page = Number(queryParams.page || this.currentPage)
    if (page < 1) {
      this.setQueryParams(1)
      return
    }

    this.currentPage = page
    this.getMessages(page, type as InboxPageType)
  }

  getMessages(page: number, type: InboxPageType) {
    this.isLoading = true

    if (this.messageCreatedByMe) {
      this.getMessagesCreateByMe(page)
    } else {
      this.getUserMessages(page, type)
    }
  }

  getUserMessages(page: number, type: InboxPageType) {
    this.isLoading = true

    const param = {
      page: page || 1,
      page_size: 20,
      user: this.userService.getUser().id,
    }

    switch (type) {
      case InboxPageType.important:
        param["is_liked"] = true

        break
      case InboxPageType.unread:
        param["is_read"] = false

        break
    }

    this.postMessageService.getMessageItems(param).subscribe(
      (res) => {
        this.messages = res.results
        this.totalPage = this.getTotalPageNumber(res)
        this.isLoading = false
      },
      (err) => {
        this.snackbarService.open(Util.getErrorTxt(err.error), "", 2000, "danger-snackbar", () =>
          this.router.navigate(["."], {
            relativeTo: this.route,
            queryParams: { page: 1 },
            replaceUrl: true,
          }),
        )
      },
    )
  }

  getMessagesCreateByMe(page: number) {
    this.postDataService
      .getListPaginated({
        page,
        creator: this.userService.getUser().creator,
        category: "message",
      })
      .pipe(map((res) => this.setPictorialThumbnailsPipe(res)))
      .pipe(map(async (res) => this.setPostThumbnailsPipe(await res)))
      .subscribe(async (res) => {
        this.productSentByMe = (await res).results
        this.isLoading = false
      })
  }

  async setPictorialThumbnailsPipe(resp: Pagination<Post>) {
    const pictorialPosts = resp.results.filter((post) => post.pictorial_id)
    const pictorialIds = pictorialPosts.map((post) => post.pictorial_id.toString())
    const pictorials = await this.pictorialService.getPictorialsByIds(pictorialIds)

    pictorialPosts.forEach((post) => {
      const attachedPictorial = pictorials.find((pictorial) => pictorial.id == post.pictorial_id)
      post.thumbnails = this.pictorialService.getThumbnails(attachedPictorial)
    })

    return resp
  }

  setPostThumbnailsPipe(resp: Pagination<Post>) {
    const postMessages = resp.results.filter((post) => !post.pictorial_id)
    postMessages.forEach((post) => {
      post.thumbnails = this.postDataService.parseThumbnails(post)
    })

    return resp
  }

  getTotalPageNumber(res: Pagination<Message>) {
    return Math.ceil(res.count / 20)
  }

  setQueryParams(params) {
    this.router.navigate(["."], {
      relativeTo: this.route,
      queryParams: { page: params },
      replaceUrl: true,
    })
  }

  updateRemoveIdSet = (id: number) => {
    if (this.idSetWillRemove.has(id)) {
      this.idSetWillRemove.delete(id)
    } else {
      this.idSetWillRemove.add(id)
    }
  }

  cancelRemove() {
    this.isRemoveMode = false
    this.idSetWillRemove.clear()
  }

  toggleRemoveMode() {
    if (this.isRemoveMode) {
      this.deleteMessages()
    }
    this.isRemoveMode = !this.isRemoveMode
  }

  deleteMessages() {
    const idsWillRemove = Array.from(this.idSetWillRemove)
    this.idSetWillRemove.clear()
    if (idsWillRemove.length == 0) {
      return
    }

    this.postMessageService.deleteMessages(idsWillRemove).subscribe((res) => {
      this.preventInvalidPage(res.deleted_count)
    })
  }

  preventInvalidPage(deletedCount: number) {
    this.totalItems -= deletedCount
    this.totalPage = Math.ceil(this.totalItems / 20)

    this.setQueryParams(this.totalPage)
  }

  get showLoadingSpinner() {
    return this.isLoading || this.isFetchingIp
  }

  get messageItems() {
    if (this.messageCreatedByMe) {
      return this.productSentByMe
    }
    return this.messages
  }
}
