import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"

import { ContestRoutingModule } from "./contest-routing.module"

import { CategoryDetailPageComponent } from "./pages/category-detail-page/category-detail-page.component"

import { SharedModule } from "../shared/shared.module"
import { AiContestCarouselComponent } from "./components/ai-contest-carousel/ai-contest-carousel.component"
import { CarouselItemComponent } from "./components/carousel-item/carousel-item.component"
import {
  MatButtonModule,
  MatDividerModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatProgressSpinnerModule,
} from "@angular/material"

import { ContestApplyPageComponent } from "./pages/contest-apply-page/contest-apply-page.component"
import { ContestMainPageComponent } from "./pages/contest-main-page/contest-main-page.component"
import { FroalaEditorModule, FroalaViewModule } from "angular-froala-wysiwyg"
import { ModelContestMainPageComponent } from "./pages/model-contest-main-page/model-contest-main-page.component"
import { ModelContestTopContainerComponent } from "./components/model-contest-top-container/model-contest-top-container.component"
import { ModelTopItemComponent } from "./components/model-top-item/model-top-item.component"
import { ModelContestContentCarouselComponent } from "./components/model-contest-content-carousel/model-contest-content-carousel.component"
import { ModelContestAllContentComponent } from "./components/model-contest-all-content/model-contest-all-content.component"
import { AsianTopModelRankingPageComponent } from "./pages/asian-top-model-ranking-page/asian-top-model-ranking-page.component"
import { AsianTopModelRankListItemComponent } from "./components/asian-top-model-rank-list-item/asian-top-model-rank-list-item.component"
import { RankRenderComponent } from "./components/rank-render/rank-render.component"
import { AngularSvgIconModule } from "angular-svg-icon"
import { ResizeImage } from "../shared/pipe/resize-image.pipe"
import { ModelContestTopPageComponent } from "./pages/model-contest-top-page/model-contest-top-page.component"
import { ModelContestMainPageHeaderComponent } from "./components/model-contest-main-page-header/model-contest-main-page-header.component"
import { BoardModule } from "src/app/board/board.module"
import { ModelContestRecommendedModelsComponent } from "./components/model-contest-recommended-models/model-contest-recommended-models.component"

@NgModule({
  declarations: [
    CategoryDetailPageComponent,
    AiContestCarouselComponent,
    CarouselItemComponent,
    ContestApplyPageComponent,
    ContestMainPageComponent,
    ModelTopItemComponent,
    ModelContestTopContainerComponent,
    ModelContestMainPageComponent,
    ModelContestContentCarouselComponent,
    ModelContestAllContentComponent,
    AsianTopModelRankingPageComponent,
    AsianTopModelRankListItemComponent,
    RankRenderComponent,
    ModelContestTopPageComponent,
    ModelContestMainPageHeaderComponent,
    ModelContestRecommendedModelsComponent,
  ],
  imports: [
    MatButtonModule,
    SharedModule,
    CommonModule,
    ContestRoutingModule,
    MatIconModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    MatProgressSpinnerModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    AngularSvgIconModule,
    BoardModule,
  ],
  exports: [CarouselItemComponent],
  providers: [ResizeImage],
})
export class ContestModule {}
