import { EventEmitter, Injectable, OnDestroy, OnInit } from "@angular/core"
import { IpRegistryService } from "./ip-registry.service"
import { IpRegistryLocation } from "../types/ipRegistry"
import { CountryRestrictionModalComponent } from "../components/country-restriction-modal/country-restriction-modal.component"

import { fromEvent } from "rxjs"
import { NgbModal } from "@ng-bootstrap/ng-bootstrap"
import { Util } from "../utils/utils"

@Injectable({
  providedIn: "root",
})
export class CountryRestrictionModalService implements OnInit, OnDestroy {
  private windowEvents = []
  private ipRegistryLocation: IpRegistryLocation
  private restrictedCountryCodes = ["KR"]

  isFetchingIpEvent: EventEmitter<boolean> = new EventEmitter<boolean>()

  constructor(private ipService: IpRegistryService, private modalService: NgbModal) {
    if (!this.windowEvents.length) {
      this.tryGetIp()
      this.setEventHandlers()
    }
  }

  ngOnInit() {
    this.tryGetIp()
    this.setEventHandlers()
  }

  ngOnDestroy() {
    this.removeEventHandlers()
  }

  private tryGetIp() {
    const tries = 20
    let triesLeft = tries

    this.isFetchingIpEvent.emit(true)

    function onError(err) {
      triesLeft -= 1

      if (!triesLeft) {
        this.isFetchingIpEvent.emit(false)
        window.location.reload()
      }
      return Util.wait(100).then(this.getIP.bind(this)).catch(onError.bind(this))
    }
    return this.getIP().catch(onError.bind(this))
  }

  private async getIP() {
    const response = await this.ipService.get()
    this.ipRegistryLocation = response.location

    this.isFetchingIpEvent.emit(false)
  }

  private setEventHandlers() {
    if (Util.isMobileDevice()) {
      this.windowEvents.push(fromEvent(window, "focus").subscribe(() => this.tryGetIp()))
    }
    this.windowEvents.push(fromEvent(window, "online").subscribe(() => this.tryGetIp()))
  }
  private removeEventHandlers() {
    this.windowEvents.forEach((event) => event.unsubscribe())
    this.windowEvents = []
  }

  openRestrictModal() {
    const modal = this.modalService.open(CountryRestrictionModalComponent, {
      ariaLabelledBy: "modal-basic-title",
      windowClass: "restricted-country-modal",
      centered: true,
      keyboard: true,
      backdrop: "static",
    })

    modal.result.then(
      () => {},
      () => {},
    )
  }

  public isRestricted(): boolean {
    return (
      this.ipRegistryLocation &&
      this.ipRegistryLocation.country &&
      this.restrictedCountryCodes.includes(this.ipRegistryLocation.country.code)
    )
  }
}
