import { Injectable } from "@angular/core"
import { environment } from "src/environments/environment"
import { AuthService } from "../../core/services/auth.service"

@Injectable({
  providedIn: "root",
})
export class BackendService {
  constructor(private auth: AuthService) {}

  getUrl(url) {
    return `${
      environment.backendUrl
    }accounts/login/redirect/?next_url=${url}&token=${this.auth.getAuthorizationToken()}`
  }
}
