import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { Pagination } from "src/app/shared/types/pagination.type"
import { environment } from "src/environments/environment"
import {
  Contest,
  GetContestParams,
  GetPictorialContestParams,
  PictorialContestCreator,
} from "../types"

@Injectable({
  providedIn: "root",
})
export class ContestService {
  constructor(private http: HttpClient) {}

  getCurrentContest() {
    return this.http.get<Contest>(`${environment.apiUrl}campaigns/current/`)
  }

  // 화보 이벤트 정보
  // 페이지 사이즈 옵션 : limit
  getContestItemList(params: GetPictorialContestParams) {
    const url = `${environment.pictorialApiUrl}pictorials/campaigns`
    return this.http.get<Pagination<any>>(url, { params })
  }

  getContestItemListByUrl(url: string) {
    return this.http.get(url)
  }

  // 화보 이벤트 크리에이터 정보
  getPictorialContestCreatorList(params: GetPictorialContestParams) {
    const url = `${environment.pictorialApiUrl}pictorials/campaigns/creators`
    return this.http.get<PictorialContestCreator[]>(url, { params })
  }

  getPictorialContestCreatorListByUrl(url: string) {
    return this.http.get<Pagination<any>>(url)
  }

  getContestList(params?: GetContestParams): Observable<Pagination<Contest>> {
    const url = `${environment.apiUrl}campaigns/`
    return this.http.get<Pagination<Contest>>(url, { params })
  }

  getContestListByUrl(url: string): Observable<Pagination<Contest>> {
    return this.http.get<Pagination<Contest>>(url)
  }

  getContestCreatorList(params?: GetContestParams) {
    const url = `${environment.apiUrl}campaigns/creators/`
    return this.http.get(url, { params })
  }

  getContestCreatorListByUrl(url: string) {
    return this.http.get(url)
  }
}
