import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core"
import { Router } from "@angular/router"
import { CookieService } from "angular2-cookie/core"

import { environment } from "src/environments/environment"
import { PostService } from "../../services/post.service"
import { UserService } from "src/app/account/services/user.service"
import { POST_VISIBILITY_MAX } from "src/app/shared/constants/constants"
import { RecommendedService } from "../../services/recommended.service"

@Component({
  selector: "app-post-list-card",
  templateUrl: "./post-list-card.component.html",
  styleUrls: ["./post-list-card.component.scss"],
})
export class PostListCardComponent implements OnInit, OnChanges {
  @Input() filterData: any
  @Input() commentPermission: any
  @Input() isBlockedUser: any
  @Input() isAppOnly = false
  @Input() isMine = false
  @Output() filterTagChanged = new EventEmitter<any>()
  posts: any[]
  user: any
  nextUrl: string
  inDashboard: boolean
  postLoaded: boolean
  loadingMore: boolean
  langFlag: string

  recommendedCreatorPosition = 2 // 번째 게시글 아래

  constructor(
    private router: Router,
    private postService: PostService,
    private cookieService: CookieService,
    private userService: UserService,
    private recommendedService: RecommendedService,
  ) {
    this.initParams()
  }

  ngOnInit() {
    this.user = this.userService.getUser()
    this.postLoaded = false

    if (this.cookieService.get("lang") !== "KOR") {
      this.langFlag = "ENG"
    } else {
      this.langFlag = "KOR"
    }
    // different text depending on url
    const currentRoute = this.router.url.split("/")
    this.inDashboard = currentRoute.length === 2 && currentRoute[1] === "dashboard"
    if (!this.isAppOnly) {
      this.getPaginatedResult()
    } else {
      this.postLoaded = true
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filterData && !changes.filterData.firstChange && changes.filterData.previousValue) {
      // check if there is a change in filterData
      for (const key in changes.filterData.previousValue) {
        if (changes.filterData.previousValue.hasOwnProperty(key)) {
          if (
            changes.filterData.previousValue[key] !== changes.filterData.currentValue[key] &&
            !this.isAppOnly
          ) {
            // update posts ONLY when there's change in property
            this.getPaginatedResult()
            break
          }
        }
      }
    }
  }

  initParams() {
    this.posts = []
    this.nextUrl = null
  }

  getPaginatedResult() {
    this.postLoaded = false
    this.initParams()
    if (this.inDashboard) {
      this.recommendedService.getPostDashboardList({sort: "random"}).subscribe((res) => {
        this.posts = res.map((recommendedPost ) => {
          return recommendedPost.post
        })
      })
    }
    this.postService.getListPaginated(this.filterData).subscribe((res) => {
      this.nextUrl = this.getNextURL(res["next"])
      this.posts = this.posts.concat(res["results"])
      this.postLoaded = true
    })
  }

  getPaginatedResultByUrl() {
    this.loadingMore = true
    this.postService.getListPaginatedByUrl(this.nextUrl).subscribe((res) => {
      this.nextUrl = this.getNextURL(res["next"])
      this.posts = this.posts.concat(res["results"])

      this.postLoaded = true
      this.loadingMore = false
    })
  }

  getNextURL(url) {
    if (url && !url.startsWith(environment.apiUrl) && !url.startsWith("https")) {
      return url.replace("http", "https")
    }
    return url
  }

  changedFilterTag($event: any) {
    this.filterTagChanged.emit($event)
  }

  isVisible(post: any) {
    if (this.isMine) {
      return true
    }
    return post.visibility < POST_VISIBILITY_MAX
  }
}
