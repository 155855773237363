import { Component, Inject, OnInit, Renderer2 } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { FormService } from "src/app/shared/services/form.service"
import { ModalPageComponent } from "src/app/shared/components/modal-page/modal-page.component"
import { TransactionService } from "../../services/transaction.service"

import { UserService } from "src/app/account/services/user.service"
import { BillingKeyService } from "../../services/billing-key.service"
import { Observable } from "rxjs"
import { catchError, startWith, switchMap } from "rxjs/operators"
import { TierService } from "src/app/creator/services/tier.service"
import { PictorialService } from "src/app/board/services/pictorial.service"
import { CreatorService } from "src/app/creator/services/creator.service"
import * as moment from "moment"

import { Location } from "@angular/common"
import { CountryService } from "src/app/shared/services/country.service"
import { CookieService } from "angular2-cookie"
import { CurrencyPipe } from "src/app/shared/pipe/currency.pipe"
import { TranslateService } from "@ngx-translate/core"
import { SnackbarService } from "src/app/shared/services/snackbar.service"
import { Form } from "src/app/shared/model/form"
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material"
import { PaymentData } from "./payment-dialog.type"
import { Pictorial } from "src/app/board/types/pictorial.type"
import { Creator, MembershipTier } from "src/app/creator/types"

interface PictorialProduct extends Pictorial {
  creator?: string
}

@Component({
  selector: "app-payment-dialog",
  templateUrl: "./payment-dialog.component.html",
  styleUrls: ["./payment-dialog.component.scss"],
})
export class PaymentDialogComponent extends ModalPageComponent implements OnInit {
  form: Form
  creatorId: number
  creator: Creator

  submitDisabled = true
  pictorialId: string
  tierId?: number

  termsDisplayed = false

  isReqError = false
  isSendme: boolean = false

  selectAll = false
  checkboxes = [
    { name: "term1", checked: false },
    { name: "term3", checked: false },
    { name: "term4", checked: false },
    { name: "term5", checked: false },
  ]
  isChangeMembership = false

  isStripeSetUpped: boolean

  isKorean: boolean
  isSaving: boolean
  isInduced: boolean
  oldProducts

  membershipProduct: MembershipTier
  pictorialProduct: PictorialProduct

  taxRate: number

  constructor(
    public dialogRef: MatDialogRef<PaymentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PaymentData,
    renderer: Renderer2,
    router: Router,
    private route: ActivatedRoute,
    private transactionService: TransactionService,
    private billingKeyService: BillingKeyService,
    private formService: FormService,
    private userService: UserService,
    private tierService: TierService,
    private pictorialService: PictorialService,
    private creatorService: CreatorService,
    private location: Location,
    private countryService: CountryService,
    private translateService: TranslateService,
    private cookieService: CookieService,
    private currencyPipe: CurrencyPipe,
    private snackbarService: SnackbarService,
  ) {
    super(renderer, router)
  }

  ngOnInit() {
    this.isSendme = window.location.href.includes("sendme")
    this.isKorean = this.cookieService.get("lang") === "KOR"
    this.countryService.get().subscribe(() => {
      this.countryService.calTax()
      this.taxRate = this.countryService.getTax()

      if (this.pictorialProduct) {
        this.updateTax(this.pictorialProduct)
      }

      if (this.membershipProduct) {
        this.updateTax(this.membershipProduct)
      }

      if (this.oldProducts) {
        this.oldProducts.forEach((product) => this.updateTax(product))
      }
    })

    this.creatorId = this.data.creatorId
    this.tierId = this.data.tierId

    this.isChangeMembership = this.data.isChangeMembership

    this.pictorialId = this.data.pictorialId

    if (this.tierId || this.pictorialId) {
      this.creatorService.get(this.creatorId).subscribe((res: any) => {
        this.creator = res
        if (this.pictorialProduct) {
          this.pictorialProduct.creator = res.name
        }
        if (this.membershipProduct) {
          this.membershipProduct.creator = res.name
        }
      })

      if (this.tierId) {
        this.tierService.get(this.tierId).subscribe((res: any) => {
          if (this.creator) {
            res.creator = this.creator.name
          }

          res.createdAt = moment().format(this.isKorean ? "YYYY MM DD" : "MMM DD YYYY")

          this.updateTax(res)

          this.membershipProduct = res
          this.membershipProduct.amount_usd = res.is_promotion ? 0 : res.amount_usd
        })
      }
      if (this.pictorialId) {
        this.pictorialService.get(this.pictorialId).subscribe((pictorial) => {
          pictorial.createdAt = moment().format(this.isKorean ? "YYYY MM DD" : "MMM DD YYYY")

          this.updateTax(pictorial)

          this.pictorialProduct = pictorial
          if (this.creator) {
            this.pictorialProduct.creator = this.creator.name
          }
        })
      }
    } else {
      this.isInduced = true
    }

    const url = this.tierId ? "transactions/?tier=" + this.tierId : "transactions/pictorial/"
    this.formService.getFormData(url).subscribe((form) => {
      this.form = form

      if (this.tierId) {
        this.form.formGroup.get("creator").setValue(this.creatorId)
        this.form.formGroup.get("tier").setValue(this.tierId)
        this.form.formGroup.removeControl("amount")
      }
    })

    this.billingKeyService.getOldPaymentMembership({ page_size: 30 }).subscribe((res: any) => {
      if (res.count == 0) {
        this.isStripeSetUpped = true
        this.oldProducts = []
      } else if (res.count > 0) {
        this.isStripeSetUpped = false

        this.oldProducts = res.results.map((result) => {
          if (!this.taxRate) {
            this.countryService.calTax()
            this.taxRate = this.countryService.getTax()
          }

          result.amount_usd = result.tier_next.amount_usd
            ? result.tier_next.amount_usd
            : this.currencyPipe.transform(result.tier_next.amount)
          this.updateTax(result)

          return result
        })
      }
    })
  }

  toggleTerms() {
    this.termsDisplayed = !this.termsDisplayed
    if (this.termsDisplayed) {
      document.getElementById("gen-terms-id").style.display = "block"
    } else {
      document.getElementById("gen-terms-id").style.display = "none"
    }
  }

  onSubmit() {
    this.formatBillingInfo()
  }

  onInputKeyPressed(event) {
    if (event.code == "Enter") {
      event.preventDefault()
      if (!this.submitDisabled && !this.isSaving) {
        this.onSubmit()
      }
      return
    }

    this.isSubmitPossible()
  }

  selectAllClicked(ev) {
    this.checkIsPromotionAndChangeCheckboxes()
    if (ev.target.checked) {
      this.checkboxes.forEach((term) => {
        term.checked = true
      })
      this.isSubmitPossible()
    } else {
      this.checkboxes.forEach((term) => {
        term.checked = false
      })
      this.isSubmitPossible()
    }
  }

  termClicked(newVal, id) {
    this.checkIsPromotionAndChangeCheckboxes()
    this.checkboxes[id].checked = newVal
    if (this.checkIfAllClicked()) {
      this.selectAll = true
    } else {
      this.selectAll = false
    }
    this.isSubmitPossible()
  }

  checkIsPromotionAndChangeCheckboxes() {
    if (this.membershipProduct && !this.membershipProduct.is_promotion) {
      this.checkboxes.splice(-1)
    }
  }

  checkIfAllClicked() {
    return this.checkboxes.every((term) => term.checked)
  }

  isSubmitPossible() {
    this.submitDisabled = !this.checkIfAllClicked()
  }

  // change later so we use 'app-dynamic-field-component' properly
  formatBillingInfo() {
    if (this.oldProducts && this.oldProducts.length > 0) {
      this.billingKeyService.changeToStripe().subscribe(() => {})
    }

    if (!this.isInduced) {
      this.branchTransaction()
    } else {
      this.router.navigate(
        [
          {
            outlets: {
              popup: null,
            },
          },
        ],
        {
          replaceUrl: true,
          skipLocationChange: true,
        },
      )
    }
  }

  async branchTransaction() {
    this.isSaving = true

    try {
      if (this.tierId) {
        if (this.membershipProduct.is_promotion) {
          try {
            await this.registerPromotionTier()
          } catch (error) {
            this.isSaving = false
            this.form.setErrors(error, false)
            return new Observable((observer) => {
              observer.error(error)
            })
          }
        } else {
          await this.createTransaction()
        }
      }

      if (this.pictorialId) {
        await this.createPictorialPayment()
          .pipe(
            startWith(),
            switchMap((payment: any) => {
              this.form.formGroup.value.payment_pictorial = payment.id
              this.form.formGroup.value.amount = undefined
              return this.createPictorialTransaction()
            }),
            catchError((error) => {
              this.isSaving = false
              this.form.setErrors(error, false)
              const newObservable = new Observable((observer) => {
                observer.error(error)
              })
              return newObservable
            }),
          )
          .toPromise()
      }

      this.isSaving = false
      this.closePictorialPaymentModal()
      if (this.data.type !== "pictorial") {
        if (this.isChangeMembership) {
          this.snackbarService.open(
            this.translateService.instant("CREATOR_DETAIL_PAGE.CHANGE_MEMBERSHIP_SUCCESS"),
            "",
            2500,
          )
          this.router.navigate(["creators", this.creatorId]).then(() => {
            this.router.navigate([
              { outlets: { popup: null } },
              { replaceUrl: true, skipLocationChange: true },
            ])
          })
        } else {
          this.router.navigate(["creators", this.creatorId]).then(() => {
            this.router.navigate([{ outlets: { popup: ["thank-you", this.creatorId] } }])
          })
        }
      }
    } catch (error) {
      this.isSaving = false
    }
  }

  registerPromotionTier() {
    return this.transactionService.registerPromotion(this.tierId).toPromise()
  }

  createTransaction() {
    return this.transactionService.create(this.form).toPromise()
  }

  goBack(event) {
    event.stopPropagation()
    event.preventDefault()
    this.location.back()
  }

  createPictorialTransaction() {
    const tempForm = new Form(
      {
        payment_pictorial: {
          type: "choice",
          required: true,
          read_only: false,
          label: "Payment pictorial",
          name: "payment_pictorial",
        },
      },
      this.transactionService,
      { payment_pictorial: this.form.formGroup.value.payment_pictorial },
    )
    return this.transactionService.createForPictorial(tempForm)
  }

  closePictorialPaymentModal() {
    this.isSaving = false
    this.dialogRef.close({
      status: "success",
    })
    if (this.isSendme && this.pictorialId) {
      this.snackbarService.open("구매가 완료되었습니다.")
      this.router
        .navigate(["pictorials", this.pictorialId], { skipLocationChange: true, replaceUrl: true })
        .then(() =>
          this.router.navigate([{ outlets: { popup: null } }], {
            replaceUrl: true,
            skipLocationChange: true,
          }),
        )
      return
    }
  }

  createPictorialPayment() {
    const params = {
      tier: this.pictorialProduct.tierId,
      pictorial_id: this.pictorialId,
      amount: this.pictorialProduct.price,
      amount_usd: this.pictorialProduct.priceUsd,
      creator: Number(this.creatorId),
    }

    return this.transactionService.createPictorialPayment(params)
  }

  updateTax(obj: any) {
    const result = this.countryService.getTaxIncludedPrice(obj.priceUsd || obj.amount_usd || 0)
    obj.amount_usd = result
  }
}
