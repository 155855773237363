import { NgModule } from "@angular/core"

import { BoardModule } from "../board/board.module"
import { SharedModule } from "../shared/shared.module"
import { DragDropModule } from "@angular/cdk/drag-drop"

import { CreatorRoutingModule } from "./creator-routing.module"
// tslint:disable-next-line: max-line-length
import { CreatorUpdateEarningVisibilityPageComponent } from "./pages/creator-update-earning-visibility-page/creator-update-earning-visibility-page.component"
import { CreatorDetailPageComponent } from "./pages/creator-detail-page/creator-detail-page.component"

import { TierFormComponent } from "./components/tier-form/tier-form.component"
import { CreatorListPageComponent } from "./pages/creator-list-page/creator-list-page.component"

import { CreatorListMediaComponent } from "./components/creator-list-media/creator-list-media.component"
import { TierListCardComponent } from "./components/tier-list-card/tier-list-card.component"

import { CreatorFormComponent } from "./components/creator-form/creator-form.component"
import { CreatorCreatePageComponent } from "./pages/creator-create-page/creator-create-page.component"
import { DashboardPageComponent } from "./pages/dashboard-page/dashboard-page.component"
import { TierListPageComponent } from "./pages/tier-list-page/tier-list-page.component"
import { CreatorUpdateProfilePageComponent } from "./pages/creator-update-profile-page/creator-update-profile-page.component"
import { CreatorUpdatePageComponent } from "./pages/creator-update-page/creator-update-page.component"
import { GoalFormPageComponent } from "./pages/goal-form-page/goal-form-page.component"
import { TierUpdatePageComponent } from "./pages/tier-update-page/tier-update-page.component"
import { RewardFormPageComponent } from "./pages/reward-form-page/reward-form-page.component"
// tslint:disable-next-line: max-line-length
import { MembershipRewardPageComponent } from "./pages/membership-reward-page-component/membership-reward-page.component"
import {
  MembershipRewardDetailComponent,
  MembershipRewardDetailListComponent,
} from "./pages/membership-reward-detail-component/membership-reward-detail.component"
import { MembershipListPageComponent } from "./pages/membership-list-page/membership-list-page.component"
import { GoalsSliderComponent } from "./components/goals-slider/goals-slider.component"
import { CreatorContributePageComponent } from "./pages/creator-contribute-page/creator-contribute-page.component"
import { MembershipListMediaComponent } from "./components/membership-list-media/membership-list-media.component"
import { ThankYouPageComponent } from "./components/thank-you-page/thank-you-page.component"

import { CreatorUpdateThankYouPageComponent } from "./pages/creator-update-thank-you-page/creator-update-thank-you-page.component"
import { CreatorListFatkatComponent } from "./components/creator-list-fatkat/creator-list-fatkat.component"
import { CreatorListFollowerComponent } from "./components/creator-list-follower/creator-list-follower.component"
import { FroalaEditorModule, FroalaViewModule } from "angular-froala-wysiwyg"
import { MembershipPermissionSettingsComponent } from "./pages/membership-permission-settings/membership-permission-settings.component"
import { MembershipSummaryPageComponent } from "./pages/membership-summary-page/membership-summary-page.component"
import { ComponentSidenavModule } from "./pages/component-sidenav/component-sidenav"
import { ComponentPageTitle } from "./components/page-title/page-title"
import { MembershipBlockUserPageComponent } from "./pages/membership-block-user-page/membership-block-user-page.component"
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatTableModule,
  MatTabsModule,
  MatTooltipModule,
  MatTreeModule,
} from "@angular/material"
import {
  MembershipBlockDialogComponent,
  MembershipContactCardComponent,
  MembershipDetailCardComponent,
  MembershipDetailDialogComponent,
  MembershipHistoryCardComponent,
  MembershipInfoCardComponent,
  MembershipMemoCardComponent,
} from "./components/membership-detail-card/membership-detail-card.component"
import { CommonModule, DatePipe } from "@angular/common"
import { TierCardComponent } from "./components/tier-card/tier-card.component"
import { PostDetailDialogComponent } from "../board/components/post-detail-dialog/post-detail-dialog.component"
import { CreatorPhotobookPageComponent } from "./pages/creator-photobook-page/creator-photobook-page.component"
import { PhotobookToggleButtonComponent } from "./components/photobook-toggle-button/photobook-toggle-button.component"
import { PhotobookAutocompleteComponent } from "./components/photobook-autocomplete/photobook-autocomplete.component"
import { CreatorPictorialListPageComponent } from "./pages/creator-pictorial-list-page/creator-pictorial-list-page.component"
// tslint:disable-next-line:max-line-length
import { CreatorPictorialCategoryManagementPageComponent } from "./pages/creator-pictorial-category-management-page/creator-pictorial-category-management-page.component"
// tslint:disable-next-line:max-line-length
import { CreatorPictorialModelManagementPageComponent } from "./pages/creator-pictorial-model-management-page/creator-pictorial-model-management-page.component"
import { ParameterSecurePipe, XhrImagePipe } from "src/app/core/pipe/image-secure.pipe"
import { CoreModule } from "src/app/core/core.module"
import { BuyerListPageComponent } from "./pages/buyer-list-page/buyer-list-page.component"
import {
  PictorialDetailCardComponent,
  PictorialDetailDialogComponent,
  PictorialHistoryCardComponent,
} from "./components/pictorial-detail-card/pictorial-detail-card.component"
import { SearchPictorialDropdownBoxComponent } from "./components/search-pictorial-dropdown-box/search-pictorial-dropdown-box.component"
import { NgbModule } from "@ng-bootstrap/ng-bootstrap"
import { CurrencyPipe } from "../shared/pipe/currency.pipe"
import { CreatorRadioButtonComponent } from "./components/creator-radio-button/creator-radio-button.component"
import { ShortNumberPipe } from "../shared/pipe/short-number.pipe"
import { SafeUrlPipe } from "../shared/pipe/safe-url.pipe"
import { ProfitSummaryComponent } from "./components/profit-summary/profit-summary.component"
import { CreatorSettleInfoPageComponent } from "./pages/creator-settle-info-page/creator-settle-info-page.component"
import { AngularSvgIconModule } from "angular-svg-icon"
import { ContestPictorialRegistryService } from "./services/contest-pictorial-registry.service"
import { CreatorPictorialListSubtextComponent } from "src/app/creator/components/creator-pictorial-list-subtext/creator-pictorial-list-subtext.component"
import { CreatorContentsNavComponent } from "./pages/creator-contents-nav/creator-contents-nav.component"

@NgModule({
  imports: [
    NgbModule,
    ComponentSidenavModule,
    SharedModule,
    CreatorRoutingModule,
    BoardModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatDialogModule,
    MatInputModule,
    MatChipsModule,
    MatExpansionModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatAutocompleteModule,
    DragDropModule,
    MatDatepickerModule,
    // MatNativeDateModule,
    MatRadioModule,
    MatTreeModule,
    MatTooltipModule,
    CommonModule,
    CoreModule,
    AngularSvgIconModule,
  ],
  entryComponents: [
    MembershipBlockDialogComponent,
    MembershipDetailDialogComponent,
    PostDetailDialogComponent,
    PictorialDetailDialogComponent,
  ],
  declarations: [
    CreatorUpdateEarningVisibilityPageComponent,
    CreatorDetailPageComponent,
    TierFormComponent,
    CreatorListPageComponent,
    CreatorListMediaComponent,
    TierListCardComponent,
    CreatorFormComponent,
    CreatorCreatePageComponent,
    DashboardPageComponent,
    TierListPageComponent,
    CreatorUpdateProfilePageComponent,
    CreatorUpdatePageComponent,
    GoalFormPageComponent,
    TierUpdatePageComponent,
    RewardFormPageComponent,
    MembershipRewardDetailComponent,
    MembershipRewardPageComponent,
    MembershipRewardDetailListComponent,
    MembershipListPageComponent,
    GoalsSliderComponent,
    CreatorContributePageComponent,
    MembershipListMediaComponent,
    ThankYouPageComponent,
    CreatorUpdateThankYouPageComponent,
    CreatorListFatkatComponent,
    CreatorListFollowerComponent,
    MembershipPermissionSettingsComponent,
    MembershipSummaryPageComponent,
    MembershipBlockUserPageComponent,
    MembershipDetailCardComponent,
    MembershipDetailDialogComponent,
    MembershipInfoCardComponent,
    MembershipBlockDialogComponent,
    MembershipMemoCardComponent,
    MembershipContactCardComponent,
    MembershipHistoryCardComponent,
    TierCardComponent,
    CreatorPhotobookPageComponent,
    PhotobookToggleButtonComponent,
    PhotobookAutocompleteComponent,
    CreatorPictorialListPageComponent,
    CreatorPictorialCategoryManagementPageComponent,
    CreatorPictorialModelManagementPageComponent,
    BuyerListPageComponent,
    PictorialDetailCardComponent,
    PictorialHistoryCardComponent,
    PictorialDetailDialogComponent,
    SearchPictorialDropdownBoxComponent,
    CreatorRadioButtonComponent,
    ProfitSummaryComponent,
    CreatorSettleInfoPageComponent,
    CreatorPictorialListSubtextComponent,
    CreatorContentsNavComponent,
  ],
  providers: [
    ComponentPageTitle,
    DatePipe,
    XhrImagePipe,
    ParameterSecurePipe,
    CurrencyPipe,
    ShortNumberPipe,
    SafeUrlPipe,
    ContestPictorialRegistryService,
  ],
  exports: [CreatorListMediaComponent, MembershipListMediaComponent],
})
export class CreatorModule {}
