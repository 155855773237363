import { Component, Input, OnInit } from "@angular/core"
import { CookieService } from "angular2-cookie/core"
import { CreatorService } from "../../services/creator.service"

@Component({
  selector: "app-tier-card",
  templateUrl: "./tier-card.component.html",
  styleUrls: ["../tier-list-card/tier-list-card.component.scss"],
})
export class TierCardComponent implements OnInit {
  @Input() tier: any
  @Input() isManger = false
  langFlag
  isSNSMode

  constructor(private cookieService: CookieService, private creatorService: CreatorService) {}

  ngOnInit() {
    if (this.cookieService.get("lang") !== "KOR") {
      this.langFlag = "ENG"
    } else {
      this.langFlag = "KOR"
    }

    this.creatorService.currentCreator$.subscribe((creator) => {
      this.isSNSMode = creator.creator_type == "sns"
    })
  }
}
