import { Component, OnInit, ViewEncapsulation } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import * as AOS from "aos"
import "aos/dist/aos.css"
import { AuthService } from "src/app/core/services/auth.service"

@Component({
  selector: "app-landing-page",
  templateUrl: "./landing-page.component.html",
  styleUrls: ["./landing-page.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class LandingPageComponent implements OnInit {
  constructor(private auth: AuthService, private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params["token"]) {
        this.auth.setTokensAndUpdateUser(params["token"])
        this.router.navigate(["/"])
      }
    })

    AOS.init({
      duration: 1200,
      delay: 300,
    })
  }
}
