import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Model } from "../../types"
import { KeenSliderOptions } from "keen-slider"
import { Breakpoint } from "src/app/shared/constants/constants"
import { UserService } from "../../../account/services/user.service"
import {
  META_DESCRIPTION_KEY,
  META_OG_DESCRIPTION_KEY,
  META_OG_IMAGE_ALT_KEY,
  META_OG_TITLE_KEY,
  META_TITLE_KEY,
  META_TWITTER_DESCRIPTION_KEY,
  META_TWITTER_IMAGE_ALT_KEY,
  META_TWITTER_TITLE_KEY,
  TITLE_SUFFIX,
} from "../../../shared/constants/meta-tag"
import { RecommendedModelService } from "src/app/shared/components/recommended-model-list/recommended-model-list.service"
import { Meta, Title } from "@angular/platform-browser"

@Component({
  selector: "app-model-detail-page",
  templateUrl: "./model-detail-page.component.html",
  styleUrls: ["./model-detail-page.component.scss"],
  providers: [RecommendedModelService],
})
export class ModelDetailPageComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private readonly titleService: Title,
    private readonly metaService: Meta,
    private userService: UserService,
  ) {}
  lang: string
  model: Model
  isLoading: boolean
  isAdmin = false

  sliderOptions: KeenSliderOptions = {
    breakpoints: {
      [`(min-width: ${Breakpoint["md"]})`]: {
        slides: { perView: 6, spacing: 20 },
      },
      [`(min-width: ${Breakpoint["lg"]})`]: {
        slides: { perView: 6, spacing: 36 },
      },
    },
    slides: { perView: 4, spacing: 8 },
  }

  ngOnInit() {
    this.route.data.subscribe((_) => {
      this.lang = this.route.snapshot.data.lang
      this.model = this.route.snapshot.data.model
      this.setMetaTags()
    })
    const user = this.userService.getUser()
    if (user) {
      this.isAdmin = user.is_staff || user.is_superuser
    }
  }

  setMetaTags() {
    const title = (this.model.metaTitle ? this.model.metaTitle : this.model.name) + TITLE_SUFFIX
    this.titleService.setTitle(title)
    this.metaService.updateTag({name: META_TITLE_KEY, content: title});
    this.metaService.updateTag({name: META_TWITTER_TITLE_KEY, content: title});
    this.metaService.updateTag({name: META_TWITTER_IMAGE_ALT_KEY, content: title});
    this.metaService.updateTag({property: META_OG_TITLE_KEY, content: title});
    this.metaService.updateTag({property: META_OG_IMAGE_ALT_KEY, content: title});
    if (this.model.metaDescription && this.model.metaDescription != "") {
      this.metaService.updateTag({ name: META_DESCRIPTION_KEY, content: this.model.metaDescription })
      this.metaService.updateTag({ name: META_TWITTER_DESCRIPTION_KEY, content: this.model.metaDescription })
      this.metaService.updateTag({ property: META_OG_DESCRIPTION_KEY, content: this.model.metaDescription })
    }
  }

  resetMetaTags() {
    const title = "MemberMe"
    const desc = "MemberMe is the best place for artists and creators to make a connection with their fans and expand their creativity."
    this.titleService.setTitle(title)
    this.metaService.updateTag({name: META_TITLE_KEY, content: title});
    this.metaService.updateTag({name: META_TWITTER_TITLE_KEY, content: title});
    this.metaService.updateTag({name: META_TWITTER_IMAGE_ALT_KEY, content: title});
    this.metaService.updateTag({property: META_OG_TITLE_KEY, content: title});
    this.metaService.updateTag({property: META_OG_IMAGE_ALT_KEY, content: title});
    this.metaService.updateTag({ name: META_DESCRIPTION_KEY, content: desc })
    this.metaService.updateTag({ name: META_TWITTER_DESCRIPTION_KEY, content: desc })
    this.metaService.updateTag({ property: META_OG_DESCRIPTION_KEY, content: desc })
  }

  ngOnDestroy() {
    this.resetMetaTags()
  }
}
