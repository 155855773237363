import { Pipe, PipeTransform } from "@angular/core"

@Pipe({
  name: "currencyPipe",
})
export class CurrencyPipe implements PipeTransform {
  transform(value: string): any {
    return Math.trunc(Number(value) / 10) / 100
  }
}
