import { EventEmitter, Injectable } from "@angular/core"
import { Subject } from "rxjs"
import { tap } from "rxjs/operators"
import { UserService } from "src/app/account/services/user.service"
import { AuthService } from "src/app/core/services/auth.service"
import countryCode from "src/assets/i18n/countryCode"

export interface CountryCodeType {
  code: string
  name: string
}

export type Country = {
  country: string
  postal_code?: string
}

@Injectable({
  providedIn: "root",
})
export class CountryService {
  countryCookieKey = "country"
  countryEmitter = new EventEmitter()
  countryObserver = new Subject<Country>()
  private countryCode: CountryCodeType[] = countryCode

  country: Country
  tax: number

  isLoaded = false

  constructor(private userService: UserService, private auth: AuthService) {
    this.auth.getChangeEmitter().subscribe(() => {
      const isLogin = this.auth.isLoggedIn()

      if (isLogin) {
        if (!this.isLoaded) {
          this.setup()
        }
      } else {
        this.isLoaded = false
        this.clear()
      }
    })
  }

  setup() {
    this.countryEmitter.subscribe((data) => {
      this.countryObserver.next(data)
    })
    this.userService.getCountry().subscribe((data) => {
      this.country = data

      this.isLoaded = true

      this.calTax()
      this.countryEmitter.emit(this.country)
    })
  }

  get() {
    this.countryEmitter.emit(this.country)
    return this.countryObserver
  }

  set(country, zip) {
    return this.userService.postCountry(country, zip).pipe(
      tap((data) => {
        this.country = { country, postal_code: zip }
        this.calTax()
        this.countryEmitter.emit(this.country)

        return data
      }),
    )
  }

  clear() {
    this.country = null
    this.countryEmitter.emit(this.country)
  }

  calTax() {
    const country = this.country

    if (!country) {
      this.tax = 0
      return
    }
    switch (country.country) {
      case "KR":
        this.tax = 10
        break
      case "TW":
        this.tax = 5
        break
      default:
        this.tax = 0
        break
    }
  }

  getCountryCode(code: CountryCodeType): string | undefined {
    return code ? code.name : undefined
  }

  filterByName(value: string): CountryCodeType[] {
    return this.countryCode.filter((country) =>
      country.name.toLocaleLowerCase().includes(value.toLocaleLowerCase()),
    )
  }

  filterByCode(value: string): CountryCodeType[] {
    if (!value) {
      return [{ code: "", name: "" }]
    }
    return this.countryCode.filter((country) =>
      country.code.toLocaleLowerCase().includes(value.toLocaleLowerCase()),
    )
  }

  getTax() {
    return this.tax || 0
  }

  getTaxIncludedPrice(price: number) {
    if (!!this.tax) {
      return price + (price * this.tax) / 100
    }
    return price
  }
}
