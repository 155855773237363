import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { SendmeCreateService } from "src/app/sendme/pages/sendme-create/sendme-create.service"

@Component({
  selector: "app-select-type-screen",
  templateUrl: "./select-type-screen.component.html",
  styleUrls: ["./select-type-screen.component.scss"],
})
export class SelectTypeScreenComponent implements OnInit {
  @Input() sendmeCreateService: SendmeCreateService
  @Output() toNextPage = new EventEmitter()

  constructor() {}

  ngOnInit() {}

  selectType(type: "post" | "pictorial") {
    this.sendmeCreateService.sendmeType = type
    this.sendmeCreateService.isFree = null
    if (this.developmentPaidPost) {
      this.selectFree(true)
    }
  }

  selectFree(isFree: boolean) {
    this.sendmeCreateService.isFree = isFree
  }

  onClickNext() {
    this.toNextPage.emit(null)
  }

  get sendmeType() {
    return this.sendmeCreateService.sendmeType
  }

  get isFree() {
    return this.sendmeCreateService.isFree
  }

  get nextButtonDisabled() {
    return this.sendmeType === null || this.isFree === null
  }

  get isSNSCreator() {
    return this.sendmeCreateService.creator.creator_type === "sns"
  }

  get developmentPaidPost() {
    return this.sendmeType === "post"
  }
}
