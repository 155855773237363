import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChange,
  SimpleChanges,
} from "@angular/core"
import { CookieService } from "angular2-cookie/core"
import * as moment from "moment"
import { Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material"
import { MessagingComponent } from "src/app/shared/components/messaging/messaging.component"
import { NotificationService } from "src/app/notification/services/notification.service"
import { MembershipService } from "../../services/membership.service"
import { Form } from "src/app/shared/model/form"
import { FormService } from "src/app/shared/services/form.service"
import { UserService } from "src/app/account/services/user.service"
import { SnackbarService } from "src/app/shared/services/snackbar.service"
import { Location } from "@angular/common"
import { User } from "src/app/account/types/user.type"
import { tap } from "rxjs/operators"
import { Pagination } from "src/app/shared/types/pagination.type"
import { Util } from "src/app/shared/utils/utils"

@Component({
  selector: "app-membership-detail-card",
  templateUrl: "./membership-detail-card.component.html",
  styleUrls: ["./membership-detail-card.component.scss"],
})
export class MembershipDetailCardComponent implements OnInit, OnChanges {
  @Output() memberChanged = new EventEmitter<boolean>()
  @Input() selectedMembership: any
  langFlag: string
  dayBefore: string
  loading = false
  user: User
  currentMembership: any

  isKorean

  constructor(private cookieService: CookieService, private membershipService: MembershipService) {}

  ngOnInit() {
    this.isKorean = this.cookieService.get("lang") === "KOR"
    moment.locale(this.isKorean ? "ko" : "en")
  }

  ngOnChanges(changes: SimpleChanges): void {
    const membership: SimpleChange = changes.selectedMembership
    this.loadMembershipDetail(membership.currentValue)
  }

  userBlocked(isBlocked) {
    if (isBlocked) {
      this.memberChanged.emit(true)
    }
  }

  private loadMembershipDetail(membership: any) {
    if (membership && membership.id) {
      this.currentMembership = membership
      this.user = membership.user
      this.loading = true
      this.membershipService
        .getPaymentHistorySince(this.currentMembership.id)
        .subscribe((since) => {
          this.loading = false
          this.dayBefore = moment(
            since["date_begin"],
            this.isKorean ? "YYYY-MM-DD" : "MMM DD YYYY",
          ).fromNow()
        })
    }
  }
}

@Component({
  selector: "app-membership-detail-dialog",
  templateUrl: "./membership-detail-dialog.component.html",
})
export class MembershipDetailDialogComponent {
  selectedMembership: any

  constructor(
    public dialogRef: MatDialogRef<MembershipDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.selectedMembership = data["selectedMembership"]
  }

  onCloseClick(): void {
    this.dialogRef.close()
  }

  memberChanged($memberChanged: boolean) {
    this.dialogRef.close($memberChanged)
  }
}

@Component({
  selector: "app-membership-info-card",
  templateUrl: "./membership-info-card.component.html",
  styleUrls: ["./membership-detail-card.component.scss"],
})
export class MembershipInfoCardComponent extends MessagingComponent implements OnInit {
  @Output() userBlocked = new EventEmitter<boolean>()
  @Input() tier: any
  @Input() user: any
  @Input() loading: boolean
  @Input() dayBefore: string
  blocked_reason: string
  name: string

  constructor(
    public dialog: MatDialog,
    private membershipService: MembershipService,
    protected snackbarService: SnackbarService,
    protected translateService: TranslateService,
  ) {
    super(snackbarService, translateService, dialog)
  }

  ngOnInit() {}

  openBlockDialog(): void {
    this.openDialog(
      {
        width: "500px",
        maxWidth: "90vw",
        data: { name: this.name, blocked_reason: this.blocked_reason },
      },
      (result: any) => {
        if (result && result.isBlock) {
          this.blocked_reason = result.reason
          this.membershipService
            .blockMember(this.tier.creator, this.user.id, this.blocked_reason)
            .subscribe(() => {
              this.userBlocked.emit(true)
              this.messageSuccess(this.translate("MEMBERSHIP_MANAGEMENT.BLOCK_SUCCESS_MESSAGE"))
            })
        }
      },
      MembershipBlockDialogComponent,
    )
  }
}

@Component({
  selector: "app-membership-block-dialog",
  templateUrl: "membership-block-dialog.component.html",
})
export class MembershipBlockDialogComponent {
  form: Form

  constructor(
    public dialogRef: MatDialogRef<MembershipBlockDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  onNoClick(): void {
    this.dialogRef.close()
  }
}

@Component({
  selector: "app-membership-contact-card",
  templateUrl: "./membership-contact-card.component.html",
  styleUrls: ["./membership-detail-card.component.scss"],
})
export class MembershipContactCardComponent extends MessagingComponent implements OnInit {
  @Input() user: User
  @Input() loading: boolean
  REQ_TYPE = {
    ADDRESS: "ADDRESS",
    PHONE: "PHONE",
  }

  isKorean

  constructor(
    private notificationService: NotificationService,
    private cookieService: CookieService,
    protected snackbarService: SnackbarService,
    protected translateService: TranslateService,
    protected dialog: MatDialog,
  ) {
    super(snackbarService, translateService, dialog)
  }

  ngOnInit() {
    this.isKorean = this.cookieService.get("lang") === "KOR"
  }

  requestAddress() {
    this.openDialog(
      {
        width: "400px",
        maxWidth: "90vw",
        data: {
          message: this.translate("MEMBERSHIP_MANAGEMENT.REQUEST_ADDRESS"),
        },
      },
      (result) => !result || this.saveNotification(this.REQ_TYPE.ADDRESS),
    )
  }

  requestPhoneNumber() {
    this.openDialog(
      {
        width: "400px",
        maxWidth: "90vw",
        data: {
          message: this.translate("MEMBERSHIP_MANAGEMENT.REQUEST_PHONE"),
        },
      },
      (result) => !result || this.saveNotification(this.REQ_TYPE.PHONE),
    )
  }

  saveNotification(requestType: string) {
    this.notificationService
      .saveRequestContact({
        userId: this.user.id,
        type: requestType,
      })
      .subscribe((res) => {
        if (res.status === 201) {
          this.messageSuccess("Successfully requested")
        } else {
          this.messageInfo("Already requested")
        }
      })
  }
}

@Component({
  selector: "app-membership-memo-card",
  templateUrl: "./membership-memo-card.component.html",
  styleUrls: ["./membership-detail-card.component.scss"],
})
export class MembershipMemoCardComponent implements OnInit, OnChanges {
  @Input() user: User
  @Input() currentMembership: any
  @Input() loading: boolean
  @Output() submitted = new EventEmitter<void>()
  form: Form
  fields = {
    additional_detail: {
      type: "string",
      required: false,
      read_only: false,
      label: "Additional Detail",
      max_length: 300,
      style: {
        base_template: "textarea.html",
      },
      widget: {
        name: "textarea",
        attrs: {
          rows: 5,
          maxlength: 300,
        },
      },
      name: "additional_detail",
    },
  }
  langFlag

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private formService: FormService,
    private userService: UserService,
    private membershipService: MembershipService,
    private cookieService: CookieService,
  ) {
    this.user = this.getUser()
  }

  ngOnInit() {
    if (this.cookieService.get("lang") === "KOR") {
      this.langFlag = "KOR"
    } else {
      this.langFlag = "ENG"
    }
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    if (changes.hasOwnProperty("currentMembership")) {
      const data = {}
      if (changes.currentMembership.currentValue.member_management) {
        data["additional_detail"] = this.currentMembership.member_management.additional_detail
      }
      this.initForm(data)
    }
  }

  initForm(data: any) {
    this.form = this.formService.getFormFromFields(this.fields, data)
  }

  getUser() {
    return this.userService.getUser()
  }

  detailChanged($additionalDetail) {
    this.membershipService
      .noteMember(this.currentMembership.creator.id, this.user.id, $additionalDetail)
      .subscribe(() => {
        if (!this.currentMembership.member_management) {
          this.currentMembership.member_management = {}
        }
        this.currentMembership.member_management.additional_detail = $additionalDetail
      })
  }
}

@Component({
  selector: "app-membership-history-card",
  templateUrl: "./membership-history-card.component.html",
  styleUrls: ["./membership-detail-card.component.scss"],
})
export class MembershipHistoryCardComponent implements OnInit, OnChanges {
  @Input() user: User
  @Input() membershipId: number
  @Input() loading: boolean
  paginatedResult$
  displayedColumns: string[] = ["name", "date_end", "amount", "status"]
  langFlag
  page: number = 1
  pageUrl: string

  constructor(
    private cookieService: CookieService,
    private membershipService: MembershipService,
    private location: Location,
  ) {}

  ngOnInit() {
    if (this.cookieService.get("lang") === "KOR") {
      this.langFlag = "KOR"
      moment.locale("ko")
    } else {
      this.langFlag = "ENG"
      moment.locale("en")
    }
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    if (changes.hasOwnProperty("membershipId")) {
      this.getPaginatedResult()
    }
  }

  getPaginatedResult() {
    this.page = 1
    this.paginatedResult$ = this.membershipService
      .getPaymentHistoryListPaginated(this.membershipId, { page_size: 5 })
      .pipe(
        tap((data: Pagination<any>) => {
          this.pageUrl = data.next || data.previous
        }),
      )
  }

  getPaginatedResultByUrl(pageNo: number) {
    if (this.pageUrl) {
      const url = Util.getPaginationUrl(this.pageUrl, pageNo)

      this.page = pageNo
      this.paginatedResult$ = this.membershipService.getListPaginatedByUrl(url).pipe(
        tap((data: Pagination<any>) => {
          this.pageUrl = data.next || data.previous
        }),
      )
    }
  }
}
