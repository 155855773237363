import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { tap } from "rxjs/operators"
import { environment } from "src/environments/environment"
import { Form } from "../../shared/model/form"
import { EventService } from "../../core/services/event.service"
import { Country } from "src/app/shared/services/country.service"
import { NotificationConfig, User } from "../types/user.type"
import { GetUserTagResp, Tag } from "../types/tag.type"
import { GetActiveTagParams, PostUserTagResponse, PostUserTagPayload } from "../types/ajax.type"

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(private http: HttpClient, private event: EventService) {}

  get(id) {
    return this.http.get<User>(environment.apiUrl + "users/" + id + "/")
  }

  getCognitoToken(authToken: string) {
    return this.http.get(`${environment.apiUrl}users/cognito-auth/`, {
      headers: {
        Authorization: `Token ${authToken}`,
      }
    })
  }

  refreshCognitoToken(refreshToken: string) {
    return this.http.put(`${environment.apiUrl}users/cognito-refresh/`, {
      refreshToken: refreshToken,
    })
  }

  updateUser() {
    return this.updateUser$().subscribe()
  }

  updateUser$() {
    return this.get("me").pipe(
      tap(
        (user) => {
          localStorage.setItem("user", JSON.stringify(user))
          this.event.onUserUpdate$.emit()
        },
        (error) => {
          localStorage.removeItem("token")
          localStorage.removeItem("user")
          this.event.onUserUpdate$.emit()
        },
      ),
    )
  }

  getUser() {
    return JSON.parse(localStorage.getItem("user"))
  }

  create(form: Form) {
    const data = form.formGroup.value
    return this.http.post(`${environment.apiUrl}users/`, data).pipe(
      tap(
        (res) => form.clearErrors(),
        (error) => form.setErrors(error),
      ),
    )
  }

  update(form: Form) {
    const data = form.formGroup.value
    return this.http.put(`${environment.apiUrl}users/me/`, data).pipe(
      tap(
        (res) => {
          form.clearErrors()
          this.updateUser()
        },
        (error) => form.setErrors(error),
      ),
    )
  }

  changePassword(form: Form) {
    const data = form.formGroup.value
    return this.http.post(`${environment.apiUrl}users/me/change_password/`, data).pipe(
      tap(
        (res) => form.clearErrors(),
        (error) => form.setErrors(error),
      ),
    )
  }

  updateMyNotifications(data: NotificationConfig[]) {
    return this.http.post(`${environment.apiUrl}users/me/notifications/`, data)
  }

  getMyNotificationConfig() {
    return this.http.get<NotificationConfig[]>(`${environment.apiUrl}users/me/notifications/`)
  }

  checkPasswordResetEmail(form: Form) {
    const data = form.formGroup.value
    return this.http.post(`${environment.apiUrl}users/email/check/`, data).pipe(
      tap(
        (res) => form.clearErrors(),
        (error) => form.setErrors(error),
      ),
    )
  }

  sendPasswordResetEmail(form: Form) {
    const data = form.formGroup.value
    return this.http.post(`${environment.apiUrl}users/password/reset/`, data).pipe(
      tap(
        (res) => form.clearErrors(),
        (error) => form.setErrors(error, false),
      ),
    )
  }

  resetPassword(form: Form) {
    const data = form.formGroup.value
    return this.http.post(`${environment.apiUrl}users/password/reset/confirm/`, data).pipe(
      tap(
        (res) => form.clearErrors(),
        (error) => form.setErrors(error),
      ),
    )
  }

  sendActivationEmail() {
    return this.http.post(`${environment.apiUrl}users/activate/`, {})
  }

  activate(form: Form) {
    const data = form.formGroup.value
    return this.http.post(`${environment.apiUrl}users/activate/confirm/`, data).pipe(
      tap(
        (res) => form.clearErrors(),
        (error) => form.setErrors(error),
      ),
    )
  }

  delete() {
    return this.http.delete(`${environment.apiUrl}users/me/`)
  }

  resetUserImage(form: Form) {
    const data = form.formGroup.value
    return this.http.patch(`${environment.apiUrl}users/me/profile_image_reset/`, data).pipe(
      tap(
        (res) => form.clearErrors(), // 폼 에러 없애기
        (error) => form.setErrors(error), // 에러가 발생할 경우 폼에 표시
      ),
    )
  }

  createPhoneVerification(phoneNumber: string) {
    const data = {
      phone: phoneNumber,
    }
    return this.http.post(`${environment.apiUrl}users/phone-verification/`, data)
  }

  checkPhoneVerification(phoneNumber: string, verificationNumber: number) {
    const data = {
      phone: phoneNumber,
      verification_num: `${verificationNumber}`,
    }
    return this.http.post(`${environment.apiUrl}users/phone-verification/exist/`, data)
  }

  verifyAdult(isOverseas: boolean, encodeData?: string) {
    const data = {
      encode_data: encodeData,
      is_overseas: isOverseas,
    }
    return this.http.post(`${environment.apiUrl}users/me/adult/`, data)
  }

  getCountry() {
    return this.http.get<Country>(`${environment.apiUrl}users/me/country/`)
  }

  postCountry(country: string, postal_code?: string) {
    return this.http.post(`${environment.apiUrl}users/me/country/`, { country, postal_code })
  }
  getTagsMine() {
    return this.http.get<GetUserTagResp>(`${environment.apiUrl}users/me/tags/`)
  }
  getTagsList(params?: GetActiveTagParams) {
    return this.http.get<Tag[]>(`${environment.apiUrl}users/tags/`, { params })
  }
  updateUserTags(payload: PostUserTagPayload) {
    return this.http.post<PostUserTagResponse>(`${environment.apiUrl}users/me/tags/`, payload)
  }
}
