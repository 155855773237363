import { Component, OnInit, Renderer2 } from "@angular/core"
import { ModalPageComponent } from "../../../shared/components/modal-page/modal-page.component"
import { ActivatedRoute, ParamMap, Router } from "@angular/router"
import { switchMap } from "rxjs/operators"
import { CreatorService } from "../../services/creator.service"
import { PostService } from "../../../board/services/post.service"

import { CookieService } from "angular2-cookie/core"

@Component({
  selector: "app-thank-you-page",
  templateUrl: "./thank-you-page.component.html",
  styleUrls: ["./thank-you-page.component.scss"],
})
export class ThankYouPageComponent extends ModalPageComponent implements OnInit {
  creator$
  post$
  post
  editorContent
  //
  loaded = false
  langFlag

  constructor(
    renderer: Renderer2,
    router: Router,
    private route: ActivatedRoute,
    private creatorService: CreatorService,
    private postService: PostService,
    private cookieService: CookieService,
  ) {
    super(renderer, router)
  }

  ngOnInit() {
    if (this.cookieService.get("lang") !== "KOR") {
      this.langFlag = "ENG"
    } else {
      this.langFlag = "KOR"
    }

    this.creator$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        const creatorId = params.get("creatorId")
        // this.post$ = this.postService.get(`${creatorId}/thank_you`);
        this.post$ = this.postService.get(`${creatorId}/thank_you`).subscribe(
          (result) => {
            this.post = result
            this.editorContent = this.post.content
            this.loaded = true
          },
          (error) => {
            this.loaded = true
          },
        )
        //
        return this.creatorService.get(creatorId)
      }),
    )
  }
}
