import { Component, OnInit } from "@angular/core"
import { UnreadMessageObserver } from "../../services/unread-message-observer.service"
import { NavigationEnd, Router } from "@angular/router"
import { BreakpointObserver } from "@angular/cdk/layout"
import { Breakpoint } from "../../constants/constants"
import { BreakpointObserverService } from "../../services/breakpoint-observer.service"
@Component({
  selector: "app-sendme-gnb-noti",
  templateUrl: "./sendme-gnb-noti.component.html",
  styleUrls: ["./sendme-gnb-noti.component.scss"],
})
export class SendmeGnbNotiComponent implements OnInit {
  hasUnread: boolean = false
  isMobile: boolean = false

  unReadCount: number = 0

  constructor(
    private breakpointObserver: BreakpointObserverService,
    private unreadObserver: UnreadMessageObserver,
    private router: Router,
  ) {}

  ngOnInit() {
    this.unreadObserver.unreadMsgEvent.subscribe((hasUnreadCount: number) => {
      this.unReadCount = hasUnreadCount
      this.hasUnread = hasUnreadCount > 0 && this.router.url == "/dashboard"
    })

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.hasUnread = this.unreadObserver.hasUnread
        this.hasUnread = this.hasUnread && event.url == "/dashboard"
      }
    })

    const bpMd = this.breakpointObserver.maxWidth(Breakpoint.md)
    this.breakpointObserver.observe([bpMd]).subscribe((result) => {
      this.isMobile = result.matches
    })
  }

  onClickNavigate() {
    this.router.navigate(["/sendme/inbox/unread"])
  }
}
