import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { environment } from "src/environments/environment"
import { CreatorSettleInfo } from "../pages/creator-settle-info-page/type"

@Injectable({
  providedIn: "root",
})
export class CreatorAccountInformationService {
  constructor(private http: HttpClient) {}

  get() {
    return this.http.get<CreatorSettleInfo>(
      environment.apiUrl + "creator-account-information/mine/",
    )
  }

  update(id: number, payload: CreatorSettleInfo) {
    return this.http.put<CreatorSettleInfo>(
      environment.apiUrl + "creator-account-information/".concat(id.toString(), "/"),
      payload,
    )
  }
  create(payload: CreatorSettleInfo) {
    return this.http.post<CreatorSettleInfo>(
      environment.apiUrl + "creator-account-information/",
      payload,
    )
  }

  delete(id: number) {
    return this.http.delete(
      environment.apiUrl + "creator-account-information/".concat(id.toString(), "/"),
    )
  }
}
