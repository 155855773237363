import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { UserService } from "../../account/services/user.service"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"

@Injectable({
  providedIn: "root",
})
export class CreatorRequiredGuardService {
  constructor(private router: Router, private userService: UserService) {}

  canActivate(): Observable<boolean> | boolean {
    return this.userService.get("me").pipe(
      map((res) => {
        return !!res["creator"]
      }),
    )
  }
}
