import { NgModule } from "@angular/core"

import { AccountRoutingModule } from "./account-routing.module"
import { UserCreatePageComponent } from "./pages/user-create-page/user-create-page.component"
import { SharedModule } from "../shared/shared.module"
import { UserUpdatePageComponent } from "./pages/user-update-page/user-update-page.component"
import { UserUpdateProfilePageComponent } from "./pages/user-update-profile-page/user-update-profile-page.component"
import { UserUpdateNotificationPageComponent } from "./pages/user-update-notification-page/user-update-notification-page.component"
import { UserUpdateAccountPageComponent } from "./pages/user-update-account-page/user-update-account-page.component"
import { MembershipPageComponent } from "./pages/membership-page/membership-page.component"
import { MembershipCancelPageComponent} from './pages/membership-cancel-page/membership-cancel-page.component';
import { CreatorModule } from "../creator/creator.module"
import { PaymentModule } from "../payment/payment.module"
import { LoginPageComponent } from "./pages/login-page/login-page.component"
import { PasswordResetPageComponent } from "./pages/password-reset-page/password-reset-page.component"
import { PasswordResetConfirmPageComponent } from "./pages/password-reset-confirm-page/password-reset-confirm-page.component"
import { UserActivatePageComponent } from "./pages/user-activate-page/user-activate-page.component"
import { UserActivateConfirmPageComponent } from "./pages/user-activate-confirm-page/user-activate-confirm-page.component"
import { SocialLoginErrorPageComponent } from "./pages/social-login-error-page/social-login-error-page.component"
import { UserPhoneVerificationComponent } from "./components/user-phone-verification/user-phone-verification.component"
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatInputModule,
  MatTabsModule,
  MatCheckboxModule,
} from "@angular/material"
import { UserAdultVerifyModalPageComponent } from "./pages/user-adult-verify-modal-page/user-adult-verify-modal-page.component"
import { UserAdultVerifyPageComponent } from "./pages/user-adult-verify-page/user-adult-verify-page.component"
import { UserAdultVerifyResultPageComponent } from "./pages/user-adult-verify-result-page/user-adult-verify-result-page.component"
import { TagSelectDialogComponent } from "./components/tag-select-dialog/tag-select-dialog.component"

@NgModule({
  imports: [
    SharedModule,
    AccountRoutingModule,
    CreatorModule,
    PaymentModule,
    MatInputModule,
    MatButtonModule,
    MatTabsModule,
    MatAutocompleteModule,
    MatCheckboxModule,
  ],
  entryComponents: [TagSelectDialogComponent],
  declarations: [
    UserCreatePageComponent,
    UserUpdatePageComponent,
    UserUpdateProfilePageComponent,
    UserUpdateNotificationPageComponent,
    UserUpdateAccountPageComponent,
    MembershipPageComponent,
    MembershipCancelPageComponent,
    LoginPageComponent,
    PasswordResetPageComponent,
    PasswordResetConfirmPageComponent,
    UserAdultVerifyPageComponent,
    UserActivatePageComponent,
    UserActivateConfirmPageComponent,
    SocialLoginErrorPageComponent,
    UserPhoneVerificationComponent,
    UserAdultVerifyResultPageComponent,
    UserAdultVerifyModalPageComponent,
    TagSelectDialogComponent,
  ],
})
export class AccountModule {}
