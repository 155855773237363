import { NgModule } from "@angular/core"
import { Routes, RouterModule } from "@angular/router"
import { LandingPageComponent } from "./pages/landing-page/landing-page.component"
import { LandingPageGuardService } from "./guards/landing-page-guard.service"

import { CollectTermsPageComponent } from "./pages/collect-terms-page/collect-terms-page.component"
import { ProvideTermsPageComponent } from "./pages/provide-terms-page/provide-terms-page.component"
import { ContentPolicyPageComponent } from "./pages/content-policy-page/content-policy-page.component"
import { ServicePlanPageComponent } from "./pages/service-plan-page/service-plan-page.component"
import { CommunityGuidelinePageComponent } from "./pages/community-guideline-page/community-guideline-page.component"
import { BenefitGuidelinePageComponent } from "./pages/benefit-guideline-page/benefit-guideline-page.component"
import { CopyrightPageComponent } from "./pages/copyright-page/copyright-page.component"
import { SecurityPageComponent } from "./pages/security-page/security-page.component"
import { CookiePageComponent } from "./pages/cookie-page/cookie-page.component"
import { AgeverificationPageComponent } from "./pages/ageverification-page/ageverification-page.component"
import { DeleteAccountRequestPageComponent } from "./pages/delete-account-request-page/delete-account-request-page.component"

const routes: Routes = [
  {
    path: "",
    component: LandingPageComponent,
    canActivate: [LandingPageGuardService],
    runGuardsAndResolvers: "always",
  },

  { path: "collectterms", component: CollectTermsPageComponent },
  { path: "provideterms", component: ProvideTermsPageComponent },
  { path: "contentpolicy", component: ContentPolicyPageComponent },
  { path: "service-plan", component: ServicePlanPageComponent },
  { path: "communityguildelines", component: CommunityGuidelinePageComponent },
  { path: "benefitguildelines", component: BenefitGuidelinePageComponent },
  { path: "copyright", component: CopyrightPageComponent },
  { path: "security", component: SecurityPageComponent },
  { path: "cookiepolicy", component: CookiePageComponent },
  { path: "ageverification", component: AgeverificationPageComponent },
  { path: "delete-account-request", component: DeleteAccountRequestPageComponent },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class WebsiteRoutingModule {}
