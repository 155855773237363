import { Component, OnInit } from "@angular/core"

@Component({
  selector: "app-service-plan-page",
  templateUrl: "./service-plan-page.component.html",
  styleUrls: ["./service-plan-page.component.scss"],
})
export class ServicePlanPageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
