import { Component, Input, OnDestroy, OnInit } from "@angular/core"
import { MatIconRegistry } from "@angular/material"
import { DomSanitizer } from "@angular/platform-browser"
import { Router } from "@angular/router"
import { Subscription } from "rxjs"
import { UnreadMessageObserver } from "../../services/unread-message-observer.service"
import _ from "lodash"
import { AuthService } from "src/app/core/services/auth.service"

@Component({
  selector: "app-sendme-mail-btn",
  templateUrl: "./sendme-mail-btn.component.html",
  styleUrls: ["./sendme-mail-btn.component.scss"],
})
export class SendmeMailBtnComponent implements OnInit, OnDestroy {
  @Input() fixedButton: boolean = true
  @Input() renderBackground: boolean = true
  @Input() callWatchFunc: boolean = false
  private eventSub: Subscription

  hasUnread: boolean = false

  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private router: Router,
    private authService: AuthService,
    private unreadObserver: UnreadMessageObserver,
  ) {
    iconRegistry.addSvgIcon(
      "mail",
      sanitizer.bypassSecurityTrustResourceUrl("assets/img/icon/mail.svg"),
    )
  }

  ngOnInit() {
    this.authService.getChangeEmitter().subscribe(() => {
      if (!this.authService.isLoggedIn()) {
        this.hasUnread = false
      }
    })

    if (this.callWatchFunc) {
      this.unreadObserver.watch()
    } else {
      _.delay(() => {
        this.hasUnread = this.unreadObserver.hasUnread
      }, 10)
    }

    this.eventSub = this.unreadObserver.unreadMsgEvent.subscribe((hasUnreadCount: number) => {
      this.hasUnread = hasUnreadCount > 0
    })
  }

  ngOnDestroy(): void {
    this.eventSub.unsubscribe()
  }

  onClick() {
    if (this.hasUnread) {
      this.router.navigate(["/sendme/inbox/unread"])
    } else {
      this.router.navigate(["/sendme/inbox/read"])
    }
  }
}
