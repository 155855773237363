import { EventEmitter, Injectable } from "@angular/core"
import { environment } from "../../../environments/environment"
import { HttpClient } from "@angular/common/http"
import { catchError, map, tap } from "rxjs/operators"
import { Form } from "../../shared/model/form"
import { UserService } from "../../account/services/user.service"
import { Observable } from "rxjs"
import { Router } from "@angular/router"
import { Pagination } from "src/app/shared/types/pagination.type"
import { Creator, CreatorPictorialInformation, CreatorSearchParams } from "../types"

export interface RecommendedCreatorParams {
  sort?: "random" | "order" | "follower"
  count?: number
}

@Injectable({
  providedIn: "root",
})
export class CreatorService {
  currentCreator
  currentCreator$
  change = new EventEmitter()

  constructor(private userService: UserService, private http: HttpClient, private router: Router) {}

  emitChangeEvent(s) {
    this.change.emit(s)
  }
  getChangeEmitter() {
    return this.change
  }

  get(idOrCreatorUrl: number | string, error404 = true): Observable<Creator> {
    const observable = this.http.get<Creator>(
      environment.apiUrl + "creators/" + idOrCreatorUrl + "/",
    )
    this.currentCreator$ = observable
    observable.subscribe((res) => {
      this.currentCreator = res
    })
    return observable.pipe<Creator, any>(
      map((res) => res),
      catchError((err) => {
        if (err.status === 404 && error404) {
          setTimeout(() => {
            this.router.navigateByUrl("/404", { skipLocationChange: true, replaceUrl: true })
          })
        }
        return new Observable((observer) => observer.next(err))
      }),
    )
  }

  getPictorialInformation(id: number) {
    return this.http.get<CreatorPictorialInformation>(environment.pictorialApiUrl + "creators/" + id)
  }

  create(form: Form) {
    const data = form.formGroup.value
    return this.http.post(environment.apiUrl + "creators/", data).pipe(
      tap(
        (res) => {
          this.userService.updateUser()
          form.clearErrors()
        },
        (error) => form.setErrors(error),
      ),
    )
  }

  update(form: Form) {
    const data = form.formGroup.value
    return this.http.put(environment.apiUrl + "creators/me/", data).pipe(
      tap(
        (res) => {
          this.userService.updateUser()
          form.clearErrors()
        },
        (error) => form.setErrors(error),
      ),
    )
  }

  updatePartial(form: Form) {
    const data = form.formGroup.value
    return this.http.patch(environment.apiUrl + "creators/me/", data).pipe(
      tap(
        (res) => form.clearErrors(),
        (error) => form.setErrors(error),
      ),
    )
  }
  updatePartialFromObject(data) {
    return this.http.patch(environment.apiUrl + "creators/me/", data)
  }

  getListPaginated(data) {
    return this.http.get<Pagination<any>>(environment.apiUrl + "creators/", { params: data })
  }

  getListPaginatedRecommended(data: RecommendedCreatorParams) {
    return this.http.get<{ order: number; creator: Creator }[]>(
      environment.apiUrl + "creators/recommended/",
      {
        params: data as any,
      },
    )
  }

  getListPaginatedByUrl(url) {
    return this.http.get<Pagination<any>>(url)
  }

  search(params: CreatorSearchParams) {
    const observable = this.http.get(`${environment.apiUrl}creators/search/`, { params })
    return params.pagination
      ? (observable as Observable<Pagination<Creator>>)
      : (observable as Observable<Creator[]>)
  }
}
