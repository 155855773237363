import { Component, OnInit } from "@angular/core"
import { UserService } from "../../services/user.service"
import { NotificationConfig } from "src/app/account/types/user.type"
import { SnackbarService } from "src/app/shared/services/snackbar.service"
import { TranslateService } from "@ngx-translate/core"

@Component({
  selector: "app-user-update-notification-page",
  templateUrl: "./user-update-notification-page.component.html",
  styleUrls: ["./user-update-notification-page.component.scss"],
})
export class UserUpdateNotificationPageComponent implements OnInit {
  notificationConfigs: NotificationConfig[]

  constructor(
    private userService: UserService,
    protected snackbarService: SnackbarService,
    protected translate: TranslateService,
  ) {}

  ngOnInit() {
    this.userService.getMyNotificationConfig().subscribe((data: NotificationConfig[]) => {
      this.notificationConfigs = data
    })
  }

  async saveNotificationSettings() {
    await this.userService.updateMyNotifications(this.notificationConfigs).toPromise()
    this.snackbarService.open(
      this.translate.instant("ALERT_CONFIRM.SAVED"),
      "",
    )
  }
}
