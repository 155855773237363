import { NgModule } from "@angular/core"

import { BoardRoutingModule } from "./board-routing.module"
import { ArticleCategoryListPageComponent } from "./pages/article-category-list-page/article-category-list-page.component"
import { ArticleListPageComponent } from "./pages/article-list-page/article-list-page.component"
import { ArticleDetailPageComponent } from "./pages/article-detail-page/article-detail-page.component"
import { PostListCardComponent } from "./components/post-list-card/post-list-card.component"
import { PostDetailCardComponent } from "./components/post-detail-card/post-detail-card.component"
import { PostDetailDialogComponent } from "./components/post-detail-dialog/post-detail-dialog.component"
import { PostFormCreatorCardComponent } from "./components/post-form-creator-card/post-form-creator-card.component"
import { SharedModule } from "../shared/shared.module"
import { ArticleListCreatorPageComponent } from "./pages/article-list-creator-page/article-list-creator-page.component"
import { CommentFormComponent } from "./components/comment-form/comment-form.component"
import { CommentDetailComponent } from "./components/comment-detail/comment-detail.component"
import { ArticleListSidebarComponent } from "./components/article-list-sidebar/article-list-sidebar.component"
import { PostFormContributorCardComponent } from "./components/post-form-contributor-card/post-form-contributor-card.component"
import { FroalaEditorModule, FroalaViewModule } from "angular-froala-wysiwyg"
import { CurrencyPipe, DatePipe } from "@angular/common"
import {
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatNativeDateModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatTabsModule,
} from "@angular/material"
import { NgbModule } from "@ng-bootstrap/ng-bootstrap"
import { PictorialCardComponent } from "./components/pictorial-card/pictorial-card.component"
import { ShortNumberPipe } from "../shared/pipe/short-number.pipe"
import { PhotobookContentsTapComponent } from "./components/photobook-contents-tap/photobook-contents-tap.component"
import { PhotobookLoginInducerComponent } from "./components/photobook-login-inducer/photobook-login-inducer.component"
import { PostCardRendererComponent } from "./components/post-card-renderer/post-card-renderer.component"
import { AngularSvgIconModule } from "angular-svg-icon"
import { PictorialThumbnailComponent } from "./components/pictorial-thumnail/pictorial-thumbnail.component"
import { PictorialDetailPageComponent } from "./pages/pictorial-detail-page/pictorial-detail-page.component"
import { PictorialDetailPageService } from "./pages/pictorial-detail-page/pictorial-detail-page.service"
import { PictorialInfoComponent } from "./pages/pictorial-detail-page/components/pictorial-info/pictorial-info.component"
import { PictorialCommentsComponent } from "./pages/pictorial-detail-page/components/pictorial-comments/pictorial-comments.component"
import { PictorialCommentDetailComponent } from "./pages/pictorial-detail-page/components/pictorial-comment-detail/pictorial-comment-detail.component"
import { PictorialCommentFormComponent } from "./pages/pictorial-detail-page/components/pictorial-comment-form/pictorial-comment-form.component"
import { FormsModule } from "@angular/forms"

@NgModule({
  imports: [
    SharedModule,
    BoardRoutingModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    MatFormFieldModule,
    MatChipsModule,
    MatIconModule,
    MatButtonModule,
    MatProgressBarModule,
    MatRadioModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatInputModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatListModule,
    MatTabsModule,
    NgbModule,
    MatProgressSpinnerModule,
    AngularSvgIconModule,
    FormsModule,
  ],
  declarations: [
    ArticleCategoryListPageComponent,
    ArticleListPageComponent,
    ArticleDetailPageComponent,
    PostListCardComponent,
    PostDetailDialogComponent,
    PostDetailCardComponent,
    PostFormCreatorCardComponent,
    PostFormContributorCardComponent,
    ArticleListCreatorPageComponent,
    CommentFormComponent,
    CommentDetailComponent,
    ArticleListSidebarComponent,
    PictorialCardComponent,
    PictorialThumbnailComponent,
    PhotobookContentsTapComponent,
    PhotobookLoginInducerComponent,
    PostCardRendererComponent,
    PictorialDetailPageComponent,
    PictorialInfoComponent,
    PictorialCommentsComponent,
    PictorialCommentDetailComponent,
    PictorialCommentFormComponent,
  ],
  exports: [
    PostListCardComponent,
    PostDetailCardComponent,
    PostDetailDialogComponent,
    PostFormCreatorCardComponent,
    PostFormContributorCardComponent,
    ArticleListCreatorPageComponent,
    CommentFormComponent,
    PictorialCardComponent,
    PictorialThumbnailComponent,
    PostCardRendererComponent,
    CommentDetailComponent,
  ],
  providers: [DatePipe, CurrencyPipe, ShortNumberPipe, PictorialDetailPageService],
})
export class BoardModule {}
