import { Pipe, PipeTransform } from "@angular/core"

@Pipe({
  name: "shortNumber",
})
export class ShortNumberPipe implements PipeTransform {
  transform(value: number, ...args: any[]): any {
    if (isNaN(value)) { return "-" }

    let abs = Math.abs(value)
    let key = ""

    const rounder = Math.pow(10, 1)
    const isNegative = value < 0

    const powers = [
      { key: "Q", value: Math.pow(10, 15) },
      { key: "T", value: Math.pow(10, 12) },
      { key: "B", value: Math.pow(10, 9) },
      { key: "M", value: Math.pow(10, 6) },
      { key: "K", value: 1000 },
    ]

    for (let i = 0; i < powers.length; i++) {
      let reduced = (abs / powers[i].value) * 10
      reduced = Math.round(reduced * rounder) / Math.pow(rounder, 2)

      if (reduced >= 1) {
        abs = reduced
        key = powers[i].key
        break
      }
    }

    return (isNegative ? "-" : "") + (key ? abs.toFixed(2) : abs) + key
  }
}
