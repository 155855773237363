import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Pagination } from "src/app/shared/types/pagination.type"
import { environment } from "src/environments/environment"
import { ContestModel, GetModelCampaignParams } from "../types"

@Injectable({
  providedIn: "root",
})
export class ModelService {
  constructor(private http: HttpClient) {}

  getModelCampaignItemList(params: GetModelCampaignParams) {
    const url = `${environment.pictorialApiUrl}models/campaigns`
    return this.http.get<Pagination<ContestModel>>(url, { params })
  }
}
