import { Injectable } from "@angular/core"
import { BehaviorSubject, Observable } from "rxjs"
import { map, publishReplay, refCount, switchMap, tap } from "rxjs/operators"
import { Pictorial } from "src/app/board/types/pictorial.type"
import { ModelService } from "./model.service"
import { PictorialService } from "src/app/board/services/pictorial.service"
import { Pagination } from "src/app/shared/types/pagination.type"

@Injectable({
  providedIn: "root",
})
export class ModelDataService {
  private modelId: string
  private _pictorials$ = new BehaviorSubject(1)
  private currentPage = 1
  private totalPages?: number = null
  private prevPictorials: Pictorial[] = []
  pictorials: Observable<Pictorial[]>
  isLoading: boolean
  totalItems: number

  constructor(
    private modelService: ModelService,
    private pictorialService: PictorialService,
  ) {}

  init(modelId: string) {
    this.pictorials = this._pictorials$.pipe(
      switchMap((page) => {
        return this.fetchPictorials(page)
      }),
      publishReplay(1),
      refCount(),
    )
    this.isLoading = true
    this.prevPictorials = []
    this.modelId = modelId
    this.currentPage = 1
    this.totalPages = null
    this._pictorials$.next(this.currentPage)
  }

  loadNextPage() {
    if (this.currentPage >= this.totalPages) {
      return
    }
    this.isLoading = true
    this.currentPage++
    this._pictorials$.next(this.currentPage)
  }

  fetchPictorials(page: number): Observable<Pictorial[]> {
    return this.pictorialService.findMany({ modelIds: [this.modelId], page: page, limit: 15 }).pipe(
      tap((res: Pagination<Pictorial>) => {
        this.isLoading = false
        this.totalItems = res.meta.totalItems
        this.totalPages = res.meta.totalPages
        this.prevPictorials = this.prevPictorials.concat(res.items)
      }),
      map(() => this.prevPictorials),
    )
  }
}
