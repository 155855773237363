import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { map, tap } from "rxjs/operators"
import { PictorialService } from "src/app/board/services/pictorial.service"
import { Pictorial } from "src/app/board/types/pictorial.type"

@Injectable({
  providedIn: "root",
})
export class PictorialDataService {
  private pictorialCache: Record<string, Pictorial> = {}
  constructor(private model: PictorialService) {}

  getListByIds(ids: string[]): Observable<Pictorial[]> {
    const idSet = []
    ids.forEach((id) => {
      if (!this.pictorialCache[id]) {
        idSet.push(id)
      }
    })

    if (idSet.length === 0) {
      return new Observable((observer) => {
        observer.next(ids.map((id) => this.pictorialCache[id]))
        observer.complete()
      })
    }

    return this.model.getListByIds([...Array.from(idSet)]).pipe(
      map((pictorials) => {
        pictorials.forEach((pictorial) => {
          this.pictorialCache[pictorial.id] = pictorial
        })

        return ids.map((id) => this.pictorialCache[id])
      }),
    )
  }

  getItem(id: string): Observable<Pictorial> {
    if (this.pictorialCache[id]) {
      return new Observable((observer) => {
        observer.next(this.pictorialCache[id])
        observer.complete()
      })
    }
    return this.model.get(id).pipe(
      tap((pictorial) => {
        this.pictorialCache[id] = pictorial
      }),
    )
  }

  async getPictorialsByIds(ids: string[]) {
    const idSet = new Set<string>()

    for (const id of ids) {
      if (!this.pictorialCache[id]) {
        idSet.add(id)
      }
    }

    await this.model
      .getListByIds(Array.from(idSet))
      .pipe(
        tap((pictorials) => {
          pictorials.forEach((pictorial) => {
            this.pictorialCache[pictorial.id] = pictorial
          })
        }),
      )
      .toPromise()

    return ids.map((id) => this.pictorialCache[id])
  }

  getThumbnails(pictorial: Pictorial) {
    const thumbnails = pictorial.__covers__.map((cover) => cover.file.url)
    return thumbnails
  }
}
