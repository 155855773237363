import { Component, Input, OnInit } from "@angular/core"
import { FormControl } from "@angular/forms"

interface ComponentRadioButtonListProp {
  label: string
  value: any
  helpText?: string
}

@Component({
  selector: "app-creator-radio-button",
  templateUrl: "./creator-radio-button.component.html",
  styleUrls: ["./creator-radio-button.component.scss"],
})
export class CreatorRadioButtonComponent implements OnInit {
  @Input()
  selected: any
  @Input()
  id: string
  @Input()
  list: ComponentRadioButtonListProp[] | undefined
  @Input()
  onClick: (key: string, value: boolean) => {}

  formControl: FormControl

  constructor() {}

  ngOnInit() {
    this.formControl = new FormControl(this.selected)
  }

  changeStatus(e) {
    this.onClick(this.id, e.value)
  }

  getHelpText(value: any) {
    const item = this.list.find((item) => item.value === value)

    if (!item) return null
    return item.helpText
  }
}
