export const META_TITLE_KEY = "title"
export const META_TWITTER_TITLE_KEY = "twitter:title"
export const META_TWITTER_IMAGE_ALT_KEY = "twitter:image:alt"
export const META_OG_TITLE_KEY = "og:title"
export const META_OG_IMAGE_ALT_KEY = "og:image:alt"
export const META_DESCRIPTION_KEY = "description"
export const META_OG_DESCRIPTION_KEY = "og:description"
export const META_TWITTER_DESCRIPTION_KEY = "twitter:description"

export const TITLE_SUFFIX = " - MemberMe"
