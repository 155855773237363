import { Component, OnInit } from "@angular/core"
import { UserService } from "../../services/user.service"
import { User } from "../../types/user.type"

@Component({
  selector: "app-user-adult-verify-result-page",
  templateUrl: "./user-adult-verify-result-page.component.html",
  styleUrls: ["./user-adult-verify-result-page.component.scss"],
})
export class UserAdultVerifyResultPageComponent implements OnInit {
  user: User
  constructor(private userService: UserService) {
    this.user = this.getUser()
  }

  ngOnInit() {
    const encodeData = location.search.substr(location.search.indexOf("?") + 1).split("=")[1]
    this.userService.verifyAdult(false, encodeData).subscribe((res) => {
      window.opener.postMessage(null, window.origin)
      window.close()
    })
  }

  getUser() {
    return this.userService.getUser()
  }
}
