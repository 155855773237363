import { Component, Input, OnInit } from "@angular/core"
import { TranslateService } from "@ngx-translate/core"

@Component({
  selector: "app-empty-indicator",
  templateUrl: "./empty-indicator.component.html",
  styleUrls: ["./empty-indicator.component.scss"],
})
export class EmptyIndicatorComponent implements OnInit {
  @Input() type: "sendme" | "memberme"

  constructor(private translateService: TranslateService) {}

  ngOnInit() {}

  getTitle() {
    let text: string

    switch (this.type) {
      case "sendme":
        text = this.translateService.instant("SENDME.EMPTY.NO_SENDME_TITLE")
        break
      case "memberme":
        text = this.translateService.instant("SENDME.EMPTY.NO_MEMBERME_TITLE")
        break
    }

    return text
  }

  getBody() {
    let text: string

    switch (this.type) {
      case "sendme":
        text = this.translateService.instant("SENDME.EMPTY.NO_SENDME_DESC")

        break
      case "memberme":
        text = this.translateService.instant("SENDME.EMPTY.NO_MEMBERME_DESC")
        break
    }

    return text
  }
}
