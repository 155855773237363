import { Component, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { CookieService } from "angular2-cookie/core"
import { TranslateService } from "@ngx-translate/core"
import { AuthService } from "src/app/core/services/auth.service"
import { FormService } from "src/app/shared/services/form.service"
import { Form } from "src/app/shared/model/form"
import { UserService } from "../../services/user.service"

@Component({
  selector: "app-user-update-account-page",
  templateUrl: "./user-update-account-page.component.html",
  styleUrls: ["./user-update-account-page.component.scss"],
})
export class UserUpdateAccountPageComponent implements OnInit {
  form: Form
  langFlag: string

  constructor(
    private auth: AuthService,
    private router: Router,
    private formService: FormService,
    private userService: UserService,
    private cookieService: CookieService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    if (this.cookieService.get("lang") !== "KOR") {
      this.langFlag = "ENG"
    } else {
      this.langFlag = "KOR"
    }

    this.formService
      .getFormData("users/me/change_password/")
      .subscribe((form) => (this.form = form))
  }

  onSubmit() {
    this.userService
      .changePassword(this.form)
      .subscribe((res) => alert(this.translateService.instant("ALERT_CONFIRM.SAVED")))
  }

  onDelete() {
    if (confirm(this.translateService.instant("ALERT_CONFIRM.EXIT_CONFIRM"))) {
      this.userService.delete().subscribe((res) => {
        this.auth.logout()
        this.router.navigate(["/"])
      })
      this.userService.changePassword(this.form).subscribe(
        (res) => {
          this.auth.logout()
          this.router.navigate(["/"])
        },
        (error) => {
          if (error.status == 403) {
            alert(error.error)
          }
        },
      )
    }
  }

  moveToMembershipCancelPage() {
    this.router.navigate(["/membership-cancel"])
  }
}
