import { Component } from "@angular/core"
import { UserService } from "src/app/account/services/user.service"
import { AuthService } from "src/app/core/services/auth.service"
import { environment } from "src/environments/environment"
import { Stage } from "src/environments/stage"
import { PreviewPostDataService } from "../../services/post-data.service"
import { User } from "src/app/account/types/user.type"

@Component({
  selector: "app-posts-tab",
  templateUrl: "./posts-tab.component.html",
  styleUrls: ["./posts-tab.component.scss"],
})
export class PostsTabComponent {
  blurArray = ["black", "red", "green", "blue", "purple"]
  selectedId: number = null
  modal = false
  isAdmin: boolean
  backendUrl =
    environment.stage === Stage.production
      ? "https://squad.memberme.net"
      : "https://smith-staging.memberme.net"
  showAdult = false

  constructor(
    public authService: AuthService,
    public postDataService: PreviewPostDataService,
    private userService: UserService,
  ) {
    this.checkAdmin(this.userService.getUser())
    this.authService.getChangeEmitter().subscribe(() => {
      this.checkAdmin(this.userService.getUser())
    })
  }

  private checkAdmin(user?: User) {
    this.isAdmin = user ? environment.adminUserId.includes(user.id) : false
  }

  onScroll() {
    if (this.postDataService.loadingMore && this.postDataService.postLoaded) {
      this.postDataService.getPaginatedResultByUrl()
    }
  }

  onClickImage(id: number) {
    this.selectedId = id
    this.modal = true
  }

  onCloseModal() {
    this.modal = false
    this.selectedId = null
  }

  onClickBlur() {
    this.authService.verifyAdult()
  }

  onToggle(value: boolean) {
    this.postDataService.setAdultFilter(value)
    this.postDataService.getPaginatedResult()
  }
}
