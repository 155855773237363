import { Pictorial } from "src/app/board/types/pictorial.type"
import { PurchasePermission } from "src/app/creator/pages/creator-photobook-page/type"

import _ from "lodash"

export class Util {
  static isMobileDevice(): boolean {
    const ua = navigator.userAgent
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(ua)
  }

  static getMobileOS(): string {
    const userAgent = navigator.userAgent || navigator.vendor

    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone"
    }

    if (/android/i.test(userAgent)) {
      return "Android"
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return "IOS"
    }

    return "unknown"
  }

  static imgPreload(imgs: string[]) {
    for (let i = 0; i < imgs.length; i++) {
      const img = new Image()
      img.src = imgs[i]
    }
  }

  static setSalesMethodFromPictorial(pictorial: Pictorial): PurchasePermission {
    if (pictorial.free) {
      return PurchasePermission.allFree
    }
    if (pictorial.tierId || pictorial.openTo == "member") {
      return pictorial.availableSale
        ? PurchasePermission.membershipPaid
        : PurchasePermission.membershipFree
    }
  }

  static async wait(ms: number) {
    return await new Promise((resolve) => setTimeout(() => resolve(null), ms))
  }

  static getErrorTxt(error: any): string {
    if (typeof error === "object") {
      return Object.keys(error).map((key) => {
        if (Array.isArray(error[key])) {
          return error[key].join(", ")
        }
        return this.getErrorTxt(error[key])
      })[0]
    }
    return error
  }

  static getPaginationUrl(nextUrl: string, page: number): string {
    const copiedUrl = nextUrl
    const [path, query] = copiedUrl.split("?")
    const splitQuery = query.split("&")

    const index = splitQuery.findIndex((queryStr: string) => queryStr.includes("page="))

    splitQuery[index] = `page=${page}`

    return `${path}?${splitQuery.join("&")}`
  }

  static parsePaginationUrl(url: string): number {
    const copiedUrl = url
    const query = copiedUrl.split("?")[1]
    const splitQuery = query.split("&")

    const index = splitQuery.findIndex((queryStr: string) => queryStr.includes("page="))

    return Number(splitQuery[index].split("=")[1])
  }

  /**
   *
   * @param obj interface 객체
   * @returns httpClient queryParam 객체
   */
  static toQueryParam(obj?: any): Record<string, string | string[] | undefined> {
    if (!obj) {
      return {}
    }

    Object.values(obj).forEach((element) => {
      element = _.toString(element)
    })

    return obj
  }
}
