import { Component, Input, OnInit } from "@angular/core"
import { UserService } from "src/app/account/services/user.service"
import { User } from "src/app/account/types/user.type"
import { Message, MessageType } from "../../types/message.type"
import { Comment } from "src/app/board/types/comment.type"
import { Post } from "src/app/board/types/post.type"
import { CommentCreator } from "./types/creator.type"

@Component({
  selector: "app-chat-list-item",
  templateUrl: "./chat-list-item.component.html",
  styleUrls: ["./chat-list-item.component.scss"],
})
export class ChatListItemComponent implements OnInit {
  @Input() messageCreatedByMe: boolean = false
  @Input() data: Comment | Message
  @Input() type: MessageType
  @Input() previousMsgUser: CommentCreator
  @Input() opponentUser: CommentCreator

  isMine: boolean
  isMobile: boolean
  sameUser: boolean

  currentUser: User

  messageId: number

  post: Post
  comment: Comment
  messageType: typeof MessageType = MessageType

  constructor(protected userService: UserService) {}

  ngOnInit() {
    this.currentUser = this.userService.getUser()

    if (!this.messageCreatedByMe) {
      this.initUserView()
    } else {
      this.initCreatorView()
    }
  }

  initUserView() {
    if (this.data) {
      this.setIsMine()
      this.setIsSameUser()

      if ((this.data as Comment).timesince) {
        this.comment = this.data as Comment
      } else {
        this.post = (this.data as Message).post
      }
    }
  }

  initCreatorView() {
    if (this.data) {
      this.opponentUser = (this.data as Message).post.user as unknown as CommentCreator
      this.setIsSameUser()
      this.isMine = false
      this.post = (this.data as Message).post
    }
  }

  setIsMine() {
    if (this.type === "post" || this.type === "pictorial") {
      this.isMine = (this.data as Message).user !== this.currentUser.id
    }

    if (this.type === "comment" && (this.data as unknown as Comment).user) {
      const commentUser = (this.data as unknown as Comment).user
      this.isMine =
        commentUser.name == this.currentUser.name ||
        commentUser.creator_url == this.currentUser.creator_url
    }
  }

  setIsSameUser() {
    this.sameUser =
      this.previousMsgUser &&
      (this.previousMsgUser.id === this.opponentUser.id ||
        this.previousMsgUser.creator_id === this.opponentUser.id)
  }
}
