import { Component, OnInit } from "@angular/core"
import * as moment from "moment"
import { CreatorService } from "../../services/creator.service"
import { switchMap } from "rxjs/operators"
import { ActivatedRoute, ParamMap, Router } from "@angular/router"
import { Form } from "../../../shared/model/form"
import { FormService } from "../../../shared/services/form.service"
import { MembershipService } from "../../services/membership.service"

import { CookieService } from "angular2-cookie/core"
import { BackendService } from "../../../shared/services/backend.service"
import { TransactionService } from "../../../payment/services/transaction.service"
import { SnackbarService } from "../../../shared/services/snackbar.service"
import { TranslateService } from "@ngx-translate/core"
import { MessagingComponent } from "../../../shared/components/messaging/messaging.component"
import { MatDialog } from "@angular/material"
import { BillingKeyService } from "src/app/payment/services/billing-key.service"
import { CountryService } from "src/app/shared/services/country.service"

@Component({
  selector: "app-creator-contribute-page",
  templateUrl: "./creator-contribute-page.component.html",
  styleUrls: ["./creator-contribute-page.component.scss"],
})
export class CreatorContributePageComponent extends MessagingComponent implements OnInit {
  creator
  isBlockedUser = false
  form: Form
  fields = {
    tier: {
      label: "멤버십",
      widget: {
        name: "radio",
      },
    },
    amount: {
      label: "금액",
      style: {
        placeholder: "금액을 입력해주세요",
      },
      widget: {
        name: "input",
        attrs: { type: "number" },
      },
    },
  }
  membership
  prevSelectedTier
  langFlag
  activeTier // 테두리 지정을 위한 전역 티어값
  isSNSMode

  isStripeSetUpped = false
  taxRate
  taxText
  moreThanOneDayLeftOfMembership

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formService: FormService,
    private creatorService: CreatorService,
    private membershipService: MembershipService,
    private cookieService: CookieService,
    private backendService: BackendService,
    private transactionService: TransactionService,
    private billingKeyService: BillingKeyService,
    private countryService: CountryService,
    protected snackbarService: SnackbarService,
    protected translateService: TranslateService,
    protected dialog: MatDialog,
  ) {
    super(snackbarService, translateService, dialog)

    this.getTaxText()
  }

  ngOnInit() {
    if (this.cookieService.get("lang") !== "KOR") {
      this.langFlag = "ENG"
    } else {
      this.langFlag = "KOR"
    }

    this.getTaxText()

    const creator$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) => this.creatorService.get(params.get("id"))),
    )
    creator$.subscribe((creator: any) => {
      this.creator = creator
      this.isSNSMode = this.creator.creator_type == "sns"
      if (this.creator.membership) {
        this.membership = this.creator.membership
        if (
          this.membership.member_management &&
          this.membership.member_management.is_blocked_user
        ) {
          this.isBlockedUser = true
        }
      }
      if (this.creator.is_blocked) {
        this.isBlockedUser = true
      }
      this.calculateMembershipDate()
    })
    this.form = this.formService.getFormFromFields(this.fields)

    this.billingKeyService.getMethod().subscribe((res: any) => {
      if (res.payment_method_id) {
        this.isStripeSetUpped = true
      }
    })
  }

  getTaxText() {
    this.countryService.get().subscribe((data) => {
      this.countryService.calTax()
      this.taxRate = this.countryService.getTax()

      if (this.langFlag === "KOR") {
        this.taxText = `**모든 결제 금액에는 <span class="text-danger">${this.taxRate}%</span> 세금이 부과됩니다`
      } else {
        this.taxText = `**<span class="text-danger">${this.taxRate}%</span>  tax is added to every purchase`
      }
    })
  }

  // 멤버십 해지 하루 전날까지만 '멤버십 복구하기' 버튼이 보여야 한다 (해지 당일 Stripe subscription 해지된 후 버튼을 누르면 오류가 발생할 것임)
  calculateMembershipDate() {
    const currentDate = moment().format("YYYY-MM-DD")
    this.moreThanOneDayLeftOfMembership = this.creator.membership.date_end > currentDate
  }

  setTier(tierId) {
    this.activeTier = tierId
    if (this.isSelectableTier(tierId)) {
      // hide button of previously selected tier
      if (this.prevSelectedTier) {
        this.prevSelectedTier.style.display = "none"
        document.getElementById("card-" + tierId).classList.toggle("membership-selected")
      }
      this.form.formGroup.get("tier").setValue(tierId)

      // no membership yet. '구매하기'
      if (!this.membership) {
        this.prevSelectedTier = document.getElementById("purch-btn-" + tierId)
        this.prevSelectedTier.style.display = "block"
      } else {
        // '변경하기'
        // check button id and tier id then show appropriate button
        this.prevSelectedTier = document.getElementById("btn-" + tierId)
        this.prevSelectedTier.style.display = "block"
      }
    }
  }

  isCurrentTier(tierId) {
    try {
      const membership = this.creator.membership
      if (tierId) {
        return membership.tier.id === tierId
      } else {
        return !membership.tier && membership.amount > 0
      }
    } catch (e) {
      return false
    }
  }

  isNextTier(tierId) {
    try {
      const membership = this.creator.membership
      if (tierId) {
        return membership.tier_next.id === tierId
      } else {
        return !membership.tier_next && membership.amount_next > 0
      }
    } catch (e) {
      return false
    }
  }

  isSelectableTier(tierId) {
    return !tierId || !this.isNextTier(tierId)
  }

  isCancelDisabled() {
    return this.creator.membership && this.creator.membership.payment_type == "free"
      && this.creator.membership.date_begin == moment().format("YYYY-MM-DD")
  }

  getMembership() {
    const tier = this.form.getValue("tier")
    const amount = this.form.getValue("amount")
    let type
    let value

    if (tier) {
      type = "tier"
      value = tier
    } else {
      type = "amount"
      value = amount
    }
    return { type: type, value: value }
  }

  canProceed() {
    const membership = this.getMembership()
    return membership.type && membership.value
  }

  onSubmit() {
    if (this.isBlockedUser) {
      this.messageDanger(this.translate("CREATOR_DETAIL_PAGE.BLOCKED_USER_CANNOT_CONTRIBUTE"))
      this.form.formGroup.get("content").setValue("")
      return false
    } else {
      const membership = this.getMembership()
      if (this.canProceed()) {
        this.router.navigate([
          "",
          {
            outlets: {
              popup: [
                "transaction-create",
                this.creator.id,
                membership.type,
                membership.value,
                this.creator.is_deny_cancel,
              ],
            },
          },
        ])
      } else {
        this.messageWarn(this.translate("CREATOR_DETAIL_PAGE.SELECT_MEMBERSHIP_MESSAGE"))
      }
    }
  }

  changeMembership() {
    if (this.isBlockedUser) {
      this.messageDanger(this.translate("CREATOR_DETAIL_PAGE.BLOCKED_USER_CANNOT_CONTRIBUTE"))
      this.form.formGroup.get("content").setValue("")
      return false
    }
    if (
      this.canProceed() &&
      confirm(this.translate("CREATOR_DETAIL_PAGE.CHANGE_MEMBERSHIP_CONFIRM"))
    ) {
      const membership = this.getMembership()
      this.router.navigate([
        "",
        {
          outlets: {
            popup: [
              "transaction-create",
              this.creator.id,
              membership.type,
              membership.value,
              this.creator.is_deny_cancel,
              true,
            ],
          },
        },
      ])
    }
  }

  deleteMembership() {
    if (confirm(this.translate("CREATOR_DETAIL_PAGE.CANCEL_MEMBERSHIP_CONFIRM"))) {
      this.membershipService.unregister(this.creator.membership.id).subscribe((res) => {
        this.ngOnInit()
        this.messageSuccess(this.translate("CREATOR_DETAIL_PAGE.CANCEL_MEMBERSHIP_SUCCESS"))
      })
    }
  }

  restoreMembership() {
    this.membershipService.restore(this.creator.membership).subscribe((res) => {
      this.ngOnInit()
      this.messageSuccess(this.translate("CREATOR_DETAIL_PAGE.RESTORE_MEMBERSHIP_SUCCESS"))
    })
  }
}
