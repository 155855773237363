import { Injectable } from "@angular/core"
import { Subject } from "rxjs"
import { environment } from "src/environments/environment"
import { HttpClient } from "@angular/common/http"

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  private messageSource = new Subject<boolean>()

  constructor(private http: HttpClient) {}

  isExistUnread$ = this.messageSource.asObservable()

  changeMessage(isExistUnread: boolean) {
    this.messageSource.next(isExistUnread)
  }

  getListPaginated(data) {
    return this.http.get(environment.apiUrl + "notifications/", { params: data })
  }

  getListPaginatedByUrl(url) {
    return this.http.get(url).subscribe()
  }

  updateNotifications() {
    return this.http.put(environment.apiUrl + "notifications/update-list/", { test: "test" })
  }

  getCountUnreadNotification() {
    const result = this.http.get(environment.apiUrl + "notifications/unread/")
    return result
  }

  saveRequestContact(data) {
    return this.http.post<Response>(environment.apiUrl + "notifications/request-contact/", data, {
      observe: "response",
    })
  }
}
