import { EventEmitter, Injectable } from "@angular/core"

@Injectable({
  providedIn: "root",
})
export class EventService {
  public onUserUpdate$ = new EventEmitter()

  constructor() {}
}
