import { Component, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { AuthService } from "src/app/core/services/auth.service"
import { BillingKeyService } from "../../services/billing-key.service"

@Component({
  selector: "app-stripe-inducer",
  templateUrl: "./stripe-inducer.component.html",
  styleUrls: ["./stripe-inducer.component.scss"],
})
export class StripeInducerComponent implements OnInit {
  isHidden
  isFolded
  isLogin

  stripeClosedUntilKey = "stripe_closed_until_key"

  constructor(
    private auth: AuthService,
    private router: Router,
    private billingKeyService: BillingKeyService,
  ) {
    this.isHidden = true
  }

  ngOnInit() {
    this.billingKeyService.getChangeEmitter().subscribe((param) => {
      this.init()
    })

    this.auth.getChangeEmitter().subscribe((param) => {
      this.init()
    })
  }

  init() {
    this.isLogin = this.auth.isLoggedIn()
    if (this.isLogin) {
      this.billingKeyService.getOldPaymentMembership().subscribe((res: any) => {
        if (res.count > 0) {
          if (!this.isDoNotDisturbSettled()) {
            this.isHidden = false
          }
        } else {
          this.isHidden = true
        }
      })
    } else {
      this.isHidden = true
    }
  }

  foldInducer() {
    this.isFolded = !this.isFolded

    this.onClickHideDay()
  }

  isDoNotDisturbSettled() {
    const localStorageDate = localStorage.getItem(this.stripeClosedUntilKey)

    if (localStorageDate) {
      const current = new Date()

      if (Number(localStorageDate) > current.getTime() && this.isLogin) {
        this.isFolded = true
        this.isHidden = false
        return true
      }
    }

    return false
  }

  onClickHideDay() {
    const date = new Date()
    date.setDate(date.getDate() + 1)
    date.setHours(0, 0, 0, 0)

    localStorage.setItem(this.stripeClosedUntilKey, date.getTime().toString())
  }

  onClickApprove() {
    this.router.navigate([
      {
        outlets: { popup: ["billing-key", { isInducer: true }] },
      },
    ])
  }
}
