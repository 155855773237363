import { Component, OnInit } from "@angular/core"
import { CookieService } from "angular2-cookie/core"
import { smoothly } from "src/app/animation"
import { NotificationService } from "../../services/notification.service"
import { Router } from "@angular/router"

@Component({
  selector: "app-notification-list-activity-page",
  animations: [smoothly],
  templateUrl: "./notification-list-activity-page.component.html",
  styleUrls: ["./notification-list-activity-page.component.scss"],
})
export class NotificationListActivityPageComponent implements OnInit {
  paginatedResult$
  langFlag
  subscribedResults
  loaded = false

  constructor(
    private notificationService: NotificationService,
    private cookieService: CookieService,
    private router: Router,
  ) {}

  ngOnInit() {
    if (this.cookieService.get("lang") !== "KOR") {
      this.langFlag = "ENG"
    } else {
      this.langFlag = "KOR"
    }

    this.getPaginatedResult()
  }

  getPaginatedResult() {
    this.paginatedResult$ = this.notificationService
      .getListPaginated({ category: ["creator", "recent", "request"] })
      .subscribe(
        (result) => {
          // this.subscribedResults = this.formatTexts(result);
          this.subscribedResults = result

          this.loaded = true
          this.notificationService.updateNotifications().subscribe(() => {
            this.notificationService.changeMessage(false)
          })
        },
        (error) => {
          if (console) {
            console.log(error)
          }
          this.loaded = true
        },
      )
    // this.paginatedResult$ = this.notificationService.getListPaginated({category: 'activity'})
  }

  getPaginatedResultByUrl(url) {
    this.paginatedResult$ = this.notificationService.getListPaginatedByUrl(url)
  }

  routeToNotification(notification) {
    if (!notification.content_extra) {
      return false
    }
    let path
    const { post_id, type, url } = notification.content_extra
    if (type == "creator" && url && post_id) {
      path = this.toPathArray(notification.content_extra.url)
    } else if (type == "message") {
      path = this.toPathArray("/sendme/inbox")
    } else {
      path = []
    }
    this.router.navigate(["/", ...path]).then(() => {})
  }

  toPathArray(url) {
    if (url && typeof url === "string") {
      url = url.split("/").filter((path) => path && path !== "")
    }
    return url
  }
}
