import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core"
import { Router } from "@angular/router"
import { DatePipe } from "@angular/common"
import { CookieService } from "angular2-cookie/core"
import { smoothly } from "src/app/animation"
import { AuthService } from "src/app/core/services/auth.service"
import { environment } from "src/environments/environment"
import { PostService } from "../../services/post.service"
import { CommentService } from "../../services/comment.service"
import { UserBlockService } from "../../services/userblock.service"
import * as moment from "moment"
import { TranslateService } from "@ngx-translate/core"
import { UserService } from "src/app/account/services/user.service"
import { UserFileService } from "src/app/board/services/user-file.service"
import { Post } from "../../types/post.type"
import { User } from "src/app/account/types/user.type"

@Component({
  selector: "app-post-detail-card",
  animations: [smoothly],
  templateUrl: "./post-detail-card.component.html",
  styleUrls: ["./post-detail-card.component.scss"],
})
export class PostDetailCardComponent implements OnInit, OnChanges {
  @Output() filterTagChanged = new EventEmitter<string>()
  @Input() post: Post
  @Input() commentPermission: any
  @Input() isBlockedUser: any
  @Input() inDashboard: boolean
  mode = "view"
  isLocked = false
  isHidden = false
  isBlocked = false
  isAdultLocked = false
  isAppOnlyLocked = false
  postLoaded
  currentUser

  dateKor
  dateEng

  editorContent
  displayedComments = []
  newLoadedComments: any[]
  nextUrl
  commentsLoading
  isPastPoll: boolean

  user: User

  constructor(
    private router: Router,
    private postService: PostService,
    private userFileService: UserFileService,
    private datePipe: DatePipe,
    private cookieService: CookieService,
    private auth: AuthService,
    private commentService: CommentService,
    private userService: UserService,
    private userBlockService: UserBlockService,
    protected translateService: TranslateService,
  ) {
    moment.locale("en")
    this.user = this.userService.getUser()
  }

  ngOnInit() {
    this.setPost()
  }

  setPost() {
    this.currentUser = this.getUser()
    this.commentsLoading = true

    if (this.post.comment_visible === undefined) {
      this.post.comment_visible = true
    }

    if (this.post.comment_enabled === undefined) {
      this.post.comment_enabled = true
    }

    // 댓글이 5개 보다 많으면 pagination 준비해야됨
    if (this.post.count_comments > 5) {
      // nextUrl 을 page=2 로 잡아줌
      this.nextUrl = `${environment.apiUrl}` + "comments/?page=2&post=" + this.post.id
      this.displayedComments = this.post.comments
      this.commentsLoading = false
    } else {
      // 아니면 그대로 보여줌
      this.displayedComments = this.post.comments
      this.commentsLoading = false
    }

    // console.log(this.post.polls.length);
    if (this.post && this.post.polls && this.post.polls.length) {
      this.isPastPoll =
        moment.duration(moment(this.post.polls[0].end_date).diff(moment())).asDays() < -1
    }

    // 전체 투표 개수 세팅

    this.setIsLocked()
    this.setIsBlocked()
    this.setIsAdultLocked()
    this.setIsAppLocked()
    this.setDate()
    setTimeout(() => {
      this.editorContent = this.post.content
      this.postLoaded = true
    }, 200)
  }

  getUser() {
    return this.userService.getUser()
  }

  addComment(comment) {
    this.displayedComments.push(comment)
  }

  updatePost() {
    this.postService.get(this.post.id).subscribe((post) => {
      this.post = post
      this.mode = "view"
      // for wysiwyg
      this.editorContent = this.post.content
      if (this.post && this.post.polls && this.post.polls.length) {
        this.isPastPoll =
          moment.duration(moment(this.post.polls[0].end_date).diff(moment())).asDays() < -1
      }
      this.setIsLocked()
      this.setDate()
    })
  }

  setIsLocked() {
    this.isLocked = !this.post.permissions.includes("view")
  }

  setIsBlocked() {
    if (!this.post.content && !this.isLocked && !this.post.is_app_only) {
      this.isBlocked = true
      this.post.content = ""
    }
  }

  setIsAdultLocked() {
    this.isAdultLocked = this.post.is_adult_content && !this.auth.isAdult()
  }

  setIsAppLocked() {
    if (!this.isBlocked && this.post.is_app_only) {
      this.isAppOnlyLocked = this.post.is_app_only
      this.isLocked = true
    }
  }

  deletePost() {
    if (confirm(this.translateService.instant("ALERT_CONFIRM.POST_DELETE_CONFIRM"))) {
      this.postService.delete(this.post.id, this.post.category).subscribe(() => {
        this.isHidden = true
        if (this.post.attach_file_ids && this.post.attach_file_ids.length) {
          this.userFileService.deleteFiles(this.post.attach_file_ids).subscribe(() => {})
        }
        alert(this.translateService.instant("ALERT_CONFIRM.POST_DELETE_SUCCESS"))
      })
    }
  }

  blockUser() {
    if (confirm(this.translateService.instant("ALERT_CONFIRM.REPORT_CONFIRM"))) {
      this.userBlockService.create(this.post.user.id).subscribe(
        () => {
          this.isHidden = true
          alert(this.translateService.instant("ALERT_CONFIRM.REPORT_SUCCESS"))
        },
        (error) => {
          console.log(error)
          if (error.error.non_field_errors) {
            alert(this.translateService.instant("ALERT_CONFIRM.ALREADY_BLOCKED"))
          }
        },
      )
    }
  }

  checkCreator() {
    return !!this.post.user["creator_url"]
  }

  navigateToCreator() {
    if (this.post.user["creator_url"]) {
      this.router.navigate(["/", this.post.user["creator_url"]])
    }
  }

  loadMoreComments() {
    this.commentsLoading = true
    this.commentService.getCommentsPaginatedByUrl(this.nextUrl).subscribe(
      (res) => {
        this.nextUrl = res["next"]
        this.newLoadedComments = res["results"]
        for (let i = 0; i < this.newLoadedComments.length; i++) {
          this.displayedComments.unshift(this.newLoadedComments[i])
        }
        this.commentsLoading = false
      },
      () => {
        this.commentsLoading = false
      },
    )
  }

  changeFilterTag(tag: string) {
    if (this.inDashboard && this.post.creator && this.post.creator.url) {
      this.router
        .navigate(["/", this.post.creator.url], { queryParams: { tag: tag } })
        .then(() => {})
    } else {
      this.filterTagChanged.emit(tag)
    }
  }

  ngOnChanges(changes) {
    //  explore-modal 에서 포스트 이동 시 작동
    if (!changes.post.firstChange) {
      this.setPost()
    }
  }

  setDate() {
    let date = this.post.created
    if (this.post.opened_status == "scheduled") {
      date = this.post.visible
    }

    const year = this.datePipe.transform(date, "y")
    const month = this.datePipe.transform(date, "M")
    const day = this.datePipe.transform(date, "d")
    const dateEngFormat = this.datePipe.transform(date, "MMM d y")
    const hour = this.datePipe.transform(date, "h")
    const min = this.datePipe.transform(date, "mm")
    const amPm = this.datePipe.transform(date, "aa")

    this.dateKor = year + "년 " + month + "월 " + day + "일 " + hour + ":" + min + " " + amPm
    this.dateEng = dateEngFormat + " " + hour + ":" + min + " " + amPm

    if (
      this.post.opened_status == "scheduled" &&
      moment.duration(moment(this.post.visible).diff(moment())).asMinutes() > 0
    ) {
      this.dateKor = "(예약공개) " + this.dateKor
      this.dateEng = "(scheduled) " + this.dateEng
    }
  }
}
