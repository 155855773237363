import { Directive, Optional } from "@angular/core"
import { NgControl } from "@angular/forms"
import { MatAutocompleteTrigger } from "@angular/material"

@Directive({
  selector: "[autocomplete-tab-directive]",
})
export class AutocompleteTabDirective {
  observable: any

  constructor(
    @Optional() private autoTrigger: MatAutocompleteTrigger,
    @Optional() private control: NgControl,
  ) {}

  ngAfterViewInit() {
    if (this.autoTrigger) {
      this.observable = this.autoTrigger.panelClosingActions.subscribe((x) => {
        if (this.autoTrigger.activeOption) {
          const value = this.autoTrigger.activeOption.value
          if (this.control) {
            this.control.control.setValue(value, {
              emit: false,
            })
          }
          this.autoTrigger.writeValue(value)
        }
      })
    }
  }
}
