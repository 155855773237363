import { Component } from "@angular/core"
import { MatDialogRef } from "@angular/material"

import { TagDataService } from "src/app/shared/services/tag-data.service"
import { AuthService } from "src/app/core/services/auth.service"

@Component({
  selector: "app-tag-select-dialog",
  templateUrl: "./tag-select-dialog.component.html",
  styleUrls: ["./tag-select-dialog.component.scss"],
})
export class TagSelectDialogComponent {
  maxSelectedTagCount = 5

  selectedTags: number[] = []

  constructor(
    public dialogRef: MatDialogRef<TagSelectDialogComponent>,
    public dataService: TagDataService,
    private authService: AuthService,
  ) {
    this.authService.getChangeEmitter().subscribe(() => {
      const isLogin = this.authService.isLoggedIn()

      if (!isLogin) {
        this.clear()
      }
    })
  }

  clear() {
    this.selectedTags = []

    this.dialogRef.close()
  }

  onTagClicked(index: number) {
    if (this.selectedTags.includes(index)) {
      this.selectedTags = this.selectedTags.filter((tagIndex) => tagIndex !== index)
      return
    }

    this.selectedTags.push(index)
  }

  async onSubmitClicked() {
    await this.dataService.updateUserTags(this.selectedTags).toPromise()

    this.dialogRef.close()
    this.dialogRef.disableClose = false
  }
}
