import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sendme-inbox-chat-detail',
  templateUrl: './sendme-inbox-chat-detail.component.html',
  styleUrls: ['./sendme-inbox-chat-detail.component.scss']
})
export class SendmeInboxChatDetailComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
