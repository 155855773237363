import { Injectable } from "@angular/core"
import { UserFileService } from "src/app/board/services/user-file.service"

@Injectable({
  providedIn: "root",
})
export class FroalaEditorService {
  domParser = new DOMParser()

  constructor(private userFileService: UserFileService) {}

  updateFileIdSet(
    removeFileIdSet: Set<string>,
    attachFileIdSet: Set<string>,
    editorContent: string,
  ) {
    const removedFileIds = [...removeFileIdSet]
    removedFileIds
      .filter((fileId) => editorContent.includes(fileId))
      .forEach((fileId) => {
        removeFileIdSet.delete(fileId)
        attachFileIdSet.add(fileId)
      })
  }

  getRemoveFileItemFunction(component) {
    return (event, editor, fileItem) => {
      const fileId = component.froalaEditorService.getFileIdFromFileItem(fileItem)
      if (fileId) {
        component.attachFileIdSet.has(fileId) && component.attachFileIdSet.delete(fileId)
        component.removeFileIdSet.add(fileId)
      }
    }
  }
  getAddFileItemFunction(component) {
    return (event, editor, response) => {
      const uploadedFile: any = JSON.parse(response)
      if (uploadedFile && uploadedFile.id) {
        component.attachFileIdSet.add(uploadedFile.id)
      }
    }
  }

  deleteAttachFiles(ids: string[]) {
    this.userFileService.deleteFiles(ids).subscribe(() => {})
  }

  confirmAttachFiles(ids: string[]) {
    this.userFileService.confirmFiles(ids).subscribe(() => {})
  }

  getFileIdFromFileItem(fileItem: any[]) {
    if (!fileItem || !fileItem.length) {
      return undefined
    }
    const dataset = fileItem[0]["dataset"]
    return dataset && dataset.id ? dataset.id : undefined
  }

  updateFileItemIdAttribute(htmlContent: string, fileIds: any[]) {
    const doc: Document = this.domParser.parseFromString(htmlContent, "text/html")
    for (const fileId of fileIds) {
      this.updateImageFileItemLink(doc, fileId)
      this.updateVideoFileItemLink(doc, fileId)
      this.updateFileItemHref(doc, fileId)
    }
    return doc.body.innerHTML
  }

  private updateFileItemHref(doc: Document, fileId: any) {
    const links: NodeList = doc.querySelectorAll("a.fr-file")
    if (links && links.length) {
      links.forEach((node: Element) => {
        const href = node.getAttribute("href")
        if (href && typeof href === "string" && href.includes(fileId)) {
          node.setAttribute("id", fileId)
        }
      })
    }
  }

  private updateImageFileItemLink(doc: Document, file: any) {
    this.updateFileItemSrc(doc, file, "img")
  }

  private updateVideoFileItemLink(doc: Document, file: any) {
    this.updateFileItemSrc(doc, file, "video")
  }

  private updateFileItemSrc(doc: Document, fileId: any, element: string) {
    const fileElement: Element = doc.querySelector(`${element}[data-id='${fileId}']`)
    if (fileElement) {
      fileElement.setAttribute("id", fileId)
    }
  }
}
