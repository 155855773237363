import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from "@angular/core"
import { MessageType } from "../../types/message.type"
import { BreakpointObserverService } from "src/app/shared/services/breakpoint-observer.service"
import { Breakpoint } from "src/app/shared/constants/constants"
@Component({
  selector: "app-sent-sendme-thumbnail",
  templateUrl: "./sent-sendme-thumbnail.component.html",
  styleUrls: ["./sent-sendme-thumbnail.component.scss"],
})
export class SentSendmeThumbnailComponent implements OnChanges, OnInit {
  @Input() thumbnails: string[]
  @Input() contentType: MessageType.pictorial | MessageType.post

  splicedThumbnail: string[]

  constructor(private breakpointObserver: BreakpointObserverService) {}

  ngOnInit() {
    const breakpoint = this.breakpointObserver.maxWidth(Breakpoint["md"])

    this.breakpointObserver.observe([breakpoint]).subscribe((result) => {
      if (result.matches) {
        this.splicedThumbnail = this.thumbnails.slice(0, 1)
      } else {
        this.splicedThumbnail = this.thumbnails.slice(0, 4)
      }
    })
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.thumbnails) {
      this.splicedThumbnail = this.thumbnails.slice(0, 4)
    }
  }

  _messageType = MessageType
  showAll = false

  showAllImages() {
    this.showAll = true
  }

  showSingleImage() {
    this.showAll = false
  }

  getCssRight(idx: number) {
    const elementWidth = this.contentType === this._messageType.pictorial ? 217 : 190
    return this.showAll ? idx * elementWidth + "px" : 0
  }
}
