import { Component, Inject, OnInit } from "@angular/core"
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material"
import { UserService } from "src/app/account/services/user.service"
import { PictorialService } from "src/app/board/services/pictorial.service"
import { Pictorial } from "src/app/board/types/pictorial.type"
import { PaymentDialogComponent } from "src/app/payment/components/payment-dialog/payment-dialog.component"

interface SelectContentDialogComponentData {
  isFree: boolean
  creatorId: number
  creatorUrl: string
}

@Component({
  selector: "app-select-content-dialog",
  templateUrl: "./select-content-dialog.component.html",
  styleUrls: ["./select-content-dialog.component.scss"],
})
export class SelectContentDialogComponent implements OnInit {
  contents: Pictorial[]
  meta: any
  selectedContent: Pictorial
  loading = true

  constructor(
    public dialogRef: MatDialogRef<PaymentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SelectContentDialogComponentData,
    private pictorialService: PictorialService,
    private userService: UserService,
  ) {}

  ngOnInit() {
    this.fetchList()
  }

  onClickDone() {
    this.dialogRef.close(this.selectedContent)
  }

  onClickNewContent() {
    this.dialogRef.close()
    this.pictorialService.create(this.data.creatorId).subscribe((res: Pictorial) => {
      window.open(`${this.data.creatorUrl}/pictorials/${res.id}`, "_blank")
    })
  }

  onScroll() {
    if (this.loading) {
      return
    }
    if (this.meta.currentPage < this.meta.totalPages) {
      this.fetchList()
    }
  }

  fetchList() {
    this.loading = true
    const queryParams = {
      creatorId: this.userService.getUser().creator,
      isFree: this.data.isFree,
    }
    const meta = {
      limit: 24,
      page: this.meta ? this.meta.currentPage + 1 : 1,
    }

    this.pictorialService.getList(Object.assign(queryParams, meta)).subscribe((res: any) => {
      const hasThumbnailList = res.items.filter((item: Pictorial) => item.thumbnailUri)
      this.contents = meta.page > 1 ? this.contents.concat(hasThumbnailList) : hasThumbnailList
      this.meta = res.meta
      this.loading = false
    })
  }

  get showIndicator() {
    return this.loading || !this.meta || this.meta.currentPage < this.meta.totalPages
  }

  get isEmpty() {
    return !this.loading && this.contents && this.contents.length === 0
  }
}
