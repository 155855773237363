import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { ScrapeService } from "../../services/scrape.service"

@Component({
  selector: "app-scrape-button",
  templateUrl: "./scrape-button.component.html",
  styleUrls: ["./scrape-button.component.scss"],
})
export class ScrapeButtonComponent implements OnInit {
  @Output() submitted = new EventEmitter<any>()
  @Input() contentType
  @Input() id
  @Input() category
  @Input() isActive

  constructor(private scrapeService: ScrapeService) {}

  ngOnInit() {}

  toggleScrape() {
    const url = `${this.contentType}/${this.id}/${this.category}/`
    this.isActive = !this.isActive
    this.scrapeService.toggleScrape(url).subscribe((res) => {
      this.submitted.emit(res)
    })
  }
}
