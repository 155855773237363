import { Component, OnInit } from "@angular/core"

@Component({
  selector: "app-creator-update-page",
  templateUrl: "./creator-update-page.component.html",
  styleUrls: ["./creator-update-page.component.scss"],
})
export class CreatorUpdatePageComponent implements OnInit {
  date = new Date()
  year
  month

  navLinks

  constructor() {}

  ngOnInit() {
    this.year = this.date.getFullYear()
    this.month = this.date.getMonth() + 1

    this.navLinks = [
      { label: "기본 설정", path: "/creators/update", rlaOption: { exact: true } },
      { label: "멤버십", path: "/creators/update/tiers", rlaOption: { exact: false } },
      {
        label: "목표",
        path: "goals/" + this.year + "/" + this.month + "/activated",
        rlaOption: { exact: false },
      },
      { label: "감사비디오 설정", path: "/creators/update/thank-you", rlaOption: { exact: false } },
    ]
  }
}
