import { Component, Input, OnInit } from "@angular/core"

import { RecommendedCreatorDataService } from "./recommended-creator-data.service"
import { MatIconRegistry } from "@angular/material"
import { DomSanitizer } from "@angular/platform-browser"

@Component({
  selector: "app-creator-list-recommended",
  templateUrl: "./creator-list-recommended.component.html",
  styleUrls: ["./creator-list-recommended.component.scss"],
})
export class CreatorListRecommendedComponent implements OnInit {
  @Input() onlyMobile?: boolean
  @Input() onlyDesktop?: boolean
  @Input() itemsPerPage = 5

  perPageCreators = []
  page = 1

  constructor(
    public dataService: RecommendedCreatorDataService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
  ) {
    iconRegistry.addSvgIcon(
      "arrow-icon",
      sanitizer.bypassSecurityTrustResourceUrl("assets/img/icon/explore-arrow.svg"),
    )
  }

  ngOnInit() {
    this.dataService.creators$.subscribe(() => {
      this.setPerPageCreator()
    })
  }

  prevPage() {
    this.page--

    if (this.page < 1) {
      this.page = this.totalPage
    }

    this.setPerPageCreator()
  }

  nextPage() {
    this.page++

    if (this.page > this.totalPage) {
      this.page = 1
    }

    this.setPerPageCreator()
  }

  changePage(page: number) {
    this.page = page
    this.setPerPageCreator()
  }

  get totalPage() {
    return Math.ceil(this.dataService.creators.length / this.itemsPerPage)
  }

  get arrayOfTotalPageLength() {
    return Array(this.totalPage)
      .fill(0)
      .map((x, i) => i + 1)
  }

  private setPerPageCreator() {
    this.perPageCreators = this.dataService.creators
      .map((recommendCreator) => recommendCreator.creator)
      .slice((this.page - 1) * this.itemsPerPage, this.page * this.itemsPerPage)
  }
}
