import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"

@Component({
  selector: "app-tier-update-page",
  templateUrl: "./tier-update-page.component.html",
  styleUrls: ["./tier-update-page.component.scss"],
})
export class TierUpdatePageComponent implements OnInit {
  tierId: number

  constructor(private route: ActivatedRoute, private translateService: TranslateService) {
    route.params.subscribe((params) => (this.tierId = Number(params.id)))
  }

  ngOnInit() {}

  onSubmitted() {
    alert(this.translateService.instant("ALERT_CONFIRM.SAVED"))
  }
}
