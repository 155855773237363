import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { IpRegistry } from "src/app/shared/types/ipRegistry"
import { environment } from "src/environments/environment"

@Injectable({
  providedIn: "root",
})
export class IpRegistryService {
  constructor(private http: HttpClient) {}

  get() {
    return this.http
      .get<IpRegistry>("https://api.ipregistry.co/?key=" + environment.ipRegistryKey)
      .toPromise()
  }
}
