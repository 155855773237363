import { Injectable } from "@angular/core"
import { CanActivate, Router } from "@angular/router"
import { UserService } from "../../../../account/services/user.service"
import { map } from "rxjs/operators"
import { Observable } from "rxjs"

@Injectable({
  providedIn: "root",
})
export class CreatorCreatePageGuardService implements CanActivate {
  constructor(private router: Router, private userService: UserService) {}

  canActivate(): Observable<boolean> | boolean {
    return this.userService.get("me").pipe(
      map((res) => {
        const creatorId = res["creator"]
        if (creatorId) {
          this.router.navigate(["/creators", creatorId])
        }
        return !creatorId
      }),
    )
  }
}
