import { Component, Input, OnInit } from "@angular/core"
import { RankType } from "./types/rank.type"

@Component({
  selector: "app-rank-render",
  templateUrl: "./rank-render.component.html",
  styleUrls: ["./rank-render.component.scss"],
})
export class RankRenderComponent implements OnInit {
  @Input() rank: number

  rankType: RankType
  _rankReference = RankType

  constructor() {}

  ngOnInit() {
    this.rankType = this.getRankType()
  }

  getRankType() {
    if (this.rank == 1) {
      return RankType.top
    } else if (this.rank < 10) {
      return RankType.high
    } else if (this.rank < 100) {
      return RankType.middle
    } else {
      return RankType.low
    }
  }

  get isTopRank() {
    return this.rankType === RankType.top
  }
}
