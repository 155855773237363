import { Component, Input, OnInit } from "@angular/core"
import { Model, ModelLinkType } from "../../types"

@Component({
  selector: "app-model-description",
  templateUrl: "./model-description.component.html",
  styleUrls: ["./model-description.component.scss"],
})
export class ModelDescriptionComponent implements OnInit {
  private icons = {
    [ModelLinkType.INSTAGRAM]: "assets/img/models/instagram-icon.svg",
    [ModelLinkType.FACEBOOK]: "assets/img/models/facebook-icon.svg",
    [ModelLinkType.YOUTUBE]: "assets/img/models/youtube-icon.svg",
  }
  @Input() model: Model

  constructor() {}
  ngOnInit() {}
  getLinkIcon(modelLinkType: ModelLinkType) {
    return this.icons[modelLinkType]
  }

  isNumber(num?: number) {
    return !isNaN(num)
  }
}
