import { Component, Input, OnInit } from "@angular/core"
import { RankedModel } from "../../types/asian-top-model-contest.type"
import { Router } from "@angular/router"

@Component({
  selector: "app-asian-top-model-rank-list-item",
  templateUrl: "./asian-top-model-rank-list-item.component.html",
  styleUrls: ["./asian-top-model-rank-list-item.component.scss"],
})
export class AsianTopModelRankListItemComponent implements OnInit {
  @Input() model: RankedModel
  @Input() rank: number

  constructor(private router: Router) {}

  ngOnInit() {}

  navigateToDetail() {
    if (this.model.id) {
      this.router.navigate(["/", "models", this.model.id])
    }
  }
}
