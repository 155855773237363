import { Component, Input, OnInit } from "@angular/core"
import { TranslateService } from "@ngx-translate/core"
import { PostService } from "src/app/board/services/post.service"
import { AuthService } from "src/app/core/services/auth.service"

@Component({
  selector: "app-lock-cover",
  templateUrl: "./lock-cover.component.html",
  styleUrls: ["./lock-cover.component.scss"],
})
export class LockCoverComponent implements OnInit {
  @Input() post?: any
  @Input() isAdultLocked = false
  @Input() isAppOnlyLocked = false

  defaultCover

  fatkatBackground: Number
  fatkatWordList = ["멤버 되기", "보고싶어요!", "인싸 되기", "이건 뭘까?"]
  fatkatWordListEng = ["Give Support", "Become a Member", "I Wanna See!", "What's this?"]
  fatkatWord = ""

  constructor(
    private postService: PostService,
    private auth: AuthService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    const index = Math.floor(Math.random() * Math.floor(4))
    this.defaultCover = index % 2 === 0
    this.fatkatBackground = Math.floor(Math.random() * 5) + 1
    this.fatkatWord = this.pickFatkatWord()
  }

  followButtonClicked(creatorId) {
    const url = "creators/" + creatorId + "/follow/"
    this.postService.followCreator(url).subscribe((res) => {
      // reload page to show content
      location.reload()
    })
  }

  onClickAdultBlur() {
    this.auth.verifyAdult()
  }

  pickFatkatWord() {
    const index = Math.floor(Math.random() * Math.floor(4))
    if (this.translateService.currentLang === "ko") {
      return this.fatkatWordList[index]
    } else {
      return this.fatkatWordListEng[index]
    }
  }
}
