import { Injectable } from "@angular/core"
import { Form } from "../../shared/model/form"
import { environment } from "../../../environments/environment"
import { tap } from "rxjs/operators"
import { HttpClient } from "@angular/common/http"

@Injectable({
  providedIn: "root",
})
export class RewardService {
  constructor(private http: HttpClient) {}

  getList() {
    return this.http.get(`${environment.apiUrl}rewards/`)
  }

  get(id) {
    return this.http.get(`${environment.apiUrl}rewards/${id}/`)
  }

  create(form: Form) {
    const data = form.formGroup.value
    return this.http.post(`${environment.apiUrl}rewards/`, data).pipe(
      tap(
        (res) => form.clearErrors(),
        (error) => form.setErrors(error),
      ),
    )
  }

  update(id, form: Form) {
    const data = form.formGroup.value
    return this.http.put(`${environment.apiUrl}rewards/${id}/`, data).pipe(
      tap(
        (res) => form.clearErrors(),
        (error) => form.setErrors(error),
      ),
    )
  }

  delete(id) {
    return this.http.delete(`${environment.apiUrl}rewards/${id}/`)
  }

  getRewardCount() {
    return this.http.get(`${environment.apiUrl}rewards/count/`)
  }
}
