import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core"
import { ActivatedRoute, Params, Router } from "@angular/router"
import { Observable } from "rxjs"
import { TranslateService } from "@ngx-translate/core"
import { map, tap } from "rxjs/operators"
import { BreakpointObserver } from "@angular/cdk/layout"
import { RewardService } from "src/app/creator/services/reward.service"
import { MembershipRewardService } from "../../services/membership-reward.service"
import { ComponentPageTitle } from "../../components/page-title/page-title"
import { FormService } from "src/app/shared/services/form.service"
import { UserService } from "src/app/account/services/user.service"
import { Form } from "src/app/shared/model/form"
import { MessagingComponent } from "src/app/shared/components/messaging/messaging.component"
import { SnackbarService } from "src/app/shared/services/snackbar.service"
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap"
import { environment } from "src/environments/environment"
import { AuthService } from "src/app/core/services/auth.service"
import { MatDialog } from "@angular/material"
import { CookieService } from "angular2-cookie"
import { Pagination } from "src/app/shared/types/pagination.type"
import { Util } from "src/app/shared/utils/utils"

const MIDDLE_WIDTH_BREAKPOINT = 1024

@Component({
  selector: "app-membership-reward-detail-list-component",
  templateUrl: "./membership-reward-detail-list.component.html",
  styleUrls: ["./membership-reward-detail.component.scss"],
})
export class MembershipRewardDetailListComponent
  extends MessagingComponent
  implements OnInit, OnChanges
{
  @Input() reward: any
  @Input() isDelivered: boolean
  @Input() loading: boolean
  @Input() updateKey: number
  @Output() dataChanged = new EventEmitter<boolean>()
  isMiddle: Observable<boolean>
  displayedColumns: string[] = ["name", "email", "membership", "due", "action"]
  schedule: string
  form: Form
  paginatedResult$

  isKorean

  // email variables
  emailList = []
  displayedEmailList = ""
  excelUrl: string

  page: number = 1
  pageUrl: string

  @Input() params: Observable<Params>
  private modalRef: NgbModalRef

  constructor(
    private router: Router,
    private auth: AuthService,
    private formService: FormService,
    private userService: UserService,
    private membershipRewardService: MembershipRewardService,
    private rewardService: RewardService,
    private breakpoints: BreakpointObserver,
    private modalService: NgbModal,
    private cookieService: CookieService,
    protected snackbarService: SnackbarService,
    protected translateService: TranslateService,
    protected dialog: MatDialog,
  ) {
    super(snackbarService, translateService, dialog)
    this.isMiddle = breakpoints
      .observe(`(max-width: ${MIDDLE_WIDTH_BREAKPOINT}px)`)
      .pipe(map((breakpoint) => breakpoint.matches))
  }

  ngOnInit() {
    this.isKorean = this.cookieService.get("lang") === "KOR"

    if (this.isKorean) {
      this.displayedColumns.splice(2, 0, "address")
    }

    const rewardId = this.reward.id
    const url = rewardId ? `rewards/${rewardId}/` : "rewards/"
    this.formService.getFormData(url).subscribe((formData: any) => {
      for (const item of formData.fields.delivery_schedule.choices) {
        if (item.value === this.reward.delivery_schedule) {
          this.schedule = item.display_name
        }
      }
    })

    this.formService.getFilterFormData(`membership-rewards/`).subscribe((form) => {
      this.form = form
      this.form.formGroup.patchValue({
        reward: rewardId,
        is_delivered: this.isDelivered,
      })
      this.setExcelUrl(this.form.formGroup.value)
      this.getPaginatedResult()
      this.form.formGroup.valueChanges.subscribe((changedValue) => {
        this.setExcelUrl(changedValue)
        this.getPaginatedResult()
      })
    })
  }

  downloadExcel() {
    const userToken: string = this.auth.getAuthorizationToken()
    const headers = new Headers()
    headers.append("Authorization", "Bearer " + userToken)
    this.membershipRewardService.downloadExcel(this.excelUrl, {
      headers: headers,
      responseType: "blob" as "blob",
    })
  }

  getQueryString(params: object) {
    if (params) {
      const searchParams = new URLSearchParams()
      Object.keys(params).forEach((key) => searchParams.set(key, params[key]))
      return searchParams.toString()
    }
    return null
  }

  setExcelUrl(params: object) {
    const queryString = this.getQueryString(params)
    if (queryString) {
      this.excelUrl = `${environment.apiUrl}membership-rewards/excel/?${queryString}`
    } else {
      this.excelUrl = null
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.updateKey && !changes.updateKey.firstChange) {
      this.getPaginatedResult()
    }
  }

  getPaginatedResult() {
    this.page = 1
    this.paginatedResult$ = this.membershipRewardService
      .getListPaginated(this.form.formGroup.value)
      .pipe(
        tap((data: Pagination<any>) => {
          this.pageUrl = data.next || data.previous
        }),
      )
  }

  getPaginatedResultByUrl(pageNo) {
    const url = Util.getPaginationUrl(this.pageUrl, pageNo)

    this.page = pageNo
    this.paginatedResult$ = this.membershipRewardService.getListPaginatedByUrl(url).pipe(
      tap((data: Pagination<any>) => {
        this.pageUrl = data.next || data.previous
      }),
    )
  }

  updateIsDelivered(membershipReward) {
    membershipReward.is_delivered = !membershipReward.is_delivered
    this.membershipRewardService
      .updateIsDelivered(membershipReward)
      .subscribe(() => this.dataChanged.emit(true))
  }

  updateIsDeliveredAll(results: any) {
    if (results && results.length) {
      this.membershipRewardService
        .updateIsDeliveredAll({
          ids: results.map((item: any) => item.id),
        })
        .subscribe(() => this.dataChanged.emit(true))
    }
  }

  getEmails(results) {
    let index = 0

    results.forEach((result) => {
      index++
      this.emailList.push(result.user.email)
      if (index === 1) {
        this.displayedEmailList = result.user.email
      } else if (index <= results.length) {
        this.displayedEmailList = this.displayedEmailList + ", " + result.user.email
      }
    })
  }

  withOutCommas(results) {
    let index = 0
    results.forEach((result) => {
      index++
      this.emailList.push(result.user.email)
      if (index === 1) {
        this.displayedEmailList = result.user.email
      } else if (index <= results.length) {
        this.displayedEmailList = this.displayedEmailList + " " + result.user.email
      }
    })
  }

  openModal(content) {
    this.modalRef = this.modalService.open(content)
  }
}

@Component({
  selector: "app-membership-reward-detail-component",
  templateUrl: "./membership-reward-detail.component.html",
  styleUrls: ["./membership-reward-detail.component.scss"],
})
export class MembershipRewardDetailComponent implements OnInit {
  reward: any
  rewardStatus: any
  schedule: string
  updateKey = 0

  form: Form
  paginatedResult$

  @Input() params: Observable<Params>

  constructor(
    public _componentPageTitle: ComponentPageTitle,
    private route: ActivatedRoute,
    private router: Router,
    private formService: FormService,
    private rewardService: RewardService,
    private membershipRewardService: MembershipRewardService,
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      const rewardId = params.id
      this._componentPageTitle.setSubtitle("보상 현황")
      const url = rewardId ? `rewards/${rewardId}/` : "rewards/"
      this.loadCount(rewardId)
      this.rewardService.get(rewardId).subscribe(
        (res: any) => {
          this.reward = res
          this.formService.getFormData(url).subscribe((formData: any) => {
            for (const item of formData.fields.delivery_schedule.choices) {
              if (item.value === res.delivery_schedule) {
                this.schedule = item.display_name
              }
            }
          })
        },
        () => {
          this.router.navigate(["/404"], { replaceUrl: true })
        },
      )
    })
  }

  loadCount(rewardId) {
    this.membershipRewardService.getStatus(rewardId).subscribe((res: any) => {
      this.rewardStatus = res
    })
  }

  setUpdateKey() {
    this.loadCount(this.reward.id)
    this.updateKey = this.updateKey + 1
  }
}
