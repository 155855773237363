import { Component, OnInit } from "@angular/core"
import { CookieService } from "angular2-cookie"

@Component({
  selector: "app-landing-first-collaboration",
  templateUrl: "./landing-first-collaboration.component.html",
  styleUrls: ["./landing-first-collaboration.component.scss"],
})
export class LandingFirstCollaborationComponent implements OnInit {
  langFlag
  constructor(private cookieService: CookieService) {
    this.langFlag = cookieService.get("lang")
  }

  ngOnInit() {}
}
