import { Component, EventEmitter, Input, Output } from "@angular/core"

@Component({
  selector: "app-adult-toggle-button",
  templateUrl: "./adult-toggle-button.component.html",
  styleUrls: ["./adult-toggle-button.component.scss"],
})
export class AdultToggleButtonComponent {
  @Input() isChecked: boolean
  @Output() onChange = new EventEmitter<boolean>()

  toggle() {
    this.isChecked = !this.isChecked
    this.onChange.emit(this.isChecked)
  }
}
