import { NgModule } from "@angular/core"
import { SendmeInboxComponent } from "./pages/sendme-inbox/sendme-inbox.component"
import { SendmeContentsStorageComponent } from "./pages/sendme-contents-storage/sendme-contents-storage.component"
import { SharedModule } from "../shared/shared.module"
import { SendmeRoutingModule } from "./sendme-routing.module"

import { SendmeRootComponent } from "./pages/sendme-root/sendme-root.component"
import { SendmeOutboxComponent } from "./pages/sendme-outbox/sendme-outbox.component"

import { SendmeCreateComponent } from "./pages/sendme-create/sendme-create.component"
import { NgbModule } from "@ng-bootstrap/ng-bootstrap"

import {
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDividerModule,
  MatListModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatTabsModule,
} from "@angular/material"
import { DocumentationItems } from "../shared/documentation-items/documentation-items"
import { ComponentSidenavModule } from "../creator/pages/component-sidenav/component-sidenav"
import { MessageListItemComponent } from "./components/message-list-item/message-list-item.component"
import { AngularSvgIconModule } from "angular-svg-icon"
import { FroalaEditorModule, FroalaViewModule } from "angular-froala-wysiwyg"
import { SendmeChatDetailComponent } from "./components/sendme-chat-detail/sendme-chat-detail.component"
import { ChatRoomHeaderComponent } from "./components/chat-room-header/chat-room-header.component"
import { ChatSpeechBubbleComponent } from "./components/chat-speech-bubble/chat-speech-bubble.component"
import { ChatContentViewerComponent } from "./components/chat-content-viewer/chat-content-viewer.component"
import { BoardModule } from "../board/board.module"
import { ChatListItemComponent } from "./components/chat-list-item/chat-list-item.component"
import { ChatCommentComponent } from "./components/chat-comment/chat-comment.component"
import { StorageItemComponent } from "./components/storage-list-item/storage-list-item.component"
import { StorageContentContainerComponent } from "./components/storage-content-container/storage-content-container.component"
import { ExploreModule } from "../explore/explore.module"
import { SendmeContentsDetailStorageComponent } from "./pages/sendme-contents-detail-storage/sendme-contents-detail-storage.component"
import { StorageContentFilterComponent } from "./components/storage-content-filter/storage-content-filter.component"
import { EmptyIndicatorComponent } from "./components/empty-indicator/empty-indicator.component"
import { MessageHistoryListComponent } from "./components/message-history-list/message-history-list.component"
import { SentSendmeThumbnailComponent } from "./components/sent-sendme-thumbnail/sent-sendme-thumbnail.component"
import { SendmeInboxChatDetailComponent } from "./pages/sendme-inbox-chat-detail/sendme-inbox-chat-detail.component"
import { SendmeOutboxChatDetailComponent } from "./pages/sendme-outbox-chat-detail/sendme-outbox-chat-detail.component"
import { SendmeCreateService } from "./pages/sendme-create/sendme-create.service"
import { SelectContentDialogComponent } from "./pages/sendme-create/components/select-content-dialog/select-content-dialog.component"
import { SelectTypeScreenComponent } from "./pages/sendme-create/screens/select-type-screen/select-type-screen.component"
import { WriteSendmeScreenComponent } from "./pages/sendme-create/screens/write-sendme-screen/write-sendme-screen.component"
import { SendmePreviewScreenComponent } from "./pages/sendme-create/screens/sendme-preview-screen/sendme-preview-screen.component";
import { SendmePictorialComponent } from './components/sendme-pictorial/sendme-pictorial.component'

@NgModule({
  imports: [
    NgbModule,
    SharedModule,
    SendmeRoutingModule,
    MatTabsModule,
    ComponentSidenavModule,
    MatCardModule,
    AngularSvgIconModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    MatProgressSpinnerModule,
    MatSelectModule,
    MatDividerModule,
    MatRadioModule,
    MatListModule,
    MatProgressBarModule,
    MatCheckboxModule,
    BoardModule,
    ExploreModule,
    MatButtonModule,
  ],
  declarations: [
    SendmeInboxComponent,
    SendmeContentsStorageComponent,

    SendmeRootComponent,
    SendmeOutboxComponent,
    SendmeCreateComponent,
    MessageListItemComponent,
    SendmeChatDetailComponent,
    ChatRoomHeaderComponent,
    ChatSpeechBubbleComponent,
    ChatContentViewerComponent,
    ChatListItemComponent,
    ChatCommentComponent,
    StorageItemComponent,
    StorageContentContainerComponent,
    SendmeContentsDetailStorageComponent,
    StorageContentFilterComponent,
    EmptyIndicatorComponent,
    MessageHistoryListComponent,
    SentSendmeThumbnailComponent,
    SendmeInboxChatDetailComponent,
    SendmeOutboxChatDetailComponent,
    SelectTypeScreenComponent,
    WriteSendmeScreenComponent,
    SendmePreviewScreenComponent,
    SelectContentDialogComponent,
    SendmePictorialComponent,
  ],
  providers: [DocumentationItems, SendmeCreateService],
  entryComponents: [SelectContentDialogComponent],
})
export class SendmeModule {}
