import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import KeenSlider, { KeenSliderInstance } from "keen-slider"
import { Observable } from "rxjs"
import { CampaignPictorial } from "src/app/board/types/pictorial.type"
import { Breakpoint } from "src/app/shared/constants/constants"

@Component({
  selector: "app-model-contest-content-carousel",
  templateUrl: "./model-contest-content-carousel.component.html",
  styleUrls: ["./model-contest-content-carousel.component.scss"],
})
export class ModelContestContentCarouselComponent implements OnInit, OnDestroy {
  @Input() title: string
  @Input() subTitle: string
  @Input() contents$: Observable<CampaignPictorial[]>
  @Input() showRank = false

  @ViewChild("sliderRef", { static: false }) sliderRef: ElementRef<HTMLElement>
  slider: KeenSliderInstance = null
  limit = 9

  constructor(private router: Router) {}

  ngOnInit() {
    this.contents$.subscribe((contents) => {
      this.limit = contents.length
      contents.forEach((content) => content.campaignId)
      setTimeout(() => {
        if (!this.sliderRef) {
          return false
        }
        this.slider = new KeenSlider(this.sliderRef.nativeElement, {
          breakpoints: {
            [`(min-width: ${Breakpoint["sm"]})`]: {
              slides: { perView: 4, spacing: 8 },
            },
            [`(min-width: ${Breakpoint["lg"]})`]: {
              slides: { perView: 5, spacing: 16 },
            },
          },
          slides: { perView: 3, spacing: 6 },
        })
      })
    })
  }

  ngOnDestroy() {
    if (this.slider) {
      this.slider.destroy()
    }
  }

  moveToContentDetail(pictorialId: string) {
    this.router.navigate(["/pictorials", pictorialId])
  }

  onClickNext() {
    const nextSlideIdx = this.currentSlide + this.slidesPerView
    const lastSlideIdx = this.limit - this.slidesPerView

    if (nextSlideIdx >= lastSlideIdx) {
      this.slider.moveToIdx(lastSlideIdx)
    } else {
      this.slider.moveToIdx(nextSlideIdx)
    }
  }

  onClickPrev() {
    const prevSlideIdx = this.currentSlide - this.slidesPerView
    if (prevSlideIdx < 0) {
      this.slider.moveToIdx(0)
    } else {
      this.slider.moveToIdx(prevSlideIdx)
    }
  }

  get currentSlide() {
    return this.slider && this.slider.track.details && this.slider.track.details.rel
  }

  get slidesPerView() {
    const slides = (this.slider &&
      (this.slider.options.slides as unknown as { perView: number })) || { perView: 1 }
    return slides.perView
  }

  get isFirstSlide() {
    return this.currentSlide ? this.currentSlide === 0 : true
  }

  get isLastSlide() {
    return this.currentSlide === this.limit - this.slidesPerView
  }
}
