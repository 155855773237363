import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core"
import { UserService } from "src/app/account/services/user.service"
import { AuthService } from "src/app/core/services/auth.service"
import { PurchasePermission } from "src/app/creator/pages/creator-photobook-page/type"
import { CookieService } from "angular2-cookie/core"
import { Util } from "src/app/shared/utils/utils"
import { Pictorial } from "../../types/pictorial.type"

@Component({
  selector: "app-pictorial-thumbnail",
  templateUrl: "./pictorial-thumbnail.component.html",
  styleUrls: ["./pictorial-thumbnail.component.scss"],
})
export class PictorialThumbnailComponent implements OnInit, AfterViewInit {
  @Input() pictorial: Pictorial
  @Input() isAdultCreator: boolean
  @Input() isVerifiedCreator: boolean
  @Input() isPaid = false
  @Input() sendMeId = -1
  @Input() rank = null
  @Input() tierIds = []
  @ViewChild("img", { static: false }) img

  isMobile: boolean
  imgLoading = true
  isMine: boolean

  isBlur: boolean
  sendmeFree = false
  sendmePay = false
  langFlag: string

  constructor(
    private userService: UserService,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private cookieService: CookieService,
  ) {}

  ngOnInit() {
    const me = this.userService.getUser()
    this.isMobile = Util.isMobileDevice()
    this.isMine = me && this.pictorial.creatorId == me.creator
    this.langFlag = this.cookieService.get("lang") === "KOR" ? "KOR" : "ENG"
    if (!this.pictorial.thumbnailUri) {
      this.pictorial.thumbnailUri =
        this.pictorial.__covers__ &&
        this.pictorial.__covers__[0] &&
        this.pictorial.__covers__[0].file.url
    }
    this.setBlur()
    this.setSendmeFreeContent()
    this.setSendmePayContent()
    this.authService.getChangeEmitter().subscribe(() => {
      this.setBlur()
    })
  }

  setBlur() {
    this.isBlur =
      (!this.isMine && this.isAdultCreator && !this.authService.isAdult()) ||
      (this.pictorial.adult && !this.authService.isAdult())
  }

  setSendmeFreeContent() {
    if (this.pictorial.salesMethod == PurchasePermission.allFree && this.sendMeId > -1) {
      this.sendmeFree = true
    }
  }

  isFree() {
    return this.pictorial.salesMethod == PurchasePermission.allFree
  }

  isMembershipFree() {
    return this.pictorial.salesMethod == PurchasePermission.membershipFree
  }

  isPromotion() {
    return (
      this.pictorial.tierId &&
      this.tierIds &&
      this.tierIds.includes(this.pictorial.tierId) &&
      this.pictorial.salesMethod == PurchasePermission.membershipFree
    )
  }

  setSendmePayContent() {
    const { salesMethod } = this.pictorial

    if (
      (salesMethod == PurchasePermission.allPaid ||
        salesMethod == PurchasePermission.membershipPaid) &&
      !this.isPaid &&
      this.sendMeId > -1
    ) {
      this.sendmePay = true
    }
  }

  ngAfterViewInit() {
    const endLoading = () => {
      this.imgLoading = false
      this.cdr.detectChanges()
      this.img.nativeElement.removeEventListener("load", endLoading)
    }
    if (this.img) {
      this.img.nativeElement.addEventListener("load", endLoading)
    } else {
      this.imgLoading = false
      this.cdr.detectChanges()
    }
  }
}
