import { Component } from "@angular/core"

@Component({
  selector: "app-sendme-inbox",
  templateUrl: "./sendme-inbox.component.html",
  styleUrls: ["./sendme-inbox.component.scss"],
})
export class SendmeInboxComponent {
  constructor() {}
}
