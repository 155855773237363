import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core"
import { DocumentationItems } from "../../../shared/documentation-items/documentation-items"
import { MatIconModule, MatSidenav, MatSidenavModule, MatTabsModule } from "@angular/material"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import { ActivatedRoute, NavigationEnd, Params, Router, RouterModule } from "@angular/router"
import { CommonModule, Location } from "@angular/common"
import { ComponentHeaderModule } from "../../components/component-page-header/component-page-header.component"
import { combineLatest, Observable, Subject, Subscription } from "rxjs"
import { map } from "rxjs/operators"
import { animate, state, style, transition, trigger } from "@angular/animations"
import { CdkAccordionModule } from "@angular/cdk/accordion"
import { BreakpointObserver } from "@angular/cdk/layout"
import { TranslateModule, TranslateService } from "@ngx-translate/core"
import { CreatorService } from "../../services/creator.service"
import { UserService } from "src/app/account/services/user.service"

import { Menu } from "./types/menu.type"

const MIDDLE_WIDTH_BREAKPOINT = 960

@Component({
  selector: "app-component-sidenav",
  templateUrl: "./component-sidenav.html",
  styleUrls: ["./component-sidenav.scss", "./component-sidenav-sendme.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ComponentSidenav implements OnInit {
  @Input() menus: Menu[]
  @Input() displaySubmenuInChild: boolean = true
  @Input() className: string = ""
  params: Observable<Params>
  isScreenSmall: Observable<boolean>

  constructor(
    private _route: ActivatedRoute,
    private zone: NgZone,
    private breakpoints: BreakpointObserver,
  ) {
    this.isScreenSmall = breakpoints.observe(`(max-width: ${MIDDLE_WIDTH_BREAKPOINT}px)`).pipe(
      map((breakpoint) => {
        return breakpoint.matches
      }),
    )
  }

  @ViewChild(MatSidenav, { static: false }) sidenav: MatSidenav

  ngOnInit() {
    this.params = combineLatest(
      this._route.pathFromRoot.map((route) => route.params),
      Object.assign,
    )
  }
}

@Component({
  selector: "app-component-nav",
  templateUrl: "./component-nav.html",
  styleUrls: ["./component-sidenav.scss", "./component-sidenav-sendme.scss"],
  animations: [
    trigger("bodyExpansion", [
      state("collapsed", style({ height: "0px", display: "none" })),
      state("expanded", style({ height: "*", display: "block" })),
      transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4,0.0,0.2,1)")),
    ]),
  ],
})
export class ComponentNav implements OnInit, OnDestroy {
  creator
  currentPath
  date = new Date()
  year
  month
  SNSManageMembers = {
    id: 1,
    name: "MEMBERSHIP_MANAGEMENT.MANAGE_MEMBERS",
    items: [
      { name: "MEMBERSHIP_MANAGEMENT.MEMBER_LIST", link: "/contributor/memberships" },
      {
        name: "MEMBERSHIP_MANAGEMENT.BENEFITS_STATUS",
        link: "/contributor/membership-rewards",
      },
      {
        name: "MEMBERSHIP_MANAGEMENT.BLOCKED_USERS",
        link: "/contributor/membership-block-user",
      },
    ],
    link: "/contributor/memberships",
  }
  pictorialManageMembers = {
    id: 1,
    name: "MEMBERSHIP_MANAGEMENT.MANAGE_MEMBERS",
    items: [
      { name: "MEMBERSHIP_MANAGEMENT.MEMBER_LIST", link: "/contributor/memberships" },
      {
        name: "MEMBERSHIP_MANAGEMENT.BUYER_LIST",
        link: "/contributor/buyers",
      },
      {
        name: "MEMBERSHIP_MANAGEMENT.BLOCKED_USERS",
        link: "/contributor/membership-block-user",
      },
    ],
    link: "/contributor/memberships",
  }
  contributorMenus: Menu[] = [
    {
      id: 2,
      name: "MEMBERSHIP_MANAGEMENT.MANAGE_PERMISSIONS",
      link: "/contributor/membership-permission",
    },
    {
      id: 3,
      name: "MEMBERSHIP_MANAGEMENT.INCOME_ESTIMATE",
      link: "/contributor/membership-summary",
    },
    {
      id: 4,
      name: "MEMBERSHIP_MANAGEMENT.SYSTEM_ALERT",
      link: "/contributor/articles",
    },
  ]
  pictorialModeMenus: Menu[] = [
    {
      id: 1,
      name: this.translateService.instant("CREATOR_NAV.ROOT"),
      link: "/creators/update/settings",
    },
    {
      id: 2,
      name: this.translateService.instant("CREATOR_NAV.TIER"),
      link: "/creators/update/tiers",
    },
  ]
  SNSModeMenus = []
  creatorCommonMenus = [
    {
      id: 0,
      name: this.translateService.instant("CREATOR_NAV.BANK_ACCOUNT"),
      link: "/creators/update/bank-account",
    },
  ]

  @Input() params: Observable<Params>
  @Input() menus: Menu[] = []
  @Output() closeSidenav = new EventEmitter<void>()
  @Input() displaySubmenuInChild: boolean = true
  @Input() className: string = ""

  isInChildRoute: boolean = false
  isLoaded: boolean = false

  private _onDestroy = new Subject<void>()
  activeLinkIndex = -1
  activeSubLinkIndex = -1
  private subPath: string = ""

  routerEventsSubscription: Subscription

  constructor(
    protected protectedRouter: Router,
    private router: Router,
    private route: ActivatedRoute,
    private creatorService: CreatorService,
    private userService: UserService,
    private location: Location,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    // path 에 따라 설정페이지 or 멤버관리 페이지를 보여준다
    this.currentPath = this.location.path()
    this.year = this.date.getFullYear()
    this.month = this.date.getMonth() + 1
    this.initCreatorAndNavLinks()

    this.SNSModeMenus = [
      {
        id: 1,
        name: this.translateService.instant("CREATOR_NAV.ROOT"),
        link: "/creators/update/settings",
      },
      {
        id: 2,
        name: this.translateService.instant("CREATOR_NAV.TIER"),
        link: "/creators/update/tiers",
      },
      {
        id: 3,
        name: this.translateService.instant("CREATOR_NAV.GOAL"),
        link: "/creators/update/goals/" + this.year + "/" + this.month + "/activated",
      },
      {
        id: 4,
        name: this.translateService.instant("CREATOR_NAV.THANK_YOU_VID"),
        link: "/creators/update/thank-you",
      },
    ]
    this.setActiveLink()
    this.isLoaded = true
    this.routerEventsSubscription = this.router.events.subscribe((res) => {
      if (res instanceof NavigationEnd) {
        this.setActiveLink()
        this.currentPath = this.location.path()
      }
    })
  }

  ngOnDestroy() {
    this.routerEventsSubscription.unsubscribe()
    this._onDestroy.next()
    this._onDestroy.complete()
  }

  navTabClick(event: PointerEvent) {
    const attr = (event.target as HTMLElement).attributes

    let index: string
    if (attr.getNamedItem("data-index")) {
      index = attr.getNamedItem("data-index").value
    }

    if (!index && attr.getNamedItem("aria-posinset")) {
      index = (Number(attr.getNamedItem("aria-posinset").value) - 1).toString()
    }

    if (!index) {
      return
    }

    this.router.navigate([this.menus[index].link])
  }

  // 크리에이터 설정 탭
  // SNS모드 인지 화보모드 인지에 따라 navLinks 가 달라야 한다
  // this.creator 에 SNS모드 인지 화보모드 인지 보일 것
  initCreatorAndNavLinks() {
    const user = this.userService.getUser()
    if (this.currentPath.includes("/contributor/")) {
      this.menus = [
        user.creator_type === "sns" ? this.SNSManageMembers : this.pictorialManageMembers,
        ...this.contributorMenus,
      ]
      this.creatorService.get("me").subscribe((res) => {
        this.creator = res
        this.menus = [
          this.isSNSMode() ? this.SNSManageMembers : this.pictorialManageMembers,
          ...this.contributorMenus,
        ]
        this.setActiveLink()
      })
    } else if (!this.menus) {
      this.menus = user.creator_type === "sns" ? this.SNSModeMenus : this.pictorialModeMenus
      this.creatorService.get("me").subscribe((res) => {
        this.creator = res
        if (this.currentPath.includes("/creators/") && this.isSNSMode()) {
          this.menus = this.SNSModeMenus
        } else {
          this.menus = this.pictorialModeMenus
        }
        this.menus = this.menus.concat(this.creatorCommonMenus)
        this.setActiveLink()
      })
    } else {
      this.setActiveLink()
    }
  }

  setActiveLink() {
    this.activeLinkIndex = this.menus.findIndex((tab) => {
      // 쿼리스트링을 제외한 path

      const path = this.router.url.split("?")[0]
      const tabUrlMatched = path === tab.link

      this.isInChildRoute = false

      if (!tabUrlMatched) {
        if (!tab.items) {
          return path.includes(tab.link)
        }

        this.activeSubLinkIndex = tab.items.findIndex((item) => {
          // 차일드 라우트에 있는 경우
          if (item.link && path.includes(item.link.concat("/"))) {
            this.isInChildRoute = true
            this.subPath = path.replace(tab.link.concat("/"), "")
          }

          return path.includes(item.link)
        })

        return this.activeSubLinkIndex > -1
      }

      this.isInChildRoute = false
      return tabUrlMatched
    })
  }

  isSNSMode() {
    return this.creator.creator_type == "sns"
  }

  getType(param: unknown) {
    return typeof param
  }
}

@NgModule({
  imports: [
    MatSidenavModule,
    RouterModule,
    CommonModule,
    ComponentHeaderModule,
    BrowserAnimationsModule,
    MatIconModule,
    CdkAccordionModule,
    TranslateModule,
    MatTabsModule,
  ],
  exports: [ComponentSidenav, ComponentNav],
  declarations: [ComponentSidenav, ComponentNav],
  providers: [DocumentationItems],
})
export class ComponentSidenavModule {}
