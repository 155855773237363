import { Injectable } from "@angular/core"
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"
import { CreatorService } from "../../../services/creator.service"
import { UserService } from "../../../../account/services/user.service"

@Injectable({
  providedIn: "root",
})
export class CreatorDetailPageGuardService implements CanActivate {
  constructor(
    private router: Router,
    private creatorService: CreatorService,
    private userService: UserService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | boolean {
    const creatorId = next.params.id

    return this.creatorService.get(creatorId).pipe(
      map((res) => {
        const creator = res
        const user = this.userService.getUser()
        const hasAdultContent = creator["has_adult_content"] || false

        if (creator["id"] !== user.creator && hasAdultContent && !user.verified_as_adult) {
          this.router.navigate(["", { outlets: { popup: ["users", "verify-adult"] } }])
          return false
        }

        return true
      }),
    )
  }
}
