import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { Router } from "@angular/router"
import { UserService } from "src/app/account/services/user.service"
import { PostService } from "src/app/board/services/post.service"
import { MediaType } from "src/app/board/types/post.type"
import { PostCategory } from "src/app/board/types/post.type"
import { SendmeCreateService } from "src/app/sendme/pages/sendme-create/sendme-create.service"
import { PostMessagesService } from "src/app/sendme/services/post-messages.service"
import { Message, MessageType } from "src/app/sendme/types/message.type"
import { Form } from "src/app/shared/model/form"
import { FormService } from "src/app/shared/services/form.service"

@Component({
  selector: "app-sendme-preview-screen",
  templateUrl: "./sendme-preview-screen.component.html",
  styleUrls: ["./sendme-preview-screen.component.scss"],
})
export class SendmePreviewScreenComponent implements OnInit {
  @Input() sendmeCreateService: SendmeCreateService
  @Output() toPrevPage = new EventEmitter()

  message: Message
  form: Form
  createLoading = false
  constructor(
    private userService: UserService,
    private postService: PostService,
    private formService: FormService,
    private messageService: PostMessagesService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.setForm()
    this.message = {
      created: new Date().toISOString(),
      user: this.userService.getUser().id,
      post: {
        title: this.sendmeCreateService.title,
        content: this.sendmeCreateService.content,
        category: PostCategory.message,
        comment_enabled: false,
        comment_visible: false,
        creator: this.sendmeCreateService.creator,
        id: 0,
        is_app_only: false,
        media_type: MediaType.text,
        user: this.userService.getUser(),
        polls: [],
        tags: [],
        thumbnail: "",
        thumbnails: [],
        permissions: ["view"],
        pictorial_id:
          this.sendmeCreateService.selectedContent && this.sendmeCreateService.selectedContent.id,
      },
      creator: this.sendmeCreateService.creator,
      id: 0,
      is_paid: true,
      is_read: true,
      message_type:
        this.sendmeCreateService.sendmeType === "pictorial"
          ? MessageType.pictorial
          : MessageType.post,
      specific_region_excluded: false,
      blocked: [],
      pictorial: this.sendmeCreateService.selectedContent,
    }
  }

  setForm() {
    const url = "posts/"
    this.formService.getFormData(url).subscribe((form) => {
      this.form = form
    })
  }

  async onSubmit() {
    this.createLoading = true

    try {
      const post = await this.createPost()
      await this.createMessage(post.id)

      this.router.navigate(["/sendme/sent", post.id])
    } catch (e) {
      console.log(e)
    } finally {
      this.createLoading = false
    }
  }

  private async createPost() {
    this.form.formGroup.value = {
      title: this.sendmeCreateService.title,
      content: this.sendmeCreateService.content,
      amount_usd: this.sendmeCreateService.priceUsd,
      attach_file_ids: [],
      id: "",
      is_adult_content: false,
      is_wysiwyg: true,
      media_type: MediaType.text,
      polls: [],
      tags: [],
      video_embed: "temporary",
      visibility: 0,
      category: "message",
      pictorial_id:
        this.sendmeCreateService.selectedContent && this.sendmeCreateService.selectedContent.id,
    }

    return await this.postService.create(this.form).toPromise()
  }

  private async createMessage(postId: number) {
    return this.messageService
      .createMessage({
        message_type: this.sendmeCreateService.sendmeType,
        post: postId,
        send: true,
        specific_region_excluded: this.sendmeCreateService.specificRegionExcluded,
        target_type: "member",
        visibility: 0,
        ...(this.sendmeCreateService.target === "membership"
          ? { target_type: "member", tier_ids: this.sendmeCreateService.selectedMembershipIds }
          : {}),
        ...(this.sendmeCreateService.target === "tag"
          ? { target_type: "tag", user_tags: this.sendmeCreateService.selectedTagIds }
          : {}),
      })
      .toPromise()
  }

  onClickEdit() {
    this.toPrevPage.emit(null)
  }
}
