import { Component, OnChanges, OnInit } from "@angular/core"
import { SendmeCreateService } from "./sendme-create.service"
import { CreatorService } from "src/app/creator/services/creator.service"

// 미리 만들어 둠
@Component({
  selector: "app-sendme-create",
  templateUrl: "./sendme-create.component.html",
  styleUrls: ["./sendme-create.component.scss"],
})
export class SendmeCreateComponent implements OnInit {
  screen: "selectType" | "write" | "preview" = "selectType"
  sendmeCreateService: SendmeCreateService

  constructor(protected creatorService: CreatorService) {
    this.sendmeCreateService = new SendmeCreateService(this.creatorService)
    this.sendmeCreateService.init().add(() => {
      if (this.sendmeCreateService.creator.creator_type === "sns") {
        this.sendmeCreateService.sendmeType = "post"
        this.sendmeCreateService.isFree = true
        this.toWritePage()
      }
    })
  }

  ngOnInit() {}

  toWritePage() {
    this.screen = "write"
  }

  toPreviewPage() {
    this.screen = "preview"
  }
}
