import { EventEmitter, Injectable } from "@angular/core"
import KeenSlider, {
  KeenSliderHooks,
  KeenSliderInstance,
  KeenSliderOptions,
  KeenSliderPlugin,
} from "keen-slider"

const animation = { duration: 6000, easing: (t) => t }

@Injectable({
  providedIn: "root",
})
export class SliderService {
  change = new EventEmitter()
  constructor() {}

  emitChangeEvent(s) {
    this.change.emit(s)
  }
  getChangeEmitter() {
    return this.change
  }

  getSlide(
    ref,
    options?: KeenSliderOptions,
    plugins?: KeenSliderPlugin<{}, {}, KeenSliderHooks>[],
  ) {
    const carouselOption: KeenSliderOptions = {
      loop: true,
      renderMode: "performance",
      drag: false,
      breakpoints: {
        "(max-width: 719px)": {
          slides: { perView: 4, spacing: 12 },
        },
        "(min-width: 720px)": {
          slides: { perView: 7, spacing: 21 },
        },
      },
      created(s) {
        s.moveToIdx(1, true, animation)
      },
      updated(s) {
        s.moveToIdx(s.track.details.abs + 1, true, animation)
      },
      animationEnded(s) {
        s.moveToIdx(s.track.details.abs + 1, true, animation)
      },
      ...options,
    }

    const slider = new KeenSlider(ref, carouselOption, plugins)

    return slider
  }

  updateOptions(s: KeenSliderInstance, ref: any) {
    if (s.options.slides && (s.options.slides as any).perView >= s.track.details.slides.length) {
      ;(s.options.slides as any).perView = s.track.details.slides.length

      if (s.slides.length == 0) {
        const dummy = document.createElement("div")
        dummy.classList.add("keen-slider__slide")
        dummy.style.width = "100%"
        dummy.style.height = "100%"
        ref.appendChild(dummy)
      } else {
        ;(s.options.slides as any).perView = s.track.details.slides.length - 1
      }
      s.update(s.options)
    }
  }
}
