import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { environment } from "src/environments/environment"

@Injectable({
  providedIn: "root",
})
export class PollService {
  constructor(private http: HttpClient) {}

  create(postId: any, data: any) {
    const url = `${environment.apiUrl}posts/${postId}/polls/`
    return this.http.post(url, data)
  }

  get(postId, id) {
    return this.http.get(`${environment.apiUrl}posts/${postId}/polls/${id}/`)
  }

  update(postId: any, id: any, data: any) {
    const url = `${environment.apiUrl}posts/${postId}/polls/${id}/`
    return this.http.put(url, data)
  }

  delete(postId: any, id: any) {
    const url = `${environment.apiUrl}posts/${postId}/polls/${id}/`
    return this.http.delete(url)
  }

  vote(postId: any, id: any, data: any) {
    const url = `${environment.apiUrl}posts/${postId}/polls/${id}/vote/`
    return this.http.post(url, data)
  }
}
