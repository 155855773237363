import { Injectable } from "@angular/core"

const API_KEY = "AIzaSyAkCbG9rpKHOD8DQZ-oDmfPGGEyjfLezFc"
const IOS_APP_STORE_ID = "1481547544"
@Injectable({
  providedIn: "root",
})
export class DynamicLinkService {
  constructor() {}

  async openContentsLink(creatorId: string, contentsId: string) {
    const result = await this._createDynamicLink(`creator/${creatorId}/contents/${contentsId}`)
    window.location.href = result.shortLink
  }
  async openCreatorLink(creatorId: string) {
    const result = await this._createDynamicLink(`creator/${creatorId}`)
    window.location.href = result.shortLink
  }

  private async _createDynamicLink(path: string) {
    const URL = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${API_KEY}`
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        dynamicLinkInfo: {
          domainUriPrefix: "https://memberme.page.link",
          link: `https://memberme.page.link/${path}`,
          androidInfo: {
            androidPackageName: "com.fatkat.memberme",
          },
          iosInfo: {
            iosBundleId: "com.fatkat.member.me",
            iosAppStoreId: IOS_APP_STORE_ID,
          },
        },
      }),
    }
    const response = await fetch(URL, options)
    return response.json()
  }
}
