import { Component, ElementRef, Input, ViewChild } from "@angular/core"
import { CommentService } from "src/app/board/services/comment.service"
import { CommentContentType } from "src/app/board/types/comment.type"
import { PostCommentPayload } from "src/app/board/types/ajax.type"
import { SnackbarService } from "src/app/shared/services/snackbar.service"
import { Util } from "src/app/shared/utils/utils"
@Component({
  selector: "app-chat-comment",
  templateUrl: "./chat-comment.component.html",
  styleUrls: ["./chat-comment.component.scss"],
})
export class ChatCommentComponent {
  @Input() parentCommentId?: number
  @Input() postId: number
  @ViewChild("commentInput", { static: false }) commentInput: ElementRef<HTMLInputElement>

  isLocked: boolean = false

  constructor(private commentService: CommentService, private snackbarService: SnackbarService) {}

  async postComment(content) {
    this.commentInput.nativeElement.value = ""

    const param: PostCommentPayload = {
      content,
      content_type: this.parentCommentId ? CommentContentType.comment : CommentContentType.post,
      object_id: this.parentCommentId || this.postId,
    } as PostCommentPayload

    try {
      const resp = await this.commentService.create(param).toPromise()

      if (!this.parentCommentId) {
        this.parentCommentId = resp.id
      }

      this.isLocked = false
    } catch (error) {
      this.showErrorSnackbar(error)
    }
  }

  onEnter($event: KeyboardEvent) {
    if ($event.key === "Enter") {
      this.postMessage(this.commentInput.nativeElement.value)
    }
  }

  onClick() {
    this.postMessage(this.commentInput.nativeElement.value)
  }

  postMessage(value: string) {
    if (!this.isLocked && value) {
      this.isLocked = true
      this.postComment(value)
    }
  }
  showErrorSnackbar(error: any) {
    this.snackbarService.open(Util.getErrorTxt(error.error), "", 2000, "danger-snackbar")
  }
}
