import { Component, OnInit } from "@angular/core"
import { Contest } from "../../types"
import { ActivatedRoute, Data, Router } from "@angular/router"
import { ModelContestDataService } from "../../services/model-contest-data.service"

@Component({
  selector: "app-model-contest-top-page",
  templateUrl: "./model-contest-top-page.component.html",
  styleUrls: ["./model-contest-top-page.component.scss"],
})
export class ModelContestTopPageComponent implements OnInit {
  lang: string
  contest: Contest
  isLoading: boolean

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public modelContestDataService: ModelContestDataService,
  ) {}

  ngOnInit() {
    this.route.data.subscribe((data) => {
      if (!this.isActivated()) {
        this.router.navigateByUrl("404")
      }
      this.setContest()
      this.modelContestDataService.init(this.contest.id)
    })
  }

  isActivated() {
    return this.route.snapshot.data.contest && this.route.snapshot.data.contest.is_activated
  }

  setContest() {
    this.lang = this.route.snapshot.data.lang
    this.contest = this.route.snapshot.data.contest
  }
}
