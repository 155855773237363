import { Component, Input, OnInit } from "@angular/core"
import { Router } from "@angular/router"

import { CookieService } from "angular2-cookie/core"
import { AuthService } from "src/app/core/services/auth.service"
import { CreatorService } from "../../services/creator.service"

@Component({
  selector: "app-tier-list-card",
  templateUrl: "./tier-list-card.component.html",
  styleUrls: ["./tier-list-card.component.scss"],
})
export class TierListCardComponent implements OnInit {
  @Input() tiers: any[]
  //
  langFlag

  constructor(
    private cookieService: CookieService,
    private creatorService: CreatorService,
    private authService: AuthService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.tiers = this.tiers.filter((tier) => tier.visible)
    if (this.cookieService.get("lang") !== "KOR") {
      this.langFlag = "ENG"

      // temporary fix for perks schedule
      for (let i = 0; i < this.tiers.length; i++) {
        if (this.tiers[i].rewards && this.tiers[i].rewards.length > 0) {
          for (let j = 0; j < this.tiers[i].rewards.length; j++) {
            if (this.tiers[i].rewards[j].delivery_schedule === "월 정기") {
              this.tiers[i].rewards[j].delivery_schedule = "Monthly"
            } else {
              this.tiers[i].rewards[j].delivery_schedule = "One-time"
            }
          }
        }
      }
    } else {
      this.langFlag = "KOR"
    }
  }

  onClickBecomeMember(tier) {
    if (this.creatorService.currentCreator.has_adult_content && !this.authService.isAdult()) {
      this.authService.verifyAdult()
    } else {
      this.router.navigate(["/creators", tier.creator, "contribute"])
    }
  }
}
