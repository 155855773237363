import { Component, Inject, OnInit } from "@angular/core"
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material"
import { NgbModal } from "@ng-bootstrap/ng-bootstrap"
import { DynamicLinkService } from "src/app/board/services/dynamic-link.service"
import { PictorialPaymentResult } from "src/app/board/types/pictorial-payment.type"
import { PurchasePermission } from "src/app/creator/pages/creator-photobook-page/type"
import { AppDownloadModalComponent } from "src/app/shared/components/app-download-modal/app-download-modal.component"
import { Util } from "src/app/shared/utils/utils"

@Component({
  selector: "app-pictorial-payment-success-dialog",
  templateUrl: "./pictorial-payment-success-dialog.component.html",
  styleUrls: ["./pictorial-payment-success-dialog.component.scss"],
})
export class PictorialPaymentSuccessDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<PictorialPaymentSuccessDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PictorialPaymentResult,
    private dynamicLinkService: DynamicLinkService,
    public modalService: NgbModal,
  ) {}

  isMobile = false

  ngOnInit() {
    this.isMobile = Util.isMobileDevice()
  }

  onClickWatchContent() {
    this.close()
    if (this.data.pictorial.visibleOnWeb) {
      setTimeout(() => {
        document.getElementsByClassName("mat-tab-group")[0].scrollIntoView()
      }, 1)
    } else if (this.isMobile) {
      this.dynamicLinkService.openContentsLink(String(this.data.creator.id), this.data.pictorial.id)
    } else {
      this.modalService.open(AppDownloadModalComponent, { centered: true })
    }
  }

  close() {
    window.history.back()
    this.dialogRef.close()
  }

  get isIndividualSale() {
    return (
      this.data.pictorial.salesMethod == PurchasePermission.allPaid ||
      this.data.pictorial.salesMethod == PurchasePermission.membershipPaid ||
      this.data.pictorial.availableSale
    )
  }
}
