import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { environment } from "../../../environments/environment"
import { CreatorFileSizeLimit } from "../types"

@Injectable({
  providedIn: "root",
})
export class CreatorFileSizeLimitService {
  constructor(private http: HttpClient) {
  }

  get() {
    return this.http.get<CreatorFileSizeLimit>(
      environment.apiUrl + "creator-file-size-limit/me/",
    )
  }
}
