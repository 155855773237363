import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { TranslateService } from "@ngx-translate/core"
import { MatDialog } from "@angular/material"
import { Form } from "src/app/shared/model/form"
import { FormService } from "src/app/shared/services/form.service"
import { UserService } from "src/app/account/services/user.service"
import { AuthService } from "src/app/core/services/auth.service"
import { EventService } from "src/app/core/services/event.service"
import { CreatorService } from "src/app/creator/services/creator.service"
import { SnackbarService } from "src/app/shared/services/snackbar.service"
import { MessagingComponent } from "src/app/shared/components/messaging/messaging.component"
import { CommentService } from "../../services/comment.service"

@Component({
  selector: "app-comment-form",
  templateUrl: "./comment-form.component.html",
  styleUrls: ["./comment-form.component.scss"],
})
export class CommentFormComponent extends MessagingComponent implements OnInit {
  @Output() submitted = new EventEmitter<any>()
  @Input() contentType
  @Input() objectId
  @Input() postCategory
  @Input() isBlockedUser
  @Input() className: string = ""
  form: Form
  commentFields = {
    content: {
      type: "string",
      required: true,
      label: "내용",
      max_length: 8000,
      style: {
        placeholder: "댓글을 남겨주세요.",
      },
      widget: {
        name: "input",
        attrs: {
          type: "text",
          maxlength: 8000,
        },
      },
    },
    content_type: {
      type: "choice",
      required: true,
      label: "Content type",
      choices: [
        {
          value: "post",
          display_name: "포스트",
        },
        {
          value: "comment",
          display_name: "댓글",
        },
      ],
      widget: {
        name: "select",
        attrs: {},
      },
    },
    object_id: {
      type: "integer",
      required: true,
      label: "Object id",
      widget: {
        name: "input",
        attrs: {
          type: "number",
        },
      },
    },
  }
  thumbnail
  inProgress = false

  constructor(
    public auth: AuthService,
    public event: EventService,
    private commentService: CommentService,
    private creatorService: CreatorService,
    private userService: UserService,
    private formService: FormService,
    protected snackbarService: SnackbarService,
    protected translateService: TranslateService,
    protected dialog: MatDialog,
  ) {
    super(snackbarService, translateService, dialog)
    event.onUserUpdate$.subscribe(this.ngOnInit.bind(this))
  }

  ngOnInit() {
    this.commentFields.content.style.placeholder = this.translate(
      "COMMENT_DETAIL.COMMENT_FIELD_PLACEHOLDER",
    )

    if (this.auth.isAuthenticated()) {
      this.initForm()
      const user = this.userService.getUser()

      if (user.is_creator_verified) {
        this.thumbnail = user.creator_thumbnail
      } else {
        this.thumbnail = user.thumbnail
      }
    } else {
      this.form = null
      this.thumbnail = null
    }
  }

  initForm() {
    this.form = this.formService.getFormFromFields(this.commentFields)
  }

  onSubmit() {
    if (this.isBlockedUser) {
      this.messageDanger(this.translate("CREATOR_DETAIL_PAGE.BLOCKED_MESSAGE"))
      this.form.formGroup.get("content").setValue("")
      return false
    } else {
      this.inProgress = true
      this.form.formGroup.patchValue({
        content_type: this.contentType,
        object_id: this.objectId,
      })
      this.commentService.create(this.form.formGroup.value, this.form).subscribe(
        (comment) => {
          this.form.formGroup.get("content").setValue("")
          this.submitted.emit(comment)
          this.inProgress = false
        },
        (error) => {
          this.inProgress = false
        },
      )
    }
  }

  onKeyupEvent(ev) {
    if (ev && ev.key === "Enter" && this.form.formGroup.get("content")) {
      this.onSubmit()
    }
  }
}
