import { Component, Input, OnInit } from "@angular/core"
import { TranslateService } from "@ngx-translate/core"
import { Pictorial } from "src/app/board/types/pictorial.type"
import { ContestPictorialRegistryService } from "src/app/creator/services/contest-pictorial-registry.service"

@Component({
  selector: "app-model-contest-apply-btn",
  templateUrl: "./model-contest-apply-btn.component.html",
  styleUrls: ["./model-contest-apply-btn.component.scss"],
})
export class ModelContestApplyBtnComponent implements OnInit {
  @Input() isMobile: boolean
  @Input() langFlag: string
  @Input() pictorial: Pictorial
  @Input() showDataText = true
  isRegistered: boolean
  isMouseEnter: boolean
  private applyModelContestTranslatePrefix = "CONTEST.MODEL_CONTEST.APPLY_PICTORIAL."

  constructor(
    private translateService: TranslateService,
    public contestRegistryService: ContestPictorialRegistryService,
  ) {}

  ngOnInit() {
    this.contestRegistryService.getRegisteredPictorial(this.pictorial.id).subscribe((pictorial) => {
      this.isRegistered = !!pictorial
    })
  }

  getRegisterBtnText() {
    let translateKey: string

    if (!this.isRegistered) {
      translateKey = this.applyModelContestTranslatePrefix + "APPLY"
    } else {
      translateKey =
        this.applyModelContestTranslatePrefix + (this.isMouseEnter ? "REVERT" : "APPLIED")
    }

    return this.translateService.instant(translateKey)
  }

  changeApplyStatus() {
    this.contestRegistryService
      .changeAppliedStatus(this.pictorial.id, this.isRegistered)
      .subscribe(() => {
        this.isRegistered = !this.isRegistered
      })
  }

  setMouseEnter(isMouseEnter: boolean) {
    this.isMouseEnter = isMouseEnter
  }
}
