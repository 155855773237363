import { EventEmitter, Injectable, OnDestroy, OnInit } from "@angular/core"
import { UserService } from "src/app/account/services/user.service"
import { PurchasePermission } from "src/app/creator/pages/creator-photobook-page/type"
import { CreatorService } from "src/app/creator/services/creator.service"
import { TierService } from "src/app/creator/services/tier.service"
import { Creator, MembershipTier } from "src/app/creator/types"
import { TransactionService } from "src/app/payment/services/transaction.service"
import { Pictorial } from "src/app/board/types/pictorial.type"
import { PictorialPaymentResult } from "src/app/board/types/pictorial-payment.type"
import { PictorialService } from "../../services/pictorial.service"
import { DynamicLinkService } from "../../services/dynamic-link.service"
import { AuthService } from "src/app/core/services/auth.service"
import { Subscription } from "rxjs"

export interface Information {
  key: string
  key_eng: string
  value: any
}

@Injectable({
  providedIn: null,
})
export class PictorialDetailPageService {
  pictorialId: string
  pictorial: Pictorial
  creator: Creator
  tier: MembershipTier
  contentPayment: { count: number }

  authSubscription: Subscription

  constructor(
    private pictorialService: PictorialService,
    private tierService: TierService,
    private creatorService: CreatorService,
    private userService: UserService,
    private authService: AuthService,
    private transactionService: TransactionService,
    private dynamicLinkService: DynamicLinkService,
  ) {}

  ngOnInit() {}

  destroy() {
    this.contentPayment = null
    this.creator = null
    this.authSubscription.unsubscribe()
  }

  async init(id: string) {
    this.pictorialId = id
    await this.fetchPictorial()
    await Promise.all([this.fetchTier(), this.fetchCreatorInfo(), this.fetchPurchased()])
    this.authSubscription = this.authService.getChangeEmitter().subscribe(() => {
      this.fetchPurchased()
    })
  }

  async fetchPictorial() {
    this.pictorial = await this.pictorialService.get(this.pictorialId).toPromise()
  }

  async fetchTier() {
    if (this.pictorial.tierId) {
      this.tier = await this.tierService.get(this.pictorial.tierId).toPromise()
    } else {
      this.tier = {} as MembershipTier
    }
  }

  async fetchCreatorInfo() {
    this.creator = await this.creatorService.get(this.pictorial.creatorId).toPromise()
  }

  async fetchPurchased() {
    if (this.isLoggedIn) {
      const payments = await this.transactionService
        .getPictorialPayment({ pictorial_id: this.pictorialId })
        .toPromise()
      this.contentPayment = payments
    } else {
      this.contentPayment = { count: 0 }
    }
  }

  async updateLike() {
    const isLiked = this.pictorial.isLiked
    if (isLiked) {
      await this.pictorialService.deleteLike(this.pictorial.id).toPromise()
    } else {
      await this.pictorialService.postLike(this.pictorial.id).toPromise()
    }
    this.pictorial.isLiked = !isLiked
    if (this.pictorial.likeCount) {
      this.pictorial.likeCount += isLiked ? -1 : 1
    } else {
      this.pictorial.likeCount = isLiked ? 0 : 1
    }
  }

  async updateVote() {
    await this.pictorialService.postVote(this.pictorial.id, this.pictorial.campaignId).toPromise()
    this.pictorial.isVotedToday = true
    if (this.pictorial.voteCount) {
      this.pictorial.voteCount += 1
    } else {
      this.pictorial.voteCount = 1
    }
  }

  async updateSuperVote() {
    const price = this.pictorial.priceUsd || 0
    const voteWeight = Math.floor(price * 10)
    const isSalesPictorial =
      this.pictorial.salesMethod === PurchasePermission.allPaid ||
      this.pictorial.salesMethod === PurchasePermission.membershipPaid ||
      this.pictorial.salesMethod === PurchasePermission.allFree ||
      this.pictorial.availableSale
    if (voteWeight < 1 || !isSalesPictorial) {
      return
    }
    await this.pictorialService
      .postVote(this.pictorial.id, this.pictorial.campaignId, voteWeight)
      .toPromise()
    this.pictorial.isVotedToday = true

    if (this.pictorial.voteCount) {
      this.pictorial.voteCount += voteWeight
    } else {
      this.pictorial.voteCount = voteWeight
    }
  }

  get isLoading() {
    return !this.pictorial || !this.creator || !this.tier || !this.contentPayment
  }

  get status() {
    const isMine = this.isLoggedIn
      ? this.pictorial.creatorId == this.userService.getUser().creator
      : false
    const joinedMembership = this.creator.membership
    const membershipAmount = joinedMembership ? joinedMembership.tier.amount : 0
    const membershipAmountUsd = joinedMembership ? joinedMembership.tier.amount_usd : 0
    let isPurchased = this.pictorial.availableSale ? !!this.contentPayment.count : true
    let isFree = this.pictorial.free === true
    let notRequireMembership = !this.pictorial.tierId

    let availableAccess: boolean | undefined

    if (this.pictorial.salesMethod) {
      isFree = this.pictorial.salesMethod === PurchasePermission.allFree
      isPurchased = [PurchasePermission.allPaid, PurchasePermission.membershipPaid].includes(
        this.pictorial.salesMethod,
      )
        ? !!this.contentPayment.count || this.pictorial.priceUsd === 0
        : true
      notRequireMembership = [PurchasePermission.allFree, PurchasePermission.allPaid].includes(
        this.pictorial.salesMethod,
      )
    }

    if (!this.pictorial) {
    } else if (isMine || isFree) {
      availableAccess = true
    } else if (notRequireMembership) {
      availableAccess = isPurchased
    } else if (this.tier) {
      if (
        (this.tier.amount_usd <= membershipAmountUsd || this.tier.amount <= membershipAmount) &&
        isPurchased
      ) {
        availableAccess = true
      } else {
        availableAccess = false
      }
    }

    return {
      isFree,
      isMine,
      isPurchased,
      membershipAmount,
      availableAccess,
      notRequireMembership,
    }
  }

  get isLoggedIn() {
    return this.authService.isLoggedIn()
  }
}
