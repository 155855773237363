import { Component, HostListener } from "@angular/core"

@Component({
  selector: "app-scroll-top-button",
  templateUrl: "./scroll-top-button.component.html",
  styleUrls: [
    "./scroll-top-button.component.scss",
    "./model-contest-scroll-top-button.component.scss",
  ],
})
export class ScrollTopButtonComponent {
  isVisible = false

  @HostListener("window:scroll", []) onWindowScroll() {
    // do some stuff here when the window is scrolled
    const verticalOffset =
      window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0

    if (verticalOffset > 200 && !this.isVisible) {
      this.isVisible = true
    } else if (this.isVisible && verticalOffset <= 200) {
      this.isVisible = false
    }
  }

  constructor() {
    this.isVisible =
      window.pageYOffset != 0 ||
      document.documentElement.scrollTop != 0 ||
      document.body.scrollTop != 0
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }
}
