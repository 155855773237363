import { Component, Input, OnInit } from "@angular/core"
import { ArticleService } from "../../services/article.service"

@Component({
  selector: "app-article-list-sidebar",
  templateUrl: "./article-list-sidebar.component.html",
  styleUrls: ["./article-list-sidebar.component.scss"],
})
export class ArticleListSidebarComponent implements OnInit {
  @Input() category
  nextUrl
  articles

  constructor(private articleService: ArticleService) {}

  ngOnInit() {
    this.setPaginatedResult()
  }

  setPaginatedResult() {
    this.articleService.getListPaginated({ category: this.category }).subscribe((res) => {
      this.nextUrl = res["next"]
      this.articles = res["results"]
    })
  }

  setPaginatedResultNext() {
    this.articleService.getListPaginatedByUrl(this.nextUrl).subscribe((res) => {
      this.nextUrl = res["next"]
      this.articles = this.articles.concat(res["results"])
    })
  }
}
