import { Component, OnInit } from "@angular/core"
import { NavigationEnd, NavigationStart, Router, RouterOutlet } from "@angular/router"
import { Location } from "@angular/common"
import { CookieService } from "angular2-cookie/core"
import { TranslateService } from "@ngx-translate/core"

import { UserService } from "./account/services/user.service"
import { AuthService } from "./core/services/auth.service"
import { CountryService } from "./shared/services/country.service"
import { filter } from "rxjs/operators"
import { UserTagModalService } from "./shared/services/user-tag-modal.service"

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  title = "fatkat"
  isApp = false // App인지 Web인지 구분하는 구분자.
  private scrollPositions: { [url: string]: number } = {}
  private currentUrl: string
  constructor(
    private router: Router,
    private auth: AuthService,
    private userService: UserService,
    private cookieService: CookieService,
    private translate: TranslateService,
    private location: Location,
    private countryService: CountryService,
    private userTagModalService: UserTagModalService,
  ) {
    // translate.setDefaultLang('ko');
    if (!this.cookieService.get("lang")) {
      translate.use("en")
      this.cookieService.put("lang", "ENG")
    } else {
      // language flag cookie is already set
      if (this.cookieService.get("lang") === "KOR") {
        translate.use("ko")
      } else {
        translate.use("en")
      }
    }

    // QueryString 에 따라서, page가 app 전용인지 web 전용인지 구분하여 header와 footer를 보여줄지 말지 결정한다.
    // Example) page-router?app-api=true&lang=ENG -> app에서 호출한 API이고, 언어값이 ENG 이다.
    const split_url = this.location.path().split(/[?&]/) // '?' 또는 '&'을 기준으로 스플릿
    if (split_url.includes("app-api=true")) {
      this.isApp = true // app에서 요청이 들어왔을 경우 헤더와 푸터를 가려준다.
    }
    // 로직 끝

    if (auth.isLoggedIn()) {
      this.userService.updateUser()
    } else {
      auth.clear()
    }
  }

  ngOnInit() {
    this.currentUrl = this.router.url
    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        const url = this.router.url
        this.scrollPositions[url] = window.scrollY
      })

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const url = event.urlAfterRedirects
        const scrollPosition = this.scrollPositions[url] || 0
        setTimeout(() => {
          window.scrollTo(0, scrollPosition)
        }, 0)
      })
  }

  getAnimationData(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData["animation"]
  }
}
