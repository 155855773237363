import { Injectable } from "@angular/core"
import { environment } from "../../../environments/environment"
import { HttpClient } from "@angular/common/http"
import { map, switchMap, tap } from "rxjs/operators"
import { RecommendedPost } from "../types/post.type"
import { ModelItem } from "src/app/creator/pages/creator-pictorial-model-management-page/creator-pictorial-model-management-page.component"

@Injectable({
  providedIn: "root",
})
export class RecommendedService {
  constructor(private http: HttpClient) {}

  getPostDashboardList(data?) {
    return this.http
      .get<RecommendedPost[]>(`${environment.apiUrl}recommended/post-dashboard/`, { params: data })
      .pipe(
        map((res) => {
          return res
        }),
      )
  }

  getModelList(data?) {
    return this.getRecommendedModelIds(data).pipe(
      switchMap((ids) => {
        return this.getRecommendedModels(ids.map((i) => i.model_id))
      }),
    )
  }

  private getRecommendedModelIds(data) {
    return this.http.get<{ model_id: string; order: number; campaign: number }[]>(
      `${environment.apiUrl}recommended/campaign-model/`,
      { params: data },
    )
  }

  private getRecommendedModels(ids: string[]) {
    return this.http.post<ModelItem[]>(`${environment.pictorialApiUrl}models/by-ids`, ids)
  }
}
