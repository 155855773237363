import { Component, Injectable, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { BehaviorSubject } from "rxjs"
import { UserService } from "../../../account/services/user.service"
import { CreatorService } from "../../services/creator.service"
import { AuthService } from "../../../core/services/auth.service"
import { SelectionModel } from "@angular/cdk/collections"
import { FlatTreeControl } from "@angular/cdk/tree"
import { MatTreeFlatDataSource, MatTreeFlattener } from "@angular/material/tree"
import { PictorialService } from "src/app/board/services/pictorial.service"
import { CdkDragDrop } from "@angular/cdk/drag-drop"
import { MatSnackBar } from "@angular/material"
import { SnackbarService } from "src/app/shared/services/snackbar.service"
import { TranslateService } from "@ngx-translate/core"
import { User } from "src/app/account/types/user.type"

/**
 * Node for to-do item
 */
export class TodoItemNode {
  children: TodoItemNode[]
  item: string
  id: string
  release: boolean
  parentCategoryId: string
  order: number
}

/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
  item: string
  level: number
  expandable: boolean
  id: any
  parentCategoryId: string
}

export class GetCategoryItem {
  creatorId: number
  id: string
  name: string
  order: number
  release: boolean
  childCategories: GetCategoryItem[]
}

/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */
@Injectable()
export class ChecklistDatabase {
  dataChange = new BehaviorSubject<TodoItemNode[]>([])
  user: User
  creator
  treeData
  categories

  get data(): TodoItemNode[] {
    return this.dataChange.value
  }

  constructor(
    private userService: UserService,
    private pictorialService: PictorialService,
    private snackbarService: SnackbarService,
    private translateService: TranslateService,
  ) {
    this.initialize()
  }

  initialize() {
    // 여기서 카테고리 API 불러서 카테고리 json 만들어야 한다. - 카테고리 구조, children, 각 공개여부 등 정보
    // this.creatorService.get 대신 this.creatorService.getCategory()... 식의 콜이 있겠지?
    this.user = this.userService.getUser()
    this.pictorialService.getCategoryList(this.user.creator).subscribe((res: GetCategoryItem[]) => {
      // API response 를 토대로 TREE_DATA 만든다
      // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
      //     file node as children.
      const data = this.buildFileTree(res, 0)

      // Notify the change.
      this.dataChange.next(data)
    })
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `TodoItemNode`.
   */
  buildFileTree(array: GetCategoryItem[], level: number, parentCategoryId = null): TodoItemNode[] {
    return array.reduce<TodoItemNode[]>((accumulator, category) => {
      const node = new TodoItemNode()
      node.item = category.name
      node.id = category.id
      node.release = category.release
      node.order = category.order
      node.parentCategoryId = parentCategoryId

      if (category.childCategories) {
        node.children = this.buildFileTree(category.childCategories, level + 1, category.id)
      }

      return accumulator.concat(node)
    }, [])
  }

  insertNewTopItem(name: string) {
    const reqData = {
      creatorId: this.user.creator,
      name,
      order: this.data.length ? this.data[this.data.length - 1].order + 1 : 1,
      parentCategoryId: null,
    }
    this.pictorialService.createCategory(reqData).subscribe((res: GetCategoryItem) => {
      const node = new TodoItemNode()
      node.children = []
      node.item = name
      node.id = res.id
      node.release = res.release

      this.data.push(node)
      this.dataChange.next(this.data)
    })
  }

  /** Add an item to to-do list */
  insertItem(parent: TodoItemNode, name: string): TodoItemNode {
    const children = parent.children
    const reqData = {
      creatorId: this.user.creator,
      name: `${name} - ${children.length}`,
      order: children.length > 0 ? children[children.length - 1].order + 1 : 1,
      parentCategoryId: parent.id,
    }

    if (!parent.children) {
      parent.children = []
    }

    const newItem = { item: reqData.name } as TodoItemNode
    this.pictorialService.createCategory(reqData).subscribe(
      (res: GetCategoryItem) => {
        newItem.id = res.id
        newItem.release = res.release
      },
      (err) => {
        if (err.error.statusCode === 409) {
          this.snackbarService.open(
            this.translateService.instant("ALERT_CONFIRM.CONNECTED_CATEGORY"),
            null,
            undefined,
            "danger-snackbar",
          )
          parent.children = []
          this.dataChange.next(this.data)
        }
      },
    )
    parent.children.push(newItem)
    this.dataChange.next(this.data)

    return newItem
  }

  updateItem(node: TodoItemNode, name: string, release: boolean) {
    node.item = name
    node.release = release
    this.dataChange.next(this.data)
    // TODO: this.data 에 있는 정보를 가공해서 update API 콜 해야함
    const reqData = {
      id: node.id,
      creatorId: this.user.creator,
      name,
      release,
    }
    return this.pictorialService.updateCategory(reqData).subscribe()
    // console.log("updateItem !!!", this.data)
  }

  deleteItem(node: TodoItemNode) {
    if (confirm(this.translateService.instant("ALERT_CONFIRM.DELETE_CONFIRM"))) {
      this.pictorialService.deleteCategory(node.id).subscribe(() => {
        this.deleteNode(this.data, node)
        this.dataChange.next(this.data)
      })
    }
  }

  deleteNode(nodes: TodoItemNode[], nodeToDelete: TodoItemNode) {
    const index = nodes.indexOf(nodeToDelete, 0)
    if (index > -1) {
      nodes.splice(index, 1)
    } else {
      nodes.forEach((node) => {
        if (node.children && node.children.length > 0) {
          this.deleteNode(node.children, nodeToDelete)
        }
      })
    }
  }

  // 새로운 상위 카테고리를 만든다
  addItemToTop() {
    const categoryName = "New Category-" + this.data.length
    this.insertNewTopItem(categoryName)
  }

  updateList(body) {
    this.pictorialService.updateCategoryList(body).subscribe()
  }
}

@Component({
  selector: "app-creator-pictorial-category-management-page",
  templateUrl: "./creator-pictorial-category-management-page.component.html",
  styleUrls: ["./creator-pictorial-category-management-page.component.scss"],
  providers: [ChecklistDatabase],
})
export class CreatorPictorialCategoryManagementPageComponent implements OnInit {
  selectedCategoryString
  selectedCategory
  color = "black"
  sliderChecked = false
  disableSlider = true
  disableDeleteButton = true

  user: User

  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>()

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>()

  /** A selected parent node to be inserted */
  selectedParent: TodoItemFlatNode | null = null

  /** The new item's name */
  newItemName = ""

  treeControl: FlatTreeControl<TodoItemFlatNode>

  treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>

  dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>

  /** The selection for checklist */
  checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */)

  /* Drag and drop */
  expansionModel = new SelectionModel<string>(true)
  dragging = false
  expandTimeout: any
  expandDelay = 1000
  validateDrop = true
  dragNode

  loading = false
  nameEmpty: boolean

  // @ViewChild("emptyItem") emptyItem: ElementRef;

  constructor(
    public auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private creatorService: CreatorService,
    private database: ChecklistDatabase,
    private _snackBar: MatSnackBar,
    private translateService: TranslateService,
  ) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren,
    )
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable)
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener)

    database.dataChange.subscribe((data) => {
      this.dataSource.data = []
      this.dataSource.data = data
    })
  }

  getLevel = (node: TodoItemFlatNode) => node.level

  isExpandable = (node: TodoItemFlatNode) => node.expandable

  getChildren = (node: TodoItemNode): TodoItemNode[] => node.children

  hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable

  hasNoContent = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.item === ""

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: TodoItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node)
    const flatNode =
      existingNode && existingNode.item === node.item ? existingNode : new TodoItemFlatNode()
    flatNode.item = node.item
    flatNode.id = node.id
    flatNode.level = level
    flatNode.expandable = node.children && node.children.length > 0
    this.flatNodeMap.set(flatNode, node)
    this.nestedNodeMap.set(node, flatNode)
    return flatNode
  }

  /** Whether all the descendants of the node are selected */
  descendantsAllSelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node)
    return descendants.every((child) => this.checklistSelection.isSelected(child))
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node)
    const result = descendants.some((child) => this.checklistSelection.isSelected(child))
    return result && !this.descendantsAllSelected(node)
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node)
    const descendants = this.treeControl.getDescendants(node)
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants)
  }

  /** Select the category so we can insert the new item. */
  addNewItem(node: TodoItemFlatNode) {
    const parentNode = this.flatNodeMap.get(node)
    // 임시 카테고리 이름 만들기
    this.database.insertItem(parentNode, "untitled")
    this.expansionModel.select(node.id)
    this.treeControl.expand(node)
  }

  /** Save the node to database */
  saveNode(node: TodoItemFlatNode, itemValue: string) {
    const nestedNode = this.flatNodeMap.get(node)
    return this.database.updateItem(nestedNode, itemValue, this.sliderChecked)
  }

  deleteItem(node: TodoItemFlatNode) {
    this.database.deleteItem(this.flatNodeMap.get(node))
  }

  addNewCategory() {
    this.database.addItemToTop()
  }

  treeNodeClick(node: TodoItemNode) {
    const element = document.getElementById("category-name-input-id")
    element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
    this.selectedCategoryString = node.item.toString()
    this.nameEmpty = false
    this.selectedCategory = node

    // 공개상태 여부 확인
    this.checkIfOpenToPublic()
    this.isAbleToDelete()
  }

  onSliderChange(e) {
    this.sliderChecked = e.checked ? true : false
  }

  ngOnInit() {}

  saveButtonClick(value: string) {
    const trimmed = value.trim()
    if (trimmed == "") {
      this.nameEmpty = true
      return
    }
    this.nameEmpty = false

    const loadingSnackBar = this.openSnackBar(
      this.translateService.instant("CATEGORY_MANAGE_FORM.SAVING"),
      9999,
    )
    this.loading = true
    this.saveNode(this.selectedCategory, value).add(() => {
      this.loading = false
      loadingSnackBar.dismiss()
      this.openSnackBar(this.translateService.instant("CATEGORY_MANAGE_FORM.saved"))
    })
  }

  deleteCategory() {
    this.database.deleteItem(this.flatNodeMap.get(this.selectedCategory))
    // 지운 후 상태 뒤처리
    this.cleanSelectedCategory()
  }

  // 지워도 되는 카테고리인지 체크 (parent 가 없거나, parent 라면 children 이 없어야함)
  isAbleToDelete() {
    if (this.selectedCategory && this.selectedCategory.level && this.selectedCategory.level !== 0) {
      this.disableDeleteButton = false
    } else if (
      this.selectedCategory &&
      this.selectedCategory.level === 0 &&
      this.selectedCategory.expandable === false
    ) {
      this.disableDeleteButton = false
    } else {
      this.disableDeleteButton = true
    }
  }

  checkIfOpenToPublic() {
    // 공개상태 여부 확인
    if (this.selectedCategory) {
      this.disableSlider = false
      const selected = this.flatNodeMap.get(this.selectedCategory)
      this.sliderChecked = selected.release
    } else {
      this.disableSlider = true
      this.sliderChecked = false
    }
  }

  cleanSelectedCategory() {
    this.selectedCategory = null
    this.selectedCategoryString = ""
    this.isAbleToDelete()
    this.checkIfOpenToPublic()
  }

  /**
   * This constructs an array of nodes that matches the DOM
   */
  visibleNodes(): TodoItemNode[] {
    const result = []

    function addExpandedChildren(node: TodoItemNode, expanded: string[]) {
      result.push(node)
      if (expanded.includes(node.id)) {
        node.children.map((child) => addExpandedChildren(child, expanded))
      }
    }

    this.dataSource.data.forEach((node) => {
      addExpandedChildren(node, this.expansionModel.selected)
    })
    return result
  }

  /**
   * Handle the drop - here we rearrange the data based on the drop event,
   * then rebuild the tree.
   * */
  drop(event: CdkDragDrop<string[]>) {
    // console.log("origin/destination", event.previousIndex, event.currentIndex)
    const dragRef = event.item._dragRef as any
    const goDown = dragRef._pickupPositionOnPage.y < dragRef._pointerPositionAtLastDirectionChange.y

    // ignore drops outside of the tree
    if (!event.isPointerOverContainer) {
      return
    }

    // construct a list of visible nodes, this will match the DOM.
    // the cdkDragDrop event.currentIndex jives with visible nodes.
    // it calls rememberExpandedTreeNodes to persist expand state
    const visibleNodes = this.visibleNodes()
    // console.log("visibleNodes", visibleNodes)

    // deep clone the data source so we can mutate it
    const changedData = JSON.parse(JSON.stringify(this.dataSource.data))

    // recursive find function to find siblings of node
    function findNodeSiblings(arr: Array<any>, id: string): Array<any> {
      let result, subResult
      arr.forEach((item, i) => {
        if (item.id === id) {
          result = arr
        } else if (item.children) {
          subResult = findNodeSiblings(item.children, id)
          if (subResult) {
            result = subResult
          }
        }
      })
      return result
    }

    // determine where to insert the node
    let currentIndex = event.currentIndex
    let spliceIndex = 0
    if (
      this.isTopMost(visibleNodes, event.currentIndex) ||
      this.isBottomMost(visibleNodes, event.currentIndex)
    ) {
      currentIndex = event.currentIndex
      if (visibleNodes[event.currentIndex].parentCategoryId) {
        spliceIndex = 1
      }
    } else if (goDown) {
      if (
        visibleNodes[event.currentIndex].id ===
        visibleNodes[event.currentIndex + 1].parentCategoryId
      ) {
        // 위에서 아래로 갈 때 배열의 처음에 드랍
        // parent의 첫번째 위치에 드래그 하면 children 안으로 넣어줌
        // 이제 나도 모르겠다
        currentIndex = event.currentIndex + 1
      } else if (
        // 위에서 아래로 갈 때 배열의 끝에 드랍
        visibleNodes[event.currentIndex].parentCategoryId !==
        visibleNodes[event.currentIndex + 1].parentCategoryId
      ) {
        spliceIndex = 1
      }
    } else {
      // 아래에서 위로 갈 때 배열의 끝에 드랍
      currentIndex =
        !visibleNodes[event.currentIndex].parentCategoryId &&
        visibleNodes[event.currentIndex - 1].parentCategoryId
          ? event.currentIndex - 1
          : event.currentIndex
    }
    const nodeAtDest = visibleNodes[currentIndex]
    // console.log("nodeAtDest", nodeAtDest)

    const newSiblings = findNodeSiblings(changedData, nodeAtDest.id)
    // console.log("newSiblings", newSiblings)

    if (!newSiblings) {
      return
    }
    const insertIndex = newSiblings.findIndex((s) => s.id === nodeAtDest.id)
    // console.log("insertIndex", insertIndex)

    // remove the node from its old place
    const node = event.item.data
    const siblings = findNodeSiblings(changedData, node.id)
    // console.log("siblings", siblings)
    const siblingIndex = siblings.findIndex((n) => n.id === node.id)
    const nodeToInsert: TodoItemNode = siblings.splice(siblingIndex, 1)[0]
    if (nodeAtDest.id === nodeToInsert.id) {
      return
    }

    // ensure validity of drop - must be same level
    const nodeAtDestFlatNode = this.treeControl.dataNodes.find((n) => nodeAtDest.id === n.id)
    if (this.validateDrop && nodeAtDestFlatNode.level !== node.level) {
      return
    }

    spliceIndex = insertIndex + spliceIndex

    if (event.currentIndex - 1 == currentIndex) {
      spliceIndex += 1
    }
    // insert node
    const leftArray = newSiblings.splice(spliceIndex, newSiblings.length)
    // console.log("leftArray", leftArray)
    const lastOrder = newSiblings.length > 0 ? newSiblings[newSiblings.length - 1].order : 0
    const orderingArray = [{ ...nodeToInsert, parentCategoryId: nodeAtDest.parentCategoryId }]
      .concat(leftArray)
      .map((sibling, index) => ({ ...sibling, order: lastOrder + index + 1 }))

    // console.log("orderingArray", orderingArray)

    newSiblings.push(...orderingArray)
    // console.log("last", newSiblings)

    this.database.updateList(
      orderingArray.map((item) => ({
        id: item.id,
        order: item.order,
        parentCategoryId: item.parentCategoryId,
      })),
    )
    // rebuild tree with mutated data
    this.rebuildTreeForData(changedData)
  }

  /**
   * Experimental - opening tree nodes as you drag over them
   */
  dragStart(node: TodoItemFlatNode, event) {
    this.dragNode = node
    this.dragging = true
    // this.expansionModel.clear()
  }

  dragEnd() {
    this.dragNode = null
    this.dragging = false
  }

  dragHover(node: TodoItemFlatNode) {
    if (this.dragging) {
      clearTimeout(this.expandTimeout)
      this.expandTimeout = setTimeout(() => {
        this.treeControl.expand(node)
      }, this.expandDelay)
    }
  }

  dragHoverEnd() {
    if (this.dragging) {
      clearTimeout(this.expandTimeout)
    }
  }

  /**
   * The following methods are for persisting the tree expand state
   * after being rebuilt
   */

  rebuildTreeForData(data: any) {
    this.dataSource.data = data
    this.expansionModel.selected.forEach((id) => {
      const node = this.treeControl.dataNodes.find((n) => n.id === id)
      this.treeControl.expand(node)
    })
    this.database.dataChange.next(data)
  }

  controlExpansion() {
    if (this.expansionModel.selected.length > 0) {
      this.expansionModel.clear()
      this.treeControl.collapseAll()
    } else {
      this.expansionModel.select(...this.dataSource._data.value.map(({ id }: TodoItemNode) => id))
      this.treeControl.expandAll()
    }

    // this.expansionModel.select()
    // this.treeControl.expandAll()
  }

  private isTopMost(visibleNodes, currentIndex) {
    return !visibleNodes[currentIndex - 1]
  }

  private isBottomMost(visibleNodes, currentIndex) {
    return !visibleNodes[currentIndex + 1]
  }

  openSnackBar(message: string, duration = 2500) {
    return this._snackBar.open(message, "", {
      duration,
      verticalPosition: "top",
      panelClass: ["snack-bar-top", "primary-snackbar"],
    })
  }
}
