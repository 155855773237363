import { Injectable } from "@angular/core"
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"
import { UserService } from "../../../../account/services/user.service"

@Injectable({
  providedIn: "root",
})
export class CreatorContributePageGuardService implements CanActivate {
  constructor(private userService: UserService, private translateService: TranslateService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | boolean {
    return this.userService.get("me").pipe(
      map((res) => {
        const creatorId = res["creator"]
        const canActivate = !creatorId || creatorId.toString() !== next.params.id
        if (!canActivate) {
          alert(this.translateService.instant("ALERT_CONFIRM.MEMBER_SELF"))
        }
        return canActivate
      }),
    )
  }
}
