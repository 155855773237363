import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { CookieService } from "angular2-cookie/core"
import { TranslateService } from "@ngx-translate/core"

import { FormService } from "src/app/shared/services/form.service"
import { Form } from "src/app/shared/model/form"
import { SnackbarService } from "src/app/shared/services/snackbar.service"
import { environment } from "src/environments/environment"
import { FroalaEditorService } from "src/app/board/services/froala-editor.service"
import { UserFileService } from "src/app/board/services/user-file.service"
import { AuthService } from "src/app/core/services/auth.service"
import { PostService } from "../../services/post.service"
import { PostCategory } from "../../types/post.type"

@Component({
  selector: "app-post-form-contributor-card",
  templateUrl: "./post-form-contributor-card.component.html",
  styleUrls: ["./post-form-contributor-card.component.scss"],
})
export class PostFormContributorCardComponent implements OnInit {
  @Input() creatorId
  @Input() postPermission
  @Input() isBlockedUser
  @Input() postMin
  @Input() postId?: number
  @Output() submitted = new EventEmitter<void>()
  form: Form
  currentUser = null

  attachFileIdSet = new Set<string>()
  removeFileIdSet = new Set<string>()

  inProgress = false
  // minimum lvl for posting
  minimumTier
  minimumTierEng

  // options for Floara wysiwyg
  public options: Object

  public editorContent: string
  //
  langFlag

  constructor(
    private froalaEditorService: FroalaEditorService,
    private userFileService: UserFileService,
    private auth: AuthService,
    private postService: PostService,
    private formService: FormService,
    private cookieService: CookieService,
    private snackbarService: SnackbarService,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.currentUser = this.auth.getCognitoUser()
    if (this.cookieService.get("lang") !== "KOR") {
      this.langFlag = "ENG"
    } else {
      this.langFlag = "KOR"
    }
    //
    if (!this.postMin && this.postMin !== "0") {
      this.minimumTier = "팔로워"
      this.minimumTierEng = "followers"
    } else if (this.postMin === "0") {
      this.minimumTier = "멤버"
      this.minimumTierEng = "members"
    } else {
      // 사용하게 되면 USD 출력 확인 필요
      this.minimumTier = "$" + this.postMin.toLocaleString() + " 멤버"
      this.minimumTierEng = "$" + this.postMin.toLocaleString() + "+ members"
    }

    this.setOptions()
    this.setForm()
  }

  setForm() {
    const url = this.postId ? `posts/contributor/${this.postId}/` : "posts/contributor/"

    this.form = null
    this.formService.getFormData(url).subscribe((form) => {
      this.form = form
      this.editorContent = this.form.data.content
      this.attachFileIdSet = new Set(
        this.form.data.attach_file_ids ? this.form.data.attach_file_ids : [],
      )
    })
  }

  clearImage() {
    this.form.formGroup.get("image").setValue("")
  }

  onSubmit() {
    this.inProgress = true

    if (this.postPermission) {
      // set content value for wysiwyg
      const formValues = this.form.formGroup.value
      this.froalaEditorService.updateFileIdSet(
        this.removeFileIdSet,
        this.attachFileIdSet,
        this.editorContent,
      )
      formValues.is_wysiwyg = true
      formValues.attach_file_ids = this.attachFileIdSet.size ? [...this.attachFileIdSet] : []
      formValues.content = this.editorContent

      delete formValues.file
      delete formValues.video_index
      if (this.postId) {
        this.postService.update(this.postId, this.form, PostCategory.contributor).subscribe(
          (res) => {
            this.setForm()
            this.submitted.emit()
            this.inProgress = false
          },
          (error) => {
            this.inProgress = false
          },
        )
      } else {
        formValues.creator = this.creatorId
        this.postService.create(this.form, PostCategory.contributor).subscribe(
          (res) => {
            this.setForm()
            this.submitted.emit()
            this.inProgress = false
          },
          (error) => {
            this.inProgress = false
          },
        )
      }
      this.froalaEditorService.confirmAttachFiles([...this.attachFileIdSet])
      this.froalaEditorService.deleteAttachFiles([...this.removeFileIdSet])
    } else {
      if (this.isBlockedUser) {
        this.snackbarService.open(
          this.translate.instant("CREATOR_DETAIL_PAGE.BLOCKED_MESSAGE"),
          "",
          2000,
          "danger-snackbar",
        )
        this.form.formGroup.get("content").setValue("")
      } else {
        if (this.langFlag === "KOR") {
          alert(this.minimumTier + " 이상이 되어야 포스트를 할 수 있습니다.")
        } else {
          alert("Posting is for " + this.minimumTierEng + " only.")
        }
      }
      this.inProgress = false
    }
  }

  setOptions() {
    const requestParams = { path: "posts" }
    const addFileItem = this.froalaEditorService.getAddFileItemFunction(this)
    const removeFileItem = this.froalaEditorService.getRemoveFileItemFunction(this)
    const cognitoToken = this.auth.getCognitoToken()
    this.options = {
      requestHeaders: {
        "cognito-authorization": cognitoToken && cognitoToken.IdToken ? cognitoToken.IdToken : null,
      },
      key: "IB2A6A4B2pD1D1D1H5E1J4A16B3C7E7kuA-8wyyoszF4ogw==",
      placeholderText: this.translate.instant("FROALA_EDITOR.PLACE_HOLDER"),
      charCounterCount: false,
      toolbarButtons: [
        "bold",
        "italic",
        "underline",
        "fontFamily",
        "fontSize",
        "color",
        "insertLink",
        "insertImage",
        "emoticon",
        "insertFile",
      ],
      requestWithCORS: false,
      requestWithCredentials: false,
      // Set the image upload URL.
      imageUploadURL: environment.uploadFileUrl,
      // Additional upload params.
      imageUploadParams: requestParams,
      // Set request type.
      imageUploadMethod: "POST",
      // Set max image size to 5MB.
      imageMaxSize: 5 * 1024 * 1024,
      // Allow to upload PNG and JPG.
      imageAllowedTypes: ["jpeg", "jpg", "png"],

      // Set the file upload URL.
      fileUploadURL: environment.uploadFileUrl,
      // Additional upload params.
      fileUploadParams: requestParams,
      // Set request type.
      fileUploadMethod: "POST",
      // Set max file size to 5MB.
      fileMaxSize: 5 * 1024 * 1024,
      // Allow to upload any file.
      fileAllowedTypes: ["*"],

      // Set the file upload URL.
      videoUploadURL: environment.uploadFileUrl,
      // Additional upload params.
      videoUploadParams: requestParams,
      // Set request type.
      videoUploadMethod: "POST",
      // Set max file size to 10MB.
      videoMaxSize: 10 * 1024 * 1024,
      // Allow to upload any file.
      videoAllowedTypes: ["mp4", "avi"],

      events: {
        "froalaEditor.commands.undo": () => {},
        "froalaEditor.image.uploaded": addFileItem,
        "froalaEditor.image.removed": removeFileItem,
        "froalaEditor.image.inserted": function (e, editor, $img, response) {},
        "froalaEditor.image.replaced": function (e, editor, $img, response) {},
        "froalaEditor.image.error": function (e, editor, error, response) {
          const $popup = editor.popups.get("image.insert")
          const $layer = $popup.find(".fr-image-progress-bar-layer")
          // Bad link.
          if (error.code === 1) {
            $layer.find("h3").text(this.translateService.instant("FROALA_EDITOR.INVALID_LINK"))
          } else if (error.code === 2) {
          } else if (error.code === 3) {
            $layer
              .find("h3")
              .text(this.translateService.instant("FROALA_EDITOR.IMAGE_UPLOAD_FAILED"))
          } else if (error.code === 4) {
          } else if (error.code === 5) {
            $layer.find("h3").text(this.translateService.instant("FROALA_EDITOR.IMAGE_UNDER_5MB"))
          } else if (error.code === 6) {
            $layer
              .find("h3")
              .text(this.translateService.instant("FROALA_EDITOR.INVALID_IMAGE_TYPE"))
          } else if (error.code === 7) {
          }
        },
        "froalaEditor.file.uploaded": addFileItem,
        "froalaEditor.file.removed": removeFileItem,
        "froalaEditor.file.inserted": function (e, editor, files) {},
        "froalaEditor.file.error": function (e, editor, error, response) {
          const $popup = editor.popups.get("file.insert")
          const $layer = $popup.find(".fr-file-progress-bar-layer")
          // Bad link.
          if (error.code === 1) {
            $layer.find("h3").text(this.translateService.instant(""))
          } else if (error.code === 2) {
            $layer
              .find("h3")
              .text(this.translateService.instant("FROALA_EDITOR.INVALID_LINK_RESPONSE"))
          } else if (error.code === 3) {
            $layer
              .find("h3")
              .text(this.translateService.instant("FROALA_EDITOR.FILE_UPLOAD_FAILED"))
          } else if (error.code === 4) {
            $layer.find("h3").text(this.translateService.instant("FROALA_EDITOR.PARSING_FAILED"))
          } else if (error.code === 5) {
            $layer.find("h3").text(this.translateService.instant("FROALA_EDITOR.file_UNDER_5MB"))
          } else if (error.code === 6) {
            $layer.find("h3").text(this.translateService.instant("FROALA_EDITOR.INVALID_file_TYPE"))
          } else if (error.code === 7) {
            $layer.find("h3").text(this.translateService.instant("FROALA_EDITOR.SAME_DOMAIN_IE9"))
          }
        },
      },
    }
  }
}
