import { EventEmitter, Injectable, OnInit } from "@angular/core"
import { Observable } from "rxjs"
import { map, tap } from "rxjs/operators"
import { SelectedTag } from "src/app/account/components/tag-select-dialog/types/tag.type"
import { UserService } from "src/app/account/services/user.service"
import { AuthService } from "src/app/core/services/auth.service"

@Injectable({
  providedIn: "root",
})
export class TagDataService implements OnInit {
  // 전체 태그 목록
  activeTags: SelectedTag[] = []

  // 유저 태그 목록
  userTags: SelectedTag[] = []

  userTagLoadedEvent = new EventEmitter<boolean>()

  constructor(private userService: UserService, private authService: AuthService) {
    this.userTagLoadedEvent.emit(false)

    this.authService.getChangeEmitter().subscribe(() => {
      const isLogin = this.authService.isLoggedIn()

      if (!isLogin) {
        this.activeTags = []
        this.userTags = []

        this.userTagLoadedEvent.emit(false)

        return
      }

      this.init()
    })
  }

  ngOnInit() {
    if (this.authService.isLoggedIn()) {
      this.init()
    }
  }

  async init() {
    await this.getActiveTags().toPromise()
    await this.fetchUserTags().toPromise()

    this.userTagLoadedEvent.emit(true)
  }

  getActiveTags(): Observable<SelectedTag[]> {
    if (this.activeTags.length === 0) {
      return this.fetchActiveTags()
    }
    return new Observable((observer) => {
      observer.next(this.activeTags)
      observer.complete()
    })
  }

  fetchActiveTags() {
    return this.userService.getTagsList().pipe(
      tap((tags) => {
        this.activeTags = tags
      }),
    )
  }

  fetchUserTags() {
    return this.userService.getTagsMine().pipe(
      map((tags) => {
        this.userTags = tags.user_tags

        return tags.user_tags
      }),
    )
  }

  updateUserTags(tags: number[]) {
    return this.userService.updateUserTags({
      user_tags: tags,
    })
  }
}
