import { Component, Input, OnInit } from "@angular/core"
import { UserService } from "../../../account/services/user.service"
import { MembershipService } from "../../services/membership.service"
import { ComponentPageTitle } from "../../components/page-title/page-title"
import { ActivatedRoute, Params } from "@angular/router"
import { Observable } from "rxjs"
import { CookieService } from "angular2-cookie"
import { CreatorService } from "../../services/creator.service"
import { Creator, ProfitSummary } from "../../types"
import * as moment from "moment"

@Component({
  selector: "app-membership-summary-page",
  templateUrl: "./membership-summary-page.component.html",
  styleUrls: ["./membership-summary-page.component.scss"],
})
export class MembershipSummaryPageComponent implements OnInit {
  creatorId: number
  today: moment.Moment
  year: number
  month: number
  summary: ProfitSummary

  summaryLoaded = false
  @Input() params: Observable<Params>

  isKorean: boolean
  creatorMode: string

  constructor(
    public _componentPageTitle: ComponentPageTitle,
    private route: ActivatedRoute,
    private userService: UserService,
    private membershipService: MembershipService,
    private cookieService: CookieService,
    private creatorService: CreatorService,
  ) {
    this.today = moment()
  }

  ngOnInit() {
    this.isKorean = this.cookieService.get("lang") === "KOR"
    this.route.params.subscribe(() => {
      this._componentPageTitle.setSubtitle("수익 정산 한눈에 보기")
    })
    this.creatorId = this.userService.getUser().creator

    this.year = this.today.get("year")
    this.month = this.today.get("month") + 1

    this.membershipService
      .getSummary({ creator: this.creatorId, year: this.year, month: this.month })
      .subscribe((res) => {
        this.summary = res
        this.summaryLoaded = true
      })

    this.creatorService.get("me").subscribe((res: Creator) => {
      this.creatorMode = res.creator_type
    })
  }

  moveMonth(offset: number) {
    this.summaryLoaded = false
    const date = new Date(this.year, this.month - 1, 1)
    date.setMonth(date.getMonth() + offset)
    this.year = date.getFullYear()
    this.month = date.getMonth() + 1

    this.membershipService.getSummary({ year: this.year, month: this.month }).subscribe((res) => {
      this.summary = res
      this.summaryLoaded = true
    })
  }
}
