import { Injectable } from "@angular/core"
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlSegment,
} from "@angular/router"
import { CookieService } from "angular2-cookie"
import { ModelService } from "src/app/models/services/model.service"
import { ContestService } from "src/app/contest/services/contest.service"
import { Contest } from "src/app/contest/types"

@Injectable({
  providedIn: "root",
})
export class ModelRoutingDataService implements CanActivateChild {
  private readonly lang: string

  constructor(
    private router: Router,
    private cookieService: CookieService,
    private modelService: ModelService,
    private contestService: ContestService,
  ) {
    this.lang = this.cookieService.get("lang")
  }

  async canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.setLang(childRoute)
    await this.setModel(childRoute)
    if (childRoute.data.model) {
      return true
    }
    return this.router.navigateByUrl("404", { skipLocationChange: true, replaceUrl: true })
  }

  private getModelId(url: UrlSegment[]) {
    return url.length > 0 ? url.shift().path : undefined
  }

  private async getCurrentContest(): Promise<Contest | null> {
    try {
      return await this.contestService.getCurrentContest().toPromise()
    } catch (e) {
      console.log("contest not found")
    }
    return Promise.resolve(null)
  }

  private async getModel(modelId?: string) {
    if (!modelId) {
      return null
    }
    const campaign = await this.getCurrentContest()
    return await this.modelService.findOne(modelId, campaign ? campaign.id : undefined).toPromise()
  }

  private setLang(next: ActivatedRouteSnapshot) {
    next.data = {
      ...next.data,
      lang: this.lang,
    }
  }

  private async setModel(next: ActivatedRouteSnapshot) {
    try {
      const modelId = this.getModelId(next.url)
      const model = await this.getModel(modelId)

      next.data = {
        ...next.data,
        ...(model && { model }),
      }
    } catch (e) {
      console.log("contest or model not found")
    }
  }
}
