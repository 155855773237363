import { EventEmitter, Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { Form } from "../../shared/model/form"
import { environment } from "../../../environments/environment"
import { tap } from "rxjs/operators"
import { AuthService } from "src/app/core/services/auth.service"

interface CreatePaymentMethodsParams {
  card_number: string
  expiry: string
  cvc: number
}

interface GetOldPaymentParams {
  [param: string]: any
  ordering?: string
  search?: string
  page?: number
  page_size?: number
}

@Injectable({
  providedIn: "root",
})
export class BillingKeyService {
  constructor(private http: HttpClient, private auth: AuthService) {}
  change = new EventEmitter()

  create(form: Form) {
    const data = form.formGroup.value
    return this.http.post(environment.apiUrl + "payment-methods/", data).pipe(
      tap(
        (res) => form.clearErrors(),
        (error) => form.setErrors(error),
      ),
    )
  }

  emitChangeEvent(s) {
    this.change.emit(s)
  }
  getChangeEmitter() {
    return this.change
  }

  getMethod() {
    return this.http.get(environment.apiUrl + "payment-methods/mine/")
  }

  getOldPaymentMembership(query?: GetOldPaymentParams) {
    return this.http.get(environment.apiUrl + "old-payment-memberships/", { params: query })
  }

  changeToStripe() {
    return this.http
      .post(environment.apiUrl + "change-to-payment-method/", undefined)
      .pipe(tap(() => this.emitChangeEvent(true)))
  }
}
