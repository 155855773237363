import { BreakpointObserver } from "@angular/cdk/layout"
import { Component, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { Breakpoint } from "src/app/shared/constants/constants"
import { UserService } from "src/app/account/services/user.service"
import { CountryRestrictionModalService } from "src/app/shared/services/country-restiction-modal.service"
import { BreakpointObserverService } from "src/app/shared/services/breakpoint-observer.service"

@Component({
  selector: "app-sendme-root",
  templateUrl: "./sendme-root.component.html",
  styleUrls: ["./sendme-root.component.scss"],
})
export class SendmeRootComponent implements OnInit {
  isMobile: boolean = false
  menus = [
    {
      id: 1,
      name: this.translateService.instant("SENDME.NAV.INBOX"),
      items: [
        { name: this.translateService.instant("SENDME.NAV.READ"), link: "/sendme/inbox/read" },
        { name: this.translateService.instant("SENDME.NAV.UNREAD"), link: "/sendme/inbox/unread" },
        {
          name: this.translateService.instant("SENDME.NAV.IMPORTANT"),
          link: "/sendme/inbox/important",
        },
      ],
      link: "/sendme/inbox",
    },
    {
      id: 2,
      name: () =>
        this.isMobile
          ? this.translateService.instant("SENDME.NAV.STORAGE_MOBILE")
          : this.translateService.instant("SENDME.NAV.STORAGE_DESKTOP"),
      link: "/sendme/storage",
    },
  ]

  constructor(
    private translateService: TranslateService,
    private breakpointObserver: BreakpointObserverService,
    private userService: UserService,
    private restrictCountryService: CountryRestrictionModalService,
    private router: Router,
  ) {}

  ngOnInit() {
    const bpMd = this.breakpointObserver.maxWidth(Breakpoint.md)
    this.breakpointObserver.observe([bpMd]).subscribe((result) => {
      this.isMobile = result.matches
    })
    if (!!this.userService.getUser().creator && !this.isCreatorMenuSet()) {
      this.menus.unshift(
        {
          id: 3,
          name: this.translateService.instant("SENDME.NAV.CREATE"),
          link: "/sendme/compose",
        },
        { id: 4, name: this.translateService.instant("SENDME.NAV.OUTBOX"), link: "/sendme/sent" },
      )
    }
  }

  isCreatorMenuSet() {
    return this.menus.filter((menu) => menu.id === 3 || menu.id === 4).length > 0
  }
}
