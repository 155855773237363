import { Injectable, NgZone } from "@angular/core"
import { MatSnackBar } from "@angular/material"
import { MatSnackBarVerticalPosition } from "@angular/material/snack-bar/typings/snack-bar-config"

@Injectable()
export class SnackbarService {
  constructor(public snackBar: MatSnackBar, private zone: NgZone) {}

  public open(
    message,
    action = "success",
    duration = 2000,
    panelClass = "primary-snackbar",
    afterClose?: () => any,
    verticalPosition: MatSnackBarVerticalPosition = "top",
  ) {
    this.zone.run(() => {
      const ref = this.snackBar.open(message, action, {
        verticalPosition: verticalPosition,
        duration: duration,
        panelClass: panelClass,
      })

      if (afterClose) {
        ref.afterDismissed().subscribe(afterClose)
      }
    })
  }
}
