import { EventEmitter, Injectable } from "@angular/core"
import { ContestService } from "../../contest/services/contest.service"
import { ModelContestDataService } from "../../contest/services/model-contest-data.service"
import { ContestPictorialService } from "../../contest/services/campaign-pictorial.service"
import { map, tap } from "rxjs/operators"
import { Observable } from "rxjs"
import { environment } from "../../../environments/environment"

@Injectable({
  providedIn: "root",
})
export class ContestPictorialRegistryService {
  private contestUrl = environment.currentContestURI
  private pictorialCache: Record<string, boolean> = {}
  private contestId: number

  // contestId가 설정되기 전에 getRegisteredPictorial 함수가 호출되는 경우 id를 저장해둔다. contestId가 설정되면 이 id들을 모두 fetch한다.
  private pictorialIdQueue: Set<string> = new Set()

  public itemHasFetchedEvent = new EventEmitter<string>()

  constructor(
    private contestService: ContestService,
    private contestPictorialService: ContestPictorialService,
    private modelContestService: ModelContestDataService,
  ) {}

  init() {
    if (!this.modelContestService.hasInit) {
      this.findAndSetContestId().add(() => {
        this.modelContestService.init(this.contestId)

        this.pictorialIdQueue.forEach((pictorialId) => {
          this.fetchRegisteredSingleItem(pictorialId)
        })
      })
    } else {
      this.contestId = this.modelContestService.contestId
    }
  }

  findAndSetContestId() {
    return this.contestService
      .getContestList()
      .subscribe(this.findContestSubscribeHandler.bind(this))
  }

  findContestIdByUrl(url?: string) {
    if (!url) {
      return
    }

    return this.contestService
      .getContestListByUrl(url)
      .subscribe(this.findContestSubscribeHandler.bind(this))
  }

  findContestSubscribeHandler(contests) {
    const modelContest = contests.results.find(
      (contest) => contest.is_activated && contest.url == this.contestUrl,
    )

    if (!modelContest) {
      const nextUrl = contests.next

      this.findContestIdByUrl(nextUrl)
    } else {
      this.contestId = modelContest.id
    }
  }

  getRegisteredPictorial(pictorialId: string) {
    // contestId가 설정 되어 있지 않은데 호출되는 경우가 있다.
    if (!this.contestId) {
      this.pictorialIdQueue.add(pictorialId)
      return new Observable((observer) => {
        this.itemHasFetchedEvent.subscribe((fetchedPictorialId) => {
          if (pictorialId == fetchedPictorialId) {
            observer.next(this.pictorialCache[pictorialId])
            observer.complete()
          }
        })
      })
    }

    if (typeof this.pictorialCache[pictorialId] === "boolean") {
      return new Observable((observer) => {
        observer.next(this.pictorialCache[pictorialId])
        observer.complete()
      })
    }

    return this.contestPictorialService.getRegisteredPictorial(this.contestId, pictorialId).pipe(
      map((result) => {
        this.pictorialCache[pictorialId] = !!result
        return !!result
      }),
    )
  }

  fetchRegisteredSingleItem(pictorialId: string) {
    if (this.contestId) {
      this.contestPictorialService.getRegisteredPictorial(this.contestId, pictorialId).subscribe(
        () => {
          this.pictorialCache[pictorialId] = true
          this.itemHasFetchedEvent.emit(pictorialId)
        },
        () => {
          this.pictorialCache[pictorialId] = false
          this.itemHasFetchedEvent.emit(pictorialId)
        },
      )

      return
    }

    this.pictorialIdQueue.add(pictorialId)
  }

  changeAppliedStatus(pictorialId: string, status: boolean) {
    const service = status ? this.unRegisterPictorial.bind(this) : this.registerPictorial.bind(this)

    return service(pictorialId)
  }

  registerPictorial(pictorialId: string) {
    return this.contestPictorialService.postItem(this.contestId, pictorialId).pipe(
      tap(() => {
        this.pictorialCache[pictorialId] = true
      }),
    )
  }

  unRegisterPictorial(pictorialId: string) {
    return this.contestPictorialService.deleteItem(this.contestId, [pictorialId]).pipe(
      tap(() => {
        this.pictorialCache[pictorialId] = false
      }),
    )
  }
}
