import { Post } from "src/app/board/types/post.type"
import { Comment } from "src/app/board/types/comment.type"
import { Pictorial } from "src/app/board/types/pictorial.type"
import { BlockedMessage } from "./block.type"
import { Creator } from "src/app/creator/types"

export interface Message {
  id: number
  user: number
  post: Post
  created: string
  creator: Creator
  is_read: boolean
  is_paid: boolean
  last_comment?: Comment
  pictorial?: Pictorial
  message_type: MessageType
  blocked?: BlockedMessage[]
  specific_region_excluded: boolean
}

export enum MessageType {
  message = "message",
  comment = "comment",
  post = "post",
  pictorial = "pictorial",
}
