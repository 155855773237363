export enum SortOrder {
  asc = "asc",
  desc = "desc",
}

export const ageSortOptions = [
  {
    label: "최신순",
    value: SortOrder.desc,
  },
  {
    label: "오래된순",
    value: SortOrder.asc,
  },
]

export interface GetParams {
  page?: number
  page_size?: number
  limit?: number
  search?: string
  ordering?: string

  [key: string]: any
}
