export enum InboxPageType {
  read = "read",
  unread = "unread",
  important = "important",
}

export enum StoragePageType {
  pictorial = "pictorial",
  post = "post",
}
