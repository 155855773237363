import { Directive, Input, ElementRef, Renderer2, SimpleChanges, OnChanges } from "@angular/core"

@Directive({
  selector: "[appDynamicAttrs]",
})
export class DynamicAttrsDirective implements OnChanges {
  @Input("appDynamicAttrs")
  public attrs: any

  constructor(private _elementRef: ElementRef, private _renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges) {
    Object.keys(this.attrs).forEach((key) => {
      this._renderer.setAttribute(this._elementRef.nativeElement, key, this.attrs[key])
    })
  }
}
