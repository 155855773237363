import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { environment } from "../../../environments/environment"

@Injectable({
  providedIn: "root",
})
export class ScrapeService {
  constructor(private http: HttpClient) {}

  toggleScrape(url) {
    return this.http.post(`${environment.apiUrl}${url}`, {})
  }
}
