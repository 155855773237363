import { Component, Input, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { Post } from "src/app/board/types/post.type"
import { CountryRestrictionModalService } from "src/app/shared/services/country-restiction-modal.service"
import { environment } from "src/environments/environment"
import { Stage } from "src/environments/stage"

@Component({
  selector: "app-explore-tab-item",
  templateUrl: "./explore-tab-item.component.html",
  styleUrls: ["./explore-tab-item.component.scss"],
})
export class ExploreTabItemComponent implements OnInit {
  @Input() post: Post
  @Input() sendMeId: number = -1
  @Input() specificRegionExcluded: boolean = false

  isAdmin: boolean
  blurArray = ["black", "red", "green", "blue", "purple"]

  backendUrl =
    environment.stage === Stage.production
      ? "https://squad.memberme.net"
      : "https://smith-staging.memberme.net"

  constructor(
    private router: Router,
    private restrictCountryService: CountryRestrictionModalService,
  ) {}

  ngOnInit() {}

  click(event: PointerEvent) {
    event.stopPropagation()
    event.preventDefault()
    if (this.specificRegionExcluded && this.restrictCountryService.isRestricted()) {
      this.restrictCountryService.openRestrictModal()
      return
    }
    if (this.sendMeId !== -1) {
      this.router.navigate([`/sendme/inbox/read/${this.sendMeId}`])

      return
    }

    this.router.navigate([
      "",
      {
        outlets: { popup: ["preview", this.post.id] },
      },
    ])
  }
}
