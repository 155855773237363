import { Component, Input, OnInit } from "@angular/core"
import { ArticleService } from "../../services/article.service"
import { Observable } from "rxjs"
import { ComponentPageTitle } from "../../../creator/components/page-title/page-title"
import { ActivatedRoute, Params } from "@angular/router"
import { CookieService } from "angular2-cookie"

@Component({
  selector: "app-article-list-creator-page",
  templateUrl: "./article-list-creator-page.component.html",
  styleUrls: ["./article-list-creator-page.component.scss"],
})
export class ArticleListCreatorPageComponent implements OnInit {
  paginatedResult$
  openedArticleId
  isKorean

  @Input() params: Observable<Params>

  constructor(
    public _componentPageTitle: ComponentPageTitle,
    private route: ActivatedRoute,
    private articleService: ArticleService,
    private cookieService: CookieService,
  ) {}

  ngOnInit() {
    this.isKorean = this.cookieService.get("lang") === "KOR"
    this.route.params.subscribe(() => {
      this._componentPageTitle.setSubtitle("시스템 알림")
    })
    this.getPaginatedResult()
  }

  toggleArticle(articleId) {
    if (this.openedArticleId === articleId) {
      this.openedArticleId = null
    } else {
      this.openedArticleId = articleId
    }
  }

  getPaginatedResult() {
    this.paginatedResult$ = this.articleService.getListPaginated({ category: "notice_creator" })
  }

  getPaginatedResultByUrl(url) {
    this.paginatedResult$ = this.articleService.getListPaginatedByUrl(url)
  }
}
