import { Component, Input, OnInit } from "@angular/core"
import { FormControl } from "@angular/forms"
import { Observable } from "rxjs"
import { startWith, switchMap } from "rxjs/operators"
import { UserService } from "src/app/account/services/user.service"
import { PictorialService } from "src/app/board/services/pictorial.service"
import { PaginationMeta } from "src/app/shared/types/pagination.type"

@Component({
  selector: "app-search-pictorial-dropdown-box",
  templateUrl: "./search-pictorial-dropdown-box.component.html",
  styleUrls: ["./search-pictorial-dropdown-box.component.scss"],
})
export class SearchPictorialDropdownBoxComponent implements OnInit {
  @Input() select: (arg) => void

  control = new FormControl("")
  filteredStreets: Observable<any[]>
  limit = 5
  meta: PaginationMeta = {
    currentPage: 0,
    itemCount: 0,
    itemsPerPage: this.limit,
    totalItems: 0,
    totalPages: 0,
  }
  creatorId
  page = 1
  changedPage = false
  selected
  loading = false

  constructor(private pictorialService: PictorialService, private userService: UserService) {
    this.creatorId = userService.getUser().creator
  }

  ngOnInit() {
    this.filteredStreets = this.control.valueChanges.pipe(
      startWith(""),
      switchMap(async (value) => {
        this.page = this.changedPage ? this.page : 1
        this.changedPage = false

        if (this.selected) {
          this.selected = false
          this.select({})
        }

        const meta = {
          limit: this.limit,
          page: this.page,
          creatorId: this.creatorId,
        }
        const search = value ? { title: value } : {}

        this.loading = true
        const result = await this.pictorialService
          .getList({
            ...search,
            ...meta,
          })
          .toPromise()
        this.loading = false

        this.meta = result.meta
        return result.items
      }),
    )
  }

  changePage(num: number) {
    this.page = num
    this.changedPage = true
    this.control.setValue(this.control.value)
  }

  selectItem(pictorial) {
    this.selected = true
    this.select(pictorial)
  }
}
