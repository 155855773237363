import { Component, Input, OnChanges, SimpleChanges } from "@angular/core"
import { TranslateService } from "@ngx-translate/core"
import { CookieService } from "angular2-cookie"
import * as moment from "moment"

interface TableHeaderProp {
  label: string
  value: string
}

const defaultHeader = [
  {
    label: "PAYMENT_MODAL.CREATOR",
    value: "creator",
  },
  {
    label: "PAYMENT_MODAL.PRODUCT",
    value: "name",
  },
  {
    label: "PAYMENT_MODAL.PRICE",
    value: "amount_usd",
  },
  {
    label: "PAYMENT_MODAL.BILLING_DATE",
    value: "createdAt",
  },
]

@Component({
  selector: "app-payment-item-table-component",
  templateUrl: "./payment-item-table-component.component.html",
  styleUrls: ["./payment-item-table-component.component.scss"],
})
export class PaymentItemTableComponentComponent implements OnChanges {
  @Input() isPictorial = false
  @Input() isRenewal = false
  @Input() products
  @Input() title

  displayedColumns
  displayedHeader

  isKorean

  constructor(private translate: TranslateService, private cookieService: CookieService) {
    this.isKorean = this.cookieService.get("lang") === "KOR"
    this.displayedColumns = defaultHeader.map((header) => {
      this.translate.get(header.label).subscribe((translated) => {
        header.label = translated
      })
      return header
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    const localPictorial = changes.isPictorial
      ? changes.isPictorial.currentValue || this.isPictorial
      : this.isPictorial

    this.displayedHeader = this.displayedColumns.map((header) => header.value)
    if (!localPictorial) {
      if (this.isRenewal) {
        this.products = changes.products.currentValue.map((product) => {
          product.creator = product.creator.name
          product.createdAt = moment(product.date_end).format(
            this.isKorean ? "YYYY MM DD" : "MMM DD YYYY",
          )

          return product
        })
      }
    }
    if (localPictorial && changes.products && changes.products.currentValue) {
      this.products = changes.products.currentValue.map((product) => {
        product.name = product.title

        return product
      })

      if (this.displayedColumns.find((header) => header.value == "createdAt")) {
        this.displayedHeader.pop()
      }
    }
  }
}
