import { Component, OnInit } from "@angular/core"
import { TranslateService } from "@ngx-translate/core"
import { CookieService } from "angular2-cookie"
import { map } from "rxjs/operators"
import { CreatorService } from "../../services/creator.service"
import { TierService } from "../../services/tier.service"

@Component({
  selector: "app-tier-list-page",
  templateUrl: "./tier-list-page.component.html",
  styleUrls: ["./tier-list-page.component.scss"],
})
export class TierListPageComponent implements OnInit {
  creator
  isTierFormShown: boolean

  isKorean

  constructor(
    private tierService: TierService,
    private creatorService: CreatorService,
    private cookieService: CookieService,
    private translateService: TranslateService,
  ) {
    this.isTierFormShown = false
  }

  ngOnInit() {
    this.initCreator()
    this.isKorean = this.cookieService.get("lang") === "KOR"
  }

  initCreator() {
    this.creatorService
      .get("me")
      .pipe(
        map((response: any) => {
          if (!this.isKorean) {
            // temporary fix for perks schedule
            for (let i = 0; i < response.tiers.length; i++) {
              if (response.tiers[i].rewards && response.tiers[i].rewards.length > 0) {
                for (let j = 0; j < response.tiers[i].rewards.length; j++) {
                  if (response.tiers[i].rewards[j].delivery_schedule === "월 정기") {
                    response.tiers[i].rewards[j].delivery_schedule = "Monthly"
                  } else {
                    response.tiers[i].rewards[j].delivery_schedule = "One-time"
                  }
                }
              }
            }
          }
          return response
        }),
      )
      .subscribe((res) => {
        this.creator = res
      })
  }

  showTierForm() {
    this.isTierFormShown = true
  }

  deleteTier(tierId) {
    const messageConfirm = this.translateService.instant("ALERT_CONFIRM.MEMBERSHIP_DELETE_CONFIRM")
    if (confirm(messageConfirm)) {
      this.tierService.delete(tierId).subscribe(
        (res) => {
          const index = this.creator.tiers.findIndex((item) => item.id === tierId)
          if (index >= 0) {
            this.creator.tiers.splice(index, 1)
          }
          alert(this.translateService.instant("ALERT_CONFIRM.MEMBERSHIP_DELETE_SUCCESS"))
        },
        (error) => {
          console.log(error)
          if (error.status === 403) {
            alert(this.translateService.instant("ALERT_CONFIRM.MEMBERSHIP_DELETE_FAIL"))
          }
        },
      )
    }
  }

  onSubmitted() {
    this.isTierFormShown = false
    this.initCreator()
  }
}
