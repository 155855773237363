import { Injectable } from "@angular/core"
import { PictorialService } from "src/app/board/services/pictorial.service"
import { PictorialListItemWithThumbnail } from "src/app/board/types/pictorial.type"
import { Pagination, PaginationMeta } from "src/app/shared/types/pagination.type"
import { ContentThemeService } from "./content-theme.service"
import { PictorialQuery } from "../types/ajax.type"

@Injectable({
  providedIn: "root",
})
export class ContentDataService {
  contents: PictorialListItemWithThumbnail[] = []
  meta: PaginationMeta
  isLoading = false
  hasMore = true
  showAdult = false
  isFree = false
  themeSlugs: string[] = []
  themeFilter = ""

  constructor(
    private pictorialService: PictorialService,
    private themeService: ContentThemeService,
  ) {}

  resetContents() {
    this.contents = []
    this.meta = null
  }

  async fetchThemeSlugs() {
    this.themeService.getList().subscribe((res) => (this.themeSlugs = res))
  }

  async fetchPictorialContent() {
    this.setLoading(true)
    const query = this.constructQuery()
    const result = (await this.pictorialService
      .getList(query)
      .toPromise()) as Pagination<PictorialListItemWithThumbnail>
    this.updateState(result)
  }

  private setLoading(isLoading: boolean) {
    this.isLoading = isLoading
  }

  private constructQuery(): PictorialQuery {
    const { currentPage = 0 } = this.meta || {}

    const query = {
      themeSlugs: this.themeFilter ? [this.themeFilter] : [],
      isAdult: this.showAdult,
      isFree: this.isFree,
      release: true,
    }
    return { ...query, limit: 24, page: currentPage + 1 }
  }

  private updateState(result: Pagination<PictorialListItemWithThumbnail>) {
    const { items, meta } = result
    const newContents = this.contents || []
    this.contents = [...newContents, ...items]
    this.meta = meta
    this.hasMore = meta.currentPage < meta.totalPages
    this.setLoading(false)
  }

  toggleAdultFilter(value?: boolean) {
    this.showAdult = typeof value === "boolean" ? value : !this.showAdult
  }

  toggleThemeFilter(themeSlug: string) {
    if (this.themeFilter === themeSlug) {
      this.themeFilter = ""
    } else {
      this.themeFilter = themeSlug
    }
  }

  setFreeFilter(value: boolean) {
    this.isFree = value
  }
}
