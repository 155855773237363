import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { Form } from "../../shared/model/form"
import { environment } from "../../../environments/environment"
import { tap } from "rxjs/operators"
import { Pagination } from "src/app/shared/types/pagination.type"
import { GetPaymentParams } from "../types/ajax.type"
import { Transaction } from "../types/transaction.type"

interface CreatePictorialPaymentParams {
  tier?: number
  pictorial_id: string
  amount: number
  amount_usd: number
}

interface StripePayment {
  creator: number
  tier?: number
}

interface StripePaymentIntent {
  payment_pictorial: number
}

@Injectable({
  providedIn: "root",
})
export class TransactionService {
  constructor(private http: HttpClient) {}

  create(form: Form) {
    const data = form.formGroup.value
    return this.http.post(environment.apiUrl + "subscriptions/", data).pipe(
      tap(
        (res) => form.clearErrors(),
        (error) => form.setErrors(error),
      ),
    )
  }

  createForTier(creatorId, tierId) {
    const data = {
      creator: creatorId,
      tier: tierId,
      use_billing_key: true,
      birth: "",
      expiry: "",
      pwd_2digit: "",
      card_number: "",
      // 'address': ''
    }
    return this.http.post(environment.apiUrl + "subscriptions/", data)
  }

  registerPromotion(tierId: number) {
    return this.http.post(environment.apiUrl + "memberships/register-promotion/", {
      tier_id: tierId,
    })
  }

  createForPictorial(form: Form) {
    const data = form.formGroup.value
    return this.http.post(environment.apiUrl + "payment-intent/", data)
  }

  createPictorialPayment(params: CreatePictorialPaymentParams) {
    return this.http.post(environment.apiUrl + "payment-pictorial/", params)
  }

  createPostPayment(params) {
    return this.http.post(environment.apiUrl + "payment-post/", params)
  }

  getPictorialPayment(params?: GetPaymentParams) {
    return this.http.get<Pagination<Transaction>>(environment.apiUrl + "payment-pictorial/mine/", {
      params,
    })
  }

  getMyContentsWithoutPagination() {
    return this.http.get<Transaction[]>(environment.apiUrl + "payment-pictorial/mine/", {
      params: {
        pagination: "false",
      },
    })
  }

  getPostPayment(params?: GetPaymentParams) {
    return this.http.get<Pagination<Transaction>>(environment.apiUrl + "payment-post/mine/", {
      params,
    })
  }

  getListPaginated(data) {
    return this.http.get(environment.apiUrl + "payment-pictorial/", { params: data })
  }

  getListPaginatedByUrl(url) {
    return this.http.get(url)
  }
}
