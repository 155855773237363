import { Component, OnInit } from "@angular/core"
import { UserService } from "../../services/user.service"

import { CookieService } from "angular2-cookie/core"
import { TranslateService } from "@ngx-translate/core"

@Component({
  selector: "app-user-activate-page",
  templateUrl: "./user-activate-page.component.html",
  styleUrls: ["./user-activate-page.component.scss"],
})
export class UserActivatePageComponent implements OnInit {
  submitDisabled = false

  //
  langFlag

  constructor(
    private userService: UserService,
    private cookieService: CookieService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    if (this.cookieService.get("lang") !== "KOR") {
      this.langFlag = "ENG"
    } else {
      this.langFlag = "KOR"
    }
  }

  onSubmit() {
    this.submitDisabled = true
    this.userService.sendActivationEmail().subscribe(
      (res) => {
        alert(this.translateService.instant("ALERT_CONFIRM.SEND_VERIFICATION_MAIL"))
      },
      (error) => {
        this.submitDisabled = false
      },
    )
  }
}
