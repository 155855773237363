import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core"
import { Form } from "../../../shared/model/form"
import { FormService } from "../../../shared/services/form.service"
import { TierService } from "../../services/tier.service"
import { DynamicFieldComponent } from "../../../shared/components/dynamic-field/dynamic-field.component"
import { RewardService } from "../../services/reward.service"
import { NavigationEnd, Router } from "@angular/router"
import { CreatorService } from "../../services/creator.service"
import { CookieService } from "angular2-cookie"
import { map } from "rxjs/operators"
import { TranslateService } from "@ngx-translate/core"
import { CurrencyPipe } from "src/app/shared/pipe/currency.pipe"

@Component({
  selector: "app-tier-form",
  templateUrl: "./tier-form.component.html",
  styleUrls: ["./tier-form.component.scss"],
})
export class TierFormComponent implements OnInit, OnDestroy {
  @ViewChild("fieldMaxContributors", { static: false }) fieldMaxContributors: DynamicFieldComponent
  @Input() tierId?: number
  @Input() updateMode: boolean
  @Output() submitted = new EventEmitter<void>()

  form: Form
  rewards
  maxContributorsChecked = false
  creator

  hasMember = false
  routerSubscription

  isKorean

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private formService: FormService,
    private rewardService: RewardService,
    private tierService: TierService,
    private creatorService: CreatorService,
    private cookieService: CookieService,
    private translateService: TranslateService,
    private currencyPipe: CurrencyPipe,
  ) {
    this.tierId = null
    this.creator = this.getCreator()
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe()
  }

  ngOnInit() {
    this.isKorean = this.cookieService.get("lang") === "KOR"
    const url = this.tierId ? `tiers/${this.tierId}/` : "tiers/"
    this.formService
      .getFormData(url)
      .pipe(
        map((response: any) => {
          this.translateResponse(response)
          return response
        }),
      )
      .subscribe(
        (formData) => {
          const usdControl = formData.formGroup.get("amount_usd")
          const amountControl = formData.formGroup.get("amount")

          if (!usdControl.value && usdControl) {
            usdControl.setValue(this.currencyPipe.transform(amountControl.value))
          }
          this.form = formData
          this.maxContributorsChecked = this.form.getValue("max_contributors") > 0
          this.onMaxContributorsCheckedChanged()
          this.onHasContributorsCheckedChanged()
          this.initRewards()
        },
        () => {
          this.router.navigate(["/404"], { replaceUrl: true })
        },
      )

    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.initRewards()
      }
    })
  }

  getCreator() {
    return this.creatorService.currentCreator
  }

  onSubmit() {
    const amountUsd = this.form.formGroup.get("amount_usd").value
    this.form.formGroup.get("amount_usd").setValue(Number(amountUsd))

    if (this.tierId) {
      this.tierService.update(this.tierId, this.form).subscribe(() => this.submitted.emit())
    } else {
      this.form.formGroup.get("amount").setValue(undefined)
      this.tierService.create(this.form).subscribe(() => this.submitted.emit())
    }
  }

  initRewards() {
    this.rewardService.getList().subscribe((rewards) => (this.rewards = rewards))
  }

  toggleReward(rewardId) {
    const rewardsControl = this.form.formGroup.get("rewards")
    const rewards = rewardsControl.value === "" ? [] : rewardsControl.value

    if (rewards.some((reward) => reward === rewardId)) {
      // const index = rewards.indexOf(rewardId);
      // rewards.splice(index, 1);
    } else {
      rewards.push(rewardId)
    }

    rewardsControl.setValue(rewards)
  }

  removeReward(rewardId) {
    const rewardsControl = this.form.formGroup.get("rewards")
    const rewards = rewardsControl.value

    if (rewards.some((reward) => reward === rewardId)) {
      const index = rewards.indexOf(rewardId)
      rewards.splice(index, 1)
    }

    rewardsControl.setValue(rewards)
  }

  getRewardName(rewardId) {
    return this.rewards.find((reward) => reward.id === Number(rewardId)).name
  }

  clearImage() {
    this.form.formGroup.get("image").setValue("")
  }

  onMaxContributorsCheckedChanged() {
    try {
      const input = this.fieldMaxContributors.input.nativeElement
      if (this.maxContributorsChecked) {
        this.renderer.removeAttribute(input, "disabled")
      } else {
        this.form.formGroup.get("max_contributors").setValue(null)
        this.renderer.setAttribute(input, "disabled", null)
      }
    } catch (e) {
      if (!this.maxContributorsChecked) {
        this.form.fields["max_contributors"].widget.attrs.disabled = "disabled"
      }
    }
  }

  onHasContributorsCheckedChanged() {
    if (this.form.getValue("has_contributors")) {
      this.hasMember = true
      this.form.fields["amount"].widget.attrs.disabled = "disabled"
      this.form.fields["amount_usd"].widget.attrs.disabled = "disabled"
    }
  }

  deleteReward(reward) {
    if (confirm(this.translateService.instant("ALERT_CONFIRM.REMOVE_REWARD_CONFIRM"))) {
      this.rewardService.delete(reward).subscribe((res) => {
        this.ngOnInit()
      })
    }
  }

  translateResponse(response) {
    const { fields } = response
    if (fields.joinable && fields.joinable.help_text) {
      fields.joinable.help_text = this.translateService.instant(
        "CREATOR_TIER_DETAIL.JOINABLE_DISCLAIMER",
      )
      fields.joinable.label = this.translateService.instant("CREATOR_TIER_DETAIL.JOINABLE")
    }

    if (fields.visible) {
      fields.visible.label = this.translateService.instant("CREATOR_TIER_DETAIL.PUBLICLY")
    }

    if (fields.category && Array.isArray(fields.category.choices)) {
      fields.category.choices = fields.category.choices.map((choice) => {
        switch (choice.value) {
          case "normal":
            choice.display_name = this.translateService.instant(
              "CREATOR_TIER_DETAIL.CATEGORY.BASIC",
            )
            break
          case "education":
            choice.display_name = this.translateService.instant("CREATOR_TIER_DETAIL.CATEGORY.EDU")
            break
        }
        return choice
      })
    }
  }
}
