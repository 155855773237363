import { Pipe, PipeTransform } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { AuthService } from "src/app/core/services/auth.service"
import { environment } from "src/environments/environment"
import { Stage } from "src/environments/stage"

@Pipe({
  name: "xhrImage",
})
export class XhrImagePipe implements PipeTransform {
  constructor(private http: HttpClient) {}

  async transform(src: string): Promise<string> {
    const imageBlob = await this.http.get(src, { responseType: "blob" }).toPromise()
    const reader = new FileReader()
    return new Promise((resolve, reject) => {
      reader.onloadend = () => resolve(reader.result as string)
      reader.readAsDataURL(imageBlob)
    })
  }
}

@Pipe({
  name: "parameterSecure",
})
export class ParameterSecurePipe implements PipeTransform {
  constructor(private http: HttpClient, private auth: AuthService) {}

  transform(src: string): string {
    const token = this.auth.getCognitoToken()
    if (token && token.IdToken && environment.stage === Stage.dev) {
      return `${src}?token=${token.IdToken}`
    }
    return src
  }
}
