import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { TranslateService } from "@ngx-translate/core"
import { Tag } from "src/app/account/types/tag.type"
import { FroalaEditorService } from "src/app/board/services/froala-editor.service"
import { SendmeCreateService } from "src/app/sendme/pages/sendme-create/sendme-create.service"
import { TagDataService } from "src/app/shared/services/tag-data.service"
import { environment } from "src/environments/environment"
import { SelectContentDialogComponent } from "../../components/select-content-dialog/select-content-dialog.component"
import { MatDialog } from "@angular/material"
import { Pictorial } from "src/app/board/types/pictorial.type"
import { AuthService } from "src/app/core/services/auth.service"

@Component({
  selector: "app-write-sendme-screen",
  templateUrl: "./write-sendme-screen.component.html",
  styleUrls: ["./write-sendme-screen.component.scss"],
})
export class WriteSendmeScreenComponent implements OnInit {
  @Input() sendmeCreateService: SendmeCreateService
  @Output() toNextPage = new EventEmitter()

  options: Object
  editorContent: string

  maxTagCount = 3

  tags: Tag[] = []

  attachFileIdSet = new Set<string>()
  removeFileIdSet = new Set<string>()

  constructor(
    private froalaEditorService: FroalaEditorService,
    private auth: AuthService,
    private translateService: TranslateService,
    private tagService: TagDataService,
    private dialog: MatDialog,
  ) {
    this.setOptions()
  }

  ngOnInit() {
    this.tagService.getActiveTags().subscribe((tags) => {
      this.tags = tags
    })
  }

  onClickNext() {
    this.toNextPage.emit(null)
  }

  onClickTag(tagId: number) {
    const index = this.sendmeCreateService.selectedTagIds.indexOf(tagId)
    if (index === -1) {
      this.sendmeCreateService.selectedTagIds.push(tagId)
    } else {
      this.sendmeCreateService.selectedTagIds.splice(index, 1)
    }
  }

  selectTarget(target: "membership" | "tag") {
    this.sendmeCreateService.target = target

    this.sendmeCreateService.selectedTagIds = []
    this.sendmeCreateService.selectedMembershipIds = []
  }

  get sendmeType() {
    return this.sendmeCreateService.sendmeType
  }

  get isFree() {
    return this.sendmeCreateService.isFree
  }

  get nextButtonDisabled() {
    return this.sendmeType === null || this.isFree === null
  }

  get allowedSendByTag() {
    return this.sendmeCreateService.creator.has_sendme_tag_permission
  }

  changeTier = (_, tierIds: number[]) => {
    this.sendmeCreateService.selectedMembershipIds = tierIds
  }

  changeRegionExcluded = (_, regionExcluded: boolean) => {
    this.sendmeCreateService.specificRegionExcluded = regionExcluded
  }

  openContentsDialog() {
    const dialog = this.dialog.open(SelectContentDialogComponent, {
      maxWidth: "100vw",
      data: {
        isFree: this.isFree,
        creatorId: this.sendmeCreateService.creator.id,
        creatorUrl: this.sendmeCreateService.creator.url,
      },
    })

    dialog.afterClosed().subscribe((res: Pictorial) => {
      if (res) {
        this.sendmeCreateService.selectedContent = res
      }
    })
  }

  async setOptions() {
    const fileSizeMb = 20
    const requestParams = {}
    const addFileItem = this.froalaEditorService.getAddFileItemFunction(this)
    const removeFileItem = this.froalaEditorService.getRemoveFileItemFunction(this)
    const fileSizeErrorMsg = this.translateService.instant("FROALA_EDITOR.FILE_UNDER_SIZE", {
      size: fileSizeMb + "MB",
    })
    const fileSizeLimit = fileSizeMb * 1024 * 1024

    const translator = this.translateService
    const cognitoToken = this.auth.getCognitoToken()
    this.options = {
      requestHeaders: {
        "cognito-authorization": cognitoToken && cognitoToken.IdToken ? cognitoToken.IdToken : null,
      },
      key: "IB2A6A4B2pD1D1D1H5E1J4A16B3C7E7kuA-8wyyoszF4ogw==",
      placeholderText: this.translateService.instant("FROALA_EDITOR.PLACE_HOLDER"),
      charCounterCount: false,
      toolbarButtons: [
        "bold",
        "italic",
        "underline",
        "fontFamily",
        "fontSize",
        "color",
        "insertLink",
        "insertImage",
        "insertVideo",
        "emoticon",
        "insertFile",
      ],
      requestWithCORS: false,
      requestWithCredentials: false,
      // Set the image upload URL.
      imageUploadURL: environment.uploadFileUrl,
      imageUploadParams: requestParams,
      // Set request type.
      imageUploadMethod: "POST",
      // Set max image size to 20MB.
      imageMaxSize: 20 * 1024 * 1024,
      // Allow to upload PNG and JPG.
      imageAllowedTypes: ["jpeg", "jpg", "png"],

      // Set the default image width (Image's original width)
      imageDefaultWidth: 0,

      // Set the file upload URL.
      fileUploadURL: environment.uploadFileUrl,
      fileUploadParams: requestParams,
      // Set request type.
      fileUploadMethod: "POST",
      // Set max file size to 20MB.
      // 파일크기 예외처리 확인 (예외 150MB)
      fileMaxSize: fileSizeLimit,
      // Allow to upload any file.
      fileAllowedTypes: ["*"],

      // Set the file upload URL.
      videoUploadURL: environment.uploadFileUrl,
      videoUploadParams: requestParams,
      // Set request type.
      videoUploadMethod: "POST",
      // Set max file size to 10MB.
      // 파일크기 예외처리 확인 (예외 150MB)
      videoMaxSize: fileSizeLimit,
      // videoMaxSize: 20 * 1024 * 1024,
      // Allow to upload any file.
      videoAllowedTypes: ["mp4", "avi"],

      events: {
        "froalaEditor.commands.undo": () => {},
        "froalaEditor.image.uploaded": addFileItem,
        "froalaEditor.image.removed": removeFileItem,
        "froalaEditor.image.replaced": function (e, editor, $img, res) {},
        "froalaEditor.image.inserted": function (e, editor, $img) {},
        "froalaEditor.image.error": function (e, editor, error) {
          const $popup = editor.popups.get("image.insert")
          const $layer = $popup.find(".fr-image-progress-bar-layer")
          // Bad link.
          if (error.code === 1) {
            $layer.find("h3").text(translator.instant("FROALA_EDITOR.INVALID_LINK"))
          } else if (error.code === 2) {
            $layer.find("h3").text(translator.instant("FROALA_EDITOR.INVALID_LINK_RESPONSE"))
          } else if (error.code === 3) {
            $layer.find("h3").text(translator.instant("FROALA_EDITOR.IMAGE_UPLOAD_FAILED"))
          } else if (error.code === 4) {
            $layer.find("h3").text(translator.instant("FROALA_EDITOR.PARSING_FAILED"))
          } else if (error.code === 5) {
            $layer.find("h3").text(translator.instant("FROALA_EDITOR.FILE_UNDER_20MB"))
          } else if (error.code === 6) {
            $layer.find("h3").text(translator.instant("FROALA_EDITOR.INVALID_IMAGE_TYPE"))
          } else if (error.code === 7) {
            $layer.find("h3").text(translator.instant("FROALA_EDITOR.SAME_DOMAIN_IE9"))
          }
        },
        "froalaEditor.file.uploaded": addFileItem,
        "froalaEditor.file.removed": removeFileItem,
        "froalaEditor.file.inserted": function (e, editor, files) {},
        "froalaEditor.file.error": function (e, editor, error, response) {
          const $popup = editor.popups.get("file.insert")
          const $layer = $popup.find(".fr-file-progress-bar-layer")
          // Bad link.
          if (error.code === 1) {
            $layer.find("h3").text(translator.instant("FROALA_EDITOR.INVALID_LINK"))
          } else if (error.code === 2) {
            $layer.find("h3").text(translator.instant("FROALA_EDITOR.INVALID_LINK_RESPONSE"))
          } else if (error.code === 3) {
            $layer.find("h3").text(translator.instant("FROALA_EDITOR.FILE_UPLOAD_FAILED"))
          } else if (error.code === 4) {
            $layer.find("h3").text(translator.instant("FROALA_EDITOR.PARSING_FAILED"))
          } else if (error.code === 5) {
            $layer.find("h3").text(fileSizeErrorMsg)
          } else if (error.code === 6) {
            $layer.find("h3").text(translator.instant("FROALA_EDITOR.INVALID_FILE_TYPE"))
          } else if (error.code === 7) {
            $layer.find("h3").text(translator.instant("FROALA_EDITOR.SAME_DOMAIN_IE9"))
          }
        },
        "froalaEditor.video.uploaded": addFileItem,
        "froalaEditor.video.removed": removeFileItem,
        "froalaEditor.video.inserted": function (e, editor, files) {},
        "froalaEditor.video.error": function (e, editor, error, response) {
          const $popup = editor.popups.get("video.insert")
          const $layer = $popup.find(".fr-video-progress-bar-layer")
          // Bad link.
          if (error.code === 1) {
            $layer.find("h3").text(translator.instant("FROALA_EDITOR.INVALID_LINK"))
          } else if (error.code === 2) {
            $layer.find("h3").text(translator.instant("FROALA_EDITOR.invalid_link_response"))
          } else if (error.code === 3) {
            $layer.find("h3").text(translator.instant("FROALA_EDITOR.VIDEO_UPLOAD_FAILED"))
          } else if (error.code === 4) {
            $layer.find("h3").text(translator.instant("FROALA_EDITOR.PARSING_FAILED"))
          } else if (error.code === 5) {
            $layer.find("h3").text(fileSizeErrorMsg)
          } else if (error.code === 6) {
            $layer.find("h3").text(translator.instant("FROALA_EDITOR.INVALID_VIDEO_TYPE"))
          } else if (error.code === 7) {
            $layer.find("h3").text(translator.instant("FROALA_EDITOR.SAME_DOMAIN_IE9"))
          }
        },
      },
    }
  }

  get completedRequiredValues() {
    const {
      content,
      priceUsd,
      selectedContent,
      selectedMembershipIds,
      selectedTagIds,
      target,
      title,
      isFree,
      sendmeType,
    } = this.sendmeCreateService

    if (!title.trim() || !content.trim()) {
      return false
    }

    if (sendmeType === "pictorial" && !selectedContent) {
      return false
    }

    if (target == "membership" && !selectedMembershipIds.length) {
      return false
    }

    if (target == "tag" && !selectedTagIds.length) {
      return false
    }

    if (!isFree && !priceUsd && sendmeType === "post") {
      return false
    }

    return true
  }
}
