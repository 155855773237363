import { Component, ElementRef, OnInit, Renderer2, ViewChild } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { ModalPageComponent } from "../../../shared/components/modal-page/modal-page.component"
import { Form } from "../../../shared/model/form"
import { FormService } from "../../../shared/services/form.service"
import { BillingKeyService } from "../../services/billing-key.service"

import countryCode from "src/assets/i18n/countryCode"
import { FormControl } from "@angular/forms"
import { UserService } from "src/app/account/services/user.service"
import { MatAutocompleteTrigger } from "@angular/material"
import { Observable } from "rxjs"
import { map, startWith } from "rxjs/operators"
import { CountryService } from "src/app/shared/services/country.service"

interface CountryCode {
  code: string
  name: string
}
@Component({
  selector: "app-billing-key-create-page",
  templateUrl: "./billing-key-create-page.component.html",
  styleUrls: ["./billing-key-create-page.component.scss"],
})
export class BillingKeyCreatePageComponent extends ModalPageComponent implements OnInit {
  @ViewChild(MatAutocompleteTrigger, {
    static: false,
  })
  autocomplete: MatAutocompleteTrigger
  @ViewChild("country", {
    static: true,
  })
  autocompleteInput: ElementRef
  form: Form
  countryControl = new FormControl("")
  zipControl = new FormControl("")
  submitDisabled = true
  termsDisplayed = false
  countryCode: CountryCode[] = countryCode
  filteredCountryCode: Observable<CountryCode[]>
  displayFn
  _filter

  // ngModel for billing inputs
  cardNumber = ["", "", "", ""]
  expDate = ["", ""]
  // birthDate = ['','',''];
  cvc = ""
  cardError = false
  expError = false
  cvcError = false
  countryError = false
  zipError = false

  hasOldPayment = false
  stripePayment
  isInducer
  isSaving = false

  constructor(
    renderer: Renderer2,
    router: Router,
    private userService: UserService,
    private billingKeyService: BillingKeyService,
    private formService: FormService,
    private activatedRoute: ActivatedRoute,
    private countryService: CountryService,
  ) {
    super(renderer, router)

    this.activatedRoute.params.subscribe((param) => {
      this.isInducer = param.isInducer
    })

    this._filter = this.countryService.filterByName
    this.displayFn = this.countryService.getCountryCode
  }

  ngOnInit() {
    const url = "payment-methods/"
    this.filteredCountryCode = this.countryControl.valueChanges.pipe(
      startWith(""),
      map((country: string | CountryCode) => {
        if (!country) {
          return this.countryCode
        }
        if (typeof country == "string") {
          return this._filter(country)
        }
        return this._filter(country.name)
      }),
    )

    this.zipControl.valueChanges.subscribe(() => {
      this.isSubmitPossible()
    })

    this.getCountry()

    this.formService.getFormData(url).subscribe((form) => {
      this.form = form
    })

    this.billingKeyService.getOldPaymentMembership().subscribe((res: any) => {
      this.hasOldPayment = res.count > 0
    })

    this.billingKeyService.getMethod().subscribe((res: any) => {
      this.stripePayment = res
    })
  }

  getCountry() {
    this.userService.getCountry().subscribe((countryData) => {
      if (countryData) {
        const { country, postal_code: postalCode } =
          typeof countryData === "string" ? JSON.parse(countryData) : countryData

        this.countryControl.setValue(this.countryCode.find((ele) => ele.code == country))
        this.zipControl.setValue(postalCode)
      }
    })
  }

  closeAutoComplete() {
    if (this.autocomplete.panelOpen) {
      this.autocomplete.closePanel()
    }
  }

  isCardNumValid() {
    return this.cardNumber.every((num, idx, origin) => {
      if (idx == origin.length - 1) {
        return 4 <= num.length && num.length <= 5
      }
      return num.length == 4
    })
  }

  isCardExpValid() {
    return this.expDate.every((num, idx, origin) => {
      if (idx != origin.length - 1) {
        return 4 == num.length
      }
      return num.length == 2
    })
  }

  onCountryInputPressed(event) {
    event.stopPropagation()

    if (event.code == "Enter") {
      event.preventDefault()
      if (!this.submitDisabled && !this.isSaving) {
        this.onSubmit()
      }
      return
    }

    this.isSubmitPossible()
  }

  onInputKeyPressed(event) {
    const { target } = event

    if (event.code == "Enter") {
      // event.stopPropagation()
      event.preventDefault()
      if (!this.submitDisabled && !this.isSaving) {
        this.onSubmit()
      }
      return
    }

    let value = target.value.replace(/[^\d]/g, "")

    const id = target.id

    switch (true) {
      case /^card-*/.test(id): {
        const idx = id.split("-")[1]
        value = value.substring(0, idx != 4 ? 4 : 5)
        this.cardNumber[idx - 1] = value
        break
      }
      case /^expiry-*/.test(id): {
        const idx = id.split("-")[1]
        value = value.substring(0, idx != 1 ? 3 : 4)
        this.expDate[idx - 1] = value
        break
      }
      case /^cvc$/.test(id):
        value = value.substring(0, 3)
        this.cvc = value
        break
    }
    target.value = value
    this.isSubmitPossible()
  }

  isCVCValid() {
    return this.cvc.length === 3
  }

  isSubmitPossible() {
    const cvc = this.isCVCValid()
    const cardNum = this.isCardNumValid()
    const exp = this.isCardExpValid()

    this.submitDisabled = !(cardNum && exp && cvc)
  }

  onSubmit() {
    this.form.clearErrors()

    // send in with correct format
    this.formatBillingInfo()

    if (this.isCountryDataValid()) {
      this.createStripe()
    }
  }

  // change later so we use 'app-dynamic-field-component' properly
  formatBillingInfo() {
    const cardNum =
      this.cardNumber[0] +
      "-" +
      this.cardNumber[1] +
      "-" +
      this.cardNumber[2] +
      "-" +
      this.cardNumber[3]
    const exp = this.expDate[0] + "-" + this.expDate[1]
    // var birth = this.birthDate[0] + '' + this.birthDate[1] + '' + this.birthDate[2];

    // set form value
    this.form.formGroup.value.card_number = cardNum !== "---" ? cardNum : ""
    // this.form.formGroup.value.card_number = cardNum;
    this.form.formGroup.value.expiry = exp
    this.form.formGroup.value.cvc = this.cvc
  }

  createStripe() {
    // now call endpoint
    this.isSaving = true
    return this.billingKeyService.create(this.form).subscribe(
      (res) => {
        this.isSaving = false
        this.setAllErrorToFalse()
        this.submitCountry()
      },
      (error) => {
        this.isSaving = false
        this.setAllErrorToFalse()
        this.showErrorMsg(error)
      },
    )
  }

  submitCountry() {
    const submitCountryCode = this.countryControl.value.code
    const zipCode = this.zipControl.value

    if (submitCountryCode != "US" && submitCountryCode != "CA") {
      this.zipControl.setValue(undefined)
    }

    this.countryService.set(submitCountryCode, zipCode).subscribe(
      () => {
        if (this.hasOldPayment) {
          this.router.navigate([
            {
              outlets: {
                popup: ["checkout-product"],
              },
            },
          ])
        } else {
          this.router.navigate([
            {
              outlets: {
                popup: null,
              },
            },
          ])
        }
      },
      (error) => {
        this.form.setErrors({
          error: {
            non_field_errors: [error.error || "Invalid Country or Post data"],
          },
        })
      },
    )
  }

  isCountryDataValid() {
    const countryData = this.countryControl.value
    const zipData = this.zipControl.value

    if (
      !countryData ||
      !countryData.code ||
      !countryCode.find((country) => country.code == countryData.code)
    ) {
      this.countryError = true
      this.countryControl.setErrors({})
      return false
    }
    if ((countryData.code == "CA" || countryData.code == "US") && !zipData) {
      this.zipError = true
      this.zipControl.setErrors({})
      return false
    }

    return true
  }

  showErrorMsg(err) {
    if (err.error) {
      if (err.error.card_number) {
        this.cardError = true
      }
      if (err.error.expiry) {
        this.expError = true
      }
      if (err.error.cvc) {
        this.cvcError = true
      }
    }
  }

  setAllErrorToFalse() {
    this.cardError = false
    this.expError = false
    this.cvcError = false
    this.countryError = false
    this.zipError = false
  }
}
