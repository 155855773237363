import { NgModule } from "@angular/core"
import { MatTabsModule } from "@angular/material"
import { NotificationRoutingModule } from "./notification-routing.module"
import { NotificationListActivityPageComponent } from "./pages/notification-list-activity-page/notification-list-activity-page.component"
import { SharedModule } from "../shared/shared.module"

@NgModule({
  imports: [SharedModule, NotificationRoutingModule, MatTabsModule],
  declarations: [NotificationListActivityPageComponent],
})
export class NotificationModule {}
