import { NgModule } from "@angular/core"
import { RouterModule, Routes } from "@angular/router"
import { SendmeChatDetailComponent } from "./components/sendme-chat-detail/sendme-chat-detail.component"

import { SendmeContentsStorageComponent } from "./pages/sendme-contents-storage/sendme-contents-storage.component"
import { SendmeInboxComponent } from "./pages/sendme-inbox/sendme-inbox.component"
import { SendmeRootComponent } from "./pages/sendme-root/sendme-root.component"
import { AuthGuardService } from "../shared/guards/auth-guard.service"
import { SendmeContentsDetailStorageComponent } from "./pages/sendme-contents-detail-storage/sendme-contents-detail-storage.component"
import { SendmeCreatorOnlyRouteGuard } from "./guard/sendme-creator-only-route.guard"
import { SendmeInboxChatDetailComponent } from "./pages/sendme-inbox-chat-detail/sendme-inbox-chat-detail.component"
import { SendmeOutboxChatDetailComponent } from "./pages/sendme-outbox-chat-detail/sendme-outbox-chat-detail.component"
import { SendmeCreateComponent } from "./pages/sendme-create/sendme-create.component"
import { SendmeOutboxComponent } from "./pages/sendme-outbox/sendme-outbox.component"

const route: Routes = [
  {
    path: "sendme",
    component: SendmeRootComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: "inbox",
        children: [
          {
            path: "",
            redirectTo: "read",
            pathMatch: "full",
          },
          { path: ":type", component: SendmeInboxComponent },
          { path: ":type/:id", component: SendmeInboxChatDetailComponent },
        ],
      },
      {
        path: "storage",
        children: [
          { path: "", component: SendmeContentsStorageComponent },
          { path: ":type", component: SendmeContentsDetailStorageComponent },
        ],
      },
      {
        path: "sent",
        component: SendmeOutboxComponent,
        canActivate: [SendmeCreatorOnlyRouteGuard],
      },
      {
        path: "sent/:id",
        component: SendmeOutboxChatDetailComponent,
        canActivate: [SendmeCreatorOnlyRouteGuard],
      },
      {
        path: "compose",
        component: SendmeCreateComponent,
        canActivate: [SendmeCreatorOnlyRouteGuard],
      },
      { path: "", redirectTo: "inbox/read", pathMatch: "full" },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forChild(route)],
  exports: [RouterModule],
})
export class SendmeRoutingModule {}
