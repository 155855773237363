import { Category, Pictorial } from "../../../board/types/pictorial.type"

export interface PhotobookAutocomplete {
  title?: string
  themes?: string[]
  photoGraphers?: string[]
  type?: string
}

export enum PurchasePermission {
  membershipFree = "membership_free",
  membershipPaid = "membership_paid",
  allPaid = "all_paid",
  allFree = "all_free",
}

export interface ModelContestPictorial extends Pictorial {
  isContestRegisteredPictorial?: boolean
  category?: Category
  modelIds?: string[]
}
