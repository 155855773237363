import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { GetRankedModelParams, RankedModel } from "../types/asian-top-model-contest.type"
import { environment } from "src/environments/environment"
import { Util } from "src/app/shared/utils/utils"
import { Pagination } from "src/app/shared/types/pagination.type"

@Injectable({
  providedIn: "root",
})
export class AsianTopModelContestService {
  constructor(private http: HttpClient) {}

  getModelList(params?: GetRankedModelParams) {
    return this.http.get<Pagination<RankedModel>>(
      `${environment.pictorialApiUrl}models/campaigns`,
      {
        params: Util.toQueryParam(params),
      },
    )
  }
}
