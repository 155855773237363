import { Component, OnInit, ViewChild } from "@angular/core"

import { environment } from "src/environments/environment"
import { FormService } from "src/app/shared/services/form.service"
import { Form } from "src/app/shared/model/form"
import { UserService } from "../../services/user.service"
import { MessagingComponent } from "src/app/shared/components/messaging/messaging.component"
import { SnackbarService } from "src/app/shared/services/snackbar.service"
import { TranslateService } from "@ngx-translate/core"
import { MatAutocompleteTrigger, MatDialog } from "@angular/material"
import { CreatorService } from "src/app/creator/services/creator.service"
import { BillingKeyService } from "src/app/payment/services/billing-key.service"
import { FormControl } from "@angular/forms"

import countryCode from "src/assets/i18n/countryCode"
import { Observable } from "rxjs"
import { startWith, map, catchError, switchMap } from "rxjs/operators"
import { CountryService, CountryCodeType } from "src/app/shared/services/country.service"
import { CookieService } from "angular2-cookie"
import { User } from "../../types/user.type"

@Component({
  selector: "app-user-update-profile-page",
  templateUrl: "./user-update-profile-page.component.html",
  styleUrls: ["./user-update-profile-page.component.scss"],
})
export class UserUpdateProfilePageComponent extends MessagingComponent implements OnInit {
  @ViewChild(MatAutocompleteTrigger, {
    static: false,
  })
  autocomplete: MatAutocompleteTrigger
  user: User
  form: Form
  billingForm: Form
  cardInfo: string
  phoneNumberVerified = true
  isBizmsgMember = false
  phoneNumber: string

  countryControl = new FormControl("")
  zipControl = new FormControl("")
  countryCode: CountryCodeType[] = countryCode
  filteredCountryCode: Observable<CountryCodeType[]>
  displayFn
  _filter

  countryError = false
  zipError = false

  isKorean

  constructor(
    private formService: FormService,
    private userService: UserService,
    private creatorService: CreatorService,
    private billingKeyService: BillingKeyService,
    private countryService: CountryService,
    private cookieService: CookieService,
    protected snackbarService: SnackbarService,
    protected translateService: TranslateService,
    protected dialog: MatDialog,
  ) {
    super(snackbarService, translateService, dialog)
    this.getCountryAndBillingKey()

    this.displayFn = this.countryService.getCountryCode
    this._filter = this.countryService.filterByName
  }

  ngOnInit() {
    this.getCountryAndBillingKey()

    this.isKorean = this.cookieService.get("lang") === "KOR"

    this.filteredCountryCode = this.countryControl.valueChanges.pipe(
      startWith(""),
      map((country: string | CountryCodeType) => {
        if (!country) {
          return this.countryCode
        }
        if (typeof country == "string") {
          return this._filter(country)
        }
        return this._filter(country.name)
      }),
    )
    this.user = this.userService.getUser()
    this.formService.getFormData("users/me/").subscribe((form) => {
      this.form = form
    })

    this.creatorService
      .getListPaginated({
        is_contributing: true,
      })
      .subscribe((res: any) => {
        const results: Array<any> = res.results
        results.forEach((result: any) => {
          if (environment.bizmsgCreatorIds.includes(result.id)) {
            this.isBizmsgMember = true
          }
        })
      })
  }

  getCountryAndBillingKey() {
    this.countryService.get().subscribe((countryData) => {
      if (countryData) {
        const { country, postal_code: postalCode } =
          typeof countryData === "string" ? JSON.parse(countryData) : countryData

        this.countryControl.setValue(this.countryCode.find((ele) => ele.code == country))
        this.zipControl.setValue(postalCode)
      }
    })
    this.formService.getFormData("billing_keys/").subscribe(
      (form) => {
        if (form.data && form.data["billing_key_name"] && !this.cardInfo) {
          this.cardInfo = form.data["billing_key_name"]
        }
        this.billingForm = form
      },
      (error: any) => this.messageDanger(error),
    )

    this.billingKeyService.getMethod().subscribe(
      (newPayment: any) => {
        if (newPayment.card_number) {
          this.cardInfo = newPayment.card_number
        }
      },
      (error: any) => this.messageDanger(error),
    )
  }

  closeAutoComplete() {
    if (this.autocomplete.panelOpen) {
      this.autocomplete.closePanel()
    }
  }

  clearImage() {
    // 상대경로 이미지
    // this.form.formGroup.patchValue({'image': '/assets/img/icon/heart.png'});
    this.form.formGroup.patchValue({ image: "https://memberme.net/static/img/defaults/user.png" })

    // 이미지 삭제 별도 처리
    this.userService.resetUserImage(this.form).subscribe((res) => {
      this.messageSuccess(this.translate("ACCOUNT.PROFILE_IMAGE_DELETED"))
    })
  }

  onSubmit() {
    this.setAllErrorToFalse()
    const phoneNumber = this.form.formGroup.get("phone").value
    if (this.isBizmsgMember && this.isNotBlank(phoneNumber) && !this.phoneNumberVerified) {
      this.messageDanger(this.translate("ACCOUNT.PHONE_NOT_VERIFIED"), "ok", 5000)
      return false
    }
    this.userService
      .update(this.form)
      .pipe(
        switchMap((res) => {
          if (!this.countryControl.value) {
            return new Observable((obs) => {
              obs.next(1)
            })
          }

          return this.submitCountry()
        }),
        catchError((err) => {
          let errorText = err.error

          if (err.error && err.error.includes("h1")) {
            errorText = "Invalid Country"
          } else if (typeof err == "string") {
            errorText = err
          }
          this.showErrorMsg(errorText)
          this.form.setErrors(
            {
              error: {
                country: [errorText],
              },
            },
            false,
          )
          return new Observable()
        }),
      )
      .subscribe(() => {
        this.messageSuccess(this.translate("ACCOUNT.PROFILE_UPDATED"))
      })
  }

  submitCountry() {
    const { code } = this.countryControl.value

    if (code != "US" && code != "CA") {
      this.zipControl.setValue(undefined)
    }
    return this.countryService.set(code, this.zipControl.value)
  }

  onAddressChanged(ev) {
    this.form.formGroup.get("address").setValue(ev.target.value)
  }

  onPhoneChanged($event) {
    if (this.isBizmsgMember) {
      this.phoneNumber = this.form.formGroup.get("phone").value
      this.phoneNumberVerified = false
    }
  }

  isNotBlank(str) {
    return str && ("" + str).trim()
  }

  verifyRecieved($event) {
    this.phoneNumberVerified = $event
  }

  showErrorMsg(str) {
    if (str.toLocaleLowerCase().includes("postal")) {
      this.zipError = str
      this.zipControl.setErrors({})
    } else {
      this.countryControl.setErrors({})
      this.countryError = str
    }
  }

  setAllErrorToFalse() {
    this.countryError = false
    this.zipError = false

    this.countryControl.setErrors(null)
    this.zipControl.setErrors(null)
  }
}
