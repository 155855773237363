import { Component, OnInit, Renderer2 } from "@angular/core"
import { ModalPageComponent } from "../../../shared/components/modal-page/modal-page.component"
import { Router } from "@angular/router"
import { UserService } from "../../services/user.service"
import { AuthService } from "src/app/core/services/auth.service"
import { CookieService } from "angular2-cookie"

@Component({
  selector: "app-user-adult-verify-modal-page",
  templateUrl: "./user-adult-verify-modal-page.component.html",
  styleUrls: ["./user-adult-verify-modal-page.component.scss"],
})
export class UserAdultVerifyModalPageComponent extends ModalPageComponent implements OnInit {
  loading

  constructor(
    protected renderer: Renderer2,
    protected router: Router,
    private userService: UserService,
    private authService: AuthService,
    private cookieService: CookieService,
  ) {
    super(renderer, router)
  }

  ngOnInit() {
    this.loading = false
  }

  verify() {
    this.loading = true
    if (this.authService.isLoggedIn()) {
      this.userService.verifyAdult(true).subscribe(() => {
        window.location.reload()
        this.close()
      })
    } else {
      this.cookieService.put("adultVerify", "true")
      this.authService.change.emit()
      this.close()
    }
  }
}
