import { Component, OnInit } from "@angular/core"
import { MatIconRegistry } from "@angular/material"
import { DomSanitizer } from "@angular/platform-browser"
import { CookieService } from "angular2-cookie"

@Component({
  selector: "app-landing-security",
  templateUrl: "./landing-security.component.html",
  styleUrls: ["./landing-security.component.scss"],
})
export class LandingSecurityComponent implements OnInit {
  langFlag
  constructor(
    private cookieService: CookieService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
  ) {
    this.langFlag = cookieService.get("lang")
    iconRegistry.addSvgIcon(
      "security-svg",
      sanitizer.bypassSecurityTrustResourceUrl("assets/img/landing/security.svg"),
    )
  }

  ngOnInit() {}
}
