import { Component, Input, OnInit } from "@angular/core"

@Component({
  selector: "app-bunnynet-iframe",
  templateUrl: "./bunnynet-iframe.component.html",
  styleUrls: ["./bunnynet-iframe.component.scss"],
})

export class BunnynetIframeComponent implements OnInit {
  @Input() url: string

  constructor() {}

  ngOnInit() {}

}
