import { EventEmitter, Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { Form } from "../../shared/model/form"
import { environment } from "../../../environments/environment"
import { tap } from "rxjs/operators"
import { GetCommentsParams, PostCommentPayload } from "../types/ajax.type"
import { Pagination } from "src/app/shared/types/pagination.type"
import { Comment } from "../types/comment.type"

@Injectable({
  providedIn: "root",
})
export class CommentService {
  constructor(private http: HttpClient) {}

  commentCreatedEvent = new EventEmitter<Comment>()

  get(id: number) {
    return this.http.get<Comment>(`${environment.apiUrl}comments/${id}/`)
  }

  getList(data: GetCommentsParams) {
    return this.http.get<Comment[] | Pagination<Comment>>(`${environment.apiUrl}comments/`, {
      params: data,
    })
  }

  getCommentsPaginatedByUrl(url) {
    return this.http.get<Pagination<Comment>>(url)
  }

  create(data: PostCommentPayload, form?: Form) {
    return this.http.post<Comment>(`${environment.apiUrl}comments/`, data).pipe(
      tap(
        (res) => {
          if (form) {
            form.clearErrors()
          }

          this.commentCreatedEvent.emit(res)
        },
        (error) => {
          if (form) {
            form.setErrors(error)
          }
        },
      ),
    )
  }

  delete(id: number) {
    return this.http.delete(`${environment.apiUrl}comments/${id}/`)
  }
}
