import { FileObject } from "src/app/shared/types/file.types"
import { Pictorial } from "src/app/board/types/pictorial.type"

export enum ModelLinkType {
  INSTAGRAM = "INSTAGRAM",
  FACEBOOK = "FACEBOOK",
  TWITTER = "TWITTER",
  TIKTOK = "TIKTOK",
  YOUTUBE = "YOUTUBE",
  AFREECATV = "AFREECATV",
  TWITCH = "TWITCH",
  ONLYFANS = "ONLYFANS",
  PATREON = "PATREON",
  NONE = "NONE",
}

export interface ModelLink {
  id: string
  url: string
  type?: ModelLinkType
  order?: number
}

export interface Model {
  id: string
  creatorId: number
  name?: string
  modelCreatorId?: number
  membermeUri?: string
  birthday?: string
  height?: number
  weight?: number
  bwh?: string
  order?: number
  profilePicture?: FileObject
  pictorials?: Pictorial[]
  links?: ModelLink[]
  voteCount?: number
  metaTitle?: string
  metaDescription?: string
}
