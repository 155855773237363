import { Injectable } from "@angular/core"
import { map } from "rxjs/operators"
import { UserService } from "src/app/account/services/user.service"
import { PictorialService } from "src/app/board/services/pictorial.service"
import { CreatorService } from "../../services/creator.service"
import { environment } from "src/environments/environment"
import { FormService } from "src/app/shared/services/form.service"
import { Form } from "src/app/shared/model/form"
import { CurrencyPipe } from "src/app/shared/pipe/currency.pipe"
import { Creator } from "../../types"
import { Observable } from "rxjs"

@Injectable({
  providedIn: "root",
})
export class CreatorDetailPageCreatorService {
  editorContent
  postPermission
  commentPermission

  creator$: Observable<Creator>
  creator: any
  form: Form
  // check to see if data is loaded
  creatorLoaded = false
  isFatkatPartner = false
  isBlockedUser = false
  notPaidUser = false
  resetCounter = 0
  isLeeheeCreator = false

  constructor(
    protected userService: UserService,
    protected creatorService: CreatorService,
    private formService: FormService,
    private currencyPipe: CurrencyPipe,
  ) {}

  isOfficialAccount(creator): boolean {
    creator = creator ? creator : this.creator
    return environment.officialAccounts.includes(creator.id)
  }

  getFilterForm(tag?: any) {
    if (this.creator && this.creator.id) {
      this.formService.getFilterFormData(`posts/?creator=${this.creator.id}`).subscribe((form) => {
        this.formService.transformVisibilityChoices(form.fields.visibility.choices)
        this.form = form
        this.form.formGroup.patchValue({
          creator: this.creator.id,
          category: "creator",
          tag: tag ? tag : "",
        })
      })
    }
  }

  getCreator(creator: any, tag: any) {
    this.creator = Object.assign(
      creator,
      this.isOfficialAccount(creator) ? { count_followers: "-" } : undefined,
    )
    this.editorContent = this.creator.description

    // *added for permission for post/comments
    this.checkMembershipSettings()

    if (this.isMine() || !creator.is_app_only) {
      this.getFilterForm(tag)
    }
    // creator is now loaded. Timeout is for smooth transition animation
    setTimeout(() => {
      if (this.creator.is_foreigner) {
        this.isFatkatPartner = true
        this.creatorLoaded = true
      } else {
        this.isFatkatPartner = false
        this.creatorLoaded = true
      }
    }, 200)
  }

  checkMembershipSettings() {
    // check post permission

    if (this.isMine()) {
      this.postPermission = true
      this.commentPermission = true
    } else if (this.creator.membership || this.creator.is_followed) {
      if (this.creator.membership && !this.creator.membership.amount_usd) {
        this.creator.membership.amount_usd = this.currencyPipe.transform(
          this.creator.membership.amount,
        )
      }

      if (
        this.creator.membership &&
        this.creator.membership.member_management &&
        this.creator.membership.member_management.is_blocked_user
      ) {
        this.postPermission = false
        this.isBlockedUser = true
      } else {
        this.postPermission =
          (this.creator.membership &&
            this.creator.membership.amount_usd >= this.creator.min_post) ||
          (this.creator.is_followed && !this.creator.min_post && this.creator.min_post !== 0)
      }
      this.commentPermission =
        (this.creator.membership &&
          this.creator.membership.amount_usd >= this.creator.min_comment) ||
        (this.creator.is_followed && !this.creator.min_comment && this.creator.min_comment !== 0)
      if (
        this.creator.membership &&
        !this.creator.membership.is_payment_completed &&
        !this.creator.membership.is_continuous
      ) {
        this.notPaidUser = true
      }
    }
  }

  getUser() {
    return this.userService.getUser()
  }

  isMine() {
    return this.getUser() && this.creator.id === this.getUser().creator
  }
}
