import {
  trigger,
  animateChild,
  group,
  transition,
  animate,
  state,
  style,
  query,
} from "@angular/animations"

// Routable animations
export const slideInAnimation = trigger("routeAnimation", [
  transition("* <=> *", [
    style({ position: "relative" }),
    query(":enter, :leave", [
      style({
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
      }),
    ]),
    query(":enter", [style({ left: "-100%" })]),
    query(":leave", animateChild()),
    group([
      query(":leave", [animate("300ms ease-out", style({ left: "100%" }))]),
      query(":enter", [animate("300ms ease-out", style({ left: "0%" }))]),
    ]),
    query(":enter", animateChild()),
  ]),
])

// smooth loading
export const smoothly = trigger("smoothly", [
  state(
    "initial",
    style({
      opacity: 0,
    }),
  ),
  state(
    "loaded",
    style({
      opacity: 1,
    }),
  ),
  transition("initial => loaded", [animate("0.5s")]),
  transition("loaded => initial", [animate("0.5s")]),
])
