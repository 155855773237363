export interface CreatorRecipient {
  business_type?: CreatorBusinessType
  cash_receipt_number?: string
  company_name?: string
  business_registration_number?: string
}

export interface CreatorSettleInfo {
  id: number
  creator: number
  country: string
  name: string
  phone_number?: string
  address1: string
  address2?: string
  city?: string
  state_province?: string
  postal_code: string
  bank_country?: string
  bank_name?: string
  bank_city?: string
  bank_account_number: string
  bank_routing_number?: string
}

export enum CreatorBusinessType {
  none = "none",
  natural = "natural",
  legal = "legal",
}

export enum CountryType {
  KR = "KR",
  US = "US",
  other = "other",
}
