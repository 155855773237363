import { Injectable } from "@angular/core"
import { Title } from "@angular/platform-browser"

/**
 * Service responsible for setting the title that appears above the components and guide pages.
 */
@Injectable({
  providedIn: "root",
})
export class ComponentPageTitle {
  _title = ""
  _subtitle = ""

  get title(): string {
    return this._title
  }

  set title(title: string) {
    this._title = title
    if (title !== "") {
      title = `${title} | `
    }
    // this.bodyTitle.setTitle(`${title}MemberMe`);
    this.bodyTitle.setTitle(`MemberMe`)
  }

  public setSubtitle(subtitle: string) {
    this._subtitle = subtitle
  }

  get subtitle(): string {
    return this._subtitle
  }

  constructor(private bodyTitle: Title) {}
}
