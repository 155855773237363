import { Component, OnInit, Renderer2 } from "@angular/core"
import { Router } from "@angular/router"
import { CookieService } from "angular2-cookie/core"

import { ModalPageComponent } from "src/app/shared/components/modal-page/modal-page.component"
import { Form } from "src/app/shared/model/form"
import { FormService } from "src/app/shared/services/form.service"
import { AuthService } from "src/app/core/services/auth.service"
import { environment } from "src/environments/environment"

@Component({
  selector: "app-login-page",
  templateUrl: "./login-page.component.html",
  styleUrls: ["./login-page.component.scss"],
})
export class LoginPageComponent extends ModalPageComponent implements OnInit {
  form: Form
  loginFields = {
    username: {
      label: "아이디",
      style: {
        placeholder: "이메일을 입력하세요",
      },
      widget: {
        name: "input",
        attrs: { type: "email" },
      },
    },
    password: {
      label: "비밀번호",
      style: {
        placeholder: "비밀번호를 입력하세요",
      },
      widget: {
        name: "input",
        attrs: { type: "password" },
      },
    },
  }
  loading
  langFlag
  facebookLoginUrl = `${environment.backendUrl}accounts/logout/?next=${environment.backendUrl}social/login/facebook/`
  googleLoginUrl = `${environment.backendUrl}accounts/logout/?next=${environment.backendUrl}social/login/google-oauth2/`

  constructor(
    protected renderer: Renderer2,
    protected router: Router,
    private auth: AuthService,
    private formService: FormService,
    private cookieService: CookieService,
  ) {
    super(renderer, router)
  }

  ngOnInit() {
    const redirectUrl = new URL(window.location.href)
    this.cookieService.put("redirect_uri", redirectUrl.pathname.replace("(popup:login)", ""))

    if (this.cookieService.get("lang") !== "KOR") {
      this.langFlag = "ENG"

      this.loginFields = {
        username: {
          label: "Email",
          style: {
            placeholder: "Email",
          },
          widget: {
            name: "input",
            attrs: { type: "email" },
          },
        },
        password: {
          label: "Password",
          style: {
            placeholder: "Password",
          },
          widget: {
            name: "input",
            attrs: { type: "password" },
          },
        },
      }
    } else {
      this.langFlag = "KOR"
    }

    this.form = this.formService.getFormFromFields(this.loginFields)
  }

  onSubmit() {
    this.loading = true
    this.auth.login(this.form).subscribe(
      () => {
        this.loading = false
        const path = this.router.url === "/users/create(popup:login)" ? ["/"] : []
        this.navigate(path)
      },
      () => {
        this.loading = false
      },
    )
  }

  appleSignIn() {
    const params = {
      client_id: environment.appleServiceId,
      response_type: "code id_token",
      response_mode: "form_post",
      scope: "name email",
      redirect_uri: `${environment.apiUrl}users/auth-by-token/apple/`,
      state: "web",
    }
    const queryString = this.getQueryString(params)
    window.location.href = `https://appleid.apple.com/auth/authorize?${queryString}`
  }

  getQueryString(params: object) {
    if (params) {
      const searchParams = new URLSearchParams()
      Object.keys(params).forEach((key) => searchParams.set(key, params[key]))
      return searchParams.toString()
    }
    return null
  }
}
