import { Component, Input, OnInit, SimpleChange, SimpleChanges } from "@angular/core"
import { CreatorService } from "../../services/creator.service"

import { CookieService } from "angular2-cookie/core"

@Component({
  selector: "app-creator-list-fatkat",
  templateUrl: "./creator-list-fatkat.component.html",
  styleUrls: ["./creator-list-fatkat.component.scss"],
})
export class CreatorListFatkatComponent implements OnInit {
  @Input() fatkatObj: any
  fatkats
  fatkatResults
  loading = true
  fatkatExists: boolean
  //
  langFlag

  constructor(private creatorService: CreatorService, private cookieService: CookieService) {}

  ngOnInit() {
    if (this.cookieService.get("lang") !== "KOR") {
      this.langFlag = "ENG"
    } else {
      this.langFlag = "KOR"
    }
  }

  // tslint:disable-next-line: use-life-cycle-interface
  ngOnChanges(changes: SimpleChanges) {
    const fatkatObj: SimpleChange = changes.fatkatObj
    this.loading = false
    if (fatkatObj && fatkatObj.currentValue.count > 0) {
      this.fatkatExists = true
      this.fatkatResults = fatkatObj.currentValue.list
    } else {
      this.fatkatExists = false
    }
  }
}
