import { Component, Inject, Input, OnInit } from "@angular/core"
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material"

@Component({
  selector: "app-post-detail-dialog",
  templateUrl: "./post-detail-dialog.component.html",
  styleUrls: ["./post-detail-dialog.component.scss"],
})
export class PostDetailDialogComponent implements OnInit {
  @Input() commentPermission: any
  @Input() isBlockedUser: any

  postLoaded
  post: any
  inDashboard: boolean

  constructor(
    public dialogRef: MatDialogRef<PostDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.post = data["post"]
  }

  ngOnInit() {
    this.inDashboard = false
    this.postLoaded = true
  }

  onCloseClick(): void {
    this.dialogRef.close()
  }
}
