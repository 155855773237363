import { Injectable } from "@angular/core"

import { Message } from "../types/message.type"
import { PostMessagesService } from "./post-messages.service"
import { GetMessageParams } from "../types/ajax.type"
import { tap } from "rxjs/operators"
import { Observable } from "rxjs"

@Injectable({
  providedIn: "root",
})
export class PostMessageDataService {
  private messageCache: Record<string, Message> = {}
  private totalItem: number

  constructor(private model: PostMessagesService) {}

  getMessage(id: number) {
    if (this.messageCache[id]) {
      return new Observable<Message>((observer) => {
        observer.next(this.messageCache[id])
        observer.complete()
      })
    }
    return this.model.getMessageFromId(id).pipe(
      tap((message) => {
        this.messageCache[id] = message
      }),
    )
  }

  getMessageItems(params: GetMessageParams) {
    return this.model.getMessageItems(params).pipe(
      tap((messages) => {
        this.totalItem = messages.count
        messages.results.forEach((message) => {
          this.messageCache[message.id] = message
        })
      }),
    )
  }

  block(id: number) {
    return this.model.postMessageBlock(id).pipe(
      tap((message) => {
        if (this.messageCache[id]) {
          this.messageCache[id].blocked = [message]
        }
      }),
    )
  }
  unblock(id: number) {
    return this.model.deleteMessageBlock(id).pipe(
      tap(() => {
        if (this.messageCache[id]) {
          this.messageCache[id].blocked = []
        }
      }),
    )
  }
  getMessageFromUrl(url: string) {
    return this.model.getNextMessageItems(url).pipe(
      tap((messages) => {
        messages.results.forEach((message) => {
          this.messageCache[message.id] = message
        })
      }),
    )
  }
}
