import { Component, OnInit } from "@angular/core"
import { FormBuilder } from "@angular/forms"
import { TranslateService } from "@ngx-translate/core"
import { CookieService } from "angular2-cookie/core"
import { tap } from "rxjs/operators"
import { smoothly } from "src/app/animation"
import { CreatorCategoryService } from "../../services/creator-category.service"
import { CreatorService } from "../../services/creator.service"

@Component({
  selector: "app-creator-list-page",
  animations: [smoothly],
  templateUrl: "./creator-list-page.component.html",
  styleUrls: ["./creator-list-page.component.scss"],
})
export class CreatorListPageComponent implements OnInit {
  creatorCategories
  searchText = ""
  filterForm = this.fb.group({
    search: [""],
    category: [""],
    is_searchable: [true],
    ordering: ["-_count_followers"],
  })
  categoryList = {
    en: [
      "Video",
      "Music",
      "Beauty",
      "Game",
      "Movie",
      "Streamer",
      "Education",
      "Comedy",
      "Dance",
      "Pets",
      "News",
      "Vlog",
      "Other",
    ],
  }
  pageLoaded = false
  creatorLoaded = false

  constructor(
    private fb: FormBuilder,
    private creatorCategoryService: CreatorCategoryService,
    private creatorService: CreatorService,
    private cookieService: CookieService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.creatorLoaded = false
    this.creatorCategoryService
      .getList()
      .pipe(
        tap(
          (data) => {
            this.setPageLoaded()
            return data
          },
          () => this.setPageLoaded(),
        ),
      )
      .subscribe((data) => {
        this.creatorCategories = data
      })
    this.onChanges()
    this.restoreState()
    this.creatorService.getChangeEmitter().subscribe((flag?: boolean) => {
      this.creatorLoaded = flag !== undefined ? flag : true
    })
  }

  onChanges(): void {
    this.filterForm.get("search").valueChanges.subscribe(() => this.showLoading())
    this.filterForm.get("category").valueChanges.subscribe(() => this.showLoading())
    this.filterForm.get("ordering").valueChanges.subscribe(() => this.showLoading())
  }

  setPageLoaded() {
    this.pageLoaded = true
  }

  showLoading(): void {
    this.creatorLoaded = false
  }

  getCategory() {
    return this.filterForm.value["category"]
  }

  restoreState() {
    if (window.state && window.state.search) {
      this.searchText = window.state.search
      this.filterForm.value.category = window.state.category || "all"
      this.filterForm.value.is_searchable = window.state.is_searchable
      this.filterForm.value.ordering = window.state.ordering
      this.filterForm.value.search = window.state.search
      this.filterForm.get("category").setValue(window.state.category || "all")
      this.filterForm.get("is_searchable").setValue(window.state.is_searchable)
      this.filterForm.get("ordering").setValue(window.state.ordering)
      this.filterForm.get("search").setValue(window.state.search)
    } else {
      this.getBasicFollowerList()
    }
  }

  getBasicFollowerList() {
    this.filterForm.get("search").setValue("")
  }

  search() {
    const twoCharMessage = this.translateService.instant("CREATOR_LIST.AT_LEAST_TWO_CHAR")
    if (this.searchText.trim().length < 2) {
      alert(twoCharMessage)
      return false
    }

    const currentValue = this.filterForm.get("search").value

    if (!this.getCategory()) {
      this.filterForm.get("category").setValue("all")
    }
    if (currentValue !== this.searchText) {
      this.showLoading()
      this.filterForm.get("search").setValue(this.searchText)
    }
  }
}
