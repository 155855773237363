import { Component, Inject, OnInit, ViewChild } from "@angular/core"
import { Location } from "@angular/common"
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatIconRegistry,
  MatSnackBar,
} from "@angular/material"
import { DomSanitizer } from "@angular/platform-browser"
import { ActivatedRoute, Router } from "@angular/router"
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter"
import { BehaviorSubject } from "rxjs"
import { trim } from "lodash"

import { PictorialService } from "src/app/board/services/pictorial.service"
import { UserService } from "src/app/account/services/user.service"
import { AuthService } from "src/app/core/services/auth.service"
import {
  EXTERNAL_VIDEO_PROVIDER_BUNNY_NET,
  EXTERNAL_VIDEO_PROVIDER_VIMEO,
  EXTERNAL_VIDEO_PROVIDER_YOUTUBE,
} from "src/app/shared/constants/file"
import { CreatorService } from "../../services/creator.service"
import { CookieService } from "angular2-cookie"
import { DatepickerFormatterComponent } from "src/app/shared/components/datepicker-formatter/datepicker-formatter.component"
import { TranslateService } from "@ngx-translate/core"
import * as moment from "moment"
import { ContestService } from "src/app/contest/services/contest.service"
import { Contest } from "src/app/contest/types"
import { ModelContestPictorial, PurchasePermission } from "./type"
import { Util } from "src/app/shared/utils/utils"
import { CreatorFileSizeLimitService } from "../../services/creator-file-size-limit.service"
import { ContestPictorialRegistryService } from "../../services/contest-pictorial-registry.service"
import { Pagination } from "src/app/shared/types/pagination.type"
import { PictorialFileType, PictorialFileUrlRes } from "src/app/board/types/pictorial.type"
import { CreatorDetailPageContentsService } from "../creator-detail-page/creator-detail-page-contents.service"
import { from } from "rxjs"
import { concatMap, filter } from "rxjs/operators"

@Component({
  selector: "app-creator-photobook-page",
  templateUrl: "./creator-photobook-page.component.html",
  styleUrls: [
    "./creator-photobook-page.component.scss",
    "./contents.scss",
    "./pictorial-info.scss",
    "./sales-setting.scss",
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useClass: DatepickerFormatterComponent },
  ],
})
export class CreatorPhotobookPageComponent implements OnInit {
  @ViewChild("autosize", { static: false }) autosize
  pictorial: ModelContestPictorial

  cid: string
  pid: string
  currentUser

  formGroup

  purchasePermission = PurchasePermission

  VIDEO_INPUT_SELECT = [
    { value: EXTERNAL_VIDEO_PROVIDER_YOUTUBE, viewValue: "Youtube" },
    { value: EXTERNAL_VIDEO_PROVIDER_VIMEO, viewValue: "Vimeo" },
    { value: EXTERNAL_VIDEO_PROVIDER_BUNNY_NET, viewValue: "bunny.net" },
  ]

  objectPaths = [
    { listName: "coverImgList", path: PictorialFileType.covers },
    { listName: "photoImgList", path: PictorialFileType.photos },
    { listName: "videoList", path: PictorialFileType.videos },
  ]

  expandedCoverContext = { $implicit: false }
  expandedPhotoContext = { $implicit: false }

  coverImgList = []
  photoImgList = []
  videoList = []
  tierList = []

  isKorean: Boolean
  isEditingTitle = false
  isAdult = false
  isPictorialDiscount = false

  // 순서 변경 dnd 컨트롤
  dragging: any

  // youtube, vimeo 추가 인풋 컨트롤
  movieInput = ""
  inputType = EXTERNAL_VIDEO_PROVIDER_YOUTUBE

  // file dnd 컨트롤
  entered = null

  allCheck = {
    coverImgList: false,
    photoImgList: false,
    videoList: false,
  }

  loadings = {
    coverImgList: false,
    photoImgList: false,
    videoList: false,
    all: false,
  }

  pictorialValidate: any = {}

  categories: BehaviorSubject<any[]> = new BehaviorSubject(null)
  models: BehaviorSubject<any[]> = new BehaviorSubject(null)
  photographers: BehaviorSubject<Record<string, any>[]> = new BehaviorSubject([])
  themes: BehaviorSubject<Record<string, any>[]> = new BehaviorSubject([])

  contest?: Map<number, Contest> = new Map()
  originalIsIsRegisteredPictorial: boolean

  fileSizeLimit = 20 * 1024 * 1024

  constructor(
    @Inject(MAT_DATE_FORMATS) private config: DatepickerFormatterComponent,
    public contestRegistryService: ContestPictorialRegistryService,
    private pictorialService: PictorialService,
    private route: ActivatedRoute,
    private router: Router,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private userService: UserService,
    private creatorService: CreatorService,
    private cookieService: CookieService,
    private location: Location,
    private _adapter: DateAdapter<any>,
    private _snackBar: MatSnackBar,
    private auth: AuthService,
    private translateService: TranslateService,
    private contestService: ContestService,
    private creatorFileSizeLimitService: CreatorFileSizeLimitService,
    private creatorDetailPagePictorialService: CreatorDetailPageContentsService,
  ) {
    iconRegistry.addSvgIcon(
      "edit-icon",
      sanitizer.bypassSecurityTrustResourceUrl("assets/img/icon/edit.svg"),
    )
  }

  async ngOnInit() {
    this.contestRegistryService.init()
    const { size_mb } = await this.creatorFileSizeLimitService
      .get()
      .toPromise()
      .catch(() => ({ size_mb: 20 }))

    this.fileSizeLimit = size_mb * 1024 * 1024
    this.isKorean = this.cookieService.get("lang") === "KOR"
    this.config.lang = this.cookieService.get("lang")
    this.route.paramMap.subscribe((params) => {
      this.pid = params.get("pid")
      this.cid = params.get("id")
      this.currentUser = this.userService.getUser()

      if (this.currentUser && this.currentUser.creator_url !== this.cid) {
        this.router.navigate(["/404"], { replaceUrl: true })
      }
      this.getTiers()
      this.getPictorial()

      const nameList = ["coverImgList", "photoImgList", "videoList"]
      nameList.forEach((listName) => {
        this.getFile(listName)
      })
    })
  }

  getFile(listName) {
    const path = this.objectPaths.find((obj) => obj.listName === listName).path
    this.pictorialService.getFileList(this.pid, path).subscribe((res: any) => {
      this[listName] = res
    })
  }

  getPictorial() {
    return this.pictorialService
      .get(this.pid)
      .subscribe((pictorial: ModelContestPictorial) => {
        this.isAdult = !pictorial.releaseDate ? this.currentUser.verified_as_adult : pictorial.adult

        pictorial.openTo = pictorial.free ? "public" : pictorial.release ? "member" : "private"
        pictorial.salesMethod =
          pictorial.salesMethod && (pictorial.salesMethod.toLowerCase() as PurchasePermission)
        const category: any = pictorial.categories && pictorial.categories[0]
        const pictorialCategory = category
          ? {
              ...category,
              viewValue: category.name,
              value: category.id,
            }
          : {}

        const __models__ = pictorial.__models__ || []
        const models = __models__.map((model) => ({
          ...model,
          viewValue: model.name,
          value: model.id,
        }))
        if (!pictorial.salesMethod) {
          pictorial.salesMethod = Util.setSalesMethodFromPictorial(pictorial)
        }

        this.categories.next(pictorialCategory.id ? [pictorialCategory] : null)
        this.models.next(!!models.length ? models : null)
        this.isPictorialDiscount = !!pictorial.discountRatePercent
        this.pictorial = {
          ...pictorial,
          category: pictorialCategory,
          categoryId: pictorialCategory.id,
          modelIds: models.map((model) => model.id),
        }
      })
      .add(() => {
        this.contestRegistryService
          .getRegisteredPictorial(this.pictorial.id)
          .subscribe((res: boolean) => {
            this.pictorial.isContestRegisteredPictorial = res
            this.originalIsIsRegisteredPictorial = res
          })

        return this.pictorial
      })
  }

  changeForm = (key, value) => {
    this.pictorial[key] = value

    delete this.pictorialValidate[key]

    if (key === "adult") {
      this.isAdult = value
    }
    if (key === "release" && value === false) {
      this.pictorial.isContestRegisteredPictorial = false
    }
    if (key === "isContestRegisteredPictorial" && value === true) {
      this.pictorial.release = true
    }
    if (
      (key === "salesMethod" && value === PurchasePermission.allFree) ||
      value === PurchasePermission.membershipFree
    ) {
      this.isPictorialDiscount = false
      this.pictorial.priceUsd = null
      this.pictorial.originPriceUsd = null
      this.pictorial.discountRatePercent = null
    }
    if (key === "priceUsd" || key === "originPriceUsd") {
      if (key === "originPriceUsd") {
        this.pictorial.priceUsd = this.pictorial.originPriceUsd
      }
      this.pictorial.discountRatePercent = this.getDiscountRatePercent(
        this.pictorial.originPriceUsd,
        this.pictorial.priceUsd,
      )
    }
  }

  getDiscountRatePercent = (originPrice, price) => {
    return 100 - Math.floor((price / originPrice) * 100)
  }

  changeIsPictorialDiscount = (key: string, value: boolean) => {
    this.isPictorialDiscount = value
    this.pictorial.discountRatePercent = 0
    this.pictorial.priceUsd = this.pictorial.originPriceUsd
  }

  onChangeInput(e) {
    this.changeForm(e.target.name, e.target.value)
  }

  dragEnter(e) {
    if (!this.dragging) {
      e.currentTarget.classList.add("red")
      this.entered = !!e.target.classList.contains("mat-card")
    }
  }

  dragLeave(e) {
    if (e.target.classList.contains("mat-card")) {
      if (this.entered) {
        e.currentTarget.classList.remove("red")
      }
    } else if (e.screenX == 0) {
      // esc 취소
      e.currentTarget.classList.remove("red")
    }
  }

  save() {
    this.pictorialValidate = {}
    const { tierList, pictorialValidate } = this
    const { salesMethod, tierId, priceUsd, originPriceUsd } = this.pictorial
    let tier = tierList.find((item) => item.id == tierId)

    const data = Object.assign({}, this.pictorial, {
      priceUsd: trim(this.pictorial.priceUsd) === "" ? 0 : Number(this.pictorial.priceUsd),
      covers: this.coverImgList.map((item, index) => ({ ...item, order: index + 1 })),
      photos: this.photoImgList.map((item, index) => ({ ...item, order: index + 1 })),
      videos: this.videoList.map((item, index) => ({ ...item, order: index + 1 })),
      adult: this.isAdult,
      categoryId: this.pictorial.categoryId ? [this.pictorial.categoryId] : [],
    })

    data.individualSale = true

    if (!this.pictorial.title) {
      this.pictorialValidate.title = "error"
    }

    if (!salesMethod) {
      this.pictorialValidate.salesMethod = "error"
    }

    if (
      (salesMethod === PurchasePermission.membershipFree ||
        salesMethod === PurchasePermission.membershipPaid) &&
      !tier
    ) {
      pictorialValidate.tierId = "error"
    }

    if (
      salesMethod !== PurchasePermission.membershipFree &&
      salesMethod !== PurchasePermission.membershipPaid
    ) {
      data.tierId = null
      tier = null
    }

    if (
      salesMethod !== PurchasePermission.allFree &&
      salesMethod !== PurchasePermission.membershipFree
    ) {
      data.availableSale = true
    } else {
      data.availableSale = false
      data.priceUsd = 0
    }

    if (tier) {
      data.visibility = tier.amount_usd * 1000
    } else {
      data.visibility = 0
    }

    if (
      (salesMethod === PurchasePermission.membershipPaid ||
        salesMethod === PurchasePermission.allPaid) &&
      !Number(originPriceUsd)
    ) {
      pictorialValidate.originPriceUsd = "error"
    }

    if (
      ((salesMethod === PurchasePermission.membershipPaid ||
        salesMethod === PurchasePermission.allPaid) &&
        !Number(priceUsd) &&
        Number(priceUsd) !== 0) ||
      Number(priceUsd) > Number(originPriceUsd)
    ) {
      pictorialValidate.priceUsd = "error"
    }

    if (this.checkHasError()) {
      return false
    }

    this.loadings.all = true
    if (this.originalIsIsRegisteredPictorial !== data.isContestRegisteredPictorial) {
      this.contestRegistryService
        .changeAppliedStatus(this.pid, this.originalIsIsRegisteredPictorial)
        .subscribe()
      this.originalIsIsRegisteredPictorial = data.isContestRegisteredPictorial
    }

    if (!this.pictorial.releaseDate) {
      const current = moment().format("YYYY-MM-DD")
      this.pictorial.releaseDate = data.releaseDate = current
    }

    if (!this.pictorial.closeDate) {
      const centuryLater = moment().add(99, "year").format("YYYY-MM-DD")
      this.pictorial.closeDate = data.closeDate = centuryLater
    }

    this.pictorialValidate = pictorialValidate

    this.pictorialService.update(this.pid, data).subscribe(
      (res: any) => {
        if (res.error && res.error.message) {
          for (const msg of res.error.message) {
            this.pictorialValidate[msg["property"]] = true
          }
        } else {
          this.openSnackBar(this.translateService.instant("ALERT_CONFIRM.SAVED"))
        }
        this.loadings.all = false
        this.creatorDetailPagePictorialService.reset()
      },
      (error) => {
        this.loadings.all = false
        if (error.error.message == "availableSale is true, priceUsd is empty") {
          this.openSnackBar(this.translateService.instant("PICTORIAL_DETAIL.ENTER_PRICE"), [
            "snack-bar-top",
            "danger-snackbar",
          ])
          this.pictorialValidate.priceUsd = true
        } else {
          this.openSnackBar(error.error.message, ["snack-bar-top", "danger-snackbar"])
        }
      },
    )
  }

  checkHasError() {
    if (Object.values(this.pictorialValidate).some((err) => !!err)) {
      this.openSnackBar(this.translateService.instant("ALERT_CONFIRM.INVALID_FORM"), [
        "snack-bar-top",
        "danger-snackbar",
      ])
      return true
    }
    return false
  }

  delete() {
    if (confirm(this.translateService.instant("ALERT_CONFIRM.DELETE_CONFIRM"))) {
      this.pictorialService.delete(this.pictorial.id).subscribe(() => {
        this.creatorDetailPagePictorialService.reset()
        this.openSnackBar(this.translateService.instant("ALERT_CONFIRM.MEMBERSHIP_DELETE_SUCCESS"))
        this.goBack()
      })
    }
  }

  onDragStart(event, listName, item) {
    this.dragging = {
      element:
        listName == "videoList"
          ? event.currentTarget.parentElement.parentElement
          : event.currentTarget,
      listName,
      item,
    }
  }

  onDragOver(e, listName, item) {
    e.preventDefault()
    if ((this.dragging && this.dragging.listName) !== listName) {
      return
    }

    const list = this[listName]

    const dragIndex = list.findIndex((listItem) => listItem.id === this.dragging.item.id)
    const dropIndex = list.findIndex((listItem) => listItem.id === item.id)
    const location = e.currentTarget.getBoundingClientRect()
    if (dragIndex !== dropIndex) {
      const newPosts = list.filter((listItem) => listItem.id !== this.dragging.item.id)

      // -1 오른쪽으로 간다. dragIndex < dropIndex
      // 0 왼쪽으로 간다. dragIndex > dropIndex
      const direction = dragIndex < dropIndex ? -1 : 0

      // dragover 대상의
      // 0 왼쪽 면
      // +1 오른쪽 면
      const side = location.x + location.width > e.pageX + location.width / 2 ? 0 : 1

      // left side when to right || right side when to left
      if (
        (dragIndex - dropIndex === -1 && side === 0) ||
        (dragIndex - dropIndex === 1 && side === 1)
      ) {
        return
      }

      const i = dropIndex + direction + side
      const left = newPosts.splice(0, i)

      this[listName] = [...left, this.dragging.item, ...newPosts]
    } else {
      this.dragging.element.classList.add("dragging")
    }
  }

  onDragEnd(event) {
    event.preventDefault()

    this.dragging.element.classList.remove("dragging")
    event.dataTransfer.dropEffect = "none"
    this.dragging = null
  }

  onClickAllCheck(listName) {
    for (const listItem of this[listName]) {
      listItem.checked = !this.allCheck[listName]
    }
    this.allCheck[listName] = !this.allCheck[listName]
  }

  onChangeList(listName, item) {
    const t = this
    return function (_, checked) {
      const list = t[listName]
      const index = list.findIndex((listItem) => listItem.order == item.order)
      list[index].checked = checked
    }
  }

  onClickDelete(listName) {
    this[listName] = this[listName].filter((listItem) => !listItem.checked)
    this.allCheck[listName] = false
  }

  allowDrop(event) {
    event.preventDefault()
  }

  onDrop(event, listName) {
    if (this.dragging) {
      return
    }

    event.preventDefault()
    event.currentTarget.classList.remove("red")

    const files: any[] = event.dataTransfer.files ? Array.from(event.dataTransfer.files) : []
    const fileType = listName === "videoList" ? /^video\// : /^image\//
    if (files.every((file) => file.type.match(fileType))) {
      if (files.every((file) => file.size < this.fileSizeLimit)) {
        this.uploadFiles(files, listName)
      } else {
        alert(
          this.translateService.instant("FROALA_EDITOR.FILE_UNDER_SIZE", {
            size: this.fileSizeLimit / 1024 / 1024 + "MB",
          }),
        )
      }
    } else {
      alert(this.translateService.instant("FROALA_EDITOR.INVALID_FILE_TYPE"))
    }
  }

  selectFiles(e, listName) {
    const files: any[] = e.target.files ? Array.from(e.target.files) : []
    if (files.every((file) => file.size < this.fileSizeLimit)) {
      this.uploadFiles(e.target.files, listName)
    } else {
      alert(
        this.translateService.instant("FROALA_EDITOR.FILE_UNDER_SIZE", {
          size: this.fileSizeLimit / 1024 / 1024 + "MB",
        }),
      )
    }
  }

  uploadFiles(files: File[], listName: string) {
    const fileType = this.objectPaths.find((item) => item.listName === listName).path;

    if (listName === "videoList") {
      // 영상은 병렬적으로 업로드해도 괜찮음
      this.pictorialService
        .createPictorialFileUploadUrls(this.pid, {
          count: files.length,
          fileType: fileType,
        })
        .subscribe((urls: PictorialFileUrlRes[]) => {
          let index = 0;
          this.loadings[listName] = true

          for (const url of urls) {
            this.pictorialService
              .uploadToPreSignUrl(url.url, files[index])
              .subscribe((s3Res: any) => {
                if (s3Res.status === 200) {
                  this.pictorialService
                    .createFile(this.pid, fileType, [{ fileObjectId: url.id }])
                    .subscribe((res: any) => {
                      const list = this[listName]
                      this[listName] = list.concat(res).sort((a, b) => a.order - b.order)
                    });
                }
              });
            index++
          }

          this.loadings[listName] = false
        });
    } else {
      // 사진은 순서에 맞춰 하나씩 업로드
      this.pictorialService
        .createPictorialFileUploadUrls(this.pid, {
          count: files.length,
          fileType: fileType,
        })
        .subscribe((urls: PictorialFileUrlRes[]) => {
          this.loadings[listName] = true

          from(urls)
            .pipe(
              concatMap((url, index) => 
                this.pictorialService.uploadToPreSignUrl(url.url, files[index]).pipe(
                  filter((s3Res: any) => s3Res.status === 200),
                  concatMap(() => this.pictorialService.createFile(this.pid, fileType, [{ fileObjectId: url.id }]))
                )
              )
            )
            .subscribe({
              next: (res: any) => {
                const list = this[listName]
                this[listName] = list.concat(res).sort((a, b) => a.order - b.order)
              },
              complete: () => {
                this.loadings[listName] = false
              },
              error: (err) => {
                this.loadings[listName] = false
                console.error("Error during upload", err)
              },
            })
        })
    }
  }

  getYoutubeEmbedUrl(url: string) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
    const match = url.match(regExp)
    if (match && match[2].length === 11) {
      return `https://www.youtube.com/embed/${match[2]}`
    }
    throw new Error(this.translateService.instant("ALERT_CONFIRM.INVALID_LINK"))
  }

  getVimeoEmbedUrl(url: string) {
    // tslint:disable-next-line:max-line-length
    const regExp =
      /(?:https?:\/\/)?(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?(\/[a-zA-Z0-9_\-]+)?/i
    const match = url.match(regExp)
    if (match && match.length > 1) {
      return `https://player.vimeo.com/video/${match[1]}${
        match[2] ? `?h=${match[2].replace("/", "")}` : ""
      }`
    }
    throw new Error(this.translateService.instant("ALERT_CONFIRM.INVALID_LINK"))
  }

  getBunnyNetEmbedUrl(url: string) {
    const bunnies = [
      "https://iframe.mediadelivery.net/embed/",
      "https://iframe.mediadelivery.net/play/",
      "https://video.bunnycdn.com/play/",
    ]
    if (url.startsWith(bunnies[0]) || url.startsWith(bunnies[1]) || url.startsWith(bunnies[2])) {
      const path = url.replace(bunnies[0], "").replace(bunnies[1], "").replace(bunnies[2], "")
      return `https://iframe.mediadelivery.net/embed/${path}`
    }
    throw new Error(this.translateService.instant("ALERT_CONFIRM.INVALID_LINK"))
  }

  changeUrlInput(event) {
    this.movieInput = event.target.value
    // this.autosize.resizeToFitContent(true)
  }

  onChangeThisInput = (name, value) => {
    this[name] = value
  }

  uploadExternalVideo() {
    try {
      const url = this.getVideoUrl()
      const body = { externalVideoProvider: this.inputType, url: url }
      this.movieInput = ""
      this.pictorialService.createFile(this.pid, "videos", [body]).subscribe((res: any) => {
        this.videoList = this.videoList.concat(res).sort((a, b) => {
          return a.order - b.order
        })
        this.loadings.videoList = false
      })
    } catch (e) {
      this.openSnackBar(this.translateService.instant("ALERT_CONFIRM.INVALID_VIDEO_URL"), [
        "snack-bar-top",
        "danger-snackbar",
      ])
    }
  }

  private getVideoUrl() {
    if (!this.movieInput) {
      throw new Error(this.translateService.instant("ALERT_CONFIRM.INVALID_VIDEO_URL"))
    }
    if (this.inputType == EXTERNAL_VIDEO_PROVIDER_YOUTUBE) {
      return this.getYoutubeEmbedUrl(this.movieInput)
    } else if (this.inputType == EXTERNAL_VIDEO_PROVIDER_VIMEO) {
      return this.getVimeoEmbedUrl(this.movieInput)
    } else if (this.inputType == EXTERNAL_VIDEO_PROVIDER_BUNNY_NET) {
      return this.getBunnyNetEmbedUrl(this.movieInput)
    }
    throw new Error(this.translateService.instant("ALERT_CONFIRM.INVALID_LINK"))
  }

  goBack() {
    this.location.back()
  }

  onChangeDate(e) {
    const date = moment(e.value).format("YYYY-MM-DD")

    e.target = e.targetElement

    e.target.value = date.toString()
    this.onChangeInput(e)
  }

  clearDate(name) {
    this.changeForm(name, undefined)
  }

  getThemes() {
    const creatorId = this.currentUser ? this.currentUser.creator : undefined
    if (this.themes.value.length === 0) {
      this.pictorialService.getThemeList(creatorId).subscribe((res: any) => {
        const contestEntries = []

        if (this.contest.size > 0) {
          for (const entry of this.contest.values()) {
            contestEntries.push({
              type: "contest",
              title: entry.title,
              themes: entry.themes.map((theme) => theme.theme),
            })
          }
        }

        this.themes.next(
          contestEntries.concat({
            themes: res.items.map((item) => item.name),
            title: this.translateService.instant("PICTORIAL_FORM.USER_THEME"),
          }),
        )
      })
    }
  }

  getPhotographers() {
    const creatorId = this.currentUser ? this.currentUser.creator : undefined
    if (this.photographers.value.length === 0 && creatorId) {
      this.pictorialService.getPhotographerList(creatorId).subscribe((res: any) => {
        this.photographers.next([{ photoGraphers: res.items.map((item) => item.name) }])
      })
    }
  }

  getModels() {
    this.models.next(null)
    this.pictorialService.getModelList(this.currentUser.creator).subscribe((res: any) => {
      this.models.next(res.map((item) => ({ ...item, viewValue: item.name, value: item.id })))
    })
  }

  getCategories() {
    this.categories.next(null)
    this.pictorialService.getCategoryList(this.currentUser.creator).subscribe((res: any) => {
      this.categories.next(
        res.map((item) => {
          const children = item.childCategories.map((child) => ({
            ...child,
            viewValue: child.name,
            value: child.id,
          }))

          return { ...item, viewValue: item.name, value: item.id, children }
        }),
      )
    })
  }

  getTiers() {
    this.creatorService.get("me").subscribe((res: any) => {
      this.tierList = res.tiers
        ? res.tiers.map((tier) => ({ ...tier, value: tier.id, viewValue: tier.name }))
        : []
    })
  }

  getTier() {
    return this.tierList.find((item) => item.id == this.pictorial.tierId)
  }

  openSnackBar(message: string, panelClass = ["snack-bar-top", "primary-snackbar"]) {
    this._snackBar.open(message, "", {
      duration: 2500,
      verticalPosition: "top",
      panelClass: panelClass,
    })
  }

  openNewPage(url: string[]) {
    const urlTree = this.router.createUrlTree(url)
    const pageUrl = this.router.serializeUrl(urlTree)

    window.open(pageUrl, "_blank")

    // 백드롭 닫기
    const backdrop: HTMLElement = document.querySelector(
      "div.cdk-overlay-backdrop.cdk-overlay-transparent-backdrop",
    )
    backdrop.click()
  }

  getContest() {
    if (this.currentUser && this.currentUser.creator !== undefined) {
      this.contestService.getContestList().subscribe(async (response: Pagination<Contest>) => {
        do {
          if (this.checkContestUrl(response, this.currentUser.creator)) {
            break
          }
          if (response["next"]) {
            response = await this.contestService.getContestListByUrl(response["next"]).toPromise()
          }
        } while (response["next"])
        if (this.checkContestUrl(response, this.currentUser.creator)) {
          return true
        }
      })
    }
  }

  checkContestUrl(response: Pagination<Contest>, creatorId: number) {
    const contest = response.results.find(
      (item) =>
        item.active_creators.find((creatorObj) => {
          return creatorObj.creator === creatorId && creatorObj.is_activated
        }) !== undefined,
    )
    if (contest) {
      this.contest.set(contest.id, contest)
      return true
    }
    return false
  }
}
