import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from "@angular/core"
import { CookieService } from "angular2-cookie/core"
import * as moment from "moment"
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material"
import { MembershipService } from "../../services/membership.service"
import { UserService } from "src/app/account/services/user.service"
import { TransactionService } from "src/app/payment/services/transaction.service"
import { map, tap } from "rxjs/operators"
import { PictorialService } from "src/app/board/services/pictorial.service"
import { User } from "src/app/account/types/user.type"
import { Util } from "src/app/shared/utils/utils"


@Component({
  selector: "app-pictorial-detail-card",
  templateUrl: "./pictorial-detail-card.component.html",
  styleUrls: ["./pictorial-detail-card.component.scss"],
})
export class PictorialDetailCardComponent implements OnInit, OnChanges {
  @Output() memberChanged = new EventEmitter<boolean>()
  @Input() selectedMembership: any
  langFlag: string
  dayBefore: string
  loading = false
  user: User
  currentMembership: any

  constructor(private cookieService: CookieService, private membershipService: MembershipService) {}

  ngOnInit() {
    moment.locale(this.cookieService.get("lang") === "KOR" ? "ko" : "en")
  }

  ngOnChanges(changes: SimpleChanges): void {
    const membership: SimpleChange = changes.selectedMembership
    this.loadMembershipDetail(membership.currentValue)
  }

  userBlocked(isBlocked) {
    if (isBlocked) {
      this.memberChanged.emit(true)
    }
  }

  private loadMembershipDetail(membership: any) {
    if (membership && membership.id) {
      this.loading = true
      this.currentMembership = membership
      this.user = membership.user
      this.membershipService.getPaymentHistorySince(membership.membership_id).subscribe((since) => {
        this.currentMembership = since
        this.dayBefore = moment(since["date_begin"], "YYYY-MM-DD").fromNow()
        this.loading = false
      })
    }
  }
}

@Component({
  selector: "app-pictorial-history-card",
  templateUrl: "./pictorial-history-card.component.html",
  styleUrls: ["./pictorial-detail-card.component.scss"],
})
export class PictorialHistoryCardComponent implements OnInit, OnChanges {
  @Input() user: any
  @Input() loading: boolean
  paginatedResult$
  displayedColumns: string[] = ["name", "date_end", "amount", "status"]
  langFlag
  creatorId
  statusDisplayedChoice = [
    { value: "paid", display_name: "결제완료" },
    { value: "refunded", display_name: "환불됨" },
    { value: "paypal_cancelled", display_name: "페이팔 취소" },
    { value: "suspended", display_name: "멤버십 해지" },
  ]

  page: number = 1
  pageUrl: string

  constructor(
    private cookieService: CookieService,
    private transactionService: TransactionService,
    private pictorialService: PictorialService,
    userService: UserService,
  ) {
    this.creatorId = userService.getUser().creator
  }

  ngOnInit() {
    if (this.cookieService.get("lang") === "KOR") {
      this.langFlag = "KOR"
      moment.locale("ko")
    } else {
      this.langFlag = "ENG"
      moment.locale("en")
    }
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    if (changes.hasOwnProperty("user")) {
      this.getPaginatedResult()
    }
  }

  getPaginatedResult() {
    this.page = 1
    this.paginatedResult$ = this.transactionService
      .getListPaginated({
        page_size: 5,
        user: this.user.id,
        creator: this.creatorId,
      })
      .pipe(
        map((data) => {
          // 한국어 일때 status 보여지는 값 변경
          if (this.cookieService.get("lang") === "KOR") {
            data["results"].forEach((payment) => {
              payment.status = this.statusDisplayedChoice.find(
                (status) => status.value === payment.status,
              ).display_name
            })
          }
          return data
        }),
        tap(async (data) => {
          this.pageUrl = data["next"] || data["previous"]
          const pictorialIds = data["results"]
            .map((payment) => payment.pictorial_id)
            .filter((id) => id.length > 10)
          const pictorials: any = await this.pictorialService.getListByIds(pictorialIds).toPromise()
          data["results"] = data["results"].map((payment) => {
            const paymentPictorial = pictorials.find(
              (pictorial) => pictorial.id === payment.pictorial_id,
            )
            payment.title = paymentPictorial ? paymentPictorial.title : ""
            return payment
          })
        }),
      )
  }

  getPaginatedResultByUrl(pageNo: number) {
    const url = Util.getPaginationUrl(this.pageUrl, pageNo)
    this.page = pageNo
    this.paginatedResult$ = this.transactionService.getListPaginatedByUrl(url).pipe(
      tap((data) => {
        // 한국어 일때 status 보여지는 값 변경
        if (this.cookieService.get("lang") === "KOR") {
          data["results"].forEach((payment) => {
            payment.status = this.statusDisplayedChoice.find(
              (status) => status.value === payment.status,
            ).display_name
          })
        }
      }),
      tap(async (data) => {
        const pictorialIds = data["results"]
          .map((payment) => payment.pictorial_id)
          .filter((id) => id.length > 10)
        const pictorials: any = await this.pictorialService.getListByIds(pictorialIds).toPromise()
        data["results"] = data["results"].map((payment) => {
          const paymentPictorial = pictorials.find(
            (pictorial) => pictorial.id === payment.pictorial_id,
          )
          payment.title = paymentPictorial ? paymentPictorial.title : ""
          return payment
        })
      }),
    )
  }
}

@Component({
  selector: "app-pictorial-detail-dialog",
  templateUrl: "./pictorial-detail-dialog.component.html",
})
export class PictorialDetailDialogComponent {
  selectedMembership: any

  constructor(
    public dialogRef: MatDialogRef<PictorialDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.selectedMembership = data["selectedMembership"]
  }

  onCloseClick(): void {
    this.dialogRef.close()
  }

  memberChanged($memberChanged: boolean) {
    this.dialogRef.close($memberChanged)
  }
}
