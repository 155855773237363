import * as moment from "moment"

import { Component, Input, OnInit } from "@angular/core"
import { Form } from "../../../shared/model/form"
import { FormService } from "../../../shared/services/form.service"
import { UserService } from "../../../account/services/user.service"
import { MembershipService } from "../../services/membership.service"
import { ActivatedRoute, Params } from "@angular/router"
import { Observable } from "rxjs"
import { ComponentPageTitle } from "../../components/page-title/page-title"
import { CookieService } from "angular2-cookie/core"
import { MatDialog } from "@angular/material"
import { BreakpointObserver } from "@angular/cdk/layout"
import { map, tap } from "rxjs/operators"
import { ConfirmationDialogComponent } from "../../../shared/components/confirmation-dialog/confirmation-dialog.component"
import { SnackbarService } from "../../../shared/services/snackbar.service"
import { TranslateService } from "@ngx-translate/core"
import { Pagination } from "src/app/shared/types/pagination.type"

const SMALL_WIDTH_BREAKPOINT = 600

@Component({
  selector: "app-membership-block-user-page",
  templateUrl: "./membership-block-user-page.component.html",
  styleUrls: ["./membership-block-user-page.component.scss"],
})
export class MembershipBlockUserPageComponent implements OnInit {
  isScreenSmall: Observable<boolean>
  displayedColumns: string[] = ["name", "blocked_date", "blocked_reason", "action"]
  langFlag: string
  form: Form
  paginatedResult$
  creatorId: number
  @Input() params: Observable<Params>
  messages: {}

  page: number = 1
  pageUrl: string

  constructor(
    public _componentPageTitle: ComponentPageTitle,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private formService: FormService,
    private userService: UserService,
    private membershipService: MembershipService,
    private breakpoints: BreakpointObserver,
    private snackbarService: SnackbarService,
    private translateService: TranslateService,
  ) {
    this.translateService.setDefaultLang("en")
    this.isScreenSmall = breakpoints
      .observe(`(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`)
      .pipe(map((breakpoint) => breakpoint.matches))
  }

  ngOnInit() {
    if (this.cookieService.get("lang") === "KOR") {
      this.langFlag = "KOR"
      moment.locale("ko")
    } else {
      this.langFlag = "ENG"
      moment.locale("en")
    }

    this.route.params.subscribe(() => {
      this._componentPageTitle.setSubtitle("블록된 사용자")
    })

    this.creatorId = this.userService.getUser().creator
    this.formService
      .getFilterFormData(`creators/${this.creatorId}/member-management/blocks/`)
      .subscribe((form) => {
        this.form = form
        this.form.formGroup.patchValue({})
        this.getPaginatedResult()
        this.form.formGroup.valueChanges.subscribe(() => {
          this.getPaginatedResult()
        })
      })
  }

  getPaginatedResult() {
    this.page = 1
    this.paginatedResult$ = this.membershipService
      .getBlockedMemberListPaginated(this.creatorId, this.form.formGroup.value)
      .pipe(
        tap((data: Pagination<any>) => {
          this.pageUrl = data.next || data.previous
        }),
      )
  }

  getPaginatedResultByUrl(url) {
    this.paginatedResult$ = this.membershipService.getListPaginatedByUrl(url).pipe(
      tap((data: Pagination<any>) => {
        this.pageUrl = data.next || data.previous
      }),
    )
  }

  unblock(memberManagement: any) {
    this.membershipService.unblockMember(this.creatorId, memberManagement.user.id).subscribe(() => {
      this.snackbarService.open(
        this.translate("MEMBERSHIP_MANAGEMENT.UNBLOCK_SUCCESS_MESSAGE"),
        "Success",
      )
      this.getPaginatedResult()
    })
  }

  openConfirmDialog(memberManagement: any): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "400px",
      maxWidth: "90vw",
      data: {
        message: this.translate("MEMBERSHIP_MANAGEMENT.UNBLOCK_MESSAGE"),
        title: "Unblock user",
      },
    })
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.unblock(memberManagement)
      }
    })
  }

  translate(key) {
    return this.translateService.instant(key)
  }
}
