import { Injectable } from "@angular/core"
import { HttpClient, HttpParams } from "@angular/common/http"
import { environment } from "src/environments/environment"
import { Pagination } from "src/app/shared/types/pagination.type"
import { CampaignPictorial } from "src/app/board/types/pictorial.type"
import { PictorialQuery } from "src/app/explore/types/ajax.type"
import { RecommendedPictorial } from "src/app/contest/types/asian-top-model-contest.type"
import { UserService } from "../../account/services/user.service"

interface ContestPictorialQueryParams extends PictorialQuery {
  campaignId: number
  modelIds?: string[]
  latest?: boolean
  likeCount?: boolean
  campaignLatest?: boolean
  voteCount?: boolean
  pictorialIds?: string
}

@Injectable({
  providedIn: "root",
})
export class ContestPictorialService {
  constructor(private userService: UserService, private http: HttpClient) {}

  getRecommendPictorialList(campaignId: number) {
    return this.http.get<RecommendedPictorial[]>(
      `${environment.apiUrl}recommended/campaign-pictorial/`,
      { params: { campaign: campaignId.toString() } },
    )
  }

  getList(query: ContestPictorialQueryParams) {
    return this.http.get<Pagination<CampaignPictorial>>(
      `${environment.pictorialApiUrl}campaign-pictorials/`,
      {
        params: query as unknown as HttpParams,
      },
    )
  }

  getRegisteredPictorial(campaignId: number, pictorialId: string) {
    return this.http.get<CampaignPictorial>(
      `${environment.pictorialApiUrl}campaign-pictorials/${campaignId}/`,
      {
        params: {
          campaignId: campaignId.toString(),
          pictorialId,
        },
      },
    )
  }

  postItem(campaignId: number, pictorialId: string) {
    return this.http.post(`${environment.pictorialApiUrl}campaign-pictorials/`, {
      campaignId: campaignId.toString(),
      pictorialId,
    })
  }

  deleteItem(campaignId: number, pictorialIds: [string]) {
    return this.http.delete(`${environment.pictorialApiUrl}campaign-pictorials/${campaignId}/`, {
      params: {
        campaignId: campaignId.toString(),
        pictorialIds,
      },
    })
  }
}
