import { Component, Input, OnInit } from "@angular/core"
import { Pictorial } from "src/app/board/types/pictorial.type"

@Component({
  selector: "app-creator-pictorial-list-subtext",
  templateUrl: "./creator-pictorial-list-subtext.component.html",
  styleUrls: ["./creator-pictorial-list-subtext.component.scss"],
})
export class CreatorPictorialListSubtextComponent implements OnInit {
  @Input() isMobile: boolean
  @Input() langFlag: string
  @Input() pictorial: Pictorial
  @Input() isVerifiedCreator: boolean

  constructor() {
  }

  ngOnInit() {
  }
}
