import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { environment } from "src/environments/environment"
import { Model } from "src/app/models/types"

@Injectable({
  providedIn: "root",
})
export class ModelService {
  constructor(private http: HttpClient) {
  }

  findOne(id: string, campaignId?: number) {
    return this.http.get<Model>(`${environment.pictorialApiUrl}models/${id}`, {
      params: {
        ...(campaignId && { campaignId: campaignId.toString() })
      }
    })
  }
}
