import { Component, EventEmitter, Input, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { MatChipInputEvent, MatDialog } from "@angular/material"
import { CookieService } from "angular2-cookie/core"

import { Form } from "src/app/shared/model/form"
import { FormService } from "src/app/shared/services/form.service"
import { AuthService } from "src/app/core/services/auth.service"
import { CreatorService } from "../../services/creator.service"
import { environment } from "src/environments/environment"
import { TranslateService } from "@ngx-translate/core"
import { MessagingComponent } from "src/app/shared/components/messaging/messaging.component"
import { SnackbarService } from "src/app/shared/services/snackbar.service"

declare var $: any

@Component({
  selector: "app-creator-form",
  templateUrl: "./creator-form.component.html",
  styleUrls: ["./creator-form.component.scss"],
})
export class CreatorFormComponent extends MessagingComponent implements OnInit {
  @Input() isUpdate?: boolean
  @Input() submitEventEmitter: EventEmitter<undefined>
  form: Form
  modeIntro = "image"
  submitDisabled = false

  // tag input variables
  selectable = true
  removable = true
  addOnBlur = true
  search_tags: string[] = []

  isSubmitted = false

  business_type = "none"

  // options for Floara wysiwyg
  public options: Object

  public editorContent: string

  constructor(
    protected snackbarService: SnackbarService,
    protected translateService: TranslateService,
    protected dialog: MatDialog,
    private router?: Router,
    private auth?: AuthService,
    private formService?: FormService,
    private creatorService?: CreatorService,
    private cookieService?: CookieService,
    private authService?: AuthService,
  ) {
    super(snackbarService, translateService, dialog)
    this.isUpdate = false
  }

  ngOnInit() {
    const endpoint = this.isUpdate ? "creators/me/" : "creators/"

    this.formService.getFormData(endpoint).subscribe((formData) => {
      this.form = formData

      // show froala wysiwyg content
      this.editorContent = this.form.data.description
      this.search_tags = this.form.data.tags || []
      this.business_type = this.form.data.business_type || "none"
      this.setModeIntro()
      this.setFormOption()
      this.form.formGroup.get("creator_type").valueChanges.subscribe((value) => {
        this.form.fields["creator_type"].errors = null
      })
      this.form.formGroup.get("image").valueChanges.subscribe((value) => {
        this.form.fields["image"].errors = null
      })
      this.form.formGroup.get("image_cover").valueChanges.subscribe((value) => {
        this.form.fields["image_cover"].errors = null
      })
      this.form.formGroup.get("description").valueChanges.subscribe((value) => {
        this.form.fields["description"].errors = null
      })
      this.form.formGroup.get("url").valueChanges.subscribe((value) => {
        this.form.fields["url"].errors = null
      })
    })

    this.setEditorOptions()

    this.submitEventEmitter.subscribe(() => this.onSubmit())
  }

  onSubmit() {
    this.submitDisabled = true
    this.isSubmitted = true

    this.clearIntro()

    // temporary fix to display wysiwyg content
    this.form.formGroup.value.description_embed = "temporary"
    this.form.formGroup.value.description = this.editorContent
    this.form.formGroup.value.category = 1
    this.form.formGroup.value.tags = this.search_tags

    const submitObservable = this.isUpdate
      ? this.creatorService.update(this.form)
      : this.creatorService.create(this.form)

    submitObservable.subscribe(
      (res) => {
        this.submitDisabled = false
        this.isSubmitted = false
        this.messageSuccess(this.translateService.instant("ALERT_CONFIRM.SAVED"))
        if (!this.isUpdate) {
          this.authService.requestSetCognitoToken().add(() => {
            setTimeout(() => {
              this.router.navigate(["/", res["url"]])
            }, 0)
          })
        }
      },
      (error) => {
        this.submitDisabled = false
        this.isSubmitted = false
      },
    )
  }

  clearIntro() {
    if (this.modeIntro === "image") {
      this.clearImageIntro()
    } else {
      this.clearEmbedIntro()
    }
  }

  removeTag(tag: string): void {
    const index = this.search_tags.indexOf(tag)

    if (index >= 0) {
      this.search_tags.splice(index, 1)
    }
    this.form.fields["tags"].errors = null
  }

  onEditorChange() {
    this.form.fields["description"].errors = null
  }

  addTag(event: MatChipInputEvent): void {
    const input = event.input
    const value = event.value
    const tag = value || ""

    if (tag) {
      this.search_tags = tag.split(",").map((val) => val.trim())
    }

    if (input) {
      input.value = ""
    }

    this.form.fields["tags"].errors = null
  }

  setModeIntro() {
    if (this.form.getValue("image_intro")) {
      this.modeIntro = "image"
    } else {
      this.modeIntro = "embed"
    }
  }

  clearImageProfile() {
    this.form.formGroup.get("image").setValue(null)
  }

  clearImageCover() {
    this.form.formGroup.get("image_cover").setValue(null)
  }

  clearImageIntro() {
    this.form.formGroup.get("image_intro").setValue(null)
  }

  clearEmbedIntro() {
    this.form.formGroup.get("description_embed").setValue("")
  }

  getEmbedHTML() {
    return this.form
      .getValue("description_embed")
      .replace("<iframe", `<iframe class="embed-responsive-item"`)
  }

  setFormOption() {
    if (this.form.fields.image) {
      this.form.fields.image.widget.attrs["accept"] = "image/jpeg,image/png,image/gif"

      this.form.formGroup.get("image").valueChanges.subscribe((value) => {
      })
    }

    if (this.form.fields.image_cover) {
      this.form.fields.image_cover.widget.attrs["accept"] = "image/jpeg,image/png,image/gif"
    }
  }

  setEditorOptions() {
    const csrf = this.cookieService.get("csrftoken")
    this.options = {
      key: "IB2A6A4B2pD1D1D1H5E1J4A16B3C7E7kuA-8wyyoszF4ogw==",
      placeholderText: this.translateService.instant("FROALA_EDITOR.PLACE_HOLDER"),
      charCounterCount: false,
      toolbarButtons: [
        "bold",
        "italic",
        "underline",
        "fontFamily",
        "fontSize",
        "color",
        "insertLink",
        "insertImage",
        "insertVideo",
        "emoticon",
      ],
      requestWithCORS: false,
      requestWithCredentials: true,
      // Set the image upload URL.
      imageUploadURL: environment.uploadFileUrl,
      // Additional upload params.
      imageUploadParams: {
        id: "my_editor",
        csrfmiddlewaretoken: csrf,
      },
      // Set request type.
      imageUploadMethod: "POST",
      // Set max image size to 5MB.
      imageMaxSize: 20 * 1024 * 1024,
      // Allow to upload PNG and JPG.
      imageAllowedTypes: ["jpeg", "jpg", "png"],

      // Set the file upload URL.
      fileUploadURL: environment.uploadFileUrl,
      // Additional upload params.
      fileUploadParams: {
        id: "my_editor",
        csrfmiddlewaretoken: csrf,
      },

      // Set the file upload URL.
      videoUploadURL: environment.uploadFileUrl,
      // Additional upload params.
      videoUploadParams: {
        id: "my_editor",
        csrfmiddlewaretoken: csrf,
      },
      // Set request type.
      videoUploadMethod: "POST",
      // Set max file size to 20MB.
      videoMaxSize: 20 * 1024 * 1024,
      // Allow to upload any file.
      videoAllowedTypes: ["mp4", "avi"],

      events: {
        "froalaEditor.image.uploaded": function(e, editor, $img, response) {
          // console.log('image uploaded to server', e, editor, $img, response);
        },
        "froalaEditor.image.inserted": function(e, editor, $img, response) {
          // console.log('image inserted in editor', e, editor, $img, response);
        },
        "froalaEditor.image.replaced": function(e, editor, $img, response) {
          // console.log('image replaced in editor', e, editor, $img, response);
        },
        "froalaEditor.image.error": function(e, editor, error, response) {
          const $popup = editor.popups.get("image.insert")
          const $layer = $popup.find(".fr-image-progress-bar-layer")
          // Bad link.
          if (error.code === 1) {
            $layer.find("h3").text(this.translateService.instant("FROALA_EDITOR.INVALID_LINK"))
          } else if (error.code === 2) {
          } else if (error.code === 3) {
            $layer
              .find("h3")
              .text(this.translateService.instant("FROALA_EDITOR.IMAGE_UPLOAD_FAILED"))
          } else if (error.code === 4) {
          } else if (error.code === 5) {
            $layer.find("h3").text(this.translateService.instant("FROALA_EDITOR.IMAGE_UNDER_20MB"))
          } else if (error.code === 6) {
            $layer
              .find("h3")
              .text(this.translateService.instant("FROALA_EDITOR.INVALID_IMAGE_TYPE"))
          } else if (error.code === 7) {
          }
        }.bind(this),
        "froalaEditor.video.uploaded": function(e, editor, files) {
          // console.log('video uploaded to the server', e, editor, files);
        },
        "froalaEditor.video.inserted": function(e, editor, files) {
          // console.log('video inserted to the editor', e, editor, files);
        },
        "froalaEditor.video.error": function(e, editor, error, response) {
          const $popup = editor.popups.get("video.insert")
          const $layer = $popup.find(".fr-video-progress-bar-layer")
          // Bad link.
          if (error.code === 1) {
            $layer.find("h3").text(this.translateService.instant("FROALA_EDITOR.INVALID_LINK"))
          } else if (error.code === 2) {
          } else if (error.code === 3) {
            $layer
              .find("h3")
              .text(this.translateService.instant("FROALA_EDITOR.VIDEO_UPLOAD_FAILED"))
          } else if (error.code === 4) {
          } else if (error.code === 5) {
            $layer.find("h3").text(this.translateService.instant("FROALA_EDITOR.FILE_UNDER_20MB"))
          } else if (error.code === 6) {
            $layer
              .find("h3")
              .text(this.translateService.instant("FROALA_EDITOR.INVALID_VIDEO_TYPE"))
          } else if (error.code === 7) {
          }
        }.bind(this),
      },
    }
  }
}
