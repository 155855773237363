import { Component, Input, OnInit } from "@angular/core"
import { Form } from "../../../shared/model/form"
import { FormService } from "../../../shared/services/form.service"
import { UserService } from "../../../account/services/user.service"
import { MembershipService } from "../../services/membership.service"
import { ActivatedRoute, Params, Router } from "@angular/router"
import { combineLatest, Observable } from "rxjs"
import { ComponentPageTitle } from "../../components/page-title/page-title"
import { map, tap } from "rxjs/operators"
import { BreakpointObserver } from "@angular/cdk/layout"
import { MembershipDetailDialogComponent } from "../../components/membership-detail-card/membership-detail-card.component"
import { MatDialog } from "@angular/material"
import { SnackbarService } from "src/app/shared/services/snackbar.service"
import { TranslateService } from "@ngx-translate/core"
import { MessagingComponent } from "src/app/shared/components/messaging/messaging.component"
import { CookieService } from "angular2-cookie"
import { Pagination } from "src/app/shared/types/pagination.type"
import { Util } from "src/app/shared/utils/utils"

const MIDDLE_WIDTH_BREAKPOINT = 960

@Component({
  selector: "app-membership-list-page",
  templateUrl: "./membership-list-page.component.html",
  styleUrls: ["./membership-list-page.component.scss"],
})
export class MembershipListPageComponent extends MessagingComponent implements OnInit {
  @Input() params: Observable<Params>
  @Input() selectedMembership
  form: Form
  isPopup: boolean
  isScreenSmall: Observable<boolean>
  paginatedResult$
  memberCount
  displayedColumns: string[] = [
    "user_name",
    "name",
    "amount",
    "amount_accumulated",
    "date_begin",
    "status",
  ]

  page: number = 1
  pageUrl: string

  private tabChanged = false
  langFlag

  constructor(
    public _componentPageTitle: ComponentPageTitle,
    private route: ActivatedRoute,
    private router: Router,
    private formService: FormService,
    private userService: UserService,
    private membershipService: MembershipService,
    private breakpoints: BreakpointObserver,
    private cookieService: CookieService,
    protected snackbarService: SnackbarService,
    protected translateService: TranslateService,
    protected dialog: MatDialog,
  ) {
    super(snackbarService, translateService, dialog)
    this.langFlag = this.cookieService.get("lang")
    this.isScreenSmall = breakpoints
      .observe(`(max-width: ${MIDDLE_WIDTH_BREAKPOINT}px)`)
      .pipe(map((breakpoint) => breakpoint.matches))
    this.isScreenSmall.subscribe((isSmall: boolean) => {
      this.isPopup = isSmall
    })
  }

  ngOnInit() {
    const creatorId = this.userService.getUser().creator
    this.route.params.subscribe(() => {
      this._componentPageTitle.setSubtitle("멤버 리스트")
    })

    combineLatest(
      this.route.queryParams,
      this.formService.getFilterFormData(`memberships/?creator=${creatorId}`),
    )
      .pipe(map(([param, form]) => ({ param, form })))
      .subscribe(({ param, form }) => {
        const { fields } = form

        if (fields.status && Array.isArray(fields.status.choices)) {
          fields.status.choices.forEach((choice) => {
            switch (choice.value) {
              case "":
                choice.display_name = this.translateService.instant("PAYMENT_STATUS.STATUS")
                break
              case "paid":
                choice.display_name = this.translateService.instant("PAYMENT_STATUS.PAID")
                break
              case "balanced":
                choice.display_name = this.translateService.instant("PAYMENT_STATUS.PAYOUT_DONE")
                break
              case "refunded":
                choice.display_name = this.translateService.instant("PAYMENT_STATUS.REFUNDED")
                break
              case "cancelled":
                choice.display_name = this.translateService.instant("PAYMENT_STATUS.CANCELED")
                break
              case "paypal_cancelled":
                choice.display_name = this.translateService.instant(
                  "PAYMENT_STATUS.CANCELED_PAYPAL",
                )
                break
              case "payment_failed":
                choice.display_name = this.translateService.instant("PAYMENT_STATUS.FAILED")
                break
              default:
                choice.display_name = this.translateService.instant("PAYMENT_STATUS.NOT_RECEIVED")
                break
            }
          })
        }
        this.form = form
        this.form.formGroup.patchValue({
          perspective: "creator",
          creator: creatorId,
          blocked_user: false,
          activated_user: true,
        })
        if (param && param.name) {
          this.form.formGroup.patchValue({
            search: param.name,
          })
        }
        this.getMemberCount()
        this.getPaginatedResult()
        this.form.formGroup.valueChanges.subscribe(() => {
          this.getMemberCount()
          this.getPaginatedResult()
        })
      })
  }

  selectMembership(membership) {
    this.selectedMembership = membership
    if (this.isPopup) {
      this.openMembershipDetailDialog()
    }
  }

  openMembershipDetailDialog(): void {
    this.openDialog(
      {
        width: "100vw",
        maxWidth: "100vw",
        panelClass: "membership-detail-dialog-panel",
        data: { selectedMembership: this.selectedMembership },
      },
      (result) => {
        if (result) {
          this.getPaginatedResult()
        }
      },
      MembershipDetailDialogComponent,
    )
  }

  getPaginatedResult() {
    this.page = 1
    this.paginatedResult$ = this.membershipService.getListPaginated(this.form.formGroup.value).pipe(
      tap((data: Pagination<any>) => {
        this.pageUrl = data.next || data.previous
        if (data["results"]) {
          if (data["results"] && data["results"].length) {
            this.selectedMembership = data["results"][0]
          }
        }
      }),
    )
  }

  getMemberCount() {
    if (!this.tabChanged) {
      const data: any = this.copyObject(this.form.formGroup.value)
      delete data.activated_user
      this.memberCount = this.membershipService.countMember(data).subscribe((res) => {
        this.memberCount = res
      })
    }
    this.tabChanged = false
  }

  getPaginatedResultByUrl(pageNo: number) {
    if (this.pageUrl) {
      const url = Util.getPaginationUrl(this.pageUrl, pageNo)

      this.page = pageNo
      this.paginatedResult$ = this.membershipService.getListPaginatedByUrl(url).pipe(
        tap((data: Pagination<any>) => {
          this.pageUrl = data.next || data.previous
          if (data["results"]) {
            if (data["results"] && data["results"].length) {
              this.selectedMembership = data["results"][0]
            }
          }
        }),
      )
    }
  }

  memberChanged($isChanged: boolean) {
    if ($isChanged) {
      this.getPaginatedResult()
    }
  }

  filterChanged($event: number) {
    this.tabChanged = true
    this.form.formGroup.patchValue({
      activated_user: !$event,
    })
    this.getPaginatedResult()
  }

  copyObject(objectToCopy: any) {
    return JSON.parse(JSON.stringify(objectToCopy))
  }
}
