import { Component, Input, OnInit } from "@angular/core"

@Component({
  selector: "app-checkbox",
  templateUrl: "./checkbox.component.html",
  styleUrls: ["./checkbox.component.scss"],
})
export class CheckboxComponent implements OnInit {
  @Input()
  checked: boolean
  @Input()
  id: string
  @Input()
  onClick: (key: string, value: boolean) => {}
  @Input()
  disabled = false

  constructor() {}

  ngOnInit() {}

  changeStatus(e) {
    this.onClick(e.currentTarget.name, !this.checked)
  }
}
