import { Component, HostListener, OnInit } from "@angular/core"
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap"

@Component({
  selector: "app-country-restriction-modal",
  templateUrl: "./country-restriction-modal.component.html",
  styleUrls: ["./country-restriction-modal.component.scss"],
})
export class CountryRestrictionModalComponent implements OnInit {
  @HostListener("window:click", ["$event"]) onClick(event: any) {
    if (event.target.className && event.target.className.includes("modal fade")) {
      this.activeModal.close()
    }
  }

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}
}
