import { Component, OnInit, Renderer2 } from "@angular/core"
import { BankAccountService, BANK_ACCOUNT_API_URL } from "../../services/bank-account.service"
import { ModalPageComponent } from "../../../shared/components/modal-page/modal-page.component"
import { Router } from "@angular/router"
import { FormService } from "../../../shared/services/form.service"
import { Form } from "../../../shared/model/form"

@Component({
  selector: "app-bank-account-form-page",
  templateUrl: "./bank-account-form-page.component.html",
  styleUrls: ["./bank-account-form-page.component.scss"],
})
export class BankAccountFormPageComponent extends ModalPageComponent implements OnInit {
  form: Form
  submitDisabled = false

  constructor(
    renderer: Renderer2,
    router: Router,
    private bankAccountService: BankAccountService,
    private formService: FormService,
  ) {
    super(renderer, router)
  }

  ngOnInit() {
    this.formService.getFormData(BANK_ACCOUNT_API_URL).subscribe((form) => {
      this.form = form
    })
  }

  onSubmit() {
    this.submitDisabled = true
    // 1. 계좌정보 업데이트 진행
    this.bankAccountService.update(this.form).subscribe(
      (res) => {
        this.submitDisabled = false
        this.router.navigate([{ outlets: { popup: null } }])

        // 선택된 계좌 모달 Form Value
        const { value } = this.form.formGroup

        const bankAccount = this.form.fields.name.choices.find(
          (choice) => choice.value === value.name,
        )

        // Global-State에 계좌 정보 등록
        this.bankAccountService.next({
          bankName: bankAccount ? bankAccount.display_name : "-",
          accountHolder: value.holder || "-",
          accountNumber: value.number || "-",
        })
      },
      (error) => {
        this.submitDisabled = false
      },
    )
  }
}
