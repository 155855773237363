import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core"
import { Subscription } from "rxjs"
import { UserService } from "src/app/account/services/user.service"
import { AuthService } from "src/app/core/services/auth.service"

@Component({
  selector: "app-pictorial-comment-form",
  templateUrl: "./pictorial-comment-form.component.html",
  styleUrls: ["./pictorial-comment-form.component.scss"],
})
export class PictorialCommentFormComponent implements OnDestroy {
  @Output() onSubmit = new EventEmitter<string>()
  @Input() disabled: boolean = false
  thumbnail: string
  comment: string
  authSubscription: Subscription
  constructor(protected userService: UserService, private authService: AuthService) {
    this.setThumbnail()
    this.authSubscription = this.authService.getChangeEmitter().subscribe(() => {
      this.setThumbnail()
    })
  }

  ngOnDestroy(): void {
    this.authSubscription.unsubscribe()
  }

  onClickSubmit() {
    if (!this.comment) {
      return
    }
    this.onSubmit.emit(this.comment)
    this.comment = ""
  }

  onClickInput() {
    if (!this.thumbnail) {
      this.authService.moveLoginPage()
    }
  }

  private setThumbnail() {
    const user = this.userService.getUser()
    this.thumbnail = user ? user.thumbnail : null
  }

  get isLoggedIn() {
    return this.authService.isLoggedIn()
  }

  get placeholder() {
    if (!this.isLoggedIn) {
      return "COMMENT_DETAIL.NEED_LOGIN"
    }
    if (this.disabled) {
      return "COMMENT_DETAIL.DISABLED_COMMENT_FIELD_PLACEHOLDER"
    }
    return "COMMENT_DETAIL.COMMENT_FIELD_PLACEHOLDER"
  }
}
