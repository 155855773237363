import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms"

export class Form {
  fields: any
  formGroup: any
  errors: string[]
  validated: boolean
  data
  translateService

  constructor(fields, translateService, data?) {
    this.data = data
    this.fields = fields
    this.formGroup = Form.getFormGroup(fields, data)
    this.translateService = translateService
  }

  private static getFormGroup(fields, data?) {
    const group = {}

    Object.keys(fields).forEach((key) => {
      const field = fields[key]
      let value = ""
      if (data && key in data) {
        value = data[key]
      }
      if (field["choices"] && field["choices"].length > 0 && !value) {
        value = field["choices"][0].value
      }
      field["name"] = key

      if (field["type"] === "list") {
        group[key] = Form.getFormArray(field, value)
      } else if (field["type"] === "nested object") {
        group[key] = Form.getFormGroup(field["children"], value)
      } else {
        group[key] = field.required
          ? new FormControl(value, Validators.required)
          : new FormControl(value)
      }
    })
    return new FormGroup(group)
  }

  private static getFormArrayControl(field, data?) {
    let formArrayControl
    let value = ""

    if (data) {
      value = data
    }

    if (field["type"] === "list") {
      if (field["child"]["type"] === "nested object") {
        formArrayControl = Form.getFormGroup(field["child"]["children"], value)
      } else {
        formArrayControl = new FormControl(value, Validators.required)
      }
    }

    return formArrayControl
  }

  private static getFormArray(field, dataList?) {
    const fields = []

    if (dataList) {
      dataList.forEach((data) => {
        fields.push(Form.getFormArrayControl(field, data))
      })
    }
    return new FormArray(fields)
  }

  getFields() {
    const fields = []
    Object.keys(this.fields).forEach((key) => {
      this.fields[key]["name"] = key
      fields.push(this.fields[key])
    })
    return fields
  }

  getValue(fieldName) {
    return this.formGroup.value[fieldName]
  }

  push(fieldName) {
    const formArray = this.formGroup.get(fieldName) as FormArray
    const formArrayControl = Form.getFormArrayControl(this.fields[fieldName])

    if (formArrayControl) {
      formArray.push(formArrayControl)
    } else {
      throw new Error("form array does not exist")
    }
  }

  setErrors(response, hasAlert = true) {
    this.clearErrors()

    if (response["error"]) {
      const errors = response["error"]

      if (typeof errors == "string") {
        this.errors = ["Failed to request. Please try later"]
      }

      if (Array.isArray(errors)) {
        this.errors = errors
      }

      if (errors["cvc"]) {
        this.errors = errors["cvc"]
      }

      if (errors["non_field_errors"]) {
        this.errors = errors["non_field_errors"]
      }

      Object.keys(errors).forEach((key) => {
        if (this.fields[key]) {
          this.fields[key]["errors"] = errors[key]
        }
      })
      this.validated = true
      if (hasAlert) {
        alert(this.translateService.instant("ALERT_CONFIRM.INVALID_FORM"))
      }
    }
  }

  clearErrors() {
    this.validated = false
    this.errors = []
    Object.keys(this.fields).forEach((key) => {
      this.fields[key]["errors"] = null
    })
  }
}
