import { Component, OnInit } from "@angular/core"
import { ArticleService } from "../../services/article.service"
import { FormBuilder } from "@angular/forms"
import { Router } from "@angular/router"

@Component({
  selector: "app-article-category-list-page",
  templateUrl: "./article-category-list-page.component.html",
  styleUrls: ["./article-category-list-page.component.scss"],
})
export class ArticleCategoryListPageComponent implements OnInit {
  articles
  nextUrl
  form = this.fb.group({
    search: [""],
  })
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private articleService: ArticleService,
  ) {}

  ngOnInit() {
    this.setPaginatedResult()
  }

  onSubmit() {
    this.router.navigate(["/articles", this.form.value])
  }

  setPaginatedResult() {
    this.articleService.getListPaginated({}).subscribe((res) => {
      this.nextUrl = res["next"]
      this.articles = res["results"]
    })
  }

  setPaginatedResultNext() {
    this.articleService.getListPaginatedByUrl(this.nextUrl).subscribe((res) => {
      this.nextUrl = res["next"]
      this.articles = this.articles.concat(res["results"])
    })
  }
}
