import { Component, OnInit } from "@angular/core"
import { MatIconRegistry } from "@angular/material"
import { DomSanitizer } from "@angular/platform-browser"
import { Subscription } from "rxjs"
import { AuthService } from "src/app/core/services/auth.service"
import { ContentDataService } from "../../services/content-data.service"

@Component({
  selector: "app-contents-tab",
  templateUrl: "./contents-tab.component.html",
  styleUrls: ["./contents-tab.component.scss"],
})
export class ContentsTabComponent implements OnInit {
  routerEventSubscription: Subscription
  expandedFilter = false
  constructor(
    public authService: AuthService,
    public contentDataService: ContentDataService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
  ) {
    iconRegistry.addSvgIcon(
      "arrow-icon",
      sanitizer.bypassSecurityTrustResourceUrl("assets/img/icon/explore-arrow.svg"),
    )
  }

  ngOnInit() {
    if (this.contentDataService.contents.length === 0) {
      this.contentDataService.setFreeFilter(true)
      this.contentDataService.fetchThemeSlugs()
      this.reloadContents()
    }
  }

  onScroll() {
    if (this.contentDataService.hasMore && !this.contentDataService.isLoading) {
      this.contentDataService.fetchPictorialContent()
    }
  }

  onClickBlur() {
    this.authService.verifyAdult()
  }

  onToggle(value: boolean) {
    this.contentDataService.toggleAdultFilter(value)
    this.reloadContents()
  }

  onClickTheme(themeSlug: string) {
    this.contentDataService.toggleThemeFilter(themeSlug)
    this.reloadContents()
  }

  private reloadContents() {
    this.contentDataService.resetContents()
    this.contentDataService.fetchPictorialContent()
  }
}
