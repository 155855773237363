import { Component, OnInit } from "@angular/core"

// 미리 만들어 둠
@Component({
  selector: "app-sendme-outbox",
  templateUrl: "./sendme-outbox.component.html",
  styleUrls: ["./sendme-outbox.component.scss"],
})
export class SendmeOutboxComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
