import { EventEmitter, Injectable, OnInit } from "@angular/core"

import { UserService } from "src/app/account/services/user.service"
import { AuthService } from "src/app/core/services/auth.service"
import { PostMessagesService } from "src/app/sendme/services/post-messages.service"

@Injectable({
  providedIn: "root",
})
export class UnreadMessageObserver implements OnInit {
  intervalTimer: ReturnType<typeof setInterval>
  unreadMsgEvent: EventEmitter<number> = new EventEmitter()

  hasUnread = false

  activatedRoutes: string[] = ["/dashboard"]

  constructor(
    private messageService: PostMessagesService,
    private userService: UserService,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.authService.getChangeEmitter().subscribe(() => {
      if (!this.authService.isLoggedIn()) {
      }
    })
  }

  watch() {
    if (!this.intervalTimer) {
      this.fetchUnreadCount()
      this.intervalTimer = setInterval(() => {
        if (this.isInActivatedRoute()) {
          this.fetchUnreadCount()
        }
      }, 30000)
    }
  }

  fetchUnreadCount() {
    if (!this.userService.getUser()) {
      return false
    }
    this.messageService
      .getMessageItems({
        page: 1,
        page_size: 1,
        is_read: false,
        user: this.userService.getUser().id,
      })
      .subscribe((data) => {
        this.hasUnread = data.count > 0
        this.unreadMsgEvent.emit(data.count)
      })
  }

  unwatch() {
    if (this.intervalTimer) {
      clearInterval(this.intervalTimer)
    }
  }

  ngOnDestroy() {
    if (this.intervalTimer) {
      clearInterval(this.intervalTimer)
    }
  }

  clear() {
    this.hasUnread = false
    this.unreadMsgEvent.emit(0)
    this.unwatch()
  }

  isInActivatedRoute() {
    return this.isInCreatorDetailPage() && this.activatedRoutes.includes(window.location.pathname)
  }

  isInCreatorDetailPage() {
    const pathname = window.location.pathname
    return pathname.split("/").length == 2 && pathname !== "/404"
  }
}
