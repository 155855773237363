import { NgModule } from "@angular/core"
import { RouterModule, Routes } from "@angular/router"
import { PictorialDetailPageComponent } from "./pages/pictorial-detail-page/pictorial-detail-page.component"

const routes: Routes = [
  {
    path: "pictorials/:id",
    component: PictorialDetailPageComponent,
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BoardRoutingModule {}
