import { Component, Inject } from "@angular/core"
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material"

export interface ImageDialogData {
  image: string
  permanentlyClosedKey: string
  link?: string
}

@Component({
  selector: "app-image-dialog",
  templateUrl: "image-dialog.component.html",
  styleUrls: ["./image-dialog.component.scss"],
})
export class ImageDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ImageDialogData,
    private dialogRef: MatDialogRef<ImageDialogComponent>,
  ) {}
  moveToLink() {
    if (this.data.link) {
      window.open(this.data.link, "_self")
    }
  }

  closePermanently() {
    localStorage.setItem(this.data.permanentlyClosedKey, "true")
    this.dialogRef.close()
  }

  close() {
    this.dialogRef.close()
  }
}
