import { Injectable } from "@angular/core"
import { PostService } from "../../board/services/post.service.js"
import { environment } from "src/environments/environment"
import { HttpClient } from "@angular/common/http"
import { AuthService } from "../../core/services/auth.service.js"
import { Post, PostCategory } from "src/app/board/types/post.type.js"
import { PostFilter } from "../types/post.type.js"
import { GetPostParams } from "../types/ajax.type.js"
import { Pagination } from "src/app/shared/types/pagination.type.js"
import { PostDataService } from "src/app/board/services/post-data.service.js"

@Injectable({ providedIn: "root" })
export class PreviewPostDataService extends PostDataService {
  posts: Post[]
  nextUrl: string
  inDashboard: boolean
  postLoaded = true
  loadingMore = true
  openedModal = false
  langFlag: string
  filterData: PostFilter = {
    visibility: 0,
    category: PostCategory.creator,
    has_adult_content: false,
  }
  isAdultUser: boolean
  isFirstLoad = true
  isFreePost: boolean = true

  constructor(
    protected postService: PostService,
    protected http: HttpClient,
    protected authService: AuthService,
  ) {
    super(postService, http, authService)
  }

  setAdultFilter(value: boolean) {
    this.filterData = { ...this.filterData, has_adult_content: value }
  }

  getListPaginated(data: GetPostParams) {
    if (this.isFreePost) {
      return this.http.get<Pagination<Post>>(`${environment.apiUrl}posts/free/?is_web=true`, {
        params: data,
      })
    }
  }
}
