import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from "@angular/core"
import { RecommendedModelService } from "./recommended-model-list.service"
import KeenSlider, { KeenSliderInstance, KeenSliderOptions } from "keen-slider"

@Component({
  selector: "app-recommended-model-list",
  templateUrl: "./recommended-model-list.component.html",
  styleUrls: ["./recommended-model-list.component.scss"],
})
export class RecommendedModelListComponent implements OnInit, OnChanges {
  @Input() sliderOptions: KeenSliderOptions
  @Input() random: boolean = false
  @Input() exclude: string[] = []

  @ViewChild("sliderRef", { static: false }) sliderRef: ElementRef<HTMLElement>
  slider: KeenSliderInstance = null

  constructor(public dataService: RecommendedModelService) {}

  ngOnInit() {
    if (this.random) {
      this.dataService.fetch({
        sort: "random",
        count: 24,
      })
    }
    this.dataService.models$.subscribe(() => {
      setTimeout(() => {
        this.slider = new KeenSlider(this.sliderRef.nativeElement, this.sliderOptions)
      })
    })
  }

  ngOnChanges() {
    if (this.slider) {
      this.slider.destroy()
      setTimeout(() => {
        this.slider = new KeenSlider(this.sliderRef.nativeElement, this.sliderOptions)
      })
    }
  }

  get models() {
    return this.dataService.models.filter((model) => !this.exclude.includes(model.id))
  }
}
