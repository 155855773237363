import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { environment } from "../../../environments/environment"

@Injectable({
  providedIn: "root",
})
export class UserBlockService {
  constructor(private http: HttpClient) {}

  get(id) {
    return this.http.get(`${environment.apiUrl}userblock/`)
  }

  create(blockeduserId, blockedReason = "") {
    return this.http.post(`${environment.apiUrl}userblock/`, {
      blocked_user: blockeduserId,
      blocked_reason: blockedReason,
    })
  }

  delete(id) {
    return this.http.delete(`${environment.apiUrl}userblock/${id}/`)
  }
}
