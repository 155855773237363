import { User } from "src/app/account/types/user.type"
import { Creator } from "src/app/creator/types"
import { Comment } from "./comment.type"

export interface UserFile {
  externalVideoProvider?: "youtube" | "vimeo"
  file?: {
    contentType?: string
    id: string
    url: string
    videoStatus?: string
  }
  id: string
  order?: number
  url?: string
}

export interface Post {
  id: number
  user: User
  amount_usd?: number
  creator: Creator
  media_type: MediaType
  category: PostCategory
  title: string
  content?: string
  thumbnail: string
  thumbnails: string[]
  file?: UserFile
  video_embed?: string
  count_comments?: number
  comments?: Comment[]
  is_liked?: boolean
  is_app_only: boolean
  permissions?: string[]
  visibility?: number
  visible?: string
  created?: string
  // [TODO] 타입 확인후 변경 필요
  tags: any[]
  polls: Poll[]
  video_index?: number
  is_wysiwyg?: boolean
  is_adult_content?: boolean
  // [TODO] 타입 확인후 변경 필요
  images?: any[]
  // [TODO] 타입 확인후 변경 필요
  attach_file_ids?: string[]
  opened_status?: PostStatus
  comment_enabled: boolean
  comment_visible: boolean
  pictorial_id?: string

  isBlur?: boolean
}

export interface RecommendedPost {
  order: number,
  post: Post
}

export interface Poll {
  id: number
  is_enabled?: boolean
  question: string
  end_date?: string
  is_ended?: boolean
  allow_multiple_vote?: boolean
  // [TODO] 타입 확인후 변경 필요
  items: any[]
}

export enum PostStatus {
  opened = "opened",
  closed = "closed",
  scheduled = "scheduled",
}

export enum MediaType {
  text = "text",
  image = "image",
  video = "video",
  audio = "audio",
}

export enum PostCategory {
  message = "message",
  creator = "creator",
  contributor = "contributor",
  thankYou = "thank_you",
  messageInitiate = "message_initiate",
}
