import { Component, ElementRef, OnInit, ViewChild } from "@angular/core"
import { AsianTopModelContestService } from "../../services/asian-top-model-contest.service"
import { PaginationMeta } from "src/app/shared/types/pagination.type"
import { GetRankedModelParams, RankedModel } from "../../types/asian-top-model-contest.type"
import { ActivatedRoute } from "@angular/router"
import { SnackbarService } from "src/app/shared/services/snackbar.service"
import { Util } from "src/app/shared/utils/utils"

@Component({
  selector: "app-asian-top-model-ranking-page",
  templateUrl: "./asian-top-model-ranking-page.component.html",
  styleUrls: ["./asian-top-model-ranking-page.component.scss"],
})
export class AsianTopModelRankingPageComponent implements OnInit {
  @ViewChild("modelSearchInput", { static: false }) modelSearchInput: ElementRef
  contestId: number
  searchModelName = ""
  isLoading: boolean

  paginationMeta: PaginationMeta = {
    currentPage: 1,
    itemCount: 0,
    itemsPerPage: 24,
    totalItems: 0,
    totalPages: 0,
  }

  models: RankedModel[] = []

  constructor(
    private contestService: AsianTopModelContestService,
    private route: ActivatedRoute,
    private snackbarService: SnackbarService,
  ) {
    this.isLoading = true
  }

  ngOnInit() {
    this.contestId = this.route.snapshot.data.contest.id
    this.getModelList()
  }

  getModelList() {
    this.isLoading = true
    this.contestService.getModelList(this.getParams()).subscribe((res) => {
      if (!this.models) {
        this.models = res.items
      } else {
        this.models = this.models.concat(res.items)
      }

      this.paginationMeta = res.meta
      this.isLoading = false
    }, this.showErrorSnackbar.bind(this))
  }

  getParams() {
    const params: GetRankedModelParams = {
      page: this.paginationMeta.currentPage,
      limit: this.paginationMeta.itemsPerPage,
      campaignId: this.contestId,
      voteCount: true,
    }

    if (this.searchModelName) {
      params.name = this.searchModelName
    }

    return params
  }

  onScrollDown() {
    if (this.canFetchNextPage) {
      this.paginationMeta.currentPage++
      this.getModelList()
    }
  }

  searchModelOnEnter($event: KeyboardEvent) {
    const keyword = this.modelSearchInput.nativeElement.value
    if ($event.key === "Enter" && keyword !== this.searchModelName) {
      this.searchModelName = keyword

      this.reset()
    }
  }

  reset() {
    this.models = []
    this.paginationMeta.currentPage = 1
    this.getModelList()
  }

  showErrorSnackbar(error: any) {
    this.snackbarService.open(Util.getErrorTxt(error.error), "", 2000, "danger-snackbar")
  }

  get canFetchNextPage() {
    return this.paginationMeta.currentPage < this.paginationMeta.totalPages && !this.isLoading
  }

  get showIndicator() {
    return this.isLoading || this.noModelResult
  }

  get noModelResult() {
    return !this.isLoading && !this.models.length
  }

  get isSearchingModel() {
    return !!this.searchModelName
  }
}
