import { DomSanitizer } from "@angular/platform-browser"
import { Pipe, PipeTransform } from "@angular/core"

@Pipe({
  name: "safeUrl",
})
export class SafeUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }
}
