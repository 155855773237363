import { Component, Input, OnInit } from "@angular/core"
import { DynamicLinkService } from "src/app/board/services/dynamic-link.service"
import { Util } from "../../utils/utils"

@Component({
  selector: "app-application-install-inducer",
  templateUrl: "./application-install-inducer.component.html",
  styleUrls: ["./application-install-inducer.component.scss"],
})

// get parameter
export class ApplicationInstallInducerComponent implements OnInit {
  @Input() isCard = false
  @Input() creatorId: string
  fatkatBackground: number
  isFirebaseDynamicLinkLoading = false
  isMobile: boolean

  constructor(private dynamicLinkService: DynamicLinkService) {
    this.isMobile = Util.isMobileDevice()
  }

  ngOnInit() {
    this.fatkatBackground = this.pickBackground()
  }

  pickBackground() {
    return Math.floor(Math.random() * 5) + 1
  }

  async onFirebaseDynamicLink() {
    this.isFirebaseDynamicLinkLoading = true
    await this.dynamicLinkService.openCreatorLink(this.creatorId)
    this.isFirebaseDynamicLinkLoading = false
  }
}
