import { Component, OnInit } from "@angular/core"

@Component({
  selector: "app-cookie-page",
  templateUrl: "./cookie-page.component.html",
  styleUrls: ["./cookie-page.component.scss"],
})
export class CookiePageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
