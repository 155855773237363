export enum CarouselType {
  popular = "popular",
  new = "new",
  creator = "creator",
  theme = "theme",
}

export interface ContestCreator {
  contest: number
  creator: number
  is_activated: boolean
}

export interface PictorialContestCreator {
  creatorId: number
  likeCount: number
}

export interface Contest {
  active_creators: ContestCreator[]
  description: string
  description_en: string
  id: number
  is_activated: boolean
  start_date: string
  end_date: string
  title: string
  url: string
  themes: ContestTheme[]
}

export interface ContestTheme {
  campaign: number
  theme: string
}

interface ModelProfilePicture {
  id: string
  contentType: string
  videoStatus: string
  url: string
}

interface ModelLink {
  id: string
  url: string
  type: string
  order: number
}

export interface ContestModel {
  id: string
  creatorId: number
  name: string
  modelCreatorId: number
  membermeUri: string
  birthday: Date | null
  height: number
  weight: number
  bwh: string
  order: number
  profilePicture: ModelProfilePicture | null

  links: ModelLink[]

  voteCount: number
}


export interface GetPictorialContestParams {
  latest?: boolean
  likeCount?: boolean
  sortOrder?: string[]
  sortBy?: string[]
  themeSlugs?: string[]
  creatorIds?: number[]
  createdAtBetween?: string[]

  [param: string]: any
}

export interface GetContestParams {
  ordering?: string
  search?: string
  page?: number

  // -1개를 요청해야 원하는 사이즈를 리턴
  page_size?: number

  [param: string]: any
}

export interface GetModelCampaignParams {
  name?: string
  campaignId?: number
  latest?: boolean
  voteCount?: boolean
  page?: number
  page_size?: number

  [param: string]: any
}
