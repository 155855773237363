import { Component, OnInit } from "@angular/core"
import { AuthService } from "../../services/auth.service"
import { NavigationEnd, Router } from "@angular/router"
import { UserService } from "../../../account/services/user.service"

import { CookieService } from "angular2-cookie/core"
import { TranslateService } from "@ngx-translate/core"
import { NotificationService } from "../../../notification/services/notification.service"
import { map } from "rxjs/operators"
import { CreatorService } from "src/app/creator/services/creator.service"

@Component({
  selector: "app-nav",
  templateUrl: "./nav.component.html",
  styleUrls: ["./nav.component.scss"],
})
export class NavComponent implements OnInit {
  maintenanceScheduled = false
  maintenanceDay = false
  maintenanceChecked = false
  toggle = false
  subToggle = false
  languageToggle = false
  isExistUnread = false
  language
  timer
  contactRequests$
  currentLocation
  isContentsCreator = false
  user

  constructor(
    public auth: AuthService,
    private userService: UserService,
    private router: Router,
    private cookieService: CookieService,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private creatorService: CreatorService,
  ) {
    this.toggleMaintenanceAlert()
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd && this.auth.isLoggedIn()) {
        // 로그인 직후에는 크리에이터의 주소 및 전화번호 요청 알람배너가 정상적으로 뜨지 않음
        // 고질적인 angular router bug로 인하여 this.router 이후,
        // ngOnInit을 실행하지 않아서 여기서 라우팅 이후 경우 강제로 실행시켜준다.
        this.contactRequests$ = this.notificationService
          .getListPaginated({ category: ["request"] })
          .pipe(
            map((request: any) => {
              if (request.results.length > 0) {
                request.results.forEach((result, idx) => {
                  if (
                    result.title.includes("address") &&
                    this.cookieService.get("lang") !== "KOR"
                  ) {
                    request.results.splice(idx, 1)
                  }
                })
              }
              return request
            }),
          )

        // fire notification alarm event
        this.timer = setTimeout(() => {
          clearTimeout(this.timer)
          this.getCountUnreadNotification().subscribe((res) => {
            if (res["notification_count"] > 0) {
              this.isExistUnread = true
            }
          })
        }, 1000)
      }
    })
  }

  ngOnInit() {
    this.user = this.userService.getUser()
    this.notificationService.isExistUnread$.subscribe((isExistUnread) => {
      this.isExistUnread = isExistUnread
    })
    if (this.cookieService.get("lang") === "KOR") {
      this.language = "KOR"
    } else {
      this.language = "ENG"
    }
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.toggle = false
        this.subToggle = false
        this.languageToggle = false
      }
    })

    this.auth.getChangeEmitter().subscribe(async () => {
      if (this.auth.isLoggedIn()) {
        this.user = this.userService.getUser()
        this.isContentsCreator =
          this.isCreator() &&
          (await this.creatorService
            .get("me")
            .pipe(map((res: any) => res.creator_type === "photo" || res.creator_type === "sns_and_photo"))
            .toPromise())
      } else {
        this.user = null
        this.isContentsCreator = false
      }
    })

    // 시작시에 애니메이션 코드 등...
  }

  toggleMaintenanceAlert() {
    const now = new Date()
    this.maintenanceChecked = localStorage.getItem("maintenanceChecked") == "true"
    const utcYearMonth = `${now.getUTCFullYear()}-${now.getUTCMonth() + 1}`
    const utcDay = `${now.getUTCDate()}`
    this.maintenanceScheduled = utcYearMonth == "2021-10" && utcDay != "25" && utcDay != "26"
    this.maintenanceDay = "2021-10" && (utcDay == "25" || utcDay == "26")
  }

  doMaintenanceNoticeChecked() {
    localStorage.setItem("maintenanceChecked", "true")
    this.maintenanceChecked = true
  }

  redirect(pagename: string) {
    this.router.navigate(["/" + pagename])
  }

  showContactAlert() {
    return (
      window.location.href.indexOf("users/update/profile") > 0 ||
      window.location.href.indexOf("notifications/activity") > 0
    )
  }

  gotoNotificationPages() {
    this.isExistUnread = false
    this.router.navigate(["/notifications/activity"])
  }

  getCountUnreadNotification() {
    return this.notificationService.getCountUnreadNotification()
  }

  getUser() {
    return this.userService.getUser()
  }

  isCreator() {
    return !!this.getUser().creator
  }

  languageSelect(lang) {
    // this.translate.use(lang);
    if (lang === "ko") {
      if (this.cookieService.get("lang") !== "KOR") {
        this.cookieService.put("lang", "KOR")
        this.translate.use(lang)
        location.reload()
      }
    } else {
      this.cookieService.put("lang", "ENG")
      this.translate.use(lang)
      location.reload()
    }
  }

  toPathArray(url) {
    if (url && typeof url === "string") {
      url = url.split("/").filter((path) => path && path !== "")
    }
    return url
  }

  onClickSignUp() {
    this.toggle = false
    const redirectUrl = new URL(window.location.href)
    this.cookieService.put("redirect_uri", redirectUrl.pathname.replace("(popup:login)", ""))
  }

  logout() {
    this.auth.logout()
    const redirectTimer = setTimeout(() => {
      clearTimeout(redirectTimer)
      this.router.navigate(["/"])
    })
  }

  changeLanguage(event: MouseEvent) {
    event.preventDefault()
    event.stopPropagation()
    this.languageToggle = !this.languageToggle
  }

  c(a) {
    console.log(a)
  }
}
