import { Injectable } from "@angular/core"
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router"
import { AuthService } from "../../core/services/auth.service"
import { Observable, of } from "rxjs"
import { catchError, map } from "rxjs/operators"
import { UserService } from "../../account/services/user.service"
import { CookieService } from "angular2-cookie"

@Injectable({
  providedIn: "root",
})
export class LandingPageGuardService implements CanActivate {
  constructor(
    private router: Router,
    private auth: AuthService,
    private userService: UserService,
    private cookieService: CookieService,
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | boolean {
    return this.userService.updateUser$().pipe(
      map(
        res => {
          const isAuthenticated = this.auth.isAuthenticated()

          if (isAuthenticated) {
            const redirectUri = this.cookieService.get("redirect_uri")
            this.cookieService.remove("redirect_uri")
            if (redirectUri && redirectUri != "%2F") {
              this.router.navigate([redirectUri])
                .then(() => {
                  this.router.navigate([{ outlets: { popup: null } }])
                })
            } else {
              this.router.navigate(["/dashboard"])
                .then(() => {
                  this.router.navigate([{ outlets: { popup: null } }])
                })
            }
          }
          return !isAuthenticated
        }),
      catchError((err, caught) => {
        return of(true)
      }),
    )
  }
}
