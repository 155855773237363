import _ from "lodash"
import { Component, OnDestroy, OnInit } from "@angular/core"
import { BreakpointObserver } from "@angular/cdk/layout"
import { ModelContestDataService } from "../../services/model-contest-data.service"
import { Breakpoint } from "src/app/shared/constants/constants"

@Component({
  selector: "app-model-contest-all-content",
  templateUrl: "./model-contest-all-content.component.html",
  styleUrls: ["./model-contest-all-content.component.scss"],
})
export class ModelContestAllContentComponent implements OnInit, OnDestroy {
  minDesktop = `(min-width: ${Breakpoint["bp-lg"]})`
  isAboveDesktop = false
  inputValue = ""
  searchKeyword = ""

  constructor(
    public contestData: ModelContestDataService,
    private breakpointObserver: BreakpointObserver,
  ) {}

  ngOnInit() {
    this.breakpointObserver.observe([this.minDesktop]).subscribe((result) => {
      this.isAboveDesktop = result.matches
    })
    this.inputValue = this.contestData.searchQuery
  }

  ngOnDestroy(): void {
    this.breakpointObserver.ngOnDestroy()
  }

  onScroll() {
    this.contestData.loadMoreAllContents()
  }

  onClickClear() {
    this.inputValue = ""
    if (this.contestData.searchQuery !== "") {
      this.contestData.resetSearchKeywordAndNext()
    }
  }

  search() {
    const searchQuery = _.trim(this.inputValue)
    if (searchQuery === "") {
      this.onClickClear()
    } else {
      this.searchKeyword = searchQuery
      this.contestData.resetSearchKeyword()
      this.contestData.setSearchKeywordAndNext(searchQuery)
    }
  }

  onClickFilter(filter) {
    this.contestData.setFilterAndNext(filter)
  }

  getRank(index: number) {
    if (!this.contestData.isRanking) {
      return null
    }
    if (this.searchKeyword && _.trim(this.searchKeyword) !== "") {
      return null
    }
    return index + 1
  }
}
