import { Injectable } from "@angular/core"
import { environment } from "../../../environments/environment"
import { HttpClient } from "@angular/common/http"

@Injectable({
  providedIn: "root",
})
export class ArticleService {
  constructor(private http: HttpClient) {}

  get(id) {
    return this.http.get(`${environment.apiUrl}articles/${id}/`)
  }

  getListPaginated(data) {
    return this.http.get(environment.apiUrl + "articles/", { params: data })
  }

  getListPaginatedByUrl(url) {
    return this.http.get(url)
  }
}
