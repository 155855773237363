import { Component, HostListener, Input, OnChanges, SimpleChanges } from "@angular/core"
import { CookieService } from "angular2-cookie"
import { ProfitSummary } from "../../types"

@Component({
  selector: "app-profit-summary",
  templateUrl: "./profit-summary.component.html",
  styleUrls: ["./profit-summary.component.scss"],
})
export class ProfitSummaryComponent implements OnChanges {
  @HostListener("window:resize", ["$event"]) onResize(event) {
    this.getTranslate()
  }

  @Input() summary: ProfitSummary
  @Input() type: "stripe" | "iamport"
  @Input() creatorMode: string

  calculated: number
  membermeFee: number
  membershipAmount: number
  pgFee: number
  pictorialAmount: number
  tax: number
  withholdingTax: number

  isKorean: boolean

  country: string
  prefix: string
  suffix: string

  constructor(private cookieService: CookieService) {
    this.isKorean = this.cookieService.get("lang") === "KOR"
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.summary) {
      const type = changes.type ? changes.type.currentValue : this.type
      switch (type) {
        case "stripe":
          this.calculated = this.trimUsd(this.summary.calculated_amount_usd)
          this.withholdingTax = this.trimUsd(this.summary.withholding_tax_usd)
          this.membermeFee = this.trimUsd(this.summary.memberme_fee_usd)
          this.membershipAmount = this.trimUsd(this.summary.membership_amount_usd)
          this.pgFee = this.trimUsd(this.summary.pg_fee_usd)
          this.pictorialAmount = this.trimUsd(this.summary.pictorial_amount_usd)
          this.tax = this.trimUsd(this.summary.tax_usd)
          break
        case "iamport":
          this.calculated = this.summary.calculated_amount_krw
          this.membermeFee = this.summary.memberme_fee_krw
          this.membershipAmount = this.summary.membership_amount_krw
          this.pgFee = this.summary.pg_fee_krw
          this.pictorialAmount = this.summary.pictorial_amount_krw
          this.tax = this.summary.tax_krw
          break
      }

      this.getTranslate()
    }

    if (changes.type) {
      this.type = changes.type.currentValue
      if (this.isKorean && this.type === "iamport") {
        this.prefix = ""
        this.suffix = "원"
      } else if (!this.isKorean && this.type === "iamport") {
        this.prefix = "₩"
        this.suffix = ""
      }

      if (this.type === "stripe") {
        this.prefix = "$"
        this.suffix = ""
      }
    }

    if (changes.creatorMode) {
      this.creatorMode = changes.creatorMode.currentValue
    }
  }

  trimUsd(value: number) {
    return Math.floor(value * 100) / 100
  }

  getTranslate() {
    let display = window.innerWidth

    if (display < 712) {
      switch (this.type) {
        case "stripe":
          this.country = this.isKorean ? "USD" : "USD Total"
          break
        case "iamport":
          this.country = this.isKorean ? "원화" : "Total KRW"
          break
      }
    } else {
      switch (this.type) {
        case "stripe":
          this.country = this.isKorean ? "USD" : "USD "
          break
        case "iamport":
          this.country = this.isKorean ? "원화" : " KRW"
          break
      }
    }
  }
}
