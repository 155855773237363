import { HttpClient } from "@angular/common/http"
import { Component, OnInit, Renderer2 } from "@angular/core"
import { Router } from "@angular/router"
import { EventService } from "src/app/core/services/event.service"
import { environment } from "src/environments/environment"
import { Location } from "@angular/common"
import { UserService } from "../../services/user.service"

declare const IMP: any
const IMP_FRANCHISEE_CODE = ""

@Component({
  selector: "app-user-verify-page",
  templateUrl: "./user-adult-verify-page.component.html",
  styleUrls: ["./user-adult-verify-page.component.scss"],
})
export class UserAdultVerifyPageComponent implements OnInit {
  returnurl = window.location.origin + "/users/adult-verification-result"
  errorurl = window.location.origin + "/users/adult-verification-result"

  encryptData = ""
  constructor(
    protected renderer: Renderer2,
    protected router: Router,
    private http: HttpClient,
    private location: Location,
    private userService: UserService,
    private eventService: EventService,
  ) {}

  ngOnInit() {
    this.getEncryptData().subscribe((res: any) => {
      this.encryptData = res.enc_data
    })
  }

  exitThisPage() {
    this.location.back()
  }

  getEncryptData() {
    return this.http.get(`${environment.apiUrl}users/adult/verify_meta_data`, {
      params: {
        returnurl: encodeURIComponent(this.returnurl),
        errorurl: encodeURIComponent(this.errorurl),
      },
    })
  }

  verify(e) {
    const niceWindow = window.open(
      "",
      "popupChk",
      "width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no",
    )

    const getMessageEvent = async (e) => {
      // const message = e.data
      if (e.origin === window.origin) {
        window.removeEventListener("message", getMessageEvent)

        // 토큰 유저 정보 수정
        const user = JSON.parse(localStorage.getItem("user"))
        user.verified_as_adult = true
        localStorage.setItem("user", JSON.stringify(user))

        await this.userService.updateUser()
        history.back()
        this.eventService.onUserUpdate$.emit()

        // IMP.init(IMP_FRANCHISEE_CODE)

        // IMP.certification({}, (rsp) => {
        //   if (rsp.success) {
        //   } else {
        //     alert("인증에 실패하였습니다. 에러 내용: " + message)
        //   }
        // })
      }
    }
    window.addEventListener("message", getMessageEvent)
    const form = document.createElement("form")

    const hiddenInput = document.createElement("input")
    hiddenInput.setAttribute("type", "hidden")
    hiddenInput.setAttribute("name", "recvMethodType")
    hiddenInput.setAttribute("value", "get")
    form.appendChild(hiddenInput)

    const encodeDataElement = document.createElement("input")
    encodeDataElement.setAttribute("type", "hidden")
    encodeDataElement.setAttribute("name", "EncodeData")
    encodeDataElement.setAttribute("value", this.encryptData)
    form.appendChild(encodeDataElement)

    const checkplusServiceElement = document.createElement("input")
    checkplusServiceElement.setAttribute("type", "hidden")
    checkplusServiceElement.setAttribute("name", "m")
    checkplusServiceElement.setAttribute("value", "checkplusService")
    form.appendChild(checkplusServiceElement)

    const submitButton = document.createElement("button")
    submitButton.setAttribute("type", "submit")
    form.appendChild(submitButton)

    form.setAttribute("action", "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb")
    form.setAttribute("target", "popupChk")
    form.setAttribute("method", "post")

    form.style.display = "none"

    document.body.appendChild(form)

    form.submit()
  }
}
