import { Component, OnInit } from "@angular/core"
import { Location } from "@angular/common"
import { Router } from "@angular/router"
import { CookieService } from "angular2-cookie/core"

import { FormService } from "src/app/shared/services/form.service"
import { Form } from "src/app/shared/model/form"
import { AuthService } from "src/app/core/services/auth.service"
import { environment } from "src/environments/environment"
import { UserService } from "../../services/user.service"

@Component({
  selector: "app-user-create-page",
  templateUrl: "./user-create-page.component.html",
  styleUrls: ["./user-create-page.component.scss"],
})
export class UserCreatePageComponent implements OnInit {
  form: Form
  contentOpened = false
  langFlag
  facebookSignupUrl = `${environment.backendUrl}accounts/logout/?next=${environment.backendUrl}social/login/facebook/`
  googleSignupUrl = `${environment.backendUrl}accounts/logout/?next=${environment.backendUrl}social/login/google-oauth2/`

  constructor(
    private userService: UserService,
    private formService: FormService,
    private location: Location,
    private router: Router,
    private auth: AuthService,
    private cookieService: CookieService,
  ) {}

  ngOnInit() {
    if (this.auth.isAuthenticated()) {
      // redirect to dashboard if user is already logged in
      this.router.navigate(["/"])
    }
    if (this.cookieService.get("lang") === "KOR") {
      this.langFlag = "KOR"
    } else {
      this.langFlag = "ENG"
    }

    this.formService.getFormData("users/").subscribe((formData) => {
      // TEMPORARY? Only for Nickname and Email label
      if (this.langFlag !== "KOR") {
        formData.fields.email.label = "Email"
        formData.fields.name.label = "Nickname"
      }
      this.form = formData
    })
    // .subscribe(formData => this.form = formData);
  }

  openContent() {
    this.contentOpened = !this.contentOpened
    if (this.contentOpened) {
      document.getElementById("agreement-id").style.display = "block"
    } else {
      document.getElementById("agreement-id").style.display = "none"
    }
  }

  onSubmit() {
    this.userService.create(this.form).subscribe((res) => {
      this.auth.setTokensAndUpdateUser(res["token"])
      this.router.navigate(["/users/activate"])
    })
  }

  goBack(): void {
    this.location.back()
  }

  appleSignIn() {
    const params = {
      client_id: "net.memberme.api.front",
      response_type: "code id_token",
      response_mode: "form_post",
      scope: "name email",
      redirect_uri: `${environment.backendUrl}/api/users/auth-by-token/apple/`,
      state: "web",
    }
    const queryString = this.getQueryString(params)
    window.location.href = `https://appleid.apple.com/auth/authorize?${queryString}`
  }

  getQueryString(params: object) {
    if (params) {
      const searchParams = new URLSearchParams()
      Object.keys(params).forEach((key) => searchParams.set(key, params[key]))
      return searchParams.toString()
    }
    return null
  }
}
