import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core"
import { FormControl } from "@angular/forms"

@Component({
  selector: "app-selectbox",
  templateUrl: "./selectbox.component.html",
  styleUrls: ["./selectbox.component.scss"],
})
export class SelectboxComponent implements OnInit, OnChanges {
  @Input()
  changeForm: (key, value) => {}
  @Input()
  list: [] | undefined
  @Input()
  selected: any
  @Input()
  multiple: boolean
  @Input()
  id: string
  @Input()
  disabled?: boolean
  @Input()
  firstChild
  @Input()
  lastChild
  @Input()
  placeholder?: string
  @Input()
  removable?: boolean

  formControl: FormControl

  constructor() {}

  ngOnInit() {
    this.formControl = new FormControl(this.selected)
    this.disabled === true ? this.formControl.disable() : this.formControl.enable()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled && this.formControl) {
      !changes.disabled.currentValue ? this.formControl.enable() : this.formControl.disable()
    }
  }

  onChange(e) {
    this.changeForm(this.id, e.value)
  }

  clear($event) {
    $event.stopPropagation()
    this.formControl.setValue(null)
    this.changeForm(this.id, null)
  }
}
