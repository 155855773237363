export class DatepickerFormatterComponent {
  lang

  constructor() {}

  get display() {
    return this.lang == "KOR"
      ? {
          dateInput: "YYYY-MM-DD",
          monthYearLabel: "YYYY MM",
          dateA11yLabel: "LL",
          monthYearA11yLabel: "YYYY MMMM",
        }
      : {
          dateInput: "MMM-DD-YYYY",
          monthYearLabel: "MM YYYY",
          dateA11yLabel: "MMM-DD-YYYY",
          monthYearA11yLabel: "MM YYYY",
        }
  }

  get parse() {
    return this.lang == "KOR"
      ? {
          dateInput: "YYYY-MM-DD",
        }
      : {
          dateInput: "MMM-DD-YYYY",
        }
  }
}
