import { Component, Input, OnChanges, SimpleChange } from "@angular/core"
import { StorageContent } from "./types/storage-container.type"
import { ActivatedRoute, Router } from "@angular/router"

@Component({
  selector: "app-storage-content-container",
  templateUrl: "./storage-content-container.component.html",
  styleUrls: ["./storage-content-container.component.scss"],
})
export class StorageContentContainerComponent implements OnChanges {
  @Input() contents: StorageContent[]
  @Input() title: string
  @Input() desc: string
  @Input() url: string
  @Input() type: "sendme" | "memberme"

  isLoading: boolean = true

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if (
      changes.contents &&
      changes.contents.currentValue &&
      Array.isArray(changes.contents.currentValue)
    ) {
      this.isLoading = false
    }
  }

  onClick() {
    this.router.navigate([this.url], { relativeTo: this.route })
  }
}
