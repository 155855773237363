import { Injectable } from "@angular/core"
import { MatDialog } from "@angular/material"
import { Pictorial } from "src/app/board/types/pictorial.type"
import { StripeMethodDialogComponent } from "./stripe-method-dialog.component"
import { StripeMethodData } from "./stripe-method-dialog.type"
import { PaymentDialogComponent } from "../payment-dialog/payment-dialog.component"

@Injectable({
  providedIn: "root",
})
export class StripeMethodDialog {
  pictorial: Pictorial

  constructor(public dialog: MatDialog) {}

  async open(data: StripeMethodData) {
    const dialogRef = this.dialog.open(StripeMethodDialogComponent, {
      maxWidth: "100vw",
      data: data,
      panelClass: "stripe-method-dialog",
    })

    const stripeMethodDialogData = await dialogRef.afterClosed().toPromise()

    if (!stripeMethodDialogData) {
      return false
    }

    const result = await this.dialog
      .open(PaymentDialogComponent, {
        data: { ...data, stripeMethodDialogData },
        panelClass: "payment-dialog",
      })
      .afterClosed()
      .toPromise()

    return result && result.status === "success"
  }
}
