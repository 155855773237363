import { BrowserModule } from "@angular/platform-browser"
import { ErrorHandler, NgModule } from "@angular/core"

import { CookieService } from "angular2-cookie/core"

import { AppComponent } from "./app.component"
import { CreatorModule } from "./creator/creator.module"
import { CoreModule } from "./core/core.module"
import { SharedModule } from "./shared/shared.module"

import { PaymentModule } from "./payment/payment.module"
import { NotificationModule } from "./notification/notification.module"
import { AccountModule } from "./account/account.module"
import { BoardModule } from "./board/board.module"
import { AppRoutingModule } from "./app-routing.module"
import { HttpClient, HttpClientModule } from "@angular/common/http"
import { httpInterceptorProviders } from "./core/http-interceptors"
import { WebsiteModule } from "./website/website.module"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import { TranslateHttpLoader } from "@ngx-translate/http-loader"
import { TranslateLoader, TranslateModule } from "@ngx-translate/core"

import { CountryService } from "./shared/services/country.service"
import { ContestModule } from "./contest/contest.module"
import { ExploreModule } from "./explore/explore.module"
import { SendmeModule } from "./sendme/sendme.module"
import { AngularSvgIconModule } from "angular-svg-icon"
import { ModelsModule } from "src/app/models/models.module"
import { GlobalErrorHandler } from "src/app/shared/error/global-error.handler"

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http)
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    CoreModule,
    SharedModule,
    BoardModule,
    WebsiteModule,
    PaymentModule,
    NotificationModule,
    AccountModule,
    SendmeModule,
    CreatorModule,
    ContestModule,
    ModelsModule,
    ExploreModule,
    AppRoutingModule
  ],
  providers: [
    httpInterceptorProviders,
    { provide: CookieService, useFactory: cookieServiceFactory },
    CountryService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function cookieServiceFactory() {
  return new CookieService()
}
