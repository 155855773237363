import { Component, OnInit } from "@angular/core"

@Component({
  selector: "app-benefit-guideline-page",
  templateUrl: "./benefit-guideline-page.component.html",
  styleUrls: ["./benefit-guideline-page.component.scss"],
})
export class BenefitGuidelinePageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
