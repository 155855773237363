import { EventEmitter, Injectable } from "@angular/core"
import { FormControl, Validators } from "@angular/forms"
import { TranslateService } from "@ngx-translate/core"
import { UserService } from "src/app/account/services/user.service"
import { CreatorAccountInformationService } from "src/app/creator/services/creator-account-information.service"
import { CreatorService } from "src/app/creator/services/creator.service"
import { Form } from "src/app/shared/model/form"
import { CountryCodeType, CountryService } from "src/app/shared/services/country.service"
import { FormService } from "src/app/shared/services/form.service"
import { SnackbarService } from "src/app/shared/services/snackbar.service"
import { CountryType, CreatorBusinessType, CreatorRecipient, CreatorSettleInfo } from "../type"
import countryCode from "src/assets/i18n/countryCode"

type SettleInfoPayload = Partial<CreatorSettleInfo> & {
  country: CountryCodeType
  bank_country: CountryCodeType
}

@Injectable({
  providedIn: "root",
})
export class CreatorSettleInfoDataService {
  settleInfo: Form
  recipient: Form

  countryType: CountryType
  countryFetched = new EventEmitter<boolean>()
  constructor(
    private settleInfoService: CreatorAccountInformationService,
    private countryService: CountryService,
    private userService: UserService,
    private creatorService: CreatorService,
    private translateService: TranslateService,
    private snackbarService: SnackbarService,
    private formService: FormService,
  ) {
    // window.deleteAccount = this.deleteAccount.bind(this)

    this.countryDisplayFn = this.countryService.getCountryCode
    this.countryFilter = this.countryService.filterByName
  }

  formControls: Record<string, FormControl> = {}
  countryCode: CountryCodeType[] = countryCode

  countryFilter: (value: string) => CountryCodeType[]
  countryDisplayFn: (value: CountryCodeType) => string | undefined

  public error: Record<string, any> = {}
  get(): Promise<{ settleInfo: Form; recipient: Form }> {
    const settleInfo = this.settleInfoService.get().toPromise()
    const settleFormInfo = this.formService.getFormData("creator-account-information").toPromise()
    const recipient = this.formService.getFormData("creators/me").toPromise()

    return Promise.all([settleFormInfo, recipient, settleInfo]).then(
      ([formResponse, recipientResponse, infoResponse]) => {
        this.settleInfo = formResponse
        this.recipient = recipientResponse

        Object.keys(infoResponse).forEach((key) => {
          if (!key.includes("country")) {
            this.settleInfo.data[key] = infoResponse[key]
          } else {
            this.settleInfo.data[key] = this.countryService.filterByCode(infoResponse[key])[0]
          }
        })

        this.setupForm()
        return {
          settleInfo: formResponse,
          recipient: recipientResponse,
        }
      },
    )
  }
  // deleteAccount(id) {
  //   return this.settleInfoService.delete(id).toPromise()
  // }

  async upsert(): Promise<Record<string, any> | undefined> {
    this.error = {}
    const settleData = this.settleInfo.data
    const recipientData = this.recipient.data

    if (settleData.country instanceof Object) {
      settleData.country = settleData.country.code
    }

    if (settleData.bank_country instanceof Object) {
      settleData.bank_country = settleData.bank_country.code
    }

    if (this.checkSettleInfoValid(settleData) + this.checkRecipientValid(recipientData) !== 0) {
      this.setErrors()
      return this.error
    }

    this.trimRecipient(recipientData)
    this.trimSettleInfo(settleData)

    if (!settleData.creator) {
      settleData.creator = this.userService.getUser().creator
    }

    const updateSettle = (
      settleData.id
        ? this.settleInfoService.update(settleData.id, settleData)
        : this.settleInfoService.create(settleData)
    ).toPromise()
    const updateRecipient = this.creatorService.updatePartialFromObject(recipientData).toPromise()
    let updateCountry: Promise<Object>

    if (!this.countryService.country.country) {
      updateCountry = this.countryService
        .set(settleData.country, settleData.postal_code)
        .toPromise()
    }

    try {
      const upsertSettleResp = await updateSettle
      await updateRecipient

      if (upsertSettleResp.id) {
        this.settleInfo.formGroup.get("id").setValue(upsertSettleResp.id)
        this.settleInfo.data.id = upsertSettleResp.id
      }

      await updateCountry
      this.snackbarService.open(this.translateService.instant("ALERT_CONFIRM.SAVED"), "", 2000)

      return upsertSettleResp
    } catch (errorResp) {
      if (typeof errorResp.error === "object") {
        this.error = errorResp.error
      } else {
        const error = errorResp.error

        if (error == "Postal code is invalid") {
          this.error.postal_code = [error]
        }
      }
      this.snackbarService.open(
        this.translateService.instant("ALERT_CONFIRM.INVALID_FORM"),
        "",
        2000,
        "danger-snackbar",
      )

      this.setErrors()
    }
  }

  checkRecipientValid(recipient: CreatorRecipient) {
    switch (recipient.business_type) {
      case CreatorBusinessType.legal: {
        if (!recipient.business_registration_number) {
          this.error.business_registration_number = { required: true }
        }

        break
      }
      case CreatorBusinessType.natural:
        break
      default: {
        this.error.business_type = { required: true }
      }
    }

    return Object.keys(this.error).length
  }

  checkSettleInfoValid(settleInfo: CreatorSettleInfo | SettleInfoPayload) {
    if (
      (typeof settleInfo.country === "object" && !(settleInfo.country as CountryCodeType).code) ||
      !settleInfo.country
    ) {
      this.error.country = { required: true }
    }

    if (
      typeof settleInfo.bank_country === "object" &&
      !(settleInfo.bank_country as CountryCodeType).code &&
      this.countryType !== CountryType.US
    ) {
      this.error.bank_country = { required: true }
    }

    if (!settleInfo.name) {
      this.error.name = { required: true }
    }
    if (!settleInfo.address1) {
      this.error.address1 = { required: true }
    }
    if (!settleInfo.postal_code) {
      this.error.postal_code = { required: true }
    }
    if (!settleInfo.bank_account_number) {
      this.error.bank_account_number = { required: true }
    }

    return Object.keys(this.error).length
  }

  trimRecipient(recipient: CreatorRecipient) {
    if (recipient.business_type == CreatorBusinessType.natural) {
      recipient.business_registration_number = ""
      recipient.company_name = ""
    } else if (recipient.business_type == CreatorBusinessType.legal) {
      recipient.cash_receipt_number = ""
    } else {
      recipient.business_registration_number = ""
      recipient.company_name = ""
      recipient.cash_receipt_number = ""
    }
  }

  trimSettleInfo(settleInfo: CreatorSettleInfo) {
    if (settleInfo.country == "KR") {
      settleInfo.city = ""
      settleInfo.state_province = ""
    } else if (settleInfo.country == "US") {
      settleInfo.bank_city = ""
    }
  }
  getCountry(country: string | CountryCodeType, from: "sellerCountry" | "settleCountry") {
    this.setCountryType(from)
    this.setFormValidators()

    if (!country) {
      return this.countryCode
    }
    if (typeof country == "string") {
      return this.countryFilter(country)
    }

    return this.countryFilter(country.name)
  }

  setCountryType(from?: "sellerCountry" | "settleCountry") {
    if (from === "sellerCountry" || !from) {
      switch (this.formControls["country"].value.code) {
        case "KR":
          this.countryType = CountryType.KR
          break
        case "US":
          this.countryType = CountryType.US
          break
        default:
          this.countryType = CountryType.other
      }
    }

    this.setFormValidators()
  }

  getFormStatus(control: FormControl) {
    return control.touched && (control.invalid || control.status == "DISABLED")
  }

  setupForm() {
    Object.keys(this.settleInfo.formGroup.controls).forEach((key) => {
      this.formControls[key] = this.settleInfo.formGroup.get(key)
      this.formControls[key].setValue(this.settleInfo.data[key])
    })

    Object.keys(this.recipient.formGroup.controls).forEach((key) => {
      if (key.includes("business")) {
        this.formControls[key] = this.recipient.formGroup.get(key)
        this.formControls[key].setValue(this.recipient.data[key])
      }
    })

    if (!this.settleInfo.data.country.code) {
      this.setupCountryControlsFromService()
    }

    this.settleInfo.data.country = this.formControls.country.value
    this.settleInfo.data.bank_country = this.formControls.bank_country.value

    if (!this.settleInfo.data.bank_country) {
      this.settleInfo.data.bank_country = this.formControls.country.value
    }

    this.setCountryType()
    this.setFormValidators()
  }

  setFormValidators() {
    this.formControls["bank_country"].setValidators([Validators.required])
    this.formControls["bank_account_number"].setValidators([Validators.required])
    switch (this.countryType) {
      case CountryType.KR:
        this.formControls["address1"].setValidators([])
        this.formControls["postal_code"].setValidators([])
        break
      default:
        this.formControls["address1"].setValidators([Validators.required])
        this.formControls["postal_code"].setValidators([Validators.required])
        break
    }
    switch (this.formControls["business_type"].value) {
      case CreatorBusinessType.legal:
        this.formControls["business_registration_number"].setValidators([Validators.required])
        break
      default:
        this.formControls["business_registration_number"].setValidators([])
        break
    }
  }

  changeForm = (key, value) => {
    this.error = {}
    if (this.formControls[key]) {
      this.formControls[key].setErrors(null)
      this.formControls[key].setValue(value)
    }

    if (key === "business_type") {
      this.setFormValidators()

      if (value == CreatorBusinessType.legal) {
        this.formControls["business_registration_number"].markAsUntouched()
      }
    }

    if (key in this.settleInfo.data) {
      this.settleInfo.data[key] = value
    } else {
      this.recipient.data[key] = value
    }
  }

  setupCountryControlsFromService() {
    if (!this.countryService.country) {
      return { code: "", name: "" }
    }
    const selectedCountry = this.countryService.filterByCode(this.countryService.country.country)[0]

    if (selectedCountry && selectedCountry.code) {
      this.formControls["bank_country"].setValue(selectedCountry)
      this.formControls["country"].setValue(selectedCountry)
    }

    return selectedCountry
  }

  setAddressError() {
    if (!this.settleInfo.data.address1) {
      this.formControls["address1"].setErrors({ required: true })
      this.formControls["address1"].markAsTouched()
    }

    if (!this.settleInfo.data.postal_code) {
      this.formControls["postal_code"].setErrors({ required: true })
      this.formControls["postal_code"].markAsTouched()
    }
  }

  getCountryType() {
    return this.countryType
  }

  getCountryDataOnFetched() {
    return this.countryService.get().subscribe((country) => {
      this.setupCountryControlsFromService()
      this.countryFetched.emit(true)
    })
  }

  setErrors() {
    Object.entries(this.error).forEach(([key, value]) => {
      if (key == "country") {
        const errorMsg = value && value.pop && value.pop()
        this.formControls["country"].markAsTouched()
        this.formControls["bank_country"].markAsTouched()
        this.formControls["country"].setErrors({
          required: true,
          message: errorMsg,
        })
        this.formControls["bank_country"].setErrors({
          required: true,
          message: errorMsg,
        })
      } else {
        this.formControls[key].setErrors({
          required: true,
          message: value && value.pop && value.pop(),
        })
        this.formControls[key].markAsTouched()
      }
    })
    this.snackbarService.open(
      this.translateService.instant("ALERT_CONFIRM.INVALID_FORM"),
      "",
      2000,
      "danger-snackbar",
    )
  }
}
