import { Component, Input, OnInit } from "@angular/core"
import { ActivatedRoute, Params, Router } from "@angular/router"

import { PostService } from "src/app/board/services/post.service"

import { Comment } from "src/app/board/types/comment.type"

import { PostMessagesService } from "../../services/post-messages.service"
import { Message, MessageType } from "../../types/message.type"
import { UserService } from "src/app/account/services/user.service"
import { CommentService } from "src/app/board/services/comment.service"
import { User } from "src/app/account/types/user.type"
import { Pagination } from "src/app/shared/types/pagination.type"
import { SnackbarService } from "src/app/shared/services/snackbar.service"
import { Util } from "src/app/shared/utils/utils"
import { Observable, throwError } from "rxjs"
import { catchError, switchMap, tap } from "rxjs/operators"
import { PostDataService } from "src/app/board/services/post-data.service"
import { Post } from "src/app/board/types/post.type"
import { GetPostParams } from "src/app/explore/types/ajax.type"

@Component({
  selector: "app-sendme-chat-detail",
  templateUrl: "./sendme-chat-detail.component.html",
  styleUrls: ["./sendme-chat-detail.component.scss"],
})
export class SendmeChatDetailComponent implements OnInit {
  @Input() messageCreatedByMe: boolean = false
  comments: Comment[]
  message: Message
  opponent: Partial<User>

  parentComment?: Comment

  _commentNextUrl: string
  isUserCommentLoaded: boolean = true

  constructor(
    private postService: PostDataService,
    private messageService: PostMessagesService,
    private route: ActivatedRoute,
    private userService: UserService,
    private commentService: CommentService,
    private router: Router,
    private snackbarService: SnackbarService,
  ) {}

  ngOnInit() {
    window.scrollTo(0, 0)

    const params = this.route.snapshot.params

    if (!this.messageCreatedByMe) {
      this.initUserView(params)
    } else {
      this.initCreatorView(params)
    }
  }
  initUserView(params: Params) {
    this.messageService
      .getMessageFromId(Number(params.id))
      .pipe(
        switchMap((res) => this.setMessageRead(res)),
        switchMap(() => this.getOpponent()),
        switchMap(() => this.getParentComment()),
        switchMap(() => this.getComments()),
      )
      .subscribe()

    this.commentService.commentCreatedEvent.subscribe((comment) => {
      this.comments = this.comments.concat(comment)
    })
  }

  initCreatorView(params: Params) {
    // 본인이 보낸 메세지는 상대방이 보낸것처럼 렌더링해야함.
    this.opponent = this.userService.getUser()
    this.postService
      .getPost(Number(params.id))
      .subscribe((res: Post) => {
        this.message = {
          message_type: res.pictorial_id ? MessageType.pictorial : MessageType.post,
          post: res,
        } as Message
      })
      .add(() => {
        this.getComments().subscribe()
      })
  }

  setMessageRead(res: Message) {
    this.message = res
    if (!res.is_read) {
      return this.postService.postMessageRead(res.post.id)
    }
    return new Observable((observer) => {
      observer.next()
      observer.complete()
    })
  }

  getComments() {
    const params: GetPostParams = {}
    let commentArray: Comment[] = []
    let commentServiceFunction: (
      params: GetPostParams | string,
    ) => Observable<Comment[] | Pagination<Comment>>

    if (this._commentNextUrl) {
      commentServiceFunction = this.commentService.getCommentsPaginatedByUrl.bind(
        this.commentService,
      )
    } else {
      commentServiceFunction = this.commentService.getList.bind(this.commentService)
    }

    if (this.messageCreatedByMe) {
      params.post = this.message.post.id
    } else if (!!this.parentComment) {
      params.parent = this.parentComment.id
    }

    return commentServiceFunction(!!this._commentNextUrl ? this._commentNextUrl : params).pipe(
      tap(
        (res: Comment[] | Pagination<Comment>) => {
          let results: Comment[] = []

          if (Array.isArray(res)) {
            results = res
          } else {
            results = res.results || []
            this._commentNextUrl = res.next
          }

          if (this.messageCreatedByMe) {
            this.comments = this.comments ? this.comments.concat(results) : results
            this.isUserCommentLoaded = true
          } else {
            results = results.reverse()
            commentArray = this.parentComment ? [this.parentComment, ...results] : results
            this.comments = this.comments ? this.comments.concat(commentArray) : commentArray
          }
        },
        (error) => {
          this.showErrorSnackbar(error)
        },
      ),
    )
  }

  getOpponent(): Observable<Partial<User>> {
    const { id: userId, creator } = this.userService.getUser()
    const { user: messageUser, creator: messageCreator } = this.message

    if (userId !== messageUser && messageCreator !== creator) {
      this.router.navigate(["/404"])
      return
    }

    if (messageUser !== userId) {
      return this.getUser(messageUser).pipe(
        tap(
          (res) => {
            this.opponent = res
          },
          (error) => {
            this.showErrorSnackbar(error)
          },
        ),
      )
    }
    return new Observable((observer) => {
      this.opponent = this.message.creator
      observer.next(this.message.creator)
      observer.complete()
    })
  }

  getUser(id: number) {
    return this.userService.get(id)
  }

  getParentComment() {
    const user = this.userService.getUser()

    return this.commentService
      .getList({
        post: this.message.post.id,
        user: user.id,
      })
      .pipe(
        tap((resp) => {
          if (Object.hasOwnProperty.call(resp, "results")) {
            this.parentComment = (resp as Pagination<Comment>).results[0]
          }
        }),
      )
  }

  showErrorSnackbar(error: any) {
    this.snackbarService.open(Util.getErrorTxt(error.error), "", 2000, "danger-snackbar")
  }

  get showLoadingSpinner() {
    return !this.message || !this.comments
  }

  onScrollDown() {
    if (this._commentNextUrl && this.isUserCommentLoaded) {
      this.isUserCommentLoaded = false
      this.getComments().subscribe(() => {
        this.isUserCommentLoaded = true
      })
    }
  }
}
