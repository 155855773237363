import { NgModule } from "@angular/core"

import { PaymentRoutingModule } from "./payment-routing.module"
import { SharedModule } from "../shared/shared.module"
import { TransactionCreatePageComponent } from "./pages/transaction-create-page/transaction-create-page.component"
import { BillingKeyCreatePageComponent } from "./pages/billing-key-create-page/billing-key-create-page.component"
import { BankAccountFormPageComponent } from "./pages/bank-account-form-page/bank-account-form-page.component"
import { StripeInducerComponent } from "./components/stripe-inducer/stripe-inducer.component"

import {
  MatAutocompleteModule,
  MatCardModule,
  MatCheckboxModule,
  MatDividerModule,
  MatFormFieldModule,
  MatInputModule,
  MatTableModule,
} from "@angular/material"
import { CheckProductPageComponent } from "./pages/check-product-page/check-product-page.component"
import { PaymentItemTableComponentComponent } from "./components/payment-item-table-component/payment-item-table-component.component"
import { PictorialBuyModalComponent } from "./pages/pictorial-buy-modal/pictorial-buy-modal.component"
import { StripeMethodDialogComponent } from "./components/stripe-method-dialog/stripe-method-dialog.component"
import { PaymentDialogComponent } from "./components/payment-dialog/payment-dialog.component"
import {
  PictorialPaymentSuccessDialogComponent,
} from "./components/pictorial-payment-success-dialog/pictorial-payment-success-dialog.component"
import {
  PaymentSuccessPromotionTiersComponent,
} from "./components/payment-success-promotion-tiers/payment-success-promotion-tiers.component"

@NgModule({
  imports: [
    SharedModule,
    PaymentRoutingModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatTableModule,
    MatCardModule,
    MatCheckboxModule,
  ],
  declarations: [
    TransactionCreatePageComponent,
    BillingKeyCreatePageComponent,
    BankAccountFormPageComponent,
    StripeInducerComponent,
    CheckProductPageComponent,
    PaymentItemTableComponentComponent,
    PictorialBuyModalComponent,
    StripeMethodDialogComponent,
    PaymentDialogComponent,
    PictorialPaymentSuccessDialogComponent,
    PaymentSuccessPromotionTiersComponent,
  ],
  entryComponents: [
    PictorialBuyModalComponent,
    StripeMethodDialogComponent,
    PaymentDialogComponent,
    PictorialPaymentSuccessDialogComponent,
    PaymentSuccessPromotionTiersComponent,
  ],
  exports: [
    StripeInducerComponent,
    MatFormFieldModule,
    MatInputModule,
    PaymentItemTableComponentComponent,
    PictorialBuyModalComponent,
    StripeMethodDialogComponent,
    PaymentDialogComponent,
  ],
})
export class PaymentModule {}
