import { Component, OnInit } from "@angular/core"

import { CookieService } from "angular2-cookie/core"

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  //
  langFlag
  year

  constructor(private cookieService: CookieService) {
    this.year = new Date().getFullYear()
  }

  ngOnInit() {
    if (this.cookieService.get("lang") === "KOR") {
      this.langFlag = "KOR"
    } else {
      this.langFlag = "ENG"
    }
  }
}
