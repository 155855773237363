import { Component, OnInit, AfterViewInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-delete-account-request-page',
  templateUrl: './delete-account-request-page.component.html',
  styleUrls: ['./delete-account-request-page.component.scss'],
})
export class DeleteAccountRequestPageComponent implements OnInit, AfterViewInit {

  constructor(private renderer: Renderer2) {}

  ngOnInit() {}

  ngAfterViewInit() {
    // Hide the navigation bar after the view has been initialized
    this.hideNavigationBar();
  }

  private hideNavigationBar() {
    // Adjust the selector based on the actual class or ID of the navigation bar
    const navbar = document.querySelector('#navbar-id'); // Replace with actual selector

    if (navbar) {
      this.renderer.setStyle(navbar, 'display', 'none');
    }
  }
}
