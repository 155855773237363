import { Component, OnDestroy, OnInit } from "@angular/core"

import { FilterLabel } from "../../services/storage-detail-data.service"
import { Message, MessageType } from "../../types/message.type"
import { ActivatedRoute, ParamMap, Router } from "@angular/router"
import { SortOrder } from "src/app/shared/types/ajax.type"
import { PaymentStatus } from "./types/payment.type"
import { FilterItem } from "../../components/storage-content-filter/types/sendme-filter.type"

import _ from "lodash"
import { PostMessagesService } from "../../services/post-messages.service"
import { GetMessageParams } from "../../types/ajax.type"
import { UserService } from "src/app/account/services/user.service"
import { PictorialDataService } from "../../services/pictorial-data.service"
import { Pagination } from "src/app/shared/types/pagination.type"
import { TransactionService } from "src/app/payment/services/transaction.service"
import { Transaction } from "src/app/payment/types/transaction.type"
import { Pictorial } from "src/app/board/types/pictorial.type"
import { PostDataService } from "src/app/board/services/post-data.service"
import { CountryRestrictionModalService } from "src/app/shared/services/country-restiction-modal.service"
import { Post } from "src/app/board/types/post.type"

@Component({
  selector: "app-sendme-contents-detail-storage",
  templateUrl: "./sendme-contents-detail-storage.component.html",
  styleUrls: ["./sendme-contents-detail-storage.component.scss"],
})
export class SendmeContentsDetailStorageComponent implements OnInit, OnDestroy {
  _messageType = MessageType
  _paymentStatus = PaymentStatus
  _sortOrder = SortOrder

  pageType: "sendme" | "memberme"
  contentType: MessageType
  paymentStatus: PaymentStatus
  page: number
  totalItemCount: number

  nextUrl: string

  isLoaded: boolean = true
  isFetchingIp: boolean = false

  contents: Message[] = []
  search: GetMessageParams = {
    page: 1,
  }

  routerSubscription: any
  routeSubscription: any

  constructor(
    private messageService: PostMessagesService,
    private transactionService: TransactionService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private pictorialService: PictorialDataService,
    private postService: PostDataService,
    private countryRestrictionService: CountryRestrictionModalService,
  ) {}

  ngOnInit() {
    this.pageType = this.route.snapshot.params.type == "sendme" ? "sendme" : "memberme"
    this.routerSubscription = this.route.paramMap.subscribe((params) => {
      this.routeSubscription = this.routeSubscription = this.route.queryParamMap.subscribe(
        (queryParams) => {
          this.init(params, queryParams)
        },
      )
    })

    this.countryRestrictionService.isFetchingIpEvent.subscribe((isFetchingIp: boolean) => {
      this.isFetchingIp = isFetchingIp
    })
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe()
    this.routerSubscription.unsubscribe()
  }

  init(params: ParamMap, queryParams: ParamMap) {
    this.isLoaded = true
    this.contents = []
    this.contentType = (queryParams.get("content") as MessageType) || MessageType.pictorial
    this.setPaymentStatus(queryParams.get("payment") || PaymentStatus.all)
    this.nextUrl = null

    this.search = {
      message_type: this.contentType,
      page: 1,
      user: this.userService.getUser().id,
      page_size: 24,
    }

    if (this.paymentStatus == PaymentStatus.paid) {
      this.search.is_paid = true
    } else {
      delete this.search.is_paid
    }

    this.getMessages()
  }

  setPaymentStatus(status: string) {
    switch (status) {
      case PaymentStatus.free:
        this.paymentStatus = PaymentStatus.free
        break
      case PaymentStatus.paid:
        this.paymentStatus = PaymentStatus.paid
        break
      case PaymentStatus.required:
        this.paymentStatus = PaymentStatus.required
        break
      case PaymentStatus.all:
        this.paymentStatus = PaymentStatus.all
        break
    }
  }

  onFilterChange(event: FilterItem) {
    if (this.isLoaded) {
      switch (event.label) {
        case FilterLabel.payment:
          this.setPaymentStatus(event.value)
          break
        case FilterLabel.contentType:
          this.contents = []
          this.page = 1
          this.contentType = event.value as MessageType
          this.setPaymentStatus(PaymentStatus.all)
      }

      this.router.navigate(["."], {
        relativeTo: this.route,
        queryParams: {
          payment: this.paymentStatus,
          content: this.contentType,
        },
        queryParamsHandling: "merge",
        replaceUrl: true,
      })
    }
  }

  getMessages() {
    if (this.isLoaded) {
      this.isLoaded = false

      if (this.pageType == "sendme") {
        this.getSendmeMessages()
      } else {
        this.getMembermeMessages()
      }
    }
  }

  private getSendmeMessages() {
    if (!this.nextUrl && this.search.page !== 1) {
      this.isLoaded = true
      return
    }

    const getMessage = this.nextUrl
      ? this.messageService.getNextMessageItems.bind(this.messageService)
      : this.messageService.getMessageItems.bind(this.messageService)

    let searchStrOrParam: string | GetMessageParams

    if (this.nextUrl) {
      searchStrOrParam = this.nextUrl
    } else if (this.search && this.search.page == 1) {
      searchStrOrParam = this.search
    }

    getMessage(searchStrOrParam).subscribe((resp: Pagination<Message>) => {
      this.setContentsFormResp(resp)
    })
  }

  private getMembermeMessages() {
    if (!this.nextUrl && this.search.page !== 1) {
      this.isLoaded = true
      return
    }

    let selectedTransactionMethod: any
    let searchStrOrParam: string | GetMessageParams
    const getTransaction =
      // this.contentType == MessageType.pictorial
      this.transactionService.getPictorialPayment.bind(this.transactionService)
    // : this.transactionService.getPostPayment.bind(this.transactionService)

    const getNextTransaction = this.transactionService.getListPaginatedByUrl.bind(
      this.transactionService,
    )

    if (this.nextUrl) {
      searchStrOrParam = this.nextUrl
      selectedTransactionMethod = getNextTransaction
    } else if (this.search && this.search.page == 1) {
      searchStrOrParam = this.search
      selectedTransactionMethod = getTransaction
    }

    selectedTransactionMethod(searchStrOrParam).subscribe((resp: Pagination<Transaction>) => {
      // if (this.contentType == MessageType.pictorial) {
      const copied = this.parsePictorialPayments(resp)

      this.setContentsFormResp(copied)
      return
      // }

      // this.isLoaded = true
      // this.search.page += 1
    })
  }

  private setContentsFormResp(resp: Pagination<Message>) {
    this.contents = this.contents.concat(resp.results)
    this.search.page += 1

    this.nextUrl = null

    if (resp.next) {
      this.nextUrl = resp.next.replace("http", "https")
    }

    if (this.contentType == MessageType.pictorial) {
      const ids = resp.results.map((message) => {
        return message.post.pictorial_id
      })
      this.getPictorials(ids, resp.results)
    } else {
      resp.results.forEach((message) => {
        message.post = this.postService.parsePost(message.post) as Post
      })
    }

    this.isLoaded = true
  }

  private parsePictorialPayments(resp: Pagination<Transaction>) {
    const messages = resp.results.map((item) => {
      return {
        id: item.id,
        created: item.created,

        is_paid: item.status === "paid",
        post: {
          pictorial_id: item.pictorial_id,
        },
        message_type: MessageType.pictorial,
      } as Message
    })

    const copied: Pagination<Message> = _.cloneDeep(resp)

    copied.results = messages

    return copied
  }

  getPictorials(ids: string[], messages: Message[]) {
    this.pictorialService.getListByIds(ids).subscribe((res) => {
      messages.forEach((message) => {
        message.pictorial = res.find((pictorial) => {
          return pictorial && pictorial.id == message.post.pictorial_id
        })

        if (!message.pictorial) {
          message.pictorial = {} as Pictorial
        }
      })
      this.isLoaded = true
    })
  }
}
