import { Component, EventEmitter, NgModule, Output } from "@angular/core"
import { ComponentPageTitle } from "../page-title/page-title"
import { NavigationFocusModule } from "../../../shared/navigation-focus/navigation-focus"
import { MatButtonModule, MatIconModule } from "@angular/material"

@Component({
  selector: "app-component-page-header",
  templateUrl: "./component-page-header.component.html",
  styleUrls: ["./component-page-header.component.scss"],
})
export class ComponentPageHeaderComponent {
  constructor(public _componentPageTitle: ComponentPageTitle) {}

  @Output() toggleSidenav = new EventEmitter<void>()

  getTitle() {
    return this._componentPageTitle.title
  }
  getSubTitle() {
    return this._componentPageTitle.subtitle
  }
}

@NgModule({
  imports: [MatButtonModule, MatIconModule, NavigationFocusModule],
  exports: [ComponentPageHeaderComponent],
  declarations: [ComponentPageHeaderComponent],
  providers: [ComponentPageTitle],
})
export class ComponentHeaderModule {}
