import { Component, EventEmitter, OnInit } from "@angular/core"

@Component({
  selector: "app-creator-create-page",
  templateUrl: "./creator-create-page.component.html",
  styleUrls: ["./creator-create-page.component.scss"],
})
export class CreatorCreatePageComponent implements OnInit {
  constructor() {}

  submitEventEmitter: EventEmitter<undefined> = new EventEmitter()

  ngOnInit() {}
}
