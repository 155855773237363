import { Component, Input, OnChanges, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { NgbModal } from "@ng-bootstrap/ng-bootstrap"
import { AuthService } from "src/app/core/services/auth.service"
import { SliderService } from "src/app/shared/services/slider.service"
import { Contest } from "../../types"
import { CountryRestrictionModalService } from "src/app/shared/services/country-restiction-modal.service"

@Component({
  selector: "app-carousel-item",
  templateUrl: "./carousel-item.component.html",
  styleUrls: ["./carousel-item.component.scss"],
})
export class CarouselItemComponent implements OnChanges, OnInit {
  @Input() data: any
  @Input() keyword: string

  isCreator = false
  isAdultLock = false
  contest: Contest

  constructor(
    private authService: AuthService,
    private sliderService: SliderService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private restrictionService: CountryRestrictionModalService,
  ) {
    this.contest = this.route.snapshot.data.contest
  }

  ngOnInit() {
    this.isCreator = typeof this.data.is_verified == "boolean"
    this.sliderService.emitChangeEvent(this.keyword)
    this.setAdultLock()

    this.authService.getChangeEmitter().subscribe(() => {
      this.setAdultLock()
    })
  }

  setAdultLock() {
    this.isAdultLock = this.data.adult && !this.authService.isAdult()
  }

  ngOnChanges(changes) {
    if (changes.data) {
      this.isCreator = typeof changes.data.currentValue.is_verified == "boolean"
    }
  }

  onClick() {
    if (this.isAdultLock) {
      this.authService.verifyAdult()
    } else if (this.isCreator) {
      this.router.navigate([this.data.url])
    } else if (this.data.adult && this.restrictionService.isRestricted()) {
      this.restrictionService.openRestrictModal()
    } else {
      this.router.navigate(["pictorials", this.data.id])
    }
  }
}
