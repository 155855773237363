import { Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core"
import { FormControl } from "@angular/forms"
import { MAT_DIALOG_DATA, MatAutocompleteTrigger, MatDialog, MatDialogRef } from "@angular/material"
import { TranslateService } from "@ngx-translate/core"
import { Observable } from "rxjs"
import { startWith, map, switchMap, catchError } from "rxjs/operators"
import { Form } from "src/app/shared/model/form"
import { CountryCodeType, CountryService } from "src/app/shared/services/country.service"
import { FormService } from "src/app/shared/services/form.service"
import countryCode from "src/assets/i18n/countryCode"
import { BillingKeyService } from "../../services/billing-key.service"
import { StripeMethodData } from "./stripe-method-dialog.type"

interface CountryCode {
  code: string
  name: string
}

@Component({
  selector: "app-stripe-method-dialog",
  templateUrl: "./stripe-method-dialog.component.html",
  styleUrls: ["./stripe-method-dialog.component.scss"],
})
export class StripeMethodDialogComponent implements OnInit {
  @ViewChild(MatAutocompleteTrigger, {
    static: false,
  })
  autocomplete: MatAutocompleteTrigger
  @ViewChild("country", {
    static: false,
  })
  autocompleteInput: ElementRef
  countryControl = new FormControl("")
  zipControl = new FormControl("")
  countryCode: CountryCodeType[] = countryCode
  filteredCountryCode: Observable<CountryCode[]>

  form: Form
  url: string
  creatorId: number
  tierId: number

  isChangeMembership = false
  isSendme: boolean = false
  isSaving = false
  isVisible = false
  submitDisabled = true
  amount: number
  pictorialId: string

  // ngModel for billing inputs
  cardNumber = ["", "", "", ""]
  expDate = ["", ""]

  // birthDate = ['','',''];
  cvc = ""
  cardError = false
  expError = false
  birthError = false
  cvcError = false
  isReqError = false
  countryError = false
  zipError = false

  isAddressUploaded = false
  isStripeSetUpped: boolean
  stripePaymentMethod

  constructor(
    public dialogRef: MatDialogRef<StripeMethodDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: StripeMethodData,
    private billingKeyService: BillingKeyService,
    private formService: FormService,
    private translate: TranslateService,
    private countryService: CountryService,
  ) {
    this.getCountry()
  }

  ngOnInit() {
    this.filteredCountryCode = this.countryControl.valueChanges.pipe(
      startWith(""),
      map((country: string | CountryCode) => {
        this.isSubmitPossible()
        if (!country) {
          return this.countryCode
        }
        if (typeof country == "string") {
          return this.countryService.filterByName(country)
        }

        return this.countryService.filterByName(country.name)
      }),
    )

    this.zipControl.valueChanges.subscribe(() => {
      this.isSubmitPossible()
    })

    this.getCountry()
    this.billingKeyService
      .getMethod()
      .subscribe((res: any) => {
        if (res.payment_method_id) {
          this.stripePaymentMethod = res
        }
      })
      .add(() => {
        this.creatorId = this.data.creatorId
        this.tierId = this.data.tierId
        this.url = this.tierId ? "transactions/?tier=" + this.tierId : "transactions/pictorial/"
        this.pictorialId = this.data.pictorialId
      })
      .add(() =>
        this.formService.getFormData(this.url).subscribe((form) => {
          form.formGroup.addControl("use_billing_key", new FormControl())
          if (this.stripePaymentMethod && this.stripePaymentMethod.card_number) {
            form.fields.use_billing_key = {
              type: "boolean",
              widget: { name: "checkbox" },
              name: "use_billing_key",
            }

            this.translate.get("PAYMENT_MODAL.CURRENT_METHOD").subscribe((translate) => {
              form.fields.use_billing_key.label = translate.concat(
                ": ",
                this.stripePaymentMethod.card_number,
              )
            })
            if (this.stripePaymentMethod && this.countryControl.value) {
              form.formGroup.get("use_billing_key").setValue(true)
              this.isVisible = false
            }
            this.isSubmitPossible()
          }

          form.formGroup.get("use_billing_key").valueChanges.subscribe((value) => {
            this.isVisible = !value
            this.isSubmitPossible()

            if (value && this.countryControl.enabled) {
              this.countryControl.disable()
              this.zipControl.disable()
            } else if (!value && !this.countryControl.disabled) {
              this.countryControl.enable()
              this.zipControl.enable()
            }
          })

          this.form = form
        }),
      )

    this.billingKeyService.getOldPaymentMembership().subscribe((res: any) => {
      if (res.count == 0) {
        this.isStripeSetUpped = true
      } else if (res.count > 0) {
        this.isStripeSetUpped = false
      }
    })
  }

  getCountry() {
    this.countryService.get().subscribe((countryData: any) => {
      if (countryData && countryData.country) {
        this.isAddressUploaded = true

        const { country, postal_code: postalCode } =
          typeof countryData === "string" ? JSON.parse(countryData) : countryData
        this.countryControl.setValue(this.countryCode.find((ele) => ele.code == country))
        this.zipControl.setValue(postalCode)
      } else {
        this.isVisible = true
      }
    })
  }

  closeAutoComplete() {
    if (this.autocomplete && this.autocomplete.panelOpen) {
      this.autocomplete.closePanel()
    }
  }

  toggleVisible(event, flag) {
    event.stopPropagation()
    event.preventDefault()
    this.form.formGroup.get("use_billing_key").setValue(flag)
  }

  onSubmit() {
    this.form.clearErrors()
    this.setAllErrorToFalse()

    if (this.form.formGroup.get("use_billing_key").value) {
      this.toNextModal()
    } else if (!this.submitDisabled) {
      this.formatBillingInfo()
    }
  }

  isCardNumValid() {
    return this.cardNumber.every((num, idx, origin) => {
      if (idx == origin.length - 1) {
        return 4 <= num.length && num.length <= 5
      }
      return num.length == 4
    })
  }

  isCardExpValid() {
    return this.expDate.every((num, idx, origin) => {
      if (idx != origin.length - 1) {
        return 4 == num.length
      }
      return num.length == 2
    })
  }

  onCountryInputPressed(event) {
    event.stopPropagation()

    if (event.code == "Enter") {
      event.preventDefault()

      if (this.autocomplete.panelOpen) {
        return
      }

      if (!this.submitDisabled && !this.isSaving) {
        this.onSubmit()
      }
      return
    }
  }

  onInputKeyPressed(event) {
    const { target } = event

    if (event.code == "Enter") {
      event.stopPropagation()
      event.preventDefault()
      if (!this.submitDisabled && !this.isSaving) {
        this.onSubmit()
      }
      return
    }

    let value = target.value.replace(/[^\d]/g, "")

    const id = target.id

    switch (true) {
      case /^card-*/.test(id): {
        const idx = id.split("-")[1]
        value = value.substring(0, idx != 4 ? 4 : 5)
        this.cardNumber[idx - 1] = value
        break
      }
      case /^expiry-*/.test(id): {
        const idx = id.split("-")[1]
        value = value.substring(0, idx != 1 ? 3 : 4)
        this.expDate[idx - 1] = value
        break
      }
      case /^cvc$/.test(id):
        value = value.substring(0, 3)
        this.cvc = value
        break
    }
    target.value = value
    this.isSubmitPossible()
  }

  isCVCValid() {
    return this.cvc.length === 3
  }

  isSubmitPossible() {
    const cvc = this.isCVCValid()
    const cardNum = this.isCardNumValid()
    const exp = this.isCardExpValid()
    const useBillingKey = this.form ? this.form.formGroup.get("use_billing_key").value : false

    this.submitDisabled = !(
      (cardNum &&
        exp &&
        cvc &&
        (this.isStripeSetUpped || (!this.isStripeSetUpped && this.isCountryDataValid()))) ||
      useBillingKey
    )
  }

  // change later so we use 'app-dynamic-field-component' properly
  formatBillingInfo() {
    const cardNum = `${this.cardNumber[0]}-${this.cardNumber[1]}-${this.cardNumber[2]}-${this.cardNumber[3]}`
    const exp = `${this.expDate[0]}-${this.expDate[1]}`
    const useBillingKey = this.form.formGroup.get("use_billing_key").value

    // set form value
    this.form.formGroup.value.card_number = cardNum === "---" ? "" : cardNum
    this.form.formGroup.value.expiry = exp === "-" ? "" : exp
    this.form.formGroup.value.cvc = this.cvc

    if (this.isStripeSetUpped == false) {
      this.isSaving = true
      this.billingKeyService
        .create(this.form)
        .pipe(
          switchMap((res) => this.submitCountry()),
          switchMap((res) => {
            this.toNextModal()
            return new Observable()
          }),
          catchError((error) => {
            this.isSaving = false
            this.showErrorMsg(error)
            let errorText = error.error

            if (error.error && error.error.includes && error.error.includes("h1")) {
              errorText = "Invalid Country"
            }
            this.form.setErrors(
              {
                error:
                  typeof errorText !== "object"
                    ? {
                        non_field_errors: [errorText || "Invalid Request"],
                      }
                    : errorText,
              },

              false,
            )
            return new Observable()
          }),
        )
        .subscribe(() => {
          this.isSaving = false
        })
    } else {
      if (!useBillingKey) {
        this.isSaving = true
        return this.billingKeyService
          .create(this.form)
          .pipe(
            switchMap((res) => this.submitCountry()),
            switchMap((res) => {
              this.toNextModal()
              return new Observable()
            }),
            catchError((error) => {
              this.isSaving = false
              this.showErrorMsg(error)
              let errorText = error.error

              if (error.error && error.error.includes && error.error.includes("h1")) {
                errorText = "Invalid Country"
              }
              this.form.setErrors(
                {
                  error:
                    typeof errorText !== "object"
                      ? {
                          non_field_errors: [errorText || "Invalid Request"],
                        }
                      : errorText,
                },

                false,
              )
              return new Observable()
            }),
          )
          .subscribe(() => (this.isSaving = false))
      }
      this.toNextModal()
    }
  }

  submitCountry() {
    const code = this.countryControl.value.code
    if (code != "US" && code != "CA") {
      this.zipControl.setValue(undefined)
    }
    return this.countryService.set(code, this.zipControl.value)
  }

  toNextModal() {
    this.dialogRef.close({
      creatorId: this.creatorId,
      pictorialId: this.pictorialId,
      isChangeMembership: this.isChangeMembership,
      tierId: this.tierId,
    })
  }

  isCountryDataValid() {
    const countryData = this.countryControl.value
    const postData = this.zipControl.value

    if (
      !countryData ||
      !countryData.code ||
      !countryCode.find((country) => country.code == countryData.code)
    ) {
      this.countryError = true
      this.countryControl.setErrors({})
      return false
    }
    if ((countryData.code == "CA" || countryData.code == "US") && !postData) {
      this.zipError = true
      this.zipControl.setErrors({})
      return false
    }

    return true
  }

  showErrorMsg(err) {
    if (err.error) {
      if (err.error.card_number) {
        this.cardError = true
      }
      if (err.error.expiry) {
        this.expError = true
      }
      if (err.error.birth) {
        this.birthError = true
      }
      if (err.error.cvc) {
        this.cvcError = true
      }
    }
  }

  setAllErrorToFalse() {
    this.cardError = false
    this.expError = false
    this.birthError = false
    this.cvcError = false
    this.countryError = false
    this.zipError = false
  }

  close() {
    this.dialogRef.close()
  }
}
