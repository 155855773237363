import { Component, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { FormService } from "src/app/shared/services/form.service"
import { Form } from "src/app/shared/model/form"
import { GoalService } from "../../services/goal.service"
import { MessagingComponent } from "src/app/shared/components/messaging/messaging.component"
import { SnackbarService } from "src/app/shared/services/snackbar.service"
import { TranslateService } from "@ngx-translate/core"
import { MatDialog } from "@angular/material"
import * as moment from "moment"

const CATEGORY_ACTIVATED = "activated"

@Component({
  selector: "app-goal-form-page",
  templateUrl: "./goal-form-page.component.html",
  styleUrls: ["./goal-form-page.component.scss"],
})
export class GoalFormPageComponent extends MessagingComponent implements OnInit {
  form: Form
  year: number
  month: number
  category: string
  goalId: number

  engMonth
  monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private goalService: GoalService,
    private formService: FormService,
    protected snackbarService: SnackbarService,
    protected translateService: TranslateService,
    protected dialog: MatDialog,
  ) {
    super(snackbarService, translateService, dialog)
    moment.locale(translateService.currentLang)
    route.params.subscribe((params) => this.initParams(params))
  }

  ngOnInit() {}

  moveMonth(offset: number) {
    const date = new Date(this.form.getValue("year"), this.form.getValue("month") - 1, 1)
    date.setMonth(date.getMonth() + offset)
    this.form.formGroup.patchValue({
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      category: CATEGORY_ACTIVATED,
    })
  }

  initParams(params) {
    this.year = Number(params.year)
    this.month = Number(params.month)
    this.engMonth = this.monthNames[this.month - 1]
    this.loadGoal(params)
  }

  initForm() {
    let url = "goals/"

    if (this.goalId) {
      url += this.goalId + "/"
    }

    this.form = null

    this.formService.getFormData(url).subscribe((formData) => {
      this.form = formData
      const { fields } = formData

      if (fields.category && Array.isArray(fields.category.choices)) {
        fields.category.choices.forEach((choice) => {
          switch (choice.value) {
            case "money_based":
              choice.display_name = this.translateService.instant(
                "CREATOR_GOAL_DETAIL.BASE_GOAL_PRICE",
              )
              break
            case "fan_based":
              choice.display_name = this.translateService.instant(
                "CREATOR_GOAL_DETAIL.BASE_GOAL_MEMBER",
              )
              break
          }
        })
      }
      this.form.formGroup.patchValue({
        year: this.year,
        month: this.month,
        category: this.category,
      })
      this.onFormChanges()
    })
  }
  loadGoal(params: any): void {
    this.goalService.get("find", params).subscribe(
      (res) => {
        this.goalId = Number(res["id"])
        this.category = res["category"]
        this.initForm()
      },
      (error) => {
        this.goalId = null
        this.initForm()
      },
    )
  }

  onFormChanges(): void {
    this.form.formGroup.valueChanges.subscribe((val) => {
      if (this.year !== val.year || this.month !== val.month) {
        this.router.navigate(["/creators/update/goals", val.year, val.month, CATEGORY_ACTIVATED])
      }
      if (this.category !== val.category) {
        this.category = val.category
        this.loadGoal({
          year: val.year,
          month: val.month,
          category: val.category,
        })
      }
    })
  }

  onSubmit(): void {
    if (this.goalId) {
      this.goalService.update(this.goalId, this.form).subscribe(
        () => this.messageSuccess(this.translateService.instant("ALERT_CONFIRM.SAVED")),
        (res) => {
          if (res.error && res.error.category) {
            this.messageDanger(this.translateService.instant("GOALS_SLIDER.MESSAGE"))
          }
        },
      )
    } else {
      this.goalService.create(this.form).subscribe(
        () => this.messageSuccess(this.translateService.instant("ALERT_CONFIRM.SAVED")),
        (res) => {
          if (res.error && res.error.category) {
            this.messageDanger(this.translateService.instant("GOALS_SLIDER.MESSAGE"))
          }
        },
      )
    }
  }

  time(s: string) {
    return moment(s, "M").format("yyyy MM")
  }
}
