import { Injectable } from "@angular/core"
import { BehaviorSubject } from "rxjs"
import { publishReplay, refCount, switchMap, tap } from "rxjs/operators"
import { RecommendedCreatorParams } from "../../../creator/services/creator.service"
import { RecommendedService } from "src/app/board/services/recommended.service"
import { ModelItem } from "src/app/creator/pages/creator-pictorial-model-management-page/creator-pictorial-model-management-page.component"

const defaultFilter: RecommendedCreatorParams = {
  sort: "order",
}

@Injectable({
  providedIn: "root",
})
export class RecommendedModelService {
  constructor(private recommendedService: RecommendedService) {}

  private refresh$ = new BehaviorSubject(undefined)
  models: ModelItem[] = []
  models$ = this.refresh$.pipe(
    switchMap((filter: RecommendedCreatorParams) => {
      return this.getPaginatedResults(filter).pipe(
        tap((response) => {
          this.models = response
        }),
      )
    }),
    publishReplay(1),
    refCount(),
  )

  fetch(filter: RecommendedCreatorParams = defaultFilter) {
    this.refresh$.next(filter)
  }

  private getPaginatedResults(filter: RecommendedCreatorParams) {
    return this.recommendedService.getModelList({
      count: 24,
      sort: "order",
      ...filter,
    })
  }
}
