import { Component, Input, OnInit } from "@angular/core"
import { UserService } from "src/app/account/services/user.service"
import { AuthService } from "src/app/core/services/auth.service"

@Component({
  selector: "app-content-blur-wrapper",
  templateUrl: "./content-blur-wrapper.component.html",
  styleUrls: ["./content-blur-wrapper.component.scss"],
})
export class ContentBlurWrapperComponent implements OnInit {
  @Input() pictorial
  @Input() isAdultCreator = false

  isMine: boolean
  isBlur: boolean

  navigate = []

  constructor(private userService: UserService, private authService: AuthService) {}

  ngOnInit() {
    const me = this.userService.getUser()
    this.isMine = me && this.pictorial.creatorId == me.creator

    this.setBlur()

    this.authService.getChangeEmitter().subscribe(() => {
      this.setBlur()
    })
  }

  setBlur() {
    this.isBlur =
      (!this.isMine && this.isAdultCreator && !this.authService.isAdult()) ||
      (this.pictorial.adult && !this.authService.isAdult())
  }

  onClick() {
    this.authService.verifyAdult()
  }
}
