import { Component, OnInit } from "@angular/core"
import { PostMessageDataService } from "../../services/post-message-data.service"

import { switchMap } from "rxjs/operators"
import { PictorialDataService } from "../../services/pictorial-data.service"

import { UserService } from "src/app/account/services/user.service"
import { Message, MessageType } from "../../types/message.type"

import { TransactionService } from "src/app/payment/services/transaction.service"
import { CountryRestrictionModalService } from "src/app/shared/services/country-restiction-modal.service"

@Component({
  selector: "app-sendme-contents-storage",
  templateUrl: "./sendme-contents-storage.component.html",
  styleUrls: ["./sendme-contents-storage.component.scss"],
})
export class SendmeContentsStorageComponent implements OnInit {
  membermeContent: Message[]
  sendmeContent: Message[]

  messageType = MessageType

  isFetchingIp: boolean = false

  constructor(
    private dataService: PostMessageDataService,
    private transactionService: TransactionService,
    protected pictorialDataService: PictorialDataService,
    private userService: UserService,
    private countryRestrictionService: CountryRestrictionModalService,
  ) {}

  ngOnInit() {
    this.transactionService
      .getPictorialPayment({
        page_size: 4,
        ordering: "created",
      })
      .subscribe(async (resp) => {
        const pictorialPayments = resp.results
        const pictorialIds = pictorialPayments.map((message) => message.pictorial_id)
        const pictorials = await this.pictorialDataService.getPictorialsByIds(pictorialIds)

        this.membermeContent = pictorialPayments.map((item) => {
          return {
            id: item.id,
            created: item.created,
            pictorial: pictorials.find((pictorial) => pictorial.id === item.pictorial_id),
            is_paid: item.status === "paid",
            post: {
              pictorial_id: item.pictorial_id,
            },
            message_type: MessageType.pictorial,
          } as Message
        })
      })

    this.dataService
      .getMessageItems({
        page: 1,
        page_size: 4,
        user: this.userService.getUser().id,
      })
      .pipe(
        switchMap(async (data) => {
          const pictorialMessages = data.results.filter((item) => !!item.post.pictorial_id)
          const pictorialIds = pictorialMessages.map((message) => message.post.pictorial_id)
          const pictorials = await this.pictorialDataService.getPictorialsByIds(pictorialIds)

          pictorialMessages.forEach((item) => {
            item.pictorial = pictorials.find(
              (pictorial) => pictorial && pictorial.id === item.post.pictorial_id,
            )
          })

          return data
        }),
      )
      .subscribe(async (data) => {
        this.sendmeContent = data.results
      })

    this.countryRestrictionService.isFetchingIpEvent.subscribe((isFetching: boolean) => {
      this.isFetchingIp = isFetching
    })
  }
}
