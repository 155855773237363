import { NgModule } from "@angular/core"
import { RouterModule, Routes } from "@angular/router"
import { ModelResolverService } from "src/app/models/services/model-resolver.service"
import { ModelRoutingDataService } from "src/app/models/guards/model-routing-data.service"
import { ModelDetailPageComponent } from "src/app/models/pages/model-detail-page/model-detail-page.component"

const routes: Routes = [
  {
    path: "models",
    canActivateChild: [ModelRoutingDataService],
    children: [
      {
        path: ":id",
        pathMatch: "full",
        component: ModelDetailPageComponent,
      },
    ],
    resolve: {
      data: ModelResolverService,
    },
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ModelsRoutingModule {}
