import { Component, OnInit } from "@angular/core"

import { CookieService } from "angular2-cookie/core"
import { PictorialService } from "src/app/board/services/pictorial.service"
import { UserService } from "src/app/account/services/user.service"
import { map } from "rxjs/operators"
import { Observable } from "rxjs"
import { ActivatedRoute, Router } from "@angular/router"
import { ContestPictorialRegistryService } from "../../services/contest-pictorial-registry.service"

@Component({
  selector: "app-creator-pictorial-list-page",
  templateUrl: "./creator-pictorial-list-page.component.html",
  styleUrls: ["./creator-pictorial-list-page.component.scss"],
})
export class CreatorPictorialListPageComponent implements OnInit {
  paginatedResults$: Observable<any>

  langFlag
  creatorId: any
  creatorUrl: any
  isVerifiedCreator: boolean

  tiers = []

  currentPage
  pageLimit = 10

  constructor(
    private contestRegistryService: ContestPictorialRegistryService,
    private userService: UserService,
    private pictorialService: PictorialService,
    private cookieService: CookieService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.contestRegistryService.init()
    if (this.cookieService.get("lang") !== "KOR") {
      this.langFlag = "ENG"
    } else {
      this.langFlag = "KOR"
    }

    const user = this.userService.getUser()
    this.creatorId = user.creator
    this.creatorUrl = user.creator_url
    this.isVerifiedCreator = user.is_creator_verified

    this.route.queryParamMap.subscribe((queryParams) => {
      const page = queryParams.get("page") || 1
      this.currentPage = page
      this.pagination(page)
    })
  }

  pagination(page) {
    const queryParams = {
      creatorId: this.creatorId,
    }

    const meta = {
      limit: this.pageLimit,
      page,
    }

    this.paginatedResults$ = this.pictorialService.getList(Object.assign(queryParams, meta)).pipe(
      // thumbnail, price 세팅
      map((res: any) => ({
        ...res,
        items: res.items.map((pictorial) => {
          const [cover] = pictorial.__covers__
          if (pictorial.availableSale) {
            return { ...pictorial, src: cover ? cover.file.url : "" }
          }
          return { ...pictorial, src: cover ? cover.file.url : "" }
        }),
      })),
      // pagination 세팅
      map((res: any) => ({
        ...res,
        pages: Array(res.meta.totalPages)
          .fill(null)
          .map((_, index) => {
            const currentPage = index + 1
            return {
              page_number: currentPage,
              is_active: currentPage == res.meta.currentPage,
              link: currentPage,
            }
          }),
      })),
    )
  }

  setQueryParams(page: number) {
    this.router.navigate(["."], { relativeTo: this.route, queryParams: { page } })
  }
}
