import { Injectable } from "@angular/core"
import { BehaviorSubject, Observable } from "rxjs"
import { map, publishReplay, refCount, switchMap, tap } from "rxjs/operators"
import { CampaignPictorial } from "src/app/board/types/pictorial.type"
import { ContestModel } from "../types"
import { ContestPictorialService } from "./campaign-pictorial.service"
import { ModelService } from "./model.service"
import { fromPromise } from "rxjs/internal-compatibility"
import { CreatorService } from "src/app/creator/services/creator.service"
import { Creator, MembershipTier } from "src/app/creator/types"
import { TransactionService } from "src/app/payment/services/transaction.service"
import { Transaction } from "src/app/payment/types/transaction.type"
import { AuthService } from "src/app/core/services/auth.service"
import { TierService } from "../../creator/services/tier.service"
import { PurchasePermission } from "../../creator/pages/creator-photobook-page/type"

interface Filter {
  label: string
  value: string
  filter: Object
  keyword?: string
}

@Injectable({
  providedIn: "root",
})
export class ModelContestDataService {
  private currentCampaignId: number
  private refresh$ = new BehaviorSubject(null)
  private allContentsSubject$ = new BehaviorSubject(1)
  tierIds: number[] = []
  private creatorIds: number[] = []
  private myContentIds: string[] = []
  pageSize = 24
  allContentsPage = 1
  allContents: CampaignPictorial[] = []
  hasMoreAllContents = true
  allContentsLoading = false
  contentsFilters: Filter[] = [
    {
      label: "EVENT",
      value: "EVENT",
      filter: { order: true, salesMethods: PurchasePermission.membershipFree, notThemeSlugs: ["AITM"] },
    },
    { label: "NEW", value: "NEW", filter: { campaignLatest: true, notThemeSlugs: ["AITM"] } },
    { label: "%OFF", value: "%OFF", filter: { promotion: true, notThemeSlugs: ["AITM"] } },
    { label: "RANK", value: "RANK", filter: { voteCount: true, notThemeSlugs: ["AITM"] } },
    { label: "FREE", value: "FREE", filter: { viewable: true, notThemeSlugs: ["AITM"] } },
    { label: "AI", value: "AI", filter: { themeSlugs: ["AITM"] } },
  ]
  searchQuery = ""
  selectedFilter = this.contentsFilters[0]
  allContents$ = this.allContentsSubject$.pipe(
    switchMap((page) => {
      if (page === 1) {
        return this.fetchAllContents()
      } else {
        return this.fetchMoreAllContents()
      }
    }),
    publishReplay(1),
    refCount(),
  )
  modelList$: Observable<ContestModel[]> = this.refresh$.pipe(
    switchMap(() => this.fetchModelList()),
    publishReplay(1),
    refCount(),
  )
  recommendedContents$: Observable<CampaignPictorial[]> = this.refresh$.pipe(
    switchMap(() => this.fetchRecommendedContents()),
    publishReplay(1),
    refCount(),
  )

  constructor(
    private modelService: ModelService,
    private contestPictorialService: ContestPictorialService,
    private creatorService: CreatorService,
    private transactionService: TransactionService,
    private authService: AuthService,
    private tierService: TierService,
  ) {
  }

  init(campaignId: number) {
    if (this.currentCampaignId === campaignId) {
      return
    }
    this.currentCampaignId = campaignId
    this.refresh$.next(1)
    this.allContentsSubject$.next(1)
  }

  loadMoreAllContents() {
    if (this.allContentsLoading || !this.hasMoreAllContents) {
      return
    }
    this.allContentsSubject$.next(this.allContentsPage + 1)
  }

  setFilterAndNext(filter: Filter) {
    this.allContentsLoading = false
    this.myContentIds = []
    this.selectedFilter = filter
    this.allContentsSubject$.next(1)
  }

  setSearchKeywordAndNext(keywords: string) {
    this.allContentsLoading = false
    this.searchQuery = keywords
    this.allContentsSubject$.next(1)
  }

  resetSearchKeyword() {
    this.searchQuery = ""
    this.creatorIds = []
  }

  resetSearchKeywordAndNext() {
    this.resetSearchKeyword()
    this.allContentsLoading = false
    this.allContentsSubject$.next(1)
  }

  private async getPromotionTiers() {
    return (this.tierService.getPromotionTier() as Observable<MembershipTier[]>).toPromise()
  }

  private async searchCreator() {
    return (this.creatorService.search({
      keyword: this.searchQuery,
    }) as Observable<Creator[]>).toPromise()
  }

  private fetchMoreAllContents() {
    return this.fetchContents(this.allContentsPage + 1)
  }

  private fetchAllContents() {
    this.allContentsPage = 1
    this.hasMoreAllContents = true
    return this.fetchContents(1)
  }

  private async chainQuery(page: number) {
    await this.setTierIds()
    await this.setCreatorIds()
    await this.setMyContentsIds()
    return this.contestPictorialService
      .getList({
        campaignId: this.currentCampaignId,
        page: page,
        limit: this.pageSize,
        ...this.selectedFilter.filter,
        ...(this.creatorIds.length && { creatorIds: this.creatorIds.join(",") }),
        ...(this.myContentIds.length && { myContentIds: this.myContentIds.join(",") }),
        ...(this.searchQuery && { search: this.searchQuery }),
        ...(this.selectedFilter.value === "EVENT" && { tierIds: this.tierIds.join(",") }),
      }).toPromise()
  }

  private async setTierIds() {
    if (!this.tierIds.length) {
      const tiers = await this.getPromotionTiers()
      this.tierIds = tiers.map((tier) => tier.id)
    }
  }

  private async setCreatorIds() {
    if (!this.creatorIds.length && this.searchQuery) {
      const creators = await this.searchCreator()
      this.creatorIds = creators.map((creator: Creator) => creator.id)
    }
  }

  private async getMyPaidPictorials() {
    return this.transactionService.getMyContentsWithoutPagination().toPromise()
  }

  private async setMyContentsIds() {
    if (!this.myContentIds.length && this.selectedFilter.filter["viewable"] && this.authService.isLoggedIn()) {
      const contents = await this.getMyPaidPictorials()
      this.myContentIds = contents.map((content: Transaction) => content.pictorial_id)
    }
  }

  private fetchContents(page: number) {
    this.allContentsLoading = true
    const isFirst = page === 1
    const o = fromPromise(this.chainQuery(page))
    return o.pipe(
      tap((res) => {
        this.allContents = isFirst ? res.items : this.allContents.concat(res.items)
        this.allContentsPage = res.meta.currentPage
        this.hasMoreAllContents = res.meta.currentPage < res.meta.totalPages
        this.allContentsLoading = false
      }),
      map((res) => isFirst ? res.items : this.allContents),
    )
  }

  private fetchModelList() {
    if (!this.currentCampaignId) {
      return []
    }
    return this.modelService
      .getModelCampaignItemList({
        campaignId: this.currentCampaignId,
        voteCount: true,
        limit: 9,
        page: 1,
      })
      .pipe(map((res) => res.items))
  }

  private fetchRecommendedContents() {
    if (!this.currentCampaignId) {
      return []
    }
    return fromPromise(this.fetchRecommendedPictorials()).pipe(
      map((res) => res.filter((pictorial) => pictorial)),
    )
  }

  private async fetchRecommendedPictorials() {
    if (!this.currentCampaignId) {
      return []
    }
    const recommendations = await this.contestPictorialService
      .getRecommendPictorialList(this.currentCampaignId)
      .toPromise()
    const pictorialIds = recommendations.map((pic) => pic.pictorial_id)
    const pictorials = await this.contestPictorialService
      .getList({
        campaignId: this.currentCampaignId,
        pictorialIds: pictorialIds.join(","),
      })
      .toPromise()
    return recommendations.map((item) =>
      pictorials.items.find((pic) => pic.pictorialId === item.pictorial_id),
    )
  }

  get hasInit() {
    return !!this.currentCampaignId
  }

  get contestId() {
    return this.currentCampaignId
  }

  get isRanking() {
    return this.selectedFilter.value === "RANK"
  }
}
