import { Component, OnInit } from "@angular/core"

@Component({
  selector: "app-user-update-page",
  templateUrl: "./user-update-page.component.html",
  styleUrls: ["./user-update-page.component.scss"],
})
export class UserUpdatePageComponent implements OnInit {
  navLinks: any[]

  constructor() {}

  ngOnInit() {
    this.navLinks = [
      { label: "NAV_BAR.PROFILE", path: "/users/update/profile" },
      { label: "NAV_BAR.ACCOUNT", path: "/users/update/account" },
      { label: "SETTINGS.NOTIFICATION_SETTING", path: "/users/update/notifications" },
    ]
  }
}
