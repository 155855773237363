import { EventEmitter, Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { Message, MessageType } from "../types/message.type"
import { Label } from "src/app/shared/constants/type"
import { PostMessagesService } from "./post-messages.service"
import { map } from "rxjs/operators"
import { Pictorial } from "src/app/board/types/pictorial.type"
import { PaymentStatus } from "../pages/sendme-contents-detail-storage/types/payment.type"
import { PurchasePermission } from "src/app/creator/pages/creator-photobook-page/type"
import { PictorialDataService } from "./pictorial-data.service"
import { PreviewPostDataService } from "src/app/explore/services/post-data.service"
import { GetMessageParams } from "../types/ajax.type"

import { UserService } from "src/app/account/services/user.service"
import { PostDataService } from "src/app/board/services/post-data.service"

export enum FilterLabel {
  contentType = "content_type",
  payment = "payment",
  ordering = "ordering",
}

@Injectable({
  providedIn: "root",
})
export class StorageDetailDataService {
  private messageCache: Record<string, Message[]> = {}

  private filteredMessages: Message[] = []
  private page = 1
  private pageSize = 24
  private totalItemCount: number
  private totalPage: number

  private filters: Label[] = [
    { label: FilterLabel.contentType, value: "" },
    { label: FilterLabel.payment, value: PaymentStatus.all },
    { label: FilterLabel.ordering, value: "created_at" },
  ]

  loadedChangedEvent: EventEmitter<boolean> = new EventEmitter()

  constructor(
    private messageService: PostMessagesService,
    private pictorialService: PictorialDataService,
    private exploreService: PostDataService,
    private userService: UserService,
  ) {}

  // 메세지 리스트 들을 백엔드에서 가져옴.
  getMessages(page?: number): Observable<Message[]> {
    if (page) {
      this.page = page
    }

    if (this.messageCache[this.page]) {
      return new Observable((subscriber) => {
        subscriber.next(this.messageCache[this.page])
        subscriber.complete()
      })
    }

    if (this.isNoPageLeft()) {
      return new Observable((subscriber) => {
        subscriber.next([])
        subscriber.complete()
      })
    }

    const params = {} as GetMessageParams
    this.filters.forEach((filter) => {
      if (filter.value && filter.label !== FilterLabel.payment) {
        params[filter.label] = filter.value
      }
    })

    if (!params.message_type) {
      return new Observable((subscriber) => {
        subscriber.next([])
        subscriber.complete()
      })
    }

    return this.messageService
      .getMessageItems({
        ...params,
        page: this.page,
        page_size: this.pageSize,
        user: this.userService.getUser().id,
      })
      .pipe(
        map((resp) => {
          this.totalItemCount = resp.count
          this.totalPage = Math.ceil(this.totalItemCount / this.pageSize)

          this.messageCache[this.page] = resp.results

          return resp.results
        }),
      )
  }

  // 메세지 리스트를 리턴. 유저가 직접 호출.
  getItems(page?: number) {
    const [messageFilter, paymentFilter] = this.getCurrentFilterForFilter()
    return this.getMessages(page).pipe(
      map(() => this.getFilteredItems(messageFilter.value, paymentFilter.value)),
    )
  }

  getPictorialItems(ids: string[]): Observable<Pictorial[]> {
    const trimmed = ids.filter((id) => !!id)

    return this.pictorialService.getListByIds(trimmed)
  }

  getFilteredItems(type: MessageType, filter: PaymentStatus) {
    this.loadedChangedEvent.emit(false)
    this.filteredMessages = []

    for (const key in this.messageCache) {
      const messages = this.messageCache[key]
      this.filterItems(messages, type, filter)
    }

    return this.filteredMessages
  }

  async filterItems(messages: Message[], type: MessageType, filter: PaymentStatus) {
    if (type == MessageType.pictorial) {
      const pictorialIdSet = new Set<string>()
      messages.forEach((message) => {
        if (!message.pictorial) {
          pictorialIdSet.add(message.post.pictorial_id)
        }
      })

      if (pictorialIdSet.size > 0) {
        const pictorials = await this.getPictorialItems([...pictorialIdSet.values()]).toPromise()
        messages.forEach((message) => {
          if (!message.pictorial) {
            message.pictorial = pictorials.find(
              (pictorial) => pictorial.id == message.post.pictorial_id,
            )
          }
        })
      }
    }

    messages.forEach((message) => {
      if (type !== MessageType.pictorial) {
        const post = this.exploreService.parsePost(message.post)
        if (post.is_app_only) {
          post.isBlur = true
        }
        this.filteredMessages.push(message)
      } else if (message.pictorial) {
        const isPaid = message.is_paid
        const salesMethod = message.pictorial.salesMethod

        if (filter == PaymentStatus.all) {
          this.filteredMessages.push(message)
        } else if (
          (filter === PaymentStatus.paid && isPaid) ||
          (filter === PaymentStatus.free && salesMethod === PurchasePermission.allFree) ||
          (filter === PaymentStatus.required &&
            (salesMethod === PurchasePermission.allPaid ||
              salesMethod === PurchasePermission.membershipPaid) &&
            !isPaid)
        ) {
          this.filteredMessages.push(message)
        }
      }
      this.loadedChangedEvent.emit(true)
    })
  }

  resetCache(): void {
    this.loadedChangedEvent.emit(false)
    this.messageCache = {}
    this.filteredMessages = []
    this.totalItemCount = undefined
    this.totalPage = undefined
    this.page = 1

    const [messageFilter, paymentFilter] = this.getCurrentFilterForFilter()

    this.getMessages()
      .pipe(map(() => this.getFilteredItems(messageFilter.value, paymentFilter.value)))
      .subscribe()
  }

  setFilter(key: string, value: string): void {
    if (this.filters.every((filter) => filter.label !== key)) {
      return
    }
    const [messageTypeFilter, paymentFilter] = this.getCurrentFilterForFilter()

    if (key == FilterLabel.contentType) {
      if (messageTypeFilter.value !== value) {
        messageTypeFilter.value = value
        this.resetCache()
        return
      }
    }

    this.filters.find((filter) => filter.label == key).value = value
    this.getFilteredItems(messageTypeFilter.value, paymentFilter.value)
  }

  setFilters(filters: Label[]): void {
    const messageTypeWillUpdate = this.getCurrentFilterForFilter()[0]
    this.filters = filters

    const [currentMessageTypeFilter, currentPaymentFilter] = this.getCurrentFilterForFilter()

    if (messageTypeWillUpdate) {
      if (messageTypeWillUpdate.value !== currentMessageTypeFilter.value) {
        messageTypeWillUpdate.value = currentMessageTypeFilter.value

        this.resetCache()
        return
      }
    }

    this.getFilteredItems(currentMessageTypeFilter.value, currentPaymentFilter.value)
  }

  isNoPageLeft(): boolean {
    return (this.page - 1) * this.pageSize >= this.totalItemCount
  }

  getCurrentFilterForFilter() {
    const paymentFilter = this.filters.find((filter) => filter.label == FilterLabel.payment)
    const messageFilter = this.filters.find((filter) => filter.label == FilterLabel.contentType)

    return [messageFilter, paymentFilter]
  }

  get(param: any): any {
    if (!this[param]) {
      throw new Error(`No such param: ${param}`)
    }
    return this[param]
  }

  set(param: any, data: any): void {
    if (!this[param]) {
      throw new Error(`No such param: ${param}`)
    }

    this[param] = data
  }

  loadMore() {
    if (!this.isNoPageLeft()) {
      this.page += 1
      this.loadedChangedEvent.emit(false)

      this.getItems().subscribe()

      return
    }

    this.loadedChangedEvent.emit(true)
  }
}
