import { Injectable } from "@angular/core"
import { environment } from "../../../environments/environment"
import { HttpClient } from "@angular/common/http"
import { Form } from "../../shared/model/form"
import { tap } from "rxjs/operators"

@Injectable({
  providedIn: "root",
})
export class MembershipRewardService {
  constructor(private http: HttpClient) {}

  update(id, form: Form) {
    const data = form.formGroup.value
    return this.http.put(`${environment.apiUrl}membership-rewards/${id}/`, data).pipe(
      tap(
        (res) => form.clearErrors(),
        (error) => form.setErrors(error),
      ),
    )
  }

  updateIsDelivered(membershipReward) {
    return this.http.put(`${environment.apiUrl}membership-rewards/${membershipReward.id}/`, {
      address: membershipReward.address,
      date: membershipReward.date,
      id: membershipReward.id,
      is_delivered: membershipReward.is_delivered,
      name: membershipReward.name,
      shipping_number: membershipReward.shipping_number,
      user: membershipReward.user,
    })
  }

  updateIsDeliveredAll(jsonIds: any) {
    return this.http.put(`${environment.apiUrl}membership-rewards/complete-all/`, jsonIds)
  }

  get(id, data) {
    return this.http.get(`${environment.apiUrl}membership-rewards/${id}/`, { params: data })
  }

  getListPaginated(data) {
    return this.http.get(environment.apiUrl + "membership-rewards/", { params: data })
  }

  getListPaginatedByUrl(url) {
    return this.http.get(url)
  }

  getStatusListPaginated(data) {
    return this.http.get(environment.apiUrl + "membership-rewards-status/", { params: data })
  }

  getStatus(id) {
    return this.http.get(`${environment.apiUrl}membership-rewards-status/${id}/`)
  }

  downloadExcel(url, httpOptions) {
    this.http.get(url, httpOptions).subscribe((fileData: BlobPart) => {
      const blob: any = new Blob([fileData], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
      const link = document.createElement("a")
      if (link.download !== undefined) {
        link.setAttribute("href", URL.createObjectURL(blob))
        link.setAttribute("download", "user.xls")
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    })
  }
}
