import { Component, Input, OnInit } from "@angular/core"
import { ActivatedRoute, Params, Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { Observable } from "rxjs"
import { RewardService } from "src/app/creator/services/reward.service"
import { FormService } from "src/app/shared/services/form.service"
import { UserService } from "src/app/account/services/user.service"
import { Form } from "src/app/shared/model/form"
import { MessagingComponent } from "src/app/shared/components/messaging/messaging.component"
import { SnackbarService } from "src/app/shared/services/snackbar.service"
import { MembershipRewardService } from "../../services/membership-reward.service"
import { ComponentPageTitle } from "../../components/page-title/page-title"
import { MatDialog } from "@angular/material"
import { tap } from "rxjs/operators"
import { Pagination } from "src/app/shared/types/pagination.type"
import { Util } from "src/app/shared/utils/utils"

@Component({
  selector: "app-membership-reward-page-component",
  templateUrl: "./membership-reward-page.component.html",
  styleUrls: ["./membership-reward-page.component.scss"],
})
export class MembershipRewardPageComponent extends MessagingComponent implements OnInit {
  @Input() params: Observable<Params>
  form: Form
  paginatedResult$
  displayedColumns: string[] = ["name", "num_membership_rewards", "switch"]
  isManagedRewardFilter = true
  rewardCounts: any

  page: number = 1
  pageUrl: string

  constructor(
    public _componentPageTitle: ComponentPageTitle,
    private route: ActivatedRoute,
    private router: Router,
    private formService: FormService,
    private userService: UserService,
    private membershipRewardService: MembershipRewardService,
    private rewardService: RewardService,
    protected snackbarService: SnackbarService,
    protected translateService: TranslateService,
    protected dialog: MatDialog,
  ) {
    super(snackbarService, translateService, dialog)
  }

  ngOnInit() {
    this.route.params.subscribe(() => {
      this.formService.getFormData("rewards/").subscribe((formData) => (this.form = formData))
      this._componentPageTitle.setSubtitle(this.translate("MEMBERSHIP_MANAGEMENT.BENEFITS_STATUS"))
      this.getRewardCount()
      this.getPaginatedResult()
    })
  }

  getPaginatedResult() {
    this.page = 1
    this.paginatedResult$ = this.membershipRewardService
      .getStatusListPaginated({
        is_managed_reward: this.isManagedRewardFilter,
      })
      .pipe(
        tap((data: Pagination<any>) => {
          this.pageUrl = data.next || data.previous
        }),
      )
  }

  getPaginatedResultByUrl(pageNo) {
    const url = Util.getPaginationUrl(this.pageUrl, pageNo)

    this.page = pageNo
    this.paginatedResult$ = this.membershipRewardService.getListPaginatedByUrl(url).pipe(
      tap((data: Pagination<any>) => {
        this.pageUrl = data.next || data.previous
      }),
    )
  }

  selectReward(row: any) {
    this.router.navigate(["/contributor/membership-rewards/", row.id])
  }

  filterChanged($event) {
    this.isManagedRewardFilter = !$event
    this.getRewardCount()
    this.getPaginatedResult()
  }

  mngChanged(row: any, $event) {
    row.is_managed_reward = $event.checked
    const data: Form = this.formService.getFormFromFields(this.form.fields, row)
    this.rewardService.update(row.id, data).subscribe(() => {
      this.getRewardCount()
      this.getPaginatedResult()
    })
  }

  getRewardCount() {
    this.rewardService.getRewardCount().subscribe((res) => (this.rewardCounts = res))
  }
}
