import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core"
import { CookieService } from "angular2-cookie"
import { UserService } from "src/app/account/services/user.service"
import { User } from "src/app/account/types/user.type"

import { Creator } from "src/app/creator/types"

import { Message } from "../../types/message.type"
import * as moment from "moment"
import { SnackbarService } from "src/app/shared/services/snackbar.service"
import { Util } from "src/app/shared/utils/utils"

import { Post } from "src/app/board/types/post.type"
import { BlockedMessage } from "../../types/block.type"
import { PostMessageDataService } from "../../services/post-message-data.service"

@Component({
  selector: "app-chat-room-header",
  templateUrl: "./chat-room-header.component.html",
  styleUrls: ["./chat-room-header.component.scss"],
})
export class ChatRoomHeaderComponent implements OnInit {
  @Input() messageCreatedByMe: boolean = false
  @Input() message: Message
  @Input() opponent: Partial<User | Creator>
  @Input() hideBackButton: boolean = false

  _BlockedItem: BlockedMessage
  post: Post

  isKorean: boolean
  createdAt: string

  isBlocking: boolean = false
  isBlocked: boolean = false

  opponentIsCreator: boolean = false

  currentUser: User

  constructor(
    private userService: UserService,
    private dataService: PostMessageDataService,
    private cookieService: CookieService,
    private snackbarService: SnackbarService,
  ) {
    this.isKorean = this.cookieService.get("lang") == "KOR"
    this.currentUser = this.userService.getUser()
  }

  ngOnInit() {
    if (this.message.blocked && this.message.blocked.length > 0) {
      this._BlockedItem = this.message.blocked[0]
      this.isBlocked = true
    }

    this.post = this.message.post

    const locale = this.isKorean ? "ko" : "en"
    const format = this.isKorean ? "yyyy년 MM월 DD일(dd) a hh:mm " : "MMM DD yyyy hh:mm a"

    if (!this.messageCreatedByMe) {
      this.setOpponent()
    }

    if (this.message) {
      this.createdAt = moment(this.message.created).locale(locale).format(format)
    }
  }

  async setOpponent() {
    if (this.message) {
      let opponentId
      if (this.message.user == this.currentUser.id) {
        this.opponent = this.message.post.creator
        this.opponentIsCreator = true

        return
      } else {
        opponentId = this.message.user
      }

      this.opponent = await this.userService.get(opponentId).toPromise()
    }
  }

  blockBtnClick() {
    if (this.isBlocked) {
      this.unblockMessage()
    } else {
      this.blockMessage()
    }
  }

  blockMessage() {
    this.isBlocking = true
    this.dataService.block(this.opponent.id).subscribe(
      (resp) => {
        this._BlockedItem = resp
        this.isBlocking = false
        this.isBlocked = true
      },
      (error) => {
        console.log(error)
        this.isBlocking = false
      },
    )
  }

  unblockMessage() {
    if (!this._BlockedItem) {
      throw new Error("blocked item is null")
    }

    this.isBlocking = true
    this.dataService.unblock(this._BlockedItem.id).subscribe(
      () => {
        this.isBlocking = false
        this.isBlocked = false
        this._BlockedItem = null
      },
      (error) => {
        console.log(error)
        this.isBlocking = false
      },
    )
  }

  goBack() {
    window.history.back()
  }

  showErrorSnackbar(error: any) {
    this.snackbarService.open(Util.getErrorTxt(error.error), "", 2000, "danger-snackbar")
  }

  get shouldRenderUserInfo() {
    return !this.messageCreatedByMe && this.opponent && this.opponentIsCreator
  }
}
