import { Component, OnInit } from "@angular/core"

@Component({
  selector: "app-ageverification-page",
  templateUrl: "./ageverification-page.component.html",
  styleUrls: ["./ageverification-page.component.scss"],
})
export class AgeverificationPageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
