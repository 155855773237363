import { MatButtonModule, MatToolbarModule, MatTabsModule } from "@angular/material"
import { NgModule } from "@angular/core"
import { SharedModule } from "../shared/shared.module"
import { NavComponent } from "./components/nav/nav.component"
import { FooterComponent } from "./components/footer/footer.component"
import { XhrImagePipe, ParameterSecurePipe } from "src/app/core/pipe/image-secure.pipe"

@NgModule({
  imports: [SharedModule, MatButtonModule, MatToolbarModule, MatTabsModule],
  exports: [NavComponent, FooterComponent, XhrImagePipe, ParameterSecurePipe],
  declarations: [NavComponent, FooterComponent, XhrImagePipe, ParameterSecurePipe],
  bootstrap: [],
})
export class CoreModule {}
