import { Component, Input, OnInit, SimpleChanges } from "@angular/core"
import { DomSanitizer } from "@angular/platform-browser"

@Component({
  selector: "app-responsive-embed",
  templateUrl: "./responsive-embed.component.html",
  styleUrls: ["./responsive-embed.component.scss"],
})
export class ResponsiveEmbedComponent implements OnInit {
  @Input() html
  htmlSanitized

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {
    // input is in proper EMBEDED format
    if (this.html.substring(0, 7) === "<iframe") {
      const html = this.html.replace("<iframe", "<iframe class=\"embed-responsive-item\"")
      this.htmlSanitized = this.sanitizer.bypassSecurityTrustHtml(html)
    } else {
      const videoId = this.getYoutubeId(this.html)
      // tslint:disable-next-line: max-line-length
      if (videoId != "error") {
        const embedCode =
          "<iframe class=\"embed-responsive-item\" width=\"949\" height=\"534\" src=\"//www.youtube.com/embed/" +
          videoId +
          "\" frameborder=\"0\" allow=\"accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen></iframe>"

        this.htmlSanitized = this.sanitizer.bypassSecurityTrustHtml(embedCode)
      } else {
        const embedCode =
          "<video class=\"embed-responsive-item\" controls=\"\" src=\"" +
          this.html +
          "\" style=\"background-color: #000000\">Your browser does not support HTML5 video.</video>"

        this.htmlSanitized = this.sanitizer.bypassSecurityTrustHtml(embedCode)
      }
    }
  }

  // get Youtube ID from url
  getYoutubeId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
    const match = url.match(regExp)

    if (match && match[2].length === 11) {
      return match[2]
    } else {
      return "error"
    }
  }

  // make sure html updates appropriately
  // tslint:disable-next-line: use-life-cycle-interface
  ngOnChanges(changes: SimpleChanges) {
    if (changes.html && changes.html.previousValue) {
      let changedHtml = changes.html.currentValue
      changedHtml = this.html.replace("<iframe", "<iframe class=\"embed-responsive-item\"")
      this.htmlSanitized = this.sanitizer.bypassSecurityTrustHtml(changedHtml)
    }
  }
}
