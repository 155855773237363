import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from "@angular/core"
import KeenSlider, { KeenSliderInstance, KeenSliderOptions } from "keen-slider"
import { Breakpoint } from "src/app/shared/constants/constants"
import { PromotionTierService } from "./promotion-tiers.service"
import { NgbModal } from "@ng-bootstrap/ng-bootstrap"
import { PictorialBuyModalComponent } from "../../pages/pictorial-buy-modal/pictorial-buy-modal.component"
import { MatDialog } from "@angular/material"
import { BuyModalData } from "../../pages/pictorial-buy-modal/types/modal-data.type"

@Component({
  selector: "app-payment-success-promotion-tiers",
  templateUrl: "./payment-success-promotion-tiers.component.html",
  styleUrls: ["./payment-success-promotion-tiers.component.scss"],
})
export class PaymentSuccessPromotionTiersComponent implements OnInit, OnChanges {
  @Input() title: string

  sliderOptions: KeenSliderOptions = {
    breakpoints: {
      [`(min-width: ${Breakpoint["sm"]})`]: {
        slides: { perView: 3, spacing: 19 },
      },
      [`(min-width: ${Breakpoint["lg"]})`]: {
        slides: { perView: 4, spacing: 14 },
      },
    },
    slides: { perView: 3, spacing: 19 },
  }

  @ViewChild("sliderRef", { static: false }) sliderRef: ElementRef<HTMLElement>
  slider: KeenSliderInstance = null

  constructor(public dataService: PromotionTierService, private dialog: MatDialog) {}

  ngOnInit() {
    this.dataService.fetch()
    this.dataService.tiers$.subscribe(() => {
      setTimeout(() => {
        this.slider = new KeenSlider(this.sliderRef.nativeElement, this.sliderOptions)
      })
    })
  }

  ngOnChanges() {
    if (this.slider) {
      this.slider.destroy()
      setTimeout(() => {
        this.slider = new KeenSlider(this.sliderRef.nativeElement, this.sliderOptions)
      })
    }
  }

  onTierClick(tier) {
    this.dialog.closeAll()
    this.dialog.open(PictorialBuyModalComponent, {
      data: {
        tier: tier,
      } as BuyModalData,
    })
  }

  get tiers() {
    return this.dataService.tiers.filter((tier) => tier.is_promotion_enable && tier.id != 736)
  }
}
