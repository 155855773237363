import { Component, OnInit } from "@angular/core"
import { CookieService } from "angular2-cookie"

@Component({
  selector: "app-landing-tenth-creator",
  templateUrl: "./landing-tenth-creator.component.html",
  styleUrls: ["./landing-tenth-creator.component.scss"],
})
export class LandingTenthCreatorComponent implements OnInit {
  langFlag
  constructor(private cookieService: CookieService) {
    this.langFlag = this.cookieService.get("lang")
  }

  ngOnInit() {}
}
