import { Component, OnInit } from "@angular/core"

@Component({
  selector: "app-photobook-adult-cover",
  templateUrl: "./photobook-adult-cover.component.html",
  styleUrls: ["./photobook-adult-cover.component.scss"],
})
export class PhotobookAdultCoverComponent implements OnInit {
  blurArray = ["black", "red", "green", "blue", "purple"]
  blurSrc = ""
  constructor() {}

  ngOnInit() {
    this.blurSrc = `assets/img/blur-${
      this.blurArray[Math.trunc(Math.random() * 10) % 5]
    }-vertical.svg`
  }
}
