import { Component, OnInit, Renderer2 } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { ModalPageComponent } from "src/app/shared/components/modal-page/modal-page.component"

@Component({
  selector: "app-social-login-error-page",
  templateUrl: "./social-login-error-page.component.html",
  styleUrls: ["./social-login-error-page.component.scss"],
})
export class SocialLoginErrorPageComponent extends ModalPageComponent implements OnInit {
  TEMPORARY_BLOCKED = "temporary-blocked"
  PERMANENT_BLOCKED = "permanent-blocked"
  SOCIAL_EMPTY_EMAIL = "empty"
  SOCIAL_EMAIL_ALREADY_EXIST = "exist"

  type: string
  langFlag

  constructor(
    private route: ActivatedRoute,
    protected renderer: Renderer2,
    protected router: Router,
  ) {
    super(renderer, router)
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.type = params.get("type")
    })
  }
}
