import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core"
import { Form } from "../../model/form"

import { CookieService } from "angular2-cookie/core"
import { TranslateService } from "@ngx-translate/core"
import { SnackbarService } from "../../services/snackbar.service"
import { MessagingComponent } from "../messaging/messaging.component"
import { MatDialog } from "@angular/material"
import { FileSizePipe } from "../../pipe/file-size.pipe"

@Component({
  selector: "app-dynamic-field",
  templateUrl: "./dynamic-field.component.html",
  styleUrls: ["./dynamic-field.component.scss"],
})
export class DynamicFieldComponent extends MessagingComponent implements OnInit {
  @ViewChild("input", { static: false }) input: ElementRef
  @Output() keyupEvent: any = new EventEmitter()
  @Output() textChangeEvent: any = new EventEmitter()
  @Input() layout?: string
  @Input() fieldName: string
  @Input() form: Form
  @Input() showLabel: boolean
  @Input() showField: boolean
  @Input() isInputGroup: boolean
  @Input() widget: string
  @Input() extraOption: any
  @Input() index: number
  @Input() prefix: string
  @Input() maxFileSize?: number = Infinity
  field: any
  placeholder: string
  formControlName: any
  emptySelectLabel: string
  emptySelectValue: any
  fieldId: string
  koreanFlag: boolean

  //
  langFlag
  timer: any

  constructor(
    private cd: ChangeDetectorRef,
    private cookieService: CookieService,
    private fileSizePipe: FileSizePipe,
    protected translateService: TranslateService,
    protected snackbarService: SnackbarService,
    protected dialog: MatDialog,
  ) {
    super(snackbarService, translateService, dialog)
    this.layout = "groups"
    this.placeholder = ""
    this.emptySelectLabel = ""
    this.emptySelectValue = ""
    this.extraOption = {}
    this.showLabel = true
    this.showField = true
    this.isInputGroup = false
    this.index = null
    this.koreanFlag = true
    translateService.setDefaultLang("en")
  }

  ngOnInit() {
    if (this.fieldName.includes("image")) {
    }
    if (this.cookieService.get("lang") !== "KOR") {
      this.langFlag = "ENG"
    } else {
      this.langFlag = "KOR"
    }

    let field = this.form.fields

    this.fieldName.split(".").forEach((fieldName) => {
      field = field[fieldName]
    })

    if (this.isList()) {
      field = field["child"]
    }

    this.fieldId = this.fieldName

    if (this.prefix) {
      this.fieldId = this.prefix + this.fieldId
    }

    this.field = field

    // translation for Month
    if (field.type === "ChoiceFilter" && field.label === "월") {
      field.choices[0].display_name = "월"
      field.choices[1].display_name = "1월"
      field.choices[2].display_name = "2월"
      field.choices[3].display_name = "3월"
      field.choices[4].display_name = "4월"
      field.choices[5].display_name = "5월"
      field.choices[6].display_name = "6월"
      field.choices[7].display_name = "7월"
      field.choices[8].display_name = "8월"
      field.choices[9].display_name = "9월"
      field.choices[10].display_name = "10월"
      field.choices[11].display_name = "11월"
      field.choices[12].display_name = "12월"

      this.field = field
    }
    if (field.type === "ChoiceFilter" && (field.label === "Month" || field.label === "month")) {
      field.choices[0].display_name = "Month"
      field.choices[1].display_name = "January"
      field.choices[2].display_name = "February"
      field.choices[3].display_name = "March"
      field.choices[4].display_name = "April"
      field.choices[5].display_name = "May"
      field.choices[6].display_name = "June"
      field.choices[7].display_name = "July"
      field.choices[8].display_name = "August"
      field.choices[9].display_name = "September"
      field.choices[10].display_name = "October"
      field.choices[11].display_name = "November"
      field.choices[12].display_name = "December"

      this.field = field
    } else if ((field.type = "email" && field.label === "이메일" && field.name === "email")) {
      if (this.langFlag !== "KOR") {
        field.label = "Email"
        this.field = field
      }
    } else if ((field.type = "string" && field.label === "전화번호")) {
      if (this.langFlag !== "KOR") {
        field.label = "Phone Number"
        field.style.placeholder = "Enter your phone number"
        this.field = field
      }
    } else if ((field.type = "string" && field.label === "닉네임")) {
      if (this.langFlag === "ENG") {
        field.label = "Nickname"
        field.style.placeholder = "Enter your nickname"
        this.field = field
      }
    } else if (field.name === "url_youtube") {
      field.label = `<img src="assets/img/icon/ic-youtube.svg">`
      field.widget.attrs.maxlength = 200
      this.field = field
    } else if (field.name === "url_afreecatv") {
      field.label = `<img src="assets/img/icon/ic-afreecatv.svg">`
      field.widget.attrs.maxlength = 200
      this.field = field
    } else if (field.name === "url_twitch") {
      field.label = `<img src="assets/img/icon/ic-twitch.svg">`
      field.widget.attrs.maxlength = 200
      this.field = field
    } else if (field.name === "url_facebook") {
      field.label = `<img src="assets/img/icon/ic-facebook.svg">`
      field.widget.attrs.maxlength = 200
      this.field = field
    } else if (field.name === "url_tiktok") {
      field.label = `<img src="assets/img/icon/ic-tiktok.svg">`
      field.widget.attrs.maxlength = 200
      this.field = field
    } else if (field.name === "url_instagram") {
      field.label = `<img src="assets/img/icon/ic-instagram.svg">`
      field.widget.attrs.maxlength = 200
      this.field = field
    }

    // TEMPORARY
    // if(this.langFlag == 'KOR') {
    //   this.field = field;
    // }
    // else {
    //   if(field.type == 'ChoiceFilter' && field.label == '연도') {
    //     field.choices[0].display_name = 'Year';

    //     this.field = field;
    //   }
    //   else if(field.type == 'ChoiceFilter' && field.label == '월') {
    //     field.choices[0].display_name = 'Month';
    //     field.choices[1].display_name = 'January';
    //     field.choices[2].display_name = 'February';
    //     field.choices[3].display_name = 'March';
    //     field.choices[4].display_name = 'April';
    //     field.choices[5].display_name = 'May';
    //     field.choices[6].display_name = 'June';
    //     field.choices[7].display_name = 'July';
    //     field.choices[8].display_name = 'August';
    //     field.choices[9].display_name = 'September';
    //     field.choices[10].display_name = 'October';
    //     field.choices[11].display_name = 'November';
    //     field.choices[12].display_name = 'December';

    //     this.field = field;
    //   }
    //   else if(field.type == 'ChoiceFilter' && field.label == '유형별 필터') {
    //     field.choices[0].display_name = 'All Posts';
    //     field.choices[1].display_name = 'Public';
    //     field.choices[2].display_name = 'All Members';

    //     this.field = field;
    //   }
    //   else if(field.type == 'choice' && field.label == '미디어 형식') {
    //     field.choices[0].display_name = 'Post Type';
    //     field.choices[1].display_name = 'Text';
    //     field.choices[2].display_name = 'Image';
    //     field.choices[3].display_name = 'Video';
    //     field.choices[4].display_name = 'Audio';

    //     this.field = field;
    //   }
    //   else if(field.type == 'choice' && field.label == 'Visibility') {
    //     field.choices[0].display_name = 'For Everyone';
    //     field.choices[1].display_name = 'Members Only';

    //     this.field = field;
    //   }
    //   else if(field.type == 'string' && field.label == '제목') {
    //     field.style.placeholder = 'Title';

    //     this.field = field;
    //   }
    //   else if(field.type == 'string' && field.label == '내용') {
    //     field.style.placeholder = 'Write something here...';
    //     //
    //     field.label = 'Message';

    //     this.field = field;
    //   }
    //   else if(field.type == 'choice' && field.label == '대상' && field.name == 'user') {
    //     field.label = 'To';

    //     this.field = field;
    //   }
    //   else if(field.type = 'email' && field.label == '이메일' && field.name == 'email') {
    //     field.label = 'Email';

    //     this.field = field;
    //   }
    //   else if(field.type = 'string' && field.label == '닉네임') {
    //     field.label = 'Nickname';
    //     field.style.placeholder = 'Enter your nickname';

    //     this.field = field;
    //   }
    //   else if(field.type = 'string' && field.label == '주소' && field.name == 'address') {
    //     field.style.placeholder = 'Address to receive goods!';

    //     this.field = field;
    //   }
    //   else if(field.type = 'password' && field.label == '기존 비밀번호') {
    //     field.style.placeholder = 'Current Password';

    //     this.field = field;
    //   }
    //   else if(field.type = 'password' && field.label == '비밀번호' && field.name == 'password1') {
    //     field.style.placeholder = 'New Password';

    //     this.field = field;
    //   }
    //   else if(field.type = 'password' && field.label == '비밀번호 확인' && field.name == 'password2') {
    //     field.style.placeholder = 'Confirm Password';

    //     this.field = field;
    //   }
    //   else {
    //     this.field = field;
    //   }
    // }

    this.formControlName = this.getFormControlName()

    if (this.field["style"] && this.field["style"]["placeholder"]) {
      this.placeholder = this.field.style.placeholder
    }

    this.emptySelectLabel = this.placeholder || this.field["label"]

    if (!this.widget) {
      if (this.field["widget"]["name"]) {
        this.widget = this.field["widget"]["name"]
      } else {
        this.widget = "input"
      }
    }
  }

  click() {
    this.input.nativeElement.click()
  }

  isList() {
    return this.index !== null
  }

  isFile() {
    try {
      return this.field.widget.attrs.type === "file"
    } catch (e) {
      return false
    }
  }

  getFormControlName() {
    if (this.isList()) {
      return this.index
    }
    return this.fieldName
  }

  onFileChange(event) {
    const files = event.target.files

    if (files && files.length) {
      if (Object.keys(files).every((key) => files[key].size <= this.maxFileSize)) {
        const reader = new FileReader()
        const [file] = event.target.files
        reader.readAsDataURL(file)
        reader.onload = () => {
          if (this.isList()) {
            this.form.formGroup.get(this.fieldName).at(this.index).setValue(reader.result)
          } else {
            this.form.formGroup.get(this.fieldName).setValue(reader.result)
          }
          this.cd.markForCheck()
        }
        this.field.errors = null
      } else {
        this.messageDanger(
          this.translateService.instant(
            this.fieldName.includes("image")
              ? "FROALA_EDITOR.IMAGE_UNDER_SIZE"
              : "FROALA_EDITOR.FILE_UNDER_SIZE",
            {
              size: this.fileSizePipe.transform(this.maxFileSize),
            },
          ),
        )
      }
    }
  }

  onTextChange(ev) {
    this.textChangeEvent.emit(ev)
    this.field.errors = null
  }

  onTextAreaChange(ev) {
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      this.textChangeEvent.emit(ev)
      this.field.errors = null
    }, 300)
  }

  onKeyupEnter(ev) {
    if (this.koreanFlag) {
      this.koreanFlag = false
      this.keyupEvent.emit(ev)
      setTimeout(() => {
        this.koreanFlag = true
      }, 200)
    }
  }
}
