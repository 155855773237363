import { Component, OnInit } from "@angular/core"
import { KeenSliderOptions } from "keen-slider"
import { Breakpoint } from "src/app/shared/constants/constants"

@Component({
  selector: "app-landing-recommended-models",
  templateUrl: "./landing-recommended-models.component.html",
  styleUrls: ["./landing-recommended-models.component.scss"],
})
export class LandingRecommendedModelsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  sliderOptions: KeenSliderOptions = {
    breakpoints: {
      [`(min-width: ${Breakpoint["sm"]})`]: {
        slides: { perView: 3, spacing: 11 },
      },
      [`(min-width: ${Breakpoint["md"]})`]: {
        slides: { perView: 4, spacing: 15 },
      },
      [`(min-width: ${Breakpoint["lg"]})`]: {
        slides: { perView: 5, spacing: 22 },
      },
    },
    slides: { perView: 3, spacing: 8 },
  }
}
