import { NgModule } from "@angular/core"
import { RouterModule, Routes } from "@angular/router"
import { CreatorListPageComponent } from "./pages/creator-list-page/creator-list-page.component"
import { CreatorDetailPageComponent } from "./pages/creator-detail-page/creator-detail-page.component"
import { DashboardPageComponent } from "./pages/dashboard-page/dashboard-page.component"
import { CreatorCreatePageComponent } from "./pages/creator-create-page/creator-create-page.component"
import { TierListPageComponent } from "./pages/tier-list-page/tier-list-page.component"
import { CreatorUpdateProfilePageComponent } from "./pages/creator-update-profile-page/creator-update-profile-page.component"
import { CreatorCreatePageGuardService } from "./components/pictorial-detail-card/guards/creator-create-page-guard.service"
import { GoalFormPageComponent } from "./pages/goal-form-page/goal-form-page.component"
import { TierUpdatePageComponent } from "./pages/tier-update-page/tier-update-page.component"
import { RewardFormPageComponent } from "./pages/reward-form-page/reward-form-page.component"
import { MembershipListPageComponent } from "./pages/membership-list-page/membership-list-page.component"
import { MembershipRewardPageComponent } from "./pages/membership-reward-page-component/membership-reward-page.component"
import { MembershipPermissionSettingsComponent } from "./pages/membership-permission-settings/membership-permission-settings.component"
import { MembershipSummaryPageComponent } from "./pages/membership-summary-page/membership-summary-page.component"
import { CreatorContributePageComponent } from "./pages/creator-contribute-page/creator-contribute-page.component"
import { TransactionCreatePageComponent } from "../payment/pages/transaction-create-page/transaction-create-page.component"
import { ArticleListCreatorPageComponent } from "../board/pages/article-list-creator-page/article-list-creator-page.component"
import { AuthGuardService } from "../shared/guards/auth-guard.service"
import { ThankYouPageComponent } from "./components/thank-you-page/thank-you-page.component"
import { CreatorRequiredGuardService } from "../shared/guards/creator-required-guard.service"
import { CreatorDetailPageGuardService } from "./components/pictorial-detail-card/guards/creator-detail-page-guard.service"
import { CreatorUpdateThankYouPageComponent } from "./pages/creator-update-thank-you-page/creator-update-thank-you-page.component"
import { CreatorContributePageGuardService } from "./components/pictorial-detail-card/guards/creator-contribute-page-guard.service"
import { BillingKeyCreatePageComponent } from "../payment/pages/billing-key-create-page/billing-key-create-page.component"
import { BankAccountFormPageComponent } from "../payment/pages/bank-account-form-page/bank-account-form-page.component"
import { ComponentSidenav } from "./pages/component-sidenav/component-sidenav"
import { MembershipBlockUserPageComponent } from "./pages/membership-block-user-page/membership-block-user-page.component"
// tslint:disable-next-line: max-line-length
import { MembershipRewardDetailComponent } from "src/app/creator/pages/membership-reward-detail-component/membership-reward-detail.component"
import { NotFoundPageComponent } from "../website/pages/not-found-page/not-found-page.component"
// tslint:disable-next-line: max-line-length
import { CreatorUpdateEarningVisibilityPageComponent } from "./pages/creator-update-earning-visibility-page/creator-update-earning-visibility-page.component"
import { CreatorPictorialListPageComponent } from "./pages/creator-pictorial-list-page/creator-pictorial-list-page.component"
// tslint:disable-next-line:max-line-length
import { CreatorPictorialCategoryManagementPageComponent } from "./pages/creator-pictorial-category-management-page/creator-pictorial-category-management-page.component"
// tslint:disable-next-line:max-line-length
import { CreatorPictorialModelManagementPageComponent } from "./pages/creator-pictorial-model-management-page/creator-pictorial-model-management-page.component"
import { BuyerListPageComponent } from "./pages/buyer-list-page/buyer-list-page.component"
import { CheckProductPageComponent } from "../payment/pages/check-product-page/check-product-page.component"
import { CreatorSettleInfoPageComponent } from "./pages/creator-settle-info-page/creator-settle-info-page.component"
import { CreatorContentsNavComponent } from "./pages/creator-contents-nav/creator-contents-nav.component"

const routes: Routes = [
  {
    path: "dashboard",
    component: DashboardPageComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "creators",
    component: CreatorListPageComponent,
  },
  {
    path: "creators/contents",
    component: CreatorContentsNavComponent,
    canActivate: [CreatorRequiredGuardService],
    children: [
      {
        path: "",
        pathMatch: "full",
        redirectTo: "analysis",
      },
      {
        path: "analysis",
        component: CreatorPictorialListPageComponent,
      },
      {
        path: "categories",
        component: CreatorPictorialCategoryManagementPageComponent,
      },
      {
        path: "models",
        component: CreatorPictorialModelManagementPageComponent,
      },
    ],
  },
  {
    path: "creators/update",
    component: ComponentSidenav,
    canActivate: [CreatorRequiredGuardService],
    children: [
      {
        path: "",
        redirectTo: "settings",
        pathMatch: "full",
      },
      {
        path: "settings",
        component: CreatorUpdateProfilePageComponent,
      },
      {
        path: "tiers",
        component: TierListPageComponent,
      },
      {
        path: "tiers/:id",
        component: TierUpdatePageComponent,
      },
      {
        path: "goals/:year/:month/:category",
        component: GoalFormPageComponent,
      },
      {
        path: "earning-visibility",
        component: CreatorUpdateEarningVisibilityPageComponent,
      },
      {
        path: "thank-you",
        component: CreatorUpdateThankYouPageComponent,
      },
      {
        path: "bank-account",
        component: CreatorSettleInfoPageComponent,
      },
    ],
  },
  {
    path: "contributor",
    component: ComponentSidenav,
    canActivate: [CreatorRequiredGuardService],
    children: [
      {
        path: "memberships",
        component: MembershipListPageComponent,
      },
      {
        path: "buyers",
        component: BuyerListPageComponent,
      },
      {
        path: "articles",
        component: ArticleListCreatorPageComponent,
      },
      {
        path: "membership-rewards",
        component: MembershipRewardPageComponent,
      },
      {
        path: "membership-rewards/:id",
        component: MembershipRewardDetailComponent,
      },
      {
        path: "membership-permission",
        component: MembershipPermissionSettingsComponent,
      },
      {
        path: "membership-summary",
        component: MembershipSummaryPageComponent,
      },
      {
        path: "membership-block-user",
        component: MembershipBlockUserPageComponent,
      },
    ],
  },
  {
    path: "creators/create",
    component: CreatorCreatePageComponent,
    canActivate: [AuthGuardService, CreatorCreatePageGuardService],
  },
  {
    path: "creators/:id",
    component: CreatorDetailPageComponent,
    canActivate: [AuthGuardService, CreatorDetailPageGuardService],
  },
  {
    path: "creators/:id/contribute",
    component: CreatorContributePageComponent,
    canActivate: [AuthGuardService, CreatorContributePageGuardService],
  },
  {
    path: "reward-form",
    component: RewardFormPageComponent,
    canActivate: [CreatorRequiredGuardService],
    outlet: "popup",
  },
  {
    path: "billing-key",
    component: BillingKeyCreatePageComponent,
    canActivate: [AuthGuardService],
    outlet: "popup",
  },
  {
    path: "reward-form/:id",
    component: RewardFormPageComponent,
    canActivate: [CreatorRequiredGuardService],
    outlet: "popup",
  },
  {
    path: "transaction-create/sendme/:creatorId/:type/:value",
    component: TransactionCreatePageComponent,
    outlet: "popup",
  },
  {
    path: "transaction-create/:creatorId/:type/:value/:isDenyCancel",
    component: TransactionCreatePageComponent,
    outlet: "popup",
  },

  {
    path: "transaction-create/:creatorId/:type/:value/:isDenyCancel/:isChangeMembership",
    component: TransactionCreatePageComponent,
    outlet: "popup",
  },
  {
    path: "checkout-product",
    component: CheckProductPageComponent,
    outlet: "popup",
  },
  {
    path: "checkout-product/:creatorId/:type/:productId",
    component: CheckProductPageComponent,
    outlet: "popup",
  },
  {
    path: "checkout-product/sendme/:creatorId/:type/:productId",
    component: CheckProductPageComponent,
    outlet: "popup",
  },
  {
    path: "checkout-product/:creatorId/:type/:productId/:isChangeMembership",
    component: CheckProductPageComponent,
    outlet: "popup",
  },
  {
    path: "bank-account",
    component: BankAccountFormPageComponent,
    outlet: "popup",
  },
  {
    path: "thank-you/:creatorId",
    component: ThankYouPageComponent,
    outlet: "popup",
  },
  {
    path: "page-not-found",
    component: NotFoundPageComponent,
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CreatorRoutingModule {}
