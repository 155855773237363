import { Component, OnInit, Renderer2 } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { FormService } from "src/app/shared/services/form.service"
import { ModalPageComponent } from "src/app/shared/components/modal-page/modal-page.component"
import { TransactionService } from "../../services/transaction.service"

import { UserService } from "src/app/account/services/user.service"
import { BillingKeyService } from "../../services/billing-key.service"
import { FormControl } from "@angular/forms"
import { Observable } from "rxjs"
import { catchError, startWith, switchMap } from "rxjs/operators"
import { TierService } from "src/app/creator/services/tier.service"
import { PictorialService } from "src/app/board/services/pictorial.service"
import { CreatorService } from "src/app/creator/services/creator.service"
import * as moment from "moment"

import { Location } from "@angular/common"
import { CountryService } from "src/app/shared/services/country.service"
import { CookieService } from "angular2-cookie"
import { CurrencyPipe } from "src/app/shared/pipe/currency.pipe"
import { TranslateService } from "@ngx-translate/core"
import { SnackbarService } from "src/app/shared/services/snackbar.service"
import { Form } from "src/app/shared/model/form"

@Component({
  selector: "app-check-product-page",
  templateUrl: "./check-product-page.component.html",
  styleUrls: ["./check-product-page.component.scss"],
})
export class CheckProductPageComponent extends ModalPageComponent implements OnInit {
  form
  url
  creatorId
  creator
  type
  // 화보 id 혹은 멤버십 티어 id
  value

  submitDisabled = true
  amountUsd: number
  amount: number
  pictorialId: string
  tierId?: string

  termsDisplayed = false

  isReqError = false
  isSendme: boolean = false

  selectAll = false
  checkboxes = [
    { name: "term1", checked: false },
    { name: "term3", checked: false },
    { name: "term4", checked: false },
  ]
  isDenyCancelCreator = false
  isChangeMembership = false

  isStripeSetUpped
  stripePaymentMethod

  isKorean
  isSaving
  isInduced
  isPictorial
  newProducts
  oldProducts

  taxRate

  constructor(
    renderer: Renderer2,
    router: Router,
    private route: ActivatedRoute,
    private transactionService: TransactionService,
    private billingKeyService: BillingKeyService,
    private formService: FormService,
    private userService: UserService,
    private tierService: TierService,
    private pictorialService: PictorialService,
    private creatorService: CreatorService,
    private location: Location,
    private countryService: CountryService,
    private translateService: TranslateService,
    private cookieService: CookieService,
    private currencyPipe: CurrencyPipe,
    private snackbarService: SnackbarService,
  ) {
    super(renderer, router)
  }

  ngOnInit() {
    this.isSendme = window.location.href.includes("sendme")
    this.isKorean = this.cookieService.get("lang") === "KOR"
    this.countryService.get().subscribe(() => {
      this.countryService.calTax()
      this.taxRate = this.countryService.getTax()

      if (this.newProducts) {
        this.newProducts.forEach((product) => {
          this.updateTax(product)
          this.updateUsdAmount(product.priceUsd)
        })
      }

      if (this.oldProducts) {
        this.oldProducts.forEach((product) => this.updateTax(product))
      }
    })

    this.route.paramMap
      .subscribe((params) => {
        this.creatorId = params.get("creatorId")
        this.type = params.get("type")

        this.value = params.get("productId")
        this.isChangeMembership = params.get("isChangeMembership") === "true"
        this.url =
          this.type === "tier" ? "transactions/?tier=" + this.value : "transactions/pictorial/"
        // if (this.creatorId && !isNaN(Number(this.creatorId)) && environment.denyCancelCreatorIds.includes(Number(this.creatorId))) {
        this.isPictorial = this.type == "pictorial"
        if (this.creatorId && !isNaN(Number(this.creatorId)) && params.get("isDenyCancel")) {
          this.isDenyCancelCreator = true
        }

        if (this.type) {
          this.creatorService.get(this.creatorId).subscribe((res: any) => {
            this.creator = res
            if (this.newProducts) {
              this.newProducts[0].creator = res.name
            }
          })
          let service
          if (this.type === "tier") {
            service = this.tierService
          } else {
            service = this.pictorialService
          }

          service.get(this.value).subscribe((res: any) => {
            if (this.creator) {
              res.creator = this.creator.name
            }

            res.createdAt = moment().format(this.isKorean ? "YYYY MM DD" : "MMM DD YYYY")

            this.updateTax(res)
            this.updateUsdAmount(res.priceUsd)

            this.newProducts = [res]
          })
        } else {
          this.isInduced = true
        }
      })
      .add(
        this.billingKeyService
          .getMethod()
          .subscribe((res: any) => {
            if (res.payment_method_id) {
              this.stripePaymentMethod = res
            }
          })
          .add(() =>
            this.formService.getFormData(this.url).subscribe((form) => {
              this.form = form

              if (this.type === "tier") {
                this.form.formGroup.get("creator").setValue(this.creatorId)
                this.form.formGroup.get("tier").setValue(this.value)
                this.form.formGroup.removeControl("amount")
              } else if (this.type === "pictorial") {
                this.route.paramMap.subscribe((queryParams) => {
                  this.pictorialId = queryParams.get("productId")
                })
                this.route.queryParamMap.subscribe((queryParams) => {
                  this.tierId = queryParams.get("tierId")
                })
              }

              form.formGroup.addControl("use_billing_key", new FormControl())
              if (this.stripePaymentMethod && this.stripePaymentMethod.card_number) {
                this.form.fields.use_billing_key = {
                  type: "boolean",
                  widget: { name: "checkbox" },
                  name: "use_billing_key",
                }
              }
            }),
          ),
      )

    this.billingKeyService.getOldPaymentMembership({ page_size: 30 }).subscribe((res: any) => {
      if (res.count == 0) {
        this.isStripeSetUpped = true
        this.oldProducts = []
      } else if (res.count > 0) {
        this.isStripeSetUpped = false

        this.oldProducts = res.results.map((result) => {
          if (!this.taxRate) {
            this.countryService.calTax()
            this.taxRate = this.countryService.getTax()
          }

          result.amount_usd = result.tier_next.amount_usd
            ? result.tier_next.amount_usd
            : this.currencyPipe.transform(result.tier_next.amount)
          this.updateTax(result)

          return result
        })
      }
    })
  }

  toggleTerms() {
    this.termsDisplayed = !this.termsDisplayed
    if (this.termsDisplayed) {
      document.getElementById("gen-terms-id").style.display = "block"
    } else {
      document.getElementById("gen-terms-id").style.display = "none"
    }
  }

  onSubmit() {
    // send in with correct format

    this.formatBillingInfo()
  }

  onInputKeyPressed(event) {
    if (event.code == "Enter") {
      // event.stopPropagation()
      event.preventDefault()
      if (!this.submitDisabled && !this.isSaving) {
        this.onSubmit()
      }
      return
    }

    this.isSubmitPossible()
  }

  selectAllClicked(ev) {
    if (ev.target.checked) {
      this.checkboxes.forEach((term) => {
        term.checked = true
      })
      this.isSubmitPossible()
    } else {
      this.checkboxes.forEach((term) => {
        term.checked = false
      })
      this.isSubmitPossible()
    }
  }

  termClicked(newVal, id) {
    this.checkboxes[id].checked = newVal
    if (this.checkIfAllClicked()) {
      this.selectAll = true
    } else {
      this.selectAll = false
    }
    this.isSubmitPossible()
  }

  checkIfAllClicked() {
    return this.checkboxes.every((term) => term.checked)
  }

  isSubmitPossible() {
    this.submitDisabled = !this.checkIfAllClicked()
  }

  // change later so we use 'app-dynamic-field-component' properly
  formatBillingInfo() {
    if (this.oldProducts && this.oldProducts.length > 0) {
      this.billingKeyService.changeToStripe().subscribe(() => {})
    }

    if (!this.isInduced) {
      this.branchTransaction()
    } else {
      this.router.navigate(
        [
          {
            outlets: {
              popup: null,
            },
          },
        ],
        {
          replaceUrl: true,
          skipLocationChange: true,
        },
      )
    }
  }

  branchTransaction() {
    this.isSaving = true
    if (this.type === "pictorial") {
      this.createPictorialPayment()
        .pipe(
          startWith(),
          switchMap((payment: any) => {
            this.form.formGroup.value.payment_pictorial = payment.id
            this.form.formGroup.value.amount = undefined
            return this.createPictorialTransaction()
          }),
          catchError((error) => {
            this.isSaving = false
            this.form.setErrors(error, false)
            const newObservable = new Observable((observer) => {
              observer.error(error)
            })

            return newObservable
          }),
        )
        .subscribe(
          (res) => {
            this.closePictorialPaymentModal()
          },
          (error) => {
            this.form.setErrors(error, false)
          },
        )
    } else {
      this.createTransaction()
    }
  }

  createTransaction() {
    this.transactionService.create(this.form).subscribe(
      (res) => {
        this.isSaving = false

        if (this.isChangeMembership) {
          this.snackbarService.open(
            this.translateService.instant("CREATOR_DETAIL_PAGE.CHANGE_MEMBERSHIP_SUCCESS"),
            "",
            2500,
          )
          this.router.navigate(["creators", this.creatorId]).then(() => {
            this.router.navigate([
              { outlets: { popup: null } },
              { replaceUrl: true, skipLocationChange: true },
            ])
          })
        } else {
          this.router.navigate(["creators", this.creatorId]).then(() => {
            this.router.navigate([{ outlets: { popup: ["thank-you", this.creatorId] } }])
          })
        }
      },
      (error) => {
        this.isSaving = false
      },
    )
  }

  goBack(event) {
    event.stopPropagation()
    event.preventDefault()
    this.location.back()
  }

  createPictorialTransaction() {
    const tempForm = new Form(
      {
        payment_pictorial: {
          type: "choice",
          required: true,
          read_only: false,
          label: "Payment pictorial",
          name: "payment_pictorial",
        },
      },
      this.transactionService,
      { payment_pictorial: this.form.formGroup.value.payment_pictorial },
    )
    return this.transactionService.createForPictorial(tempForm)
  }

  closePictorialPaymentModal() {
    this.isSaving = false
    if (this.isSendme && this.type === "pictorial") {
      this.snackbarService.open("구매가 완료되었습니다.")
      this.router
        .navigate(["pictorials", this.pictorialId], { skipLocationChange: true, replaceUrl: true })
        .then(() =>
          this.router.navigate([{ outlets: { popup: null } }], {
            replaceUrl: true,
            skipLocationChange: true,
          }),
        )
      return
    }

    this.router
      .navigate(["", { outlets: { popup: null } }], { skipLocationChange: true, replaceUrl: true })
      .then(() =>
        this.router.navigate(["./"], {
          relativeTo: this.route,
          queryParams: { status: "success", by: "credit" },
          skipLocationChange: true,
          replaceUrl: true,
        }),
      )
  }

  createPictorialPayment() {
    const params = {
      tier: this.tierId ? Number(this.tierId) : null,
      pictorial_id: this.pictorialId,
      amount: this.amount,
      amount_usd: this.amountUsd || 0,
      creator: Number(this.creatorId),
    }

    return this.transactionService.createPictorialPayment(params)
  }

  getQueryString(params: object) {
    if (params) {
      const searchParams = new URLSearchParams()
      Object.keys(params).forEach((key) => searchParams.set(key, params[key]))
      return searchParams.toString()
    }
    return null
  }

  updateTax(obj: any) {
    const result = this.countryService.getTaxIncludedPrice(obj.priceUsd || obj.amount_usd || 0)
    obj.amount_usd = result
  }

  updateUsdAmount(price: number) {
    if (!this.amountUsd) {
      this.amountUsd = price
    } else {
      this.amountUsd += price
    }
  }

  close() {
    super.close()
    if (this.isPictorial) {
      window.history.back()
    }
  }
}
