import { Stage } from "src/environments/stage"

export const environment = {
  production: true,
  stage: Stage.staging,
  apiUrl: "https://smith-staging.memberme.net/fatkat/api/",
  backendUrl: "https://smith-staging.memberme.net/fatkat/backend/",
  pictorialApiUrl: "https://pictorial-staging.memberme.net/mempic/",
  uploadFileUrl: "https://pictorial-staging.memberme.net/mempic/user-files",
  appleServiceId: "net.memberme.staging.front",
  ipRegistryKey: "12nd7uei17gazuxf",
  bizmsgCreatorIds: [],
  denyCancelCreatorIds: [],
  officialAccounts: [],
  bimilStoryCreatorIds: [],
  agapefitCreatorIds: [],
  mesquadCreatorIds: [],
  videoSizeRequestCreatorIds: [],
  adminUserId: [],
  leeheeCreatorIds: [5],
  showContentCountCreators: [
    { id: 6, count: 1234 }, // staging jmail
  ],
  currentContestURI: "atm",
}
