import { Component, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { FormService } from "../../../shared/services/form.service"
import { UserService } from "../../services/user.service"

import { CookieService } from "angular2-cookie/core"
import { AuthService } from "src/app/core/services/auth.service"

@Component({
  selector: "app-user-activate-confirm-page",
  templateUrl: "./user-activate-confirm-page.component.html",
  styleUrls: ["./user-activate-confirm-page.component.scss"],
})
export class UserActivateConfirmPageComponent implements OnInit {
  submitted = false
  isVerified = false
  title: string
  message: string
  isMobile = false

  //
  langFlag

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formService: FormService,
    private userService: UserService,
    private authService: AuthService,
    private cookieService: CookieService,
  ) {
    this.authService.getChangeEmitter().subscribe(() => {
      const isLogin = this.authService.isLoggedIn()

      if (!isLogin) {
        this.reset()
      }
    })
  }

  ngOnInit() {
    if (this.cookieService.get("lang") !== "KOR") {
      this.langFlag = "ENG"
    } else {
      this.langFlag = "KOR"
    }

    this.formService.getFormData("users/activate/confirm/").subscribe((form) => {
      this.route.paramMap.subscribe((params) => {
        form.formGroup.patchValue({
          uid: params.get("uid"),
          token: params.get("token"),
        })

        this.userService.activate(form).subscribe(
          (res) => {
            if (this.langFlag === "KOR") {
              this.title = "인증완료"
              this.message = "성공적으로 인증되었습니다."
            } else {
              this.title = "Confirmed"
              this.message = "Account successfully confirmed."
            }
            // this.title = '인증완료';
            // this.message = '성공적으로 인증되었습니다.';
            this.submitted = true
            this.isVerified = true
          },
          (error) => {
            if (this.langFlag === "KOR") {
              this.title = "인증실패"
              this.message = "인증링크가 유효하지 않습니다."
            } else {
              this.title = "Confirmation Failed"
              this.message = "Account confirmation was unsuccessful."
            }

            this.submitted = true
          },
        )

        // check device is mobile, init the link to deep link
        // "TouchEvent" 이벤트는 모바일에서만 생성가능한 이벤트이기 때문에 해당 로직을 사용하였습니다
        try {
          // 모바일일 경우
          document.createEvent("TouchEvent")
          this.isMobile = true
        } catch (e) {
          // 웹일 경우
          this.isMobile = false
        }
      })
    })
  }

  reset() {
    this.submitted = false
    this.isVerified = false
    this.title = ""
    this.message = ""
  }
}
