import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from "@angular/core"
import { CookieService } from "angular2-cookie"

import { KeenSliderInstance } from "keen-slider"
import { SliderService } from "src/app/shared/services/slider.service"

const animation = { duration: 22000, easing: (t) => t }

@Component({
  selector: "app-landing-sixth-profit",
  templateUrl: "./landing-sixth-profit.component.html",
  styleUrls: ["./landing-sixth-profit.component.scss"],
})
export class LandingSixthProfitComponent implements AfterViewInit, OnDestroy {
  langFlag
  @ViewChild("unLockSliderRef", { static: true }) unLockSliderRef: ElementRef<HTMLElement>
  @ViewChild("lockSliderRef", { static: true }) lockSliderRef: ElementRef<HTMLElement>

  unLockSlider: KeenSliderInstance = null
  lockSlider: KeenSliderInstance = null

  constructor(private cookieService: CookieService, private sliderService: SliderService) {
    this.langFlag = this.cookieService.get("lang")
  }

  ngAfterViewInit() {
    // this.unLockSlider = this.sliderService.getSlide(this.unLockSliderRef.nativeElement)
    // this.lockSlider = this.sliderService.getSlide(this.lockSliderRef.nativeElement)
  }

  ngOnDestroy() {
    if (this.unLockSlider) {
      this.unLockSlider.destroy()
    }
    if (this.lockSlider) {
      this.unLockSlider.destroy()
    }
  }
}
