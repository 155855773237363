import { Component, OnInit } from "@angular/core"
import { CookieService } from "angular2-cookie"

@Component({
  selector: "app-landing-second-communication",
  templateUrl: "./landing-second-communication.component.html",
  styleUrls: ["./landing-second-communication.component.scss"],
})
export class LandingSecondCommunicationComponent implements OnInit {
  langFlag
  constructor(private cookieService: CookieService) {
    this.langFlag = this.cookieService.get("lang")
  }

  ngOnInit() {}
}
