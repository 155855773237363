import { Component, Input, OnInit } from "@angular/core"
import { Post } from "../../types/post.type"
import * as moment from "moment/moment"
import { MatCheckboxChange, MatDialog } from "@angular/material"
import { PollService } from "../../services/poll.service"
import { MessagingComponent } from "../../../shared/components/messaging/messaging.component"
import { SnackbarService } from "../../../shared/services/snackbar.service"
import { TranslateService } from "@ngx-translate/core"
import { User } from "../../../account/types/user.type"

@Component({
  selector: "app-post-card-renderer",
  templateUrl: "./post-card-renderer.component.html",
  styleUrls: ["./post-card-renderer.component.scss"],
})
export class PostCardRendererComponent extends MessagingComponent implements OnInit {
  @Input() post: Post
  @Input() user: User
  @Input() isLocked: boolean
  @Input() isPastPoll: boolean
  @Input() isBlockedUser: any

  totalVoted: Number

  constructor(
    private pollService: PollService,
    protected snackbarService: SnackbarService,
    protected translateService: TranslateService,
    protected dialog: MatDialog,
  ) {
    super(snackbarService, translateService, dialog)
  }

  ngOnInit() {
    this.getTotalVotedCount(this.post.polls[0])
  }

  calcToEnd(end_date: any) {
    return moment()
      .to(moment(end_date).add(moment.duration(24, "hours")))
      .toUpperCase()
  }

  voteCheckbox($event: MatCheckboxChange, answer: any) {
    answer.checked = $event.checked
    this.vote(answer)
  }

  vote(answer: any) {
    this.pollService.vote(this.post.id, this.post.polls[0].id, answer).subscribe(
      (res: any) => {
        if (this.post.polls && this.post.polls.length) {
          // 투표 카운팅 수정
          this.post.polls[0] = res

          // 전체 투표 개수 더하기
          this.getTotalVotedCount(this.post.polls[0])

          // 각 투표개수를 초기화 한 후 다시 세팅하여, 프로그레스 바 렌더링 버그를 해결한다.
          this.eachVotedCountSet()
        }
      },
      (error: any) => {
        if (error.msg) {
          this.messageDanger(error.msg)
        }
      },
    )
  }

  getTotalVotedCount(poll: any) {
    if (poll) {
      this.totalVoted = poll.items.reduce((a, b) => a + (b["voted"] || 0), 0)
    }
    return 0
  }

  // 렌더링 버그 해결을 위해서 만든 함수,
  // 투표가 진행될 때 마다 투표값을 초기화시킨 후 다시 세팅하는 과정을 거치면서 프로그레스바를 작동시킨다.
  eachVotedCountSet() {
    for (let i = 0; i < this.post.polls[0].items.length; i++) {
      // Deep Copy를 위한 편법
      // shallow copy를 하게되면 기존에 있는 객체의 투표값(voted)이 아래에서 this.post.polls[0].items[i].voted = 0 이와 같이 초기화가 되어
      // 그것을 복사한 item 변수의 내부 voted 값까지 0으로 변하기 때문에, 별도의 주소값을 가질 수 있도록 딥카피를 진행한다.
      const item = JSON.parse(JSON.stringify(this.post.polls[0].items[i]))

      this.post.polls[0].items[i].voted = 0

      setTimeout(() => {
        this.post.polls[0].items[i].voted = item.voted
      }, 0)
    }
  }
}
