import { BreakpointObserver } from "@angular/cdk/layout"
import { Breakpoint } from "src/app/shared/constants/constants"
import { Injectable } from "@angular/core"

@Injectable({
  providedIn: "root",
})
export class BreakpointObserverService {
  bpXxs = `(max-width: ${Breakpoint.xs})`
  bpXs = `(min-width: ${Breakpoint.xs}) and (max-width: ${Breakpoint.sm})`
  bpSm = `(min-width: ${Breakpoint.sm}) and (max-width: ${Breakpoint.md})`
  bpMd = `(min-width:${Breakpoint.md}) and (max-width: ${Breakpoint.lg})`
  bpLg = `(min-width: ${Breakpoint.lg})`

  constructor(private breakpointObserver: BreakpointObserver) {}

  observe(bps: string[]) {
    return this.breakpointObserver.observe(bps)
  }

  observeAll() {
    return this.breakpointObserver.observe([this.bpXxs, this.bpXs, this.bpSm, this.bpMd, this.bpLg])
  }

  minWidth(bp: Breakpoint) {
    return `(min-width: ${bp})`
  }

  maxWidth(bp: Breakpoint) {
    return `(max-width: ${bp})`
  }

  between(bp1: Breakpoint, bp2: Breakpoint) {
    return `(min-width: ${bp1}) and (max-width: ${bp2})`
  }
}
