import { Component, ElementRef, OnInit, ViewChild } from "@angular/core"
import { ModelContestDataService } from "../../services/model-contest-data.service"
import KeenSlider, {
  KeenSliderHooks,
  KeenSliderInstance,
  KeenSliderOptions,
  SliderInstance,
} from "keen-slider"
import { CookieService } from "angular2-cookie"

@Component({
  selector: "app-model-contest-main-page-header",
  templateUrl: "./model-contest-main-page-header.component.html",
  styleUrls: ["./model-contest-main-page-header.component.scss"],
})
export class ModelContestMainPageHeaderComponent implements OnInit {
  @ViewChild("sliderRef", { static: false }) sliderRef: ElementRef<HTMLElement>

  slider: KeenSliderInstance = null
  lang: string
  eventRemovers: Array<() => void> = []

  constructor(
    public modelContestDataService: ModelContestDataService,
    private cookieService: CookieService,
  ) {}

  ngOnInit() {
    this.lang = this.cookieService.get("lang")
  }

  ngAfterViewInit() {
    this.modelContestDataService.modelList$.subscribe(() => {
      setTimeout(() => {
        this.slider = new KeenSlider(
          this.sliderRef.nativeElement,
          {
            loop: true,
          },
          [this.autoSlide],
        )
      })
    })
  }

  mouseOver = false
  timeout

  autoSlide = (
    slider: SliderInstance<
      KeenSliderOptions,
      KeenSliderInstance<{}, {}, KeenSliderHooks>,
      KeenSliderHooks
    >,
  ) => {
    let timeout
    function clearNextTimeout() {
      clearTimeout(timeout)
    }
    function nextTimeout() {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        slider.next()
      }, 3000)
    }
    function startAutoSlide() {
      nextTimeout()
    }
    slider.on("created", startAutoSlide)
    slider.on("dragStarted", clearNextTimeout)
    slider.on("animationEnded", nextTimeout)
    slider.on("updated", nextTimeout)
    slider.on("destroyed", () => {
      clearNextTimeout()
      slider.on("created", startAutoSlide, true)
      slider.on("dragStarted", clearNextTimeout, true)
      slider.on("animationEnded", nextTimeout, true)
      slider.on("updated", nextTimeout, true)
    })
  }

  ngOnDestroy() {
    if (this.slider) {
      this.slider.destroy()
    }
  }
}
