import { Injectable } from "@angular/core"
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router"
import { Observable } from "rxjs"
import { UserService } from "src/app/account/services/user.service"

@Injectable({
  providedIn: "root",
})
export class SendmeCreatorOnlyRouteGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.userService.getUser().creator) {
      this.router.navigate(["/sendme/inbox/read"], {
        replaceUrl: true,
      })
    }

    return true
  }
}
