import { Injectable } from "@angular/core"
import { MatDialog } from "@angular/material"
import { NavigationEnd, Router } from "@angular/router"

import { TagSelectDialogComponent } from "src/app/account/components/tag-select-dialog/tag-select-dialog.component"
import { UserService } from "src/app/account/services/user.service"

import { AuthService } from "src/app/core/services/auth.service"

import _ from "lodash"
import { TagDataService } from "./tag-data.service"

@Injectable({
  providedIn: "root",
})
export class UserTagModalService {
  isLogin: boolean
  hasShown: boolean

  dialogDisabledUrls: string[] = ["/users/activate"]

  debouncedShowTagSelectDialog = _.debounce(this.showTagSelectDialog, 100, {
    leading: true,
    trailing: false,
  })

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private dialog: MatDialog,
    private router: Router,
    private tagService: TagDataService,
  ) {
    this.router.events.subscribe(async (event) => {
      if (event instanceof NavigationEnd && this.authService.isAuthenticated()) {
        this.tagService.userTagLoadedEvent.subscribe((loaded: boolean) => {
          if (!this.isLogin) {
            this.isLogin = this.authService.isLoggedIn()
            this.hasShown = false
          }

          if (!loaded) {
            return
          }

          if (this.shouldOpenTagSelectDialog) {
            this.debouncedShowTagSelectDialog()
          }
        })
      }
    })

    this.authService.getChangeEmitter().subscribe(() => {
      const isLogin = this.authService.isLoggedIn()

      if (!isLogin) {
        this.hasShown = false
      }
    })
  }

  showTagSelectDialog() {
    this.hasShown = true
    this.dialog.open(TagSelectDialogComponent, {
      maxWidth: "90vw",
      closeOnNavigation: false,
      disableClose: true,
      panelClass: "tag-select-dialog",
    })
  }

  get shouldOpenTagSelectDialog() {
    const url = this.router.url
    return (
      this.isLogin &&
      !this.hasShown &&
      this.tagService.activeTags.length &&
      this.tagService.userTags &&
      this.tagService.userTags.length === 0 &&
      !this.userService.getUser().creator &&
      this.dialogDisabledUrls.every((disabledUrl) => !url.includes(disabledUrl))
    )
  }
}
