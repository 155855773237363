import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { map } from "rxjs/operators"
import { environment } from "src/environments/environment"
import { Form } from "../model/form"
import { TranslateService } from "@ngx-translate/core"
import { POST_VISIBILITY_MAX } from "src/app/shared/constants/constants"

@Injectable({
  providedIn: "root",
})
export class FormService {
  constructor(private http: HttpClient, private translateService: TranslateService) {}

  getFormData(url: string, fieldNamesPartial?: string[]) {
    return this.http.options(environment.apiUrl + url).pipe(
      map((res) => {
        let fields = res["actions"] ? res["actions"]["POST"] || res["actions"]["PUT"] : null
        const fieldsPartial = {}
        const data = res["data"] || {}

        if (!fields) {
          throw new Error(this.translateService.instant("ALERT_CONFIRM.NO_FORM_DATA"))
        }

        if (fieldNamesPartial) {
          fieldNamesPartial.forEach((value) => {
            fieldsPartial[value] = fields[value]
          })
          fields = fieldsPartial
        }
        return new Form(fields, this.translateService, data)
      }),
    )
  }

  getFilterFormData(url: string, fieldNamesPartial?: string[]) {
    return this.http.options(environment.apiUrl + url).pipe(
      map((res) => {
        let fields = res["filters"]
        const fieldsPartial = {}

        if (fieldNamesPartial) {
          fieldNamesPartial.forEach((value) => {
            fieldsPartial[value] = fields[value]
          })
          fields = fieldsPartial
        }

        return new Form(fields, this.translateService)
      }),
    )
  }

  getFormFromFields(fields, data?) {
    return new Form(fields, this.translateService, data)
  }

  transformVisibilityChoices(choices: any[]) {
    if (!choices) {
      return choices
    }
    return choices.map((choice) => {
      if (choice.value < 1000 || choice.value == POST_VISIBILITY_MAX) {
        return choice
      }
      const usdValue = Math.round(choice.value / 10) / 100
      choice.display_name = choice.display_name.replace(
        choice.value.toLocaleString(),
        `${usdValue.toLocaleString()} `,
      )
      return choice
    })
  }
}
