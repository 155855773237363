import {
  Component,
  Injectable,
  ElementRef,
  ViewChild,
  OnInit,
  ChangeDetectorRef,
  Inject,
} from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { BehaviorSubject } from "rxjs"
import { UserService } from "../../../account/services/user.service"
import { CreatorService } from "../../services/creator.service"
import { AuthService } from "../../../core/services/auth.service"
import { SelectionModel } from "@angular/cdk/collections"
import { FlatTreeControl } from "@angular/cdk/tree"
import { MatTreeFlatDataSource, MatTreeFlattener } from "@angular/material/tree"
import { FormService } from "../../../shared/services/form.service"
import { Form } from "../../../shared/model/form"
import { PictorialService } from "src/app/board/services/pictorial.service"
import { CdkDragDrop } from "@angular/cdk/drag-drop"
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from "@angular/material-moment-adapter"
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS, MatSnackBar } from "@angular/material"
import { DatepickerFormatterComponent } from "src/app/shared/components/datepicker-formatter/datepicker-formatter.component"
import { CookieService } from "angular2-cookie"
import { TranslateService } from "@ngx-translate/core"
import { User } from "src/app/account/types/user.type"

export interface ModelItem {
  id: string
  creatorId: number
  name: string
  modelCreatorId: string | null
  membermeUri: string | null
  birthday: string
  height: string
  bwh: string
  order: number
  profilePicture: ProfilePicture | null
}

interface ProfilePicture {
  id: string
  url: string
}
/**
 * Node for to-do item
 */
export class TodoItemNode {
  children: TodoItemNode[]
  modelCreatorId: null | string

  item: string
  id: string
  url: null | string
  birthday: string
  height: string
  threeSize: string
  cupSize: string
  order: number
  profilePictureUrl: string
  profilePictureId: string
}

interface ModelData {
  name: string
  birthday: string
  height: string
  threeSize: string
  url: string
  cupSize: string
  profilePictureUrl: string
}
/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
  item: string
  level: number
  expandable: boolean
  id: string
}

/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */
@Injectable()
export class ChecklistDatabase {
  dataChange = new BehaviorSubject<TodoItemNode[]>([])
  user: User
  creator
  treeData

  get data(): TodoItemNode[] {
    return this.dataChange.value
  }

  constructor(
    private userService: UserService,
    private creatorService: CreatorService,
    private pictorialService: PictorialService,
  ) {
    this.initialize()
  }

  initialize() {
    // 여기서 모델 리스트 API 불러서 모델 리스트 json 만들어야 한다.
    // this.creatorService.get 대신 this.creatorService.getModelList()... 식의 콜이 있겠지?
    this.user = this.userService.getUser()

    this.pictorialService.getModelList(this.user.creator).subscribe((res: ModelItem[]) => {
      // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
      //     file node as children.
      this.treeData = this.buildFileTree(res)

      // Notify the change.
      this.dataChange.next(this.treeData)
    })
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `TodoItemNode`.
   */
  buildFileTree(array: ModelItem[]): TodoItemNode[] {
    return array.reduce<TodoItemNode[]>((accumulator, model) => {
      const node = new TodoItemNode()
      node.item = model.name
      node.id = model.id
      node.modelCreatorId = model.modelCreatorId
      node.url = model.membermeUri
      node.birthday = model.birthday
      node.height = model.height
      node.threeSize = model.bwh
      node.order = model.order
      node.profilePictureUrl = model.profilePicture ? model.profilePicture.url : null
      node.profilePictureId = model.profilePicture ? model.profilePicture.id : null
      return accumulator.concat(node)
    }, [])
  }

  insertNewTopItem(name: string) {
    const models = this.data
    const reqData = {
      creatorId: this.user.creator,
      name,
      order: models.length > 0 ? models[models.length - 1].order + 1 : 1,
    }
    return this.pictorialService.createModel(reqData).subscribe((model: ModelItem) => {
      const node = new TodoItemNode()
      node.item = model.name
      node.id = model.id
      node.modelCreatorId = model.modelCreatorId
      node.url = model.membermeUri
      node.birthday = model.birthday
      node.height = model.height
      node.threeSize = model.bwh
      node.order = model.order
      node.profilePictureUrl = model.profilePicture ? model.profilePicture.url : null
      node.profilePictureId = model.profilePicture ? model.profilePicture.id : null

      this.data.push(node)
      this.dataChange.next(this.data)
    })
  }

  updateItem(node: TodoItemNode, modelData: ModelData) {
    node.item = modelData.name
    node.birthday = modelData.birthday
    node.height = modelData.height
    node.threeSize = modelData.threeSize
    node.url = modelData.url
    node.profilePictureId = modelData.profilePictureUrl ? node.profilePictureId : ""
    node.profilePictureUrl = modelData.profilePictureUrl ? node.profilePictureUrl : ""

    this.dataChange.next(this.data)
    const reqData = {
      id: node.id,
      name: modelData.name,
      modelCreatorId: node.modelCreatorId,
      membermeUri: modelData.url,
      birthday: modelData.birthday,
      height: modelData.height,
      bwh: modelData.threeSize,
      order: node.order,
      creatorId: this.user.creator,
      profilePictureId: node.profilePictureId,
    }
    return this.pictorialService.updateModel(reqData).subscribe()
  }

  deleteItem(node: TodoItemNode) {
    this.deleteNode(this.data, node)
    this.dataChange.next(this.data)
    this.pictorialService.deleteModel(node.id).subscribe()
  }

  deleteNode(nodes: TodoItemNode[], nodeToDelete: TodoItemNode) {
    const index = nodes.indexOf(nodeToDelete, 0)
    if (index > -1) {
      nodes.splice(index, 1)
    } else {
      nodes.forEach((node) => {
        if (node.children && node.children.length > 0) {
          this.deleteNode(node.children, nodeToDelete)
        }
      })
    }
  }

  // 새로운 상위 카테고리를 만든다
  addItemToTop() {
    const categoryName = "New Model-" + this.data.length
    return this.insertNewTopItem(categoryName)
  }

  uploadProfile(node: TodoItemNode, file) {
    const formData = new FormData()
    formData.append("files", file)
    return this.pictorialService
      .uploadModelProfile(node.id, formData)
      .subscribe(({ id, url }: { id: string; url: string }) => {
        node.profilePictureId = id
        node.profilePictureUrl = url
        this.dataChange.next(this.data)
      })
  }

  updateList(body) {
    this.pictorialService.updateModelList(body).subscribe()
  }
}

@Component({
  selector: "app-pictorial-model-management-page",
  templateUrl: "./creator-pictorial-model-management-page.component.html",
  styleUrls: ["./creator-pictorial-model-management-page.component.scss"],
  providers: [
    ChecklistDatabase,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useClass: DatepickerFormatterComponent },
  ],
})
export class CreatorPictorialModelManagementPageComponent implements OnInit {
  @ViewChild("input", { static: false }) input: ElementRef
  form: Form
  selectedModelString: string
  selectedModel: TodoItemFlatNode
  modelData: ModelData
  modelValidate: ModelData

  disableDeleteButton = true
  // 파일첨부를 위해 필요한 [appDynamicAttrs] 값
  typeFile = { type: "file" }

  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>()

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>()

  /** A selected parent node to be inserted */
  selectedParent: TodoItemFlatNode | null = null

  /** The new item's name */
  newItemName = ""

  treeControl: FlatTreeControl<TodoItemFlatNode>

  treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>

  dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>

  // @ViewChild("emptyItem") emptyItem: ElementRef;

  expansionModel = new SelectionModel<string>(true)
  dragging = false
  expandTimeout: any
  expandDelay = 1000
  validateDrop = false

  loading = false

  imageUploading = false

  constructor(
    @Inject(MAT_DATE_FORMATS) private config: DatepickerFormatterComponent,
    private cd: ChangeDetectorRef,
    public auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private creatorService: CreatorService,
    private database: ChecklistDatabase,
    private _snackBar: MatSnackBar,
    private cookieService: CookieService,
    private translateService: TranslateService,
    private formService?: FormService,
  ) {
    this.config.lang = this.cookieService.get("lang")
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren,
    )
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable)
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener)

    database.dataChange.subscribe((data) => {
      this.dataSource.data = []
      this.dataSource.data = data
    })

    // 새로 만들기로 왔을 때 자동으로 만들어 줄지...
    this.route.queryParams.subscribe((params) => {
      if (params["new-model"]) {
        const subscribe = database.dataChange.subscribe(() => {
          if (database.treeData) {
            this.createModelAndSelect()
            subscribe.unsubscribe()
          }
        })
      }
    })

    this.modelData = this.getEmptyModelData()

    this.emptyValidate()
  }

  getLevel = (node: TodoItemFlatNode) => node.level

  isExpandable = (node: TodoItemFlatNode) => node.expandable

  getChildren = (node: TodoItemNode): TodoItemNode[] => node.children

  hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable

  hasNoContent = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.item === ""

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: TodoItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node)
    const flatNode =
      existingNode && existingNode.item === node.item ? existingNode : new TodoItemFlatNode()
    flatNode.item = node.item
    flatNode.id = node.id
    flatNode.level = level
    flatNode.expandable = node.children && node.children.length > 0
    this.flatNodeMap.set(flatNode, node)
    this.nestedNodeMap.set(node, flatNode)
    return flatNode
  }

  /**
   * This constructs an array of nodes that matches the DOM
   */
  visibleNodes(): TodoItemNode[] {
    const result = []

    function addExpandedChildren(node: TodoItemNode, expanded: string[]) {
      result.push(node)
      if (expanded.includes(node.id)) {
        node.children.map((child) => addExpandedChildren(child, expanded))
      }
    }
    this.dataSource.data.forEach((node) => {
      addExpandedChildren(node, this.expansionModel.selected)
    })
    return result
  }

  /**
   * Handle the drop - here we rearrange the data based on the drop event,
   * then rebuild the tree.
   * */
  drop(event: CdkDragDrop<string[]>) {
    // ignore drops outside of the tree
    if (!event.isPointerOverContainer) {
      return
    }

    // construct a list of visible nodes, this will match the DOM.
    // the cdkDragDrop event.currentIndex jives with visible nodes.
    // it calls rememberExpandedTreeNodes to persist expand state
    const visibleNodes = this.visibleNodes()

    // deep clone the data source so we can mutate it
    const changedData = JSON.parse(JSON.stringify(this.dataSource.data))

    // recursive find function to find siblings of node
    function findNodeSiblings(arr: Array<any>, id: string): Array<any> {
      let result, subResult
      arr.forEach((item, i) => {
        if (item.id === id) {
          result = arr
        } else if (item.children) {
          subResult = findNodeSiblings(item.children, id)
          if (subResult) {
            result = subResult
          }
        }
      })
      return result
    }

    // determine where to insert the node
    const nodeAtDest = visibleNodes[event.currentIndex]
    const newSiblings = findNodeSiblings(changedData, nodeAtDest.id)
    if (!newSiblings) {
      return
    }
    const insertIndex = newSiblings.findIndex((s) => s.id === nodeAtDest.id)

    // remove the node from its old place
    const node = event.item.data
    const siblings = findNodeSiblings(changedData, node.id)
    const siblingIndex = siblings.findIndex((n) => n.id === node.id)
    const nodeToInsert: TodoItemNode = siblings.splice(siblingIndex, 1)[0]
    if (nodeAtDest.id === nodeToInsert.id) {
      return
    }

    // ensure validity of drop - must be same level
    const nodeAtDestFlatNode = this.treeControl.dataNodes.find((n) => nodeAtDest.id === n.id)
    if (this.validateDrop && nodeAtDestFlatNode.level !== node.level) {
      alert("Items can only be moved within the same level.")
      return
    }

    // insert node
    const leftArray = newSiblings.splice(insertIndex, newSiblings.length)
    const lastOrder = newSiblings.length > 0 ? newSiblings[newSiblings.length - 1].order : 0

    const orderingArray = [nodeToInsert]
      .concat(leftArray)
      .map((sibling, index) => ({ ...sibling, order: lastOrder + index + 1 }))

    newSiblings.push(...orderingArray)

    this.database.updateList(orderingArray.map((item) => ({ id: item.id, order: item.order })))
    // rebuild tree with mutated data
    this.rebuildTreeForData(changedData)
  }

  /**
   * Experimental - opening tree nodes as you drag over them
   */
  dragStart(node: TodoItemFlatNode) {
    this.dragging = true
    this.treeControl.collapse(node)
  }

  dragEnd() {
    this.dragging = false
  }

  dragHover(node: TodoItemFlatNode) {
    if (this.dragging) {
      clearTimeout(this.expandTimeout)
      this.expandTimeout = setTimeout(() => {
        this.treeControl.expand(node)
      }, this.expandDelay)
    }
  }

  dragHoverEnd() {
    if (this.dragging) {
      clearTimeout(this.expandTimeout)
    }
  }

  rebuildTreeForData(data: any) {
    this.database.dataChange.next(data)
    this.dataSource.data = data
    this.expansionModel.selected.forEach((id) => {
      const node = this.treeControl.dataNodes.find((n) => n.id === id)
      this.treeControl.expand(node)
    })
  }

  /** Save the node to database */
  saveNode(node: TodoItemFlatNode, modelData: ModelData) {
    const nestedNode = this.flatNodeMap.get(node)
    return this.database.updateItem(nestedNode, modelData)
  }

  deleteItem(node: TodoItemFlatNode) {
    this.database.deleteItem(this.flatNodeMap.get(node))
  }

  addNewCategory() {
    return this.database.addItemToTop()
  }

  treeNodeClick(node: TodoItemFlatNode) {
    const element = document.getElementById("category-name-input-id")
    element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
    this.selectedModelString = node.item.toString()
    this.selectedModel = node
    this.setInputValue()
    this.emptyValidate()
  }

  emptyValidate() {
    this.modelValidate = this.getEmptyModelData()
  }

  click() {
    this.input.nativeElement.click()
  }
  clearImage() {
    this.modelData.profilePictureUrl = null
  }

  ngOnInit() {}

  createModelAndSelect() {
    this.addNewCategory().add(() => {
      const nodes = this.dataSource._data.value
      this.treeNodeClick(this.nestedNodeMap.get(nodes[nodes.length - 1]))
    })
  }

  onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      this.imageUploading = true
      const [file] = event.target.files
      const node = this.flatNodeMap.get(this.selectedModel)
      this.database.uploadProfile(node, file).add(() => {
        this.modelData.profilePictureUrl = node.profilePictureUrl
        this.imageUploading = false
      })
    }
  }

  setInputValue() {
    const model = this.flatNodeMap.get(this.selectedModel)

    const modelsThreeSize = model.threeSize || ""
    const res = /[A-Z]/.exec(modelsThreeSize)
    const cupSize = res ? res[0] : ""
    const threeSize = modelsThreeSize.replace(/[A-Z]/, "")

    this.modelData = {
      ...model,
      name: model.item,
      profilePictureUrl: model.profilePictureUrl,
      cupSize,
      threeSize,
    }
  }

  saveButtonClick() {
    let valid = true
    const modelData = Object.assign({}, this.modelData)
    this.emptyValidate()
    if (!modelData.name) {
      this.modelValidate.name = this.translateService.instant("ALERT_CONFIRM.REQUIRED_FIELD")
      valid = false
    }
    if (!this.validateDate(modelData.birthday)) {
      this.modelValidate.birthday =
        this.translateService.instant("ALERT_CONFIRM.INVALID_FORMAT") + " ex) 1999-01-01"
      valid = false
    }
    if (!this.validateHeight(modelData.height)) {
      this.modelValidate.height =
        this.translateService.instant("ALERT_CONFIRM.INVALID_FORMAT") + " ex) 165"
      valid = false
    }
    if (!this.validateThreeSize(modelData.threeSize)) {
      this.modelValidate.threeSize =
        this.translateService.instant("ALERT_CONFIRM.INVALID_FORMAT") + " ex) 77-44-55"
      valid = false
    }

    if (modelData.cupSize) {
      modelData.threeSize = modelData.threeSize.replace(/([0-9]{2})-/, `$1${modelData.cupSize}-`)
    }

    if (valid) {
      this.loading = true
      const savingSnackBar = this.openSnackBar(
        this.translateService.instant("CATEGORY_MANAGE_FORM.SAVING"),
        99999,
      )
      this.saveNode(this.selectedModel, modelData).add(() => {
        this.loading = false
        savingSnackBar.dismiss()
        this.openSnackBar(this.translateService.instant("CATEGORY_MANAGE_FORM.SAVED"))
      })
    } else {
      alert(this.translateService.instant("ALERT_CONFIRM.INVALID_FORM"))
    }
  }

  deleteCategory() {
    if (confirm(this.translateService.instant("ALERT_CONFIRM.DELETE_CONFIRM"))) {
      this.database.deleteItem(this.flatNodeMap.get(this.selectedModel))
      // 지운 후 상태 뒤처리
      this.cleanSelectedModel()
    }
  }

  cleanSelectedModel() {
    this.selectedModel = null
    this.modelData = this.getEmptyModelData()
    this.emptyValidate()
  }

  getEmptyModelData(): ModelData {
    return {
      name: "",
      birthday: "",
      height: "",
      threeSize: "",
      cupSize: "",
      url: "",
      profilePictureUrl: "",
    }
  }

  keydownThreeSize(event) {
    const validReg = /\d/
    const { key } = event
    if (validReg.test(key)) {
      setTimeout(() => {
        const { threeSize } = this.modelData
        const sizeArray = threeSize.split("-")
        if (sizeArray.length < 3 && sizeArray[sizeArray.length - 1].length === 2) {
          this.modelData.threeSize += "-"
        } else if (sizeArray[sizeArray.length - 1].length > 2) {
          this.modelData.threeSize = threeSize.substring(0, 8)
        }
      }, 1)
    } else {
      setTimeout(() => {
        const { threeSize } = this.modelData
        this.modelData.threeSize = threeSize.substring(0, threeSize.length - 1)
      }, 1)
    }
  }

  onChangeDate(e) {
    this.modelData.birthday = e.targetElement.value
  }

  private validateDate(date: string): boolean {
    if (!date) {
      return true
    }
    const dateReg = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/
    return dateReg.test(date)
  }

  private validateHeight(height: string | number): boolean {
    if (!height) {
      return true
    }
    const heightReg = /^[123][0-9]{2}$/
    const strHeight = typeof height === "number" ? height.toString() : height
    return heightReg.test(strHeight)
  }

  private validateThreeSize(threeSize: string) {
    if (!threeSize) {
      return true
    }
    const threeSizeReg = /^[0-9]{2}-[0-9]{2}-[0-9]{2}$/
    return threeSizeReg.test(threeSize)
  }

  openSnackBar(message: string, duration = 2500) {
    return this._snackBar.open(message, "", {
      duration,
      verticalPosition: "top",
      panelClass: ["snack-bar-top", "primary-snackbar"],
    })
  }
}
