import {
  Component,
  ComponentFactoryResolver,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import {
  KeenSliderHooks,
  KeenSliderInstance,
  KeenSliderOptions,
  KeenSliderPlugin,
} from "keen-slider"
import { combineLatest } from "rxjs"

import { map } from "rxjs/operators"
import { AuthService } from "src/app/core/services/auth.service"
import { CreatorService } from "src/app/creator/services/creator.service"
import { SliderService } from "src/app/shared/services/slider.service"
import { Pagination, PaginationMeta } from "src/app/shared/types/pagination.type"
import { ContestService } from "../../services/contest.service"
import {
  CarouselType,
  Contest,
  GetPictorialContestParams,
  PictorialContestCreator,
} from "../../types"
import _ from "lodash"

@Component({
  selector: "app-ai-contest-carousel",
  templateUrl: "./ai-contest-carousel.component.html",
  styleUrls: ["./ai-contest-carousel.component.scss"],
})
export class AiContestCarouselComponent implements OnInit, OnDestroy {
  @HostListener("window:resize", ["$event"]) onResize(event) {
    if (this.carousel && (this.animatedByPage || this.isCreator)) {
      this.setDotHelperAsPage()
      this.updateDotClass()
    }
  }

  @ViewChild("keenSlider", { static: true }) carouselRef: ElementRef<HTMLElement>
  @Input() title?: string
  @Input() link?: string
  @Input() keyword: CarouselType
  @Input() contest: Contest
  @Input() theme?: string
  @Input() disableArrows = false
  @Input() disableDots = false
  @Input() animatedByPage = false

  carouselItems: any[] = []
  originItems: any[] = []

  carousel: KeenSliderInstance = null
  currentSlide = 0
  dotHelper: Array<Number> = []
  opacities: number[] = []
  dotClass: string[] = []

  isLoaded = false
  isCreator: boolean

  // 테마 캐러셀 전용 보여줄 화보가 있는 경우에만 보여주기 위함.
  isVisible = false
  meta: PaginationMeta

  constructor(
    private authService: AuthService,
    private contestService: ContestService,
    private sliderService: SliderService,
    private creatorService: CreatorService,
    private router: Router,
    private componentFactoryResolver: ComponentFactoryResolver,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.isCreator = this.keyword === CarouselType.creator
    this.getCarouselItems()
    this.sliderService.getChangeEmitter().subscribe((res) => {
      if (
        res &&
        (res === this.keyword || res === this.theme) &&
        !this.isLoaded &&
        this.carouselRef.nativeElement
      ) {
        this.isLoaded = true
        this.setSlide()
      }
    })
  }

  ngOnDestroy(): void {
    if (this.carousel) {
      this.carousel.destroy()
    }
  }

  getCarouselItems() {
    const param: GetPictorialContestParams = {
      limit: 10,
      page: 1,
    }

    switch (this.keyword) {
      case CarouselType.popular:
        param.likeCount = true
        break
      case CarouselType.new:
        param.latest = true
        break
      case CarouselType.theme:
        param.themeSlugs = [this.theme]
        param.latest = true
        break
      default:
        param.sortBy = ["likeCount"]
    }

    param.createdAtBetween = [this.contest.start_date, this.contest.end_date]
    param.creatorIds = this.contest.active_creators.map((item) => item.creator)

    if (this.isCreator) {
      this.contestService
        .getPictorialContestCreatorList(param)
        .subscribe((res: PictorialContestCreator[]) => {
          combineLatest(
            res.map((creator) => this.creatorService.get(creator.creatorId).toPromise()),
          ).subscribe((creators) => {
            this.carouselItems = creators
            this.originItems = _.cloneDeep(creators)
          })
        })
    } else {
      this.contestService
        .getContestItemList(param)
        .pipe(
          map((res: Pagination<any>) => {
            this.meta = res.meta
            const items = res.items.map((item, idx) => ({ ...item, index: idx + 1 }))

            this.isVisible = items.length > 0
            return items
          }),
        )
        .subscribe((res) => {
          this.carouselItems = res
        })
    }
  }

  goToCategoryPage() {
    this.router.navigate([`${this.link}`], {
      relativeTo: this.route,
    })
  }

  setSlide() {
    const plugins: KeenSliderPlugin<{}, {}, KeenSliderHooks>[] = []
    const options: KeenSliderOptions = {
      created: undefined,
      updated: undefined,
      animationEnded: undefined,
      renderMode: "performance",
      loop: false,
      drag: true,
      mode: "snap",
      initial: this.currentSlide,

      breakpoints: {
        "(max-width: 279px)": {
          slides: { perView: 1 },
        },
        "(min-width: 280px)": {
          slides: { perView: 2 },
        },
        "(min-width: 360px)": {
          slides: { perView: 2 },
        },
        "(min-width: 428px)": {
          slides: { perView: 3 },
        },
        "(min-width: 720px)": {
          slides: { perView: 3 },
        },
        "(min-width: 1280px)": {
          slides: { perView: 4 },
        },
      },
      detailsChanged: (s) => {
        const { details } = s.track
        if (details && details.slides) {
          this.opacities = s.track.details.slides.map((slide) => slide.portion)
        }
      },
      slideChanged: (instance) => {
        const { details } = instance.track

        this.setCurrentSlide(
          details.maxIdx == details.abs ? details.slides.length - 1 : details.abs,
        )

        this.currentSlide = details.maxIdx == details.abs ? details.slides.length - 1 : details.abs
      },
    }

    if (this.isCreator) {
      options.updated = null
      options.animationEnded = null
      options.initial = null
      delete options.slideChanged
      delete options.mode
      options.loop = true
      options.mode = "free-snap"

      options.breakpoints = {
        "(max-width: 279px)": {
          slides: { perView: 2 },
        },
        "(min-width: 280px)": {
          slides: { perView: 2 },
        },
        "(min-width: 360px)": {
          slides: { perView: 3 },
        },
        "(min-width: 720px)": {
          slides: { perView: 3 },
        },
        "(min-width: 1280px)": {
          slides: { perView: 4 },
        },
      }

      const setCurrent = this.setCurrentSlide.bind(this)

      plugins.push((slider: KeenSliderInstance) => {
        let timeout
        let mouseOver = false

        function clearNextTimeout() {
          clearTimeout(timeout)
        }

        function nextTimeout() {
          clearTimeout(timeout)
          if (mouseOver) {
            return
          }
          const { abs } = slider.track.details

          setCurrent(abs % slider.track.details.slides.length)

          timeout = setTimeout(function () {
            slider.moveToIdx(abs + (slider.options.slides as any).perView, true)
          }, 2000)
        }

        slider.on("created", () => {
          slider.slides.map((slide) =>
            slide.firstChild.addEventListener("mouseover", () => {
              slider.animator.stop()
              mouseOver = true
              clearNextTimeout()
            }),
          )
          slider.slides.map((slide) =>
            slide.firstChild.addEventListener("mouseout", () => {
              mouseOver = false
              nextTimeout()
            }),
          )
          nextTimeout()
        })
        slider.on("dragStarted", clearNextTimeout)
        slider.on("animationEnded", nextTimeout)
        slider.on("updated", nextTimeout)
      }),
        plugins.push((slider: KeenSliderInstance) => {
          slider.on("animationEnded", this.updateDotClass.bind(this))
        })
    }
    setTimeout(() => {
      this.carousel.update()
    }, 200)

    this.carousel = this.sliderService.getSlide(this.carouselRef.nativeElement, options, plugins)

    if (this.carousel.track && this.carousel.track.details) {
      if (this.animatedByPage || this.isCreator) {
        this.setDotHelperAsPage()
      } else {
        this.dotHelper = []

        for (let i = 0; i < this.carousel.track.details.slides.length; i++) {
          this.dotHelper.push(i)
        }
      }
    }
    this.updateDotClass()
  }

  setCurrentSlide(index: number) {
    this.currentSlide = index
  }

  setDotHelperAsPage() {
    if (this.carousel && !this.disableDots) {
      this.dotHelper = []
      this.carouselItems = [...this.originItems]

      // 캐러셀의 상세 정보
      const { slides } = this.carousel.track.details
      const { perView } = this.carousel.options.slides as any

      const page = perView ? Math.ceil(slides.length / perView) : slides.length
      let need = perView - (this.originItems.length % perView)
      let fromIdx = parseInt((this.originItems.length / perView).toString()) * perView

      for (let i = 1; need > 0; need--, i++) {
        this.carouselItems.splice(fromIdx, 0, this.originItems[fromIdx - i])
      }

      for (let i = 0; i < page; i++) {
        this.dotHelper.push(i)
      }

      this.carousel.update(this.carousel.options)
      this.carousel.moveToIdx(0, true, { duration: 0 })
    }
  }

  updateDotClass() {
    if (this.carousel) {
      if (this.isCreator || this.animatedByPage) {
        const classes = this.opacities.map((o) => (o == 1 ? "dot active" : "dot"))

        // 케러셀 상태 정보가 아니라 breakpoint 등 옵션 정보
        const { slides } = this.carousel.options
        const pageSize = (slides as any).perView
        const lastIdx = classes.lastIndexOf("dot active")
        const firstIdx = classes.indexOf("dot active")

        this.dotClass = Array(Math.ceil(classes.length / pageSize) + 1).fill("dot")

        // lastIdx !== firstIdx +1 -> 마지막 요소와 맨처음 요소가 같이 보이는 경우
        const page = Math.trunc((firstIdx + 1 === lastIdx ? lastIdx : firstIdx) / pageSize)
        this.dotClass[page] = "dot page active"
      } else {
        this.dotClass = this.opacities.map((o) => (o == 1 ? "dot active" : "dot"))
      }
    }
  }

  getDotClass($idx: number) {
    return this.dotClass[$idx]
  }

  onClickDot($idx) {
    const { abs, rel } = this.carousel.track.details
    const { slides } = this.carousel.options

    // rel == 현재 맨앞에 보이는 슬라이드의 인덱스
    if (this.animatedByPage || this.isCreator) {
      this.setCurrentSlide($idx)
      const pageSize = (slides as any).perView
      const page = Math.trunc(rel / pageSize)

      if (page !== $idx) {
        this.carousel.moveToIdx(abs + ($idx - page) * pageSize, true)
      }
    } else {
      this.carousel.moveToIdx($idx + abs, true)
    }

    this.updateDotClass()
  }
}
