import { NgModule } from "@angular/core"
import { RouterModule, Routes } from "@angular/router"
import { UserCreatePageComponent } from "./pages/user-create-page/user-create-page.component"
import { UserUpdatePageComponent } from "./pages/user-update-page/user-update-page.component"
import { UserUpdateProfilePageComponent } from "./pages/user-update-profile-page/user-update-profile-page.component"
import { UserUpdateAccountPageComponent } from "./pages/user-update-account-page/user-update-account-page.component"
import { MembershipPageComponent } from "./pages/membership-page/membership-page.component"
import { MembershipCancelPageComponent } from "./pages/membership-cancel-page/membership-cancel-page.component"
import { LoginPageComponent } from "./pages/login-page/login-page.component"
import { AuthGuardService } from "../shared/guards/auth-guard.service"
import { PasswordResetPageComponent } from "./pages/password-reset-page/password-reset-page.component"
import { PasswordResetConfirmPageComponent } from "./pages/password-reset-confirm-page/password-reset-confirm-page.component"
import { UserAdultVerifyResultPageComponent } from "./pages/user-adult-verify-result-page/user-adult-verify-result-page.component"
import { UserAdultVerifyPageComponent } from "./pages/user-adult-verify-page/user-adult-verify-page.component"
import { UserActivatePageComponent } from "./pages/user-activate-page/user-activate-page.component"
import { UserActivateConfirmPageComponent } from "./pages/user-activate-confirm-page/user-activate-confirm-page.component"
import { SocialLoginErrorPageComponent } from "./pages/social-login-error-page/social-login-error-page.component"
import { UserAdultVerifyModalPageComponent } from "./pages/user-adult-verify-modal-page/user-adult-verify-modal-page.component"
import {
  UserUpdateNotificationPageComponent
} from "src/app/account/pages/user-update-notification-page/user-update-notification-page.component"

const routes: Routes = [
  {
    path: "users/create",
    component: UserCreatePageComponent,
  },
  {
    path: "users/update",
    component: UserUpdatePageComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: "profile",
        component: UserUpdateProfilePageComponent,
      },
      {
        path: "account",
        component: UserUpdateAccountPageComponent,
      },
      {
        path: "notifications",
        component: UserUpdateNotificationPageComponent,
      },
    ],
  },
  {
    path: "users/password/reset",
    component: PasswordResetPageComponent,
  },
  {
    path: "users/password/reset/confirm/:uid/:token",
    component: PasswordResetConfirmPageComponent,
  },
  {
    path: "users/activate",
    component: UserActivatePageComponent,
  },
  {
    path: "users/activate/confirm/:uid/:token",
    component: UserActivateConfirmPageComponent,
  },
  {
    path: "membership",
    component: MembershipPageComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "membership-cancel",
    component: MembershipCancelPageComponent,
  },
  {
    path: "login",
    component: LoginPageComponent,
    outlet: "popup",
  },
  {
    path: "users/verify-adult",
    component: UserAdultVerifyModalPageComponent,
    outlet: "popup",
  },
  {
    path: "social-login-error/:type",
    component: SocialLoginErrorPageComponent,
    outlet: "popup",
  },
  {
    path: "users/adult-verification",
    component: UserAdultVerifyPageComponent,
  },
  {
    path: "users/adult-verification-result",
    component: UserAdultVerifyResultPageComponent,
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AccountRoutingModule {}
