import { Component, OnInit } from "@angular/core"

@Component({
  selector: "app-community-guideline-page",
  templateUrl: "./community-guideline-page.component.html",
  styleUrls: ["./community-guideline-page.component.scss"],
})
export class CommunityGuidelinePageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
