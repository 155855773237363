import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from "@angular/core"
import { smoothly } from "src/app/animation"
import { UserService } from "src/app/account/services/user.service"
import { MessagingComponent } from "src/app/shared/components/messaging/messaging.component"
import { SnackbarService } from "src/app/shared/services/snackbar.service"
import { TranslateService } from "@ngx-translate/core"
import { MatDialog } from "@angular/material"

@Component({
  selector: "app-user-phone-verification",
  animations: [smoothly],
  templateUrl: "./user-phone-verification.component.html",
  styleUrls: ["./user-phone-verification.component.scss"],
})
export class UserPhoneVerificationComponent
  extends MessagingComponent
  implements OnInit, OnChanges {
  @Input()
  showVerification: boolean
  @Input()
  phoneNumber: string
  @Output()
  verified = new EventEmitter<boolean>()
  verificationNumber: number
  verificationNumberSent: boolean

  constructor(
    private userService: UserService,
    protected snackbarService: SnackbarService,
    protected translateService: TranslateService,
    protected dialog: MatDialog,
  ) {
    super(snackbarService, translateService, dialog)
  }

  ngOnInit() {
    this.verificationNumberSent = false
    this.verificationNumber = undefined
  }

  ngOnChanges(changes: SimpleChanges): void {
    const phoneNumberChange: SimpleChange = changes.phoneNumber
    if (
      phoneNumberChange &&
      phoneNumberChange.previousValue &&
      phoneNumberChange.currentValue !== phoneNumberChange.previousValue
    ) {
      this.ngOnInit()
    }
  }

  checkVerificationNumber() {
    this.userService.checkPhoneVerification(this.phoneNumber, this.verificationNumber).subscribe(
      (res: any) => {
        const isExist = res.is_exist
        if (isExist) {
          this.messageSuccess(this.translate("ACCOUNT.VERIFICATION_OK"))
          this.ngOnInit()
          this.verified.emit(true)
        } else {
          this.messageDanger(this.translate("ACCOUNT.VERIFICATION_WRONG"))
        }
      },
      (error: any) => {
        console.log(error)
      },
    )
  }

  sendVerificationNumber() {
    this.userService.createPhoneVerification(this.phoneNumber).subscribe(
      (res: any) => {
        this.verificationNumberSent = true
        this.messageSuccess(this.translate("ACCOUNT.VERIFICATION_SENT"))
      },
      (error: any) => {
        if (error.status === 403) {
          this.verificationNumberSent = true
          this.messageDanger(this.translate("ACCOUNT.VERIFICATION_ALREADY_SENT"))
        }
      },
    )
  }
}
