import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { environment } from "../../../environments/environment"
import { Form } from "../../shared/model/form"
import { tap } from "rxjs/operators"
import { GetProfitSummaryParams, ProfitSummary } from "../types"

@Injectable({
  providedIn: "root",
})
export class MembershipService {
  constructor(private http: HttpClient) {}

  update(id, form: Form) {
    const data = form.formGroup.value
    return this.http.put(environment.apiUrl + `memberships/${id}/`, data).pipe(
      tap(
        (res) => form.clearErrors(),
        (error) => form.setErrors(error),
      ),
    )
  }

  registerAgain(id, form: Form) {
    const data = form.formGroup.value
    return this.http.put(environment.apiUrl + `memberships/${id}/register-again/`, data).pipe(
      tap(
        (res) => form.clearErrors(),
        (error) => form.setErrors(error),
      ),
    )
  }

  getSummary(params: GetProfitSummaryParams) {
    return this.http.get<ProfitSummary>(`${environment.apiUrl}memberme-calculate-for-creators/`, {
      params: {
        ...params,
      },
    })
  }

  unregister(id) {
    const data = {
      amount: null,
      tier: null,
    }

    return this.http.put(environment.apiUrl + `memberships/${id}/unregister/`, data)
  }

  restore(membership) {
    const id = membership.id
    const data = {
      amount: membership.amount,
      tier: membership.tier.id,
    }

    return this.http.put(environment.apiUrl + `memberships/${id}/register-again/`, data)
  }

  getListPaginated(data) {
    return this.http.get(environment.apiUrl + "memberships/", { params: data })
  }

  getListPaginatedByUrl(url) {
    return this.http.get(url)
  }

  getPaymentHistoryListPaginated(id, data) {
    return this.http.get(environment.apiUrl + `memberships/${id}/history/`, { params: data })
  }

  getPaymentHistorySince(id) {
    return this.http.get(environment.apiUrl + `memberships/${id}/since/`)
  }

  getBlockedMemberListPaginated(creatorId, data) {
    return this.http.get(environment.apiUrl + `creators/${creatorId}/member-management/blocks/`, {
      params: data,
    })
  }

  blockMember(creatorId: number, userId: number, blockedReason: string) {
    return this.http.post(environment.apiUrl + `creators/${creatorId}/member-management/block/`, {
      creator: creatorId,
      user: userId,
      blocked_reason: blockedReason ? blockedReason : "",
    })
  }

  noteMember(creatorId: number, userId: number, additionalDetail: string) {
    return this.http.post(environment.apiUrl + `creators/${creatorId}/member-management/note/`, {
      creator: creatorId,
      user: userId,
      additional_detail: additionalDetail ? additionalDetail : "",
    })
  }

  unblockMember(creatorId: number, userId: number) {
    return this.http.post(environment.apiUrl + `creators/${creatorId}/member-management/unblock/`, {
      creator: creatorId,
      user: userId,
    })
  }

  countMember(data) {
    return this.http.get(environment.apiUrl + "memberships/count/", { params: data })
  }
}
