import { Injectable } from "@angular/core"
import { AuthService } from "../../core/services/auth.service"
import { Router } from "@angular/router"

@Injectable({
  providedIn: "root",
})
export class AuthGuardService {
  constructor(private router: Router, private auth: AuthService) {}

  canActivate() {
    const isAuthenticated = this.auth.isAuthenticated()
    const isLoggedIn = this.auth.isLoggedIn()

    if (!isLoggedIn) {
      this.router.navigate(["", { outlets: { popup: ["login"] } }])
    } else if (!isAuthenticated) {
      this.router.navigate(["/users/activate"])
    }
    return isAuthenticated
  }
}
