import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from "@angular/core"
import { Location } from "@angular/common"
import { ActivatedRoute, Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { map, tap } from "rxjs/operators"
import { ContestService } from "../../services/contest.service"
import { CarouselType, Contest, GetPictorialContestParams } from "../../types"
import { PaginationMeta } from "src/app/shared/types/pagination.type"
import { combineLatest } from "rxjs"
import { CreatorService } from "src/app/creator/services/creator.service"
import { SliderService } from "src/app/shared/services/slider.service"
import { CountryRestrictionModalService } from "src/app/shared/services/country-restiction-modal.service"

@Component({
  selector: "app-category-detail-page",
  templateUrl: "./category-detail-page.component.html",
  styleUrls: ["./category-detail-page.component.scss"],
})
export class CategoryDetailPageComponent implements OnInit, AfterViewInit {
  @ViewChild("theme", { static: false }) theme: ElementRef<HTMLElement>

  category: string
  contest: Contest

  itemList
  meta: PaginationMeta
  selectedTheme: string
  themeCarousel
  currentSlide = 0

  isLoading = true
  isFetchingIp = false

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private contestService: ContestService,
    private creatorService: CreatorService,
    private sliderService: SliderService,
    private restrictionService: CountryRestrictionModalService,
  ) {
    this.contest = this.route.snapshot.data.contest
    this.route.paramMap.subscribe((params) => {
      this.category = params.get("category")
    })

    this.route.queryParamMap.subscribe((params) => {
      this.selectedTheme = params.get("theme")
    })

    this.restrictionService.isFetchingIpEvent.subscribe((isFetchingIp: boolean) => {
      this.isFetchingIp = isFetchingIp
    })
  }

  ngAfterViewInit(): void {
    window.scrollTo(0, 0)
    if (this.theme) {
      this.themeCarousel = this.sliderService.getSlide(this.theme.nativeElement, {
        created: undefined,
        updated: undefined,
        animationEnded: undefined,
        loop: false,
        drag: true,
        mode: "snap",
        initial: this.currentSlide,

        breakpoints: {
          "(max-width: 279px)": {
            slides: { perView: 1, spacing: 11 },
          },
          "(min-width: 280px)": {
            slides: { perView: 2, spacing: 11 },
          },
          "(min-width: 360px)": {
            slides: { perView: 3, spacing: 11 },
          },
          "(min-width: 428px)": {
            slides: { perView: 4, spacing: 11 },
          },
          "(min-width: 720px)": {
            slides: { perView: "auto", spacing: 11 },
          },
        },
        slideChanged: (instance) => {
          const { details } = instance.track

          this.currentSlide =
            details.maxIdx == details.abs ? details.slides.length - 1 : details.abs
        },
      })
    }
  }

  ngOnInit() {
    this.getItems()
  }

  goMain() {
    this.location.back()
  }

  getTitle() {
    let key
    switch (this.category) {
      case CarouselType.popular:
        key = "POPULAR"
        break
      case CarouselType.new:
        key = "NEW"
        break
      default:
        return this.category
    }
    return this.translate.instant(`CONTEST.${key}`)
  }

  getItems() {
    this.itemList = undefined
    this.request()
      .pipe(
        map((response) => {
          this.meta = response.meta
          return (response.items || response.results).map((item, idx) => ({
            ...item,
            index: idx + 1,
          }))
        }),
      )
      .subscribe((array) => {
        this.isLoading = false
        this.itemList = array
      })
  }

  request() {
    this.isLoading = true
    if (this.category === CarouselType.creator) {
      return this.contestService
        .getPictorialContestCreatorList({
          limit: 10,
          page: 1,
          latest: true,
          creatorIds: this.contest.active_creators.map((creator) => creator.creator),
          createdAtBetween: [this.contest.start_date, this.contest.end_date],
        })
        .pipe(
          tap(async (res: any) => {
            this.itemList = await combineLatest(
              res.map(
                async (creator) => await this.creatorService.get(creator.creatorId).toPromise(),
              ),
            ).toPromise()
          }),
        )
    }

    const param: GetPictorialContestParams = {
      limit: this.category === CarouselType.popular ? 10 : 24,
      page: 1,
      latest: this.category !== CarouselType.popular,
      likeCount: this.category === CarouselType.popular,
      creatorIds: this.contest.active_creators.map((creator) => creator.creator),
      createdAtBetween: [this.contest.start_date, this.contest.end_date],
      ...(this.selectedTheme && { themeSlugs: [this.selectedTheme] }),
    }

    if (this.category !== CarouselType.popular && this.category !== CarouselType.new) {
      param.themeSlugs = [this.category]
    }

    return this.contestService.getContestItemList(param)
  }

  selectTheme(theme: string) {
    if (this.selectedTheme === theme) {
      this.selectedTheme = undefined
    } else {
      this.selectedTheme = theme
    }
    this.router.navigate([], {
      queryParams: this.selectedTheme ? { theme: this.selectedTheme } : undefined,
      relativeTo: this.route,
      replaceUrl: true,
    })

    this.getItems()
  }

  onScrollDown() {
    if (
      this.meta &&
      this.meta.currentPage < this.meta.totalPages &&
      this.category !== CarouselType.popular &&
      !this.isLoading
    ) {
      const option: GetPictorialContestParams = {
        limit: 24,
        latest: this.category !== CarouselType.popular,
        likeCount: this.category === CarouselType.popular,
        page: this.meta.currentPage + 1,
        creatorIds: this.contest.active_creators.map((creator) => creator.creator),
        createdAtBetween: [this.contest.start_date, this.contest.end_date],
        ...(this.selectedTheme && { themeSlugs: [this.selectedTheme] }),
      }

      if (this.category !== CarouselType.popular && this.category !== CarouselType.new) {
        option.themeSlugs = [this.category]
      }

      this.isLoading = true
      this.contestService
        .getContestItemList(option)
        .pipe(
          map((response) => {
            this.meta = response.meta
            return response.items || response.results
          }),
        )
        .subscribe((array) => {
          this.isLoading = false
          this.itemList = this.itemList.concat(array)
        })
    }
  }
}
