import { Injectable } from "@angular/core"
import { environment } from "../../../environments/environment"
import { HttpClient } from "@angular/common/http"
import { map, tap } from "rxjs/operators"
import { Form } from "../../shared/model/form"
import { Post } from "../types/post.type"
import { Pagination } from "src/app/shared/types/pagination.type"
import { PostCategory } from "../types/post.type"

@Injectable({
  providedIn: "root",
})
export class PostService {
  constructor(private http: HttpClient) {}

  get(id: number | string) {
    return this.http.get<Post>(`${environment.apiUrl}posts/${id}/?is_web=true`)
  }

  getList(data) {
    data.is_web = true
    return this.http.get<Post[]>(`${environment.apiUrl}posts/`, { params: data }).pipe(
      map((res) => {
        return res["results"]
      }),
    )
  }

  getListPaginated(data) {
    data.is_web = true
    return this.http.get<Pagination<Post>>(`${environment.apiUrl}posts/`, { params: data })
  }

  getListPaginatedByUrl(url: string) {
    return this.http.get<Pagination<any>>(url)
  }

  create(form: Form, mode?: PostCategory) {
    const data = form.formGroup.value
    let url = `${environment.apiUrl}posts/`

    if (mode === PostCategory.contributor) {
      url = `${environment.apiUrl}posts/contributor/`
    }
    return this.http.post<Post>(url, data).pipe(
      tap(
        () => form.clearErrors(),
        (error) => form.setErrors(error),
      ),
    )
  }

  update(id: number, form: Form, mode?: PostCategory) {
    const data = form.formGroup.value
    let url = `${environment.apiUrl}posts/${id}/`

    if (mode === PostCategory.contributor) {
      url = `${environment.apiUrl}posts/contributor/${id}/`
    }
    return this.http.put<Post>(url, data).pipe(
      tap(
        () => form.clearErrors(),
        (error) => form.setErrors(error),
      ),
    )
  }

  delete(id: number, mode?: PostCategory) {
    let url = `${environment.apiUrl}posts/${id}/`

    if (mode === PostCategory.contributor) {
      url = `${environment.apiUrl}posts/contributor/${id}/`
    }
    return this.http.delete(url)
  }

  followCreator(url: string) {
    return this.http.post(`${environment.apiUrl}${url}`, {})
  }
  updateLike(postId: number, isLike: boolean) {
    return this.http.post(`${environment.apiUrl}posts/${postId}/like/`, { is_like: isLike })
  }
  postMessageRead(postId: number) {
    return this.http.post(`${environment.apiUrl}posts/${postId}/message_read/`, {})
  }
}
