import { NgModule } from "@angular/core"
import { Routes, RouterModule } from "@angular/router"
import { ExplorePageComponent } from "./pages/explore-page/explore-page.component"
import { ExploreModalPageComponent } from "./pages/explore-modal-page/explore-modal-page.component"
import { PostsTabComponent } from "./components/posts-tab/posts-tab.component"
import { ContentsTabComponent } from "./components/contents-tab/contents-tab.component"

const routes: Routes = [
  {
    path: "preview",
    component: ExplorePageComponent,
    children: [
      {
        path: "",
        pathMatch: "full",
        redirectTo: "posts",
      },
      {
        path: "posts",
        component: PostsTabComponent,
      },
      {
        path: "contents",
        component: ContentsTabComponent,
      },
    ],
  },
  {
    path: "preview/:id",
    component: ExploreModalPageComponent,
    outlet: "popup",
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ExploreRoutingModule {}
