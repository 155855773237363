import { Component, OnInit } from "@angular/core"

@Component({
  selector: "app-security-page",
  templateUrl: "./security-page.component.html",
  styleUrls: ["./security-page.component.scss"],
})
export class SecurityPageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
