import { Component, HostListener, OnDestroy, Renderer2 } from "@angular/core"
import { Router } from "@angular/router"

@Component({
  selector: "app-modal-page",
  template: "",
})
export class ModalPageComponent implements OnDestroy {
  modalBackdrop

  @HostListener("click", ["$event"])
  public onClick(event: any): void {
    event.stopPropagation()
  }

  constructor(protected renderer: Renderer2, protected router: Router) {
    this.modalBackdrop = this.renderer.createElement("div")
    this.renderer.setAttribute(this.modalBackdrop, "class", "modal-backdrop fade show")
    this.renderer.addClass(document.body, "modal-open")
    this.renderer.appendChild(document.body, this.modalBackdrop)
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, "modal-open")
    this.renderer.removeChild(document.body, this.modalBackdrop)
  }

  navigate(route) {
    this.router.navigate([{ outlets: { popup: null } }]).then(() => this.router.navigate(route))
  }

  close() {
    this.router.navigate([{ outlets: { popup: null } }])
  }
}
