import { Injectable } from "@angular/core"
import { map } from "rxjs/operators"
import { UserService } from "src/app/account/services/user.service"
import { PictorialService } from "src/app/board/services/pictorial.service"
import { CreatorService } from "../../services/creator.service"

interface Filter {
  query: any
  [key: string]: any
}

@Injectable({
  providedIn: "root",
})
export class CreatorDetailPageContentsService {
  creator
  creatorId: number

  viewAll = { query: {}, name: "CREATOR_DETAIL_PAGE.ALL" }

  selectedFilter: Filter = this.viewAll

  meta
  pictorials = []
  models = []
  categories = []
  themes = []
  mainThemes = []
  modelCreator = {}

  constructor(
    protected pictorialService: PictorialService,
    protected userService: UserService,
    protected creatorService: CreatorService,
  ) {}

  init(creator) {
    if (this.creator && this.creator.id == creator.id && this.pictorials.length > 0) {
      return
    }
    this.creator = creator
    this.creatorId = creator.id
    this.reset()

    this.getPictorialsResult()
    this.getModelsResult()
    this.getCategoriesResult()
    this.getThemesResult()
    this.getMainThemesResult()
  }

  setFilter(filter) {
    this.selectedFilter = filter
    this.modelCreator = {}
    this.initTabInfo()
  }

  initTabInfo() {
    if (this.creator.creator_type == "photo" || this.creator.creator_type == "sns_and_photo") {
      this.meta = null
      this.pictorials = []
      this.getPictorialsResult()
    }

    if (this.selectedFilter.membermeUri) {
      this.creatorService.get(this.selectedFilter.membermeUri, false).subscribe((creator) => {
        this.modelCreator = creator
      })
    }
  }

  reset() {
    this.meta = null
    this.pictorials = []
    this.categories = []
    this.themes = []
    this.mainThemes = []
    this.modelCreator = {}
  }

  resetPictorial() {
    this.meta = null
    this.pictorials = []
  }

  getPictorialsResult() {
    const queryParams = {
      creatorId: this.creatorId,
      ...this.query,
    }
    if (!this.isMine()) {
      queryParams.release = true
    }
    const meta = {
      limit: 12,
      page: this.meta ? this.meta.currentPage + 1 : 1,
    }

    this.pictorialService.getList(Object.assign(queryParams, meta)).subscribe((res: any) => {
      this.pictorials = meta.page > 1 ? this.pictorials.concat(res.items) : res.items
      this.meta = res.meta
    })
  }

  getModelsResult() {
    const creatorId = this.creatorId
    this.pictorialService
      .getModelList(creatorId)
      .pipe(
        map((res: any) => {
          return res.map((model) => ({
            ...model,
            query: { modelIds: [model.id] },
          }))
        }),
      )
      .subscribe((res: any) => {
        this.models = res
      })
  }

  getCategoriesResult() {
    this.pictorialService
      .getCategoryList(this.creatorId)
      .pipe(
        map((res: any) => {
          return res.map(this.setCategoryQuery)
        }),
      )
      .subscribe((res: any) => {
        this.categories = res
      })
  }

  getThemesResult() {
    this.pictorialService
      .getThemeList(this.creatorId, undefined, false)
      .pipe(
        map((res: any) => {
          return res.items.map((theme) => ({
            id: theme.slug,
            name: theme.name,
            query: { themeSlugs: [theme.slug] },
          }))
        }),
      )
      .subscribe((res: any) => {
        this.themes = res
      })
  }

  getMainThemesResult() {
    this.pictorialService
      .getThemeList(this.creatorId, undefined, true)
      .pipe(
        map((res: any) => {
          return res.items.map((theme) => ({
            id: theme.slug,
            name: theme.name,
            query: { themeSlugs: [theme.slug] },
          }))
        }),
      )
      .subscribe((res: any) => {
        this.mainThemes = res
      })
  }

  setCategoryQuery = (category) => {
    const childCategories = category.childCategories
    const categoryIds = []
    if (childCategories) {
      categoryIds.push(...childCategories.map((c) => c["id"]))
      category.childCategories = category.childCategories.map(this.setCategoryQuery)
      return {
        ...category,
        query: { categoryIds: [...category.childCategories.map((child) => child.id), category.id] },
      }
    }
    return { ...category, query: { categoryIds: [category.id, ...categoryIds] } }
  }

  private isMine() {
    return this.getUser() && this.creatorId === this.getUser().creator
  }

  private getUser() {
    return this.userService.getUser()
  }

  get query() {
    return this.selectedFilter.query
  }
}
