import { Component, Input } from "@angular/core"
import { Router } from "@angular/router"

@Component({
  selector: "app-photobook-login-inducer",
  templateUrl: "./photobook-login-inducer.component.html",
  styleUrls: ["./photobook-login-inducer.component.scss"],
})
export class PhotobookLoginInducerComponent {
  @Input() cover: string
  @Input() isAdultLocked: boolean

  constructor(private router: Router) {}

  openLoginModal() {
    this.router.navigate(["", { outlets: { popup: ["login"] } }], { skipLocationChange: true })
  }
}
