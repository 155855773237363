import { Component, OnInit } from "@angular/core"
import { ActivatedRoute, Data, Router } from "@angular/router"
import { ModelContestDataService } from "../../services/model-contest-data.service"
import { Contest } from "../../types"
import { CookieService } from "angular2-cookie"
import { AuthService } from "src/app/core/services/auth.service"

@Component({
  selector: "app-model-contest-main-page",
  templateUrl: "./model-contest-main-page.component.html",
  styleUrls: ["./model-contest-main-page.component.scss"],
})
export class ModelContestMainPageComponent implements OnInit {
  lang: string
  contest: Contest
  isLoading: boolean

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cookieService: CookieService,
    private auth: AuthService,
    public modelContestDataService: ModelContestDataService,
  ) {}

  ngOnInit() {
    if (!this.auth.isAdult()) {
      this.auth.verifyAdult()
    }
    this.setAfterLoginUri()
    this.route.queryParams.subscribe((params) => {
      if (params["token"] && !this.auth.isLoggedIn()) {
        this.auth.setTokensAndUpdateUser(params["token"])
        this.router.navigate(["/atm"])
      }
    })
    this.route.data.subscribe((data) => {
      if (!this.isActivated()) {
        this.router.navigateByUrl("404")
      }
      this.checkAIContest(data)
      this.setContest()
      this.modelContestDataService.init(this.contest.id)
    })
  }

  isActivated() {
    return this.route.snapshot.data.contest && this.route.snapshot.data.contest.is_activated
  }

  setContest() {
    this.lang = this.route.snapshot.data.lang
    this.contest = this.route.snapshot.data.contest
  }

  private setAfterLoginUri() {
    if (!this.auth.isLoggedIn()) {
      const redirectUrl = new URL(window.location.href)
      this.cookieService.put("redirect_uri", redirectUrl.pathname.replace("(popup:login)", ""))
    } else {
      this.cookieService.remove("redirect_uri")
    }
  }

  private checkAIContest(data: Data) {
    if (data.contest && data.contest.url === "aicontest" && this.isActivated()) {
      this.router.navigateByUrl("archive/aicontest")
    }
  }
}
