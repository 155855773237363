import { Injectable } from "@angular/core"
import { BehaviorSubject } from "rxjs"
import { publishReplay, refCount, switchMap, tap } from "rxjs/operators"
import { Creator } from "src/app/creator/types"

import { CreatorService, RecommendedCreatorParams } from "../../../creator/services/creator.service"

const defaultFilter: RecommendedCreatorParams = {
  sort: "random",
}

@Injectable({
  providedIn: "root",
})
export class RecommendedCreatorDataService {
  constructor(private creatorService: CreatorService) {
    this.fetchNextPage()
  }

  private refresh$ = new BehaviorSubject(undefined)
  creators: {
    order: number
    creator: Creator
  }[] = []
  creators$ = this.refresh$.pipe(
    switchMap((filter: RecommendedCreatorParams) => {
      return this.getPaginatedResults(filter).pipe(
        tap((response) => {
          this.creators = this.creators.concat(response)
        }),
      )
    }),
    publishReplay(1),
    refCount(),
  )

  fetchNextPage(filter: RecommendedCreatorParams = defaultFilter) {
    this.refresh$.next(filter)
  }

  private getPaginatedResults(filter: RecommendedCreatorParams) {
    return this.creatorService.getListPaginatedRecommended({
      count: 20,
      ...filter,
    })
  }
}
