import { Component, Input, OnInit } from "@angular/core"
import { smoothly } from "src/app/animation"
import { GoalService } from "../../services/goal.service"

import { CookieService } from "angular2-cookie/core"
import { CurrencyPipe } from "src/app/shared/pipe/currency.pipe"

@Component({
  selector: "app-goals-slider",
  animations: [smoothly],
  templateUrl: "./goals-slider.component.html",
  styleUrls: ["./goals-slider.component.scss"],
})
export class GoalsSliderComponent implements OnInit {
  @Input() creatorId
  @Input() isGoalActivated: boolean
  origin
  year
  month
  summary
  goalLoaded
  langFlag
  engMonth
  monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  constructor(
    private goalService: GoalService,
    private cookieService: CookieService,
    private currency: CurrencyPipe,
  ) {}

  ngOnInit() {
    if (this.cookieService.get("lang") === "KOR") {
      this.langFlag = "KOR"
    } else {
      this.langFlag = "ENG"
    }

    const today = new Date()
    this.year = today.getFullYear()
    this.month = today.getMonth() + 1
    this.engMonth = this.monthNames[this.month - 1]
    this.initSummary()
  }

  initSummary() {
    this.goalService.getSummary(this.creatorId, this.year, this.month).subscribe((res: any) => {
      if (res.amount_current) {
        res.amount_current = this.currency.transform(res.amount_current)
      }

      this.summary = res
      this.goalLoaded = true
      if (!this.origin) {
        this.origin = res
      }
    })
  }

  hasGoal() {
    return this.summary && (this.summary.amount_goal || this.summary.count_fans_goal)
  }

  moveNext() {
    const next = this.summary.next
    if (next) {
      this.year = next.year
      this.month = next.month
      this.engMonth = this.monthNames[this.month - 1]
      this.initSummary()
    }
  }

  movePrev() {
    const prev = this.summary.prev
    if (prev) {
      this.year = prev.year
      this.month = prev.month
      this.engMonth = this.monthNames[this.month - 1]
      this.initSummary()
    }
  }
}
