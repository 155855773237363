import { Component, OnInit } from "@angular/core"
import { CookieService } from "angular2-cookie/core"
import { Form } from "src/app/shared/model/form"
import { FormService } from "src/app/shared/services/form.service"

import { UserService } from "../../services/user.service"
import { MessagingComponent } from "src/app/shared/components/messaging/messaging.component"
import { SnackbarService } from "src/app/shared/services/snackbar.service"
import { TranslateService } from "@ngx-translate/core"
import { MatDialog } from "@angular/material"
import { Location } from "@angular/common"

@Component({
  selector: "app-user-password-reset-page",
  templateUrl: "./password-reset-page.component.html",
  styleUrls: ["./password-reset-page.component.scss"],
})
export class PasswordResetPageComponent extends MessagingComponent implements OnInit {
  form: Form
  submitDisabled = false
  langFlag

  constructor(
    private formService: FormService,
    private userService: UserService,
    private location: Location,
    private cookieService: CookieService,
    protected snackbarService: SnackbarService,
    protected translateService: TranslateService,
    protected dialog: MatDialog,
  ) {
    super(snackbarService, translateService, dialog)
  }

  ngOnInit() {
    this.onWebView()
    this.setForm()
  }

  setForm() {
    this.form = null
    this.formService.getFormData("users/password/reset/").subscribe((form) => (this.form = form))
  }

  onWebView() {
    const split_url = this.location.path().split(/[?&]/) // '?' 또는 '&'을 기준으로 스플릿
    split_url.map((url) => {
      if (url.includes("lang")) {
        const value = url.split("=")[1]

        switch (value) {
          case "KOR":
            this.cookieService.put("lang", "KOR")
            this.translateService.use("ko")
            break
          default:
            this.cookieService.put("lang", "ENG")
            this.translateService.use("en")
        }
        // url을 수정함으로써 언어 변경이 가능하도록 함 없으면 언어가 유지됨
        window.location.replace(split_url[0])
      }
    })
  }

  onSubmit() {
    this.submitDisabled = true
    this.userService.sendPasswordResetEmail(this.form).subscribe(
      (res) => {
        this.submitDisabled = false
        this.messageSuccess(this.translate("ACCOUNT.RESET_PASSWORD_MAIL_SUCCESS"))
      },
      (error) => {
        this.submitDisabled = false
        console.log(error)
        // this.form.setErrors(error, false)
      },
    )
  }
}
