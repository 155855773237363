import { Injectable } from "@angular/core"
import { BehaviorSubject } from "rxjs"
import { publishReplay, refCount, switchMap, tap } from "rxjs/operators"
import { RecommendedCreatorParams } from "../../../creator/services/creator.service"
import { TierService } from "../../../creator/services/tier.service"
import { MembershipTier } from "../../../creator/types"

const defaultFilter: RecommendedCreatorParams = {
  sort: "order",
}

@Injectable({
  providedIn: "root",
})
export class PromotionTierService {
  constructor(private tierService: TierService) {}

  private refresh$ = new BehaviorSubject(undefined)
  tiers: MembershipTier[] = []
  tiers$ = this.refresh$.pipe(
    switchMap((filter: RecommendedCreatorParams) => {
      return this.getPaginatedResults().pipe(
        tap((response) => {
          this.tiers = response
        }),
      )
    }),
    publishReplay(1),
    refCount(),
  )

  fetch() {
    this.refresh$.next(null)
  }

  private getPaginatedResults() {
    return this.tierService.getPromotionTier()
  }
}
