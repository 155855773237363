import { Component, Input, OnInit, SimpleChange, SimpleChanges } from "@angular/core"
import { CreatorService } from "../../services/creator.service"

@Component({
  selector: "app-creator-list-follower",
  templateUrl: "./creator-list-follower.component.html",
  styleUrls: ["./creator-list-follower.component.scss"],
})
export class CreatorListFollowerComponent implements OnInit {
  @Input() followerObj: any
  followers
  followerResults
  loadingOrNone = true

  constructor(private creatorService: CreatorService) {}

  ngOnInit() {}

  // tslint:disable-next-line: use-life-cycle-interface
  ngOnChanges(changes: SimpleChanges) {
    const followerObj: SimpleChange = changes.followerObj

    if (followerObj && followerObj.currentValue.count > 0) {
      this.loadingOrNone = false
      this.followerResults = followerObj.currentValue.list
    }
  }
}
