import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { environment } from "../../../environments/environment"

@Injectable({
  providedIn: "root",
})
export class CreatorCategoryService {
  constructor(private http: HttpClient) {}

  getList() {
    return this.http.get(environment.apiUrl + "creator_categories/")
  }
}
