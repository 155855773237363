import { Component, OnInit } from "@angular/core"
import { TranslateService } from "@ngx-translate/core"
import { CreatorDetailPageContentsService } from "../creator-detail-page/creator-detail-page-contents.service"

@Component({
  selector: "app-creator-contents-nav",
  templateUrl: "./creator-contents-nav.component.html",
  styleUrls: ["./creator-contents-nav.component.scss"],
})
export class CreatorContentsNavComponent implements OnInit {
  constructor(
    private translateService: TranslateService,
    private creatorDetailPageContentsService: CreatorDetailPageContentsService,
  ) {
    this.creatorDetailPageContentsService.reset()
  }

  menus = [
    {
      id: 1,
      name: this.translateService.instant("CREATOR_NAV.PICTORIAL.ANALYZE"),
      link: "/creators/contents/analysis",
    },
    {
      id: 2,
      name: this.translateService.instant("CREATOR_NAV.PICTORIAL.CATEGORY"),
      link: "/creators/contents/categories",
    },
    {
      id: 3,
      name: this.translateService.instant("CREATOR_NAV.PICTORIAL.MODEL"),
      link: "/creators/contents/models",
    },
  ]

  ngOnInit() {}
}
