import { ErrorHandler, Injectable, NgZone } from "@angular/core"
import { AuthService } from "src/app/core/services/auth.service"

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private INVALID_TOKEN_MSG = "Invalid token."
  constructor(
    private zone: NgZone,
    private authService: AuthService,
  ) {}

  handleError(error: any) {
    console.error("unhandled error", error.message)
    this.zone.run(() => {
      if (error.status === 401 && this.isInvalidTokenError(error)) {
        this.authService.clear()
        window.location.href = "/"
      }
    })
  }

  isInvalidTokenError(error: any) {
    return error.error && error.error.detail === this.INVALID_TOKEN_MSG
  }
}
