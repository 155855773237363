import { NgModule } from "@angular/core"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuardService } from "../shared/guards/auth-guard.service"
import { NotificationListActivityPageComponent } from "./pages/notification-list-activity-page/notification-list-activity-page.component"

const routes: Routes = [
  {
    path: "notifications/activity",
    component: NotificationListActivityPageComponent,
    canActivate: [AuthGuardService],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NotificationRoutingModule {}
