import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core"
import { CreatorService } from "../../services/creator.service"
import { CookieService } from "angular2-cookie/core"
import { map } from "rxjs/operators"
import { Router } from "@angular/router"
import _ from "lodash"
import { Util } from "src/app/shared/utils/utils"

@Component({
  selector: "app-creator-list-media",
  templateUrl: "./creator-list-media.component.html",
  styleUrls: ["./creator-list-media.component.scss"],
})
export class CreatorListMediaComponent implements OnInit, OnChanges, OnDestroy {
  @Input() data: any
  @Input() enableMembership = false
  @Input() fromCancelMembershipPage = false
  paginatedResults$
  langFlag

  page: number = 1
  pageUrl: string

  constructor(
    private creatorService: CreatorService,
    private cookieService: CookieService,
    private router: Router,
  ) {}

  ngOnInit() {
    if (this.cookieService.get("lang") !== "KOR") {
      this.langFlag = "ENG"
    } else {
      this.langFlag = "KOR"
    }

    if (window.state) {
      this.data = window.state

      if (!this.pageUrl && window.state.url && isNaN(Number(window.state.url))) {
        this.pageUrl = window.state.url
        this.page = Util.parsePaginationUrl(window.state.url)
      }

      if (!window.state.url || !this.pageUrl) {
        this.getPaginatedResults()
      } else {
        this.getPaginatedResultsByUrl(this.data.url)
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const { currentValue, previousValue } = changes.data

    if (!_.isEqual(currentValue, previousValue)) {
      this.getPaginatedResults()
    }
  }

  ngOnDestroy(): void {
    window.state = null
  }

  getPaginatedResults() {
    this.page = 1
    const data = Object.assign({}, this.data)

    if (data["category"] === "all") {
      delete data["category"]
    }

    if (this.router.url == "/creators") {
      if (window.state && window.state.url) {
        const url = window.state.url
        data.url = url
      }
      window.state = data
    }

    // data.ordering = '-_count_contributors';
    this.paginatedResults$ = this.creatorService.getListPaginated(data).pipe(
      map((mappedData: any) => {
        this.pageUrl = mappedData.next || mappedData.previous
        this.creatorService.emitChangeEvent(true)
        return mappedData
      }),
    )
  }

  getPaginatedResultsByUrl(pageNo: number) {
    this.creatorService.emitChangeEvent(false)
    let url: number | string = pageNo
    if (!isNaN(Number(pageNo))) {
      url = Util.getPaginationUrl(this.pageUrl, pageNo)
      this.page = pageNo
    }
    if (this.router.url == "/creators") {
      window.state.url = url
    }

    this.paginatedResults$ = this.creatorService.getListPaginatedByUrl(url).pipe(
      map((data: any) => {
        this.pageUrl = data.next || data.previous
        this.creatorService.emitChangeEvent(true)
        return data
      }),
    )
  }
}
