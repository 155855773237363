import { Component, Input, OnInit } from "@angular/core"
import { Comment } from "src/app/board/types/comment.type"
import { PictorialService } from "src/app/board/services/pictorial.service"
import { TranslateService } from "@ngx-translate/core"

@Component({
  selector: "app-pictorial-comments",
  templateUrl: "./pictorial-comments.component.html",
  styleUrls: ["./pictorial-comments.component.scss"],
})
export class PictorialCommentsComponent implements OnInit {
  @Input() pictorialId: string
  @Input() creatorId: number
  @Input() writable: boolean = false
  comments: Comment[]

  constructor(
    private pictorialService: PictorialService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.fetchComments()
  }

  private async fetchComments() {
    const params = {
      pictorialId: this.pictorialId,
    }
    const comments = await this.pictorialService.getComments(params).toPromise()
    this.comments = comments.results.reverse()
  }

  async deleteComment(id: number) {
    if (!confirm(this.translateService.instant("ALERT_CONFIRM.DELETE_COMMENT_CONFIRM"))) {
      return
    }
    this.comments = this.comments.filter((comment) => comment.id !== id)
    await this.pictorialService.deleteComment(id).toPromise()
  }

  async postComment(content: string) {
    const body = {
      pictorialId: this.pictorialId,
      content: content,
      creatorId: this.creatorId,
    }
    const comment = await this.pictorialService.postComment(body).toPromise()

    this.comments = this.comments.concat(comment)
  }
}
