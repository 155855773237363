import { Component, OnInit } from "@angular/core"
import { CookieService } from "angular2-cookie"

@Component({
  selector: "app-landing-fifth-pictorial",
  templateUrl: "./landing-fifth-pictorial.component.html",
  styleUrls: ["./landing-fifth-pictorial.component.scss"],
})
export class LandingFifthPictorialComponent implements OnInit {
  langFlag
  constructor(private cookieService: CookieService) {
    this.langFlag = cookieService.get("lang")
  }

  ngOnInit() {}
}
