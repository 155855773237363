import { Component, Input, OnInit } from "@angular/core"
import { KeenSliderHooks, KeenSliderOptions } from "keen-slider"
import { Breakpoint } from "src/app/shared/constants/constants"

@Component({
  selector: "app-model-contest-recommended-models",
  templateUrl: "./model-contest-recommended-models.component.html",
  styleUrls: ["./model-contest-recommended-models.component.scss"],
})
export class ModelContestRecommendedModelsComponent implements OnInit {
  @Input() title: string
  @Input() subTitle: string

  sliderOptions: KeenSliderOptions = {
    breakpoints: {
      [`(min-width: ${Breakpoint["sm"]})`]: {
        slides: { perView: 4, spacing: 8 },
      },
      [`(min-width: ${Breakpoint["lg"]})`]: {
        slides: { perView: 5, spacing: 14 },
      },
    },
    slides: { perView: 3, spacing: 6 },
  }

  constructor() {}

  ngOnInit() {}
}
