import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { FormControl } from "@angular/forms"
import { MatAutocompleteTrigger } from "@angular/material"
import { TranslateService } from "@ngx-translate/core"
import { CookieService } from "angular2-cookie"
import { Observable } from "rxjs"
import { map, startWith } from "rxjs/operators"
import { Form } from "src/app/shared/model/form"

import { CountryCodeType } from "src/app/shared/services/country.service"
import { SnackbarService } from "src/app/shared/services/snackbar.service"

import { CreatorSettleInfoDataService } from "./service/creator-settle-info-data.service"
import { CountryType } from "./type"

@Component({
  selector: "app-creator-settle-info-page",
  templateUrl: "./creator-settle-info-page.component.html",
  styleUrls: ["./creator-settle-info-page.component.scss"],
})
export class CreatorSettleInfoPageComponent implements OnInit, OnDestroy {
  @ViewChild(MatAutocompleteTrigger, {
    static: false,
  })
  autocomplete: MatAutocompleteTrigger

  settleInfo: Form
  recipient: Form

  lang: string

  filteredSellerCountryCode: Observable<CountryCodeType[]>
  filteredSettleCountryCode: Observable<CountryCodeType[]>

  formControls: Record<string, FormControl> = {}
  isSubmitted = false

  countryFilter: (value: string) => CountryCodeType[]
  countryDisplayFn: (value: CountryCodeType) => string | undefined

  originalCloseFunction: (this: GlobalEventHandlers, ev: MouseEvent) => any
  countryType: CountryType

  changeForm
  getFormStatus

  constructor(
    private dataService: CreatorSettleInfoDataService,
    private cookieService: CookieService,
    private snackbarService: SnackbarService,
    private translateService: TranslateService,
  ) {
    this.lang = this.cookieService.get("lang")
    this.changeForm = this.dataService.changeForm
    this.getFormStatus = this.dataService.getFormStatus
    this.countryFilter = this.dataService.countryFilter
    this.countryDisplayFn = this.dataService.countryDisplayFn
  }

  ngOnDestroy(): void {
    if (this.originalCloseFunction) {
      const closeBtn = document.getElementById("btnCloseLayer")
      closeBtn.onclick = this.originalCloseFunction
    }
  }

  async ngOnInit() {
    try {
      const { settleInfo, recipient } = await this.dataService.get()
      this.formControls = this.dataService.formControls
      this.settleInfo = settleInfo

      this.recipient = recipient

      this.countryType = this.dataService.getCountryType()

      if (!this.settleInfo.getValue("country").code) {
        this.dataService.getCountryDataOnFetched().add(() => {
          this.settleInfo = this.dataService.settleInfo
          this.recipient = this.dataService.recipient
          this.countryType = this.dataService.getCountryType()
        })
      }
    } catch (e) {
      this.snackbarService.open(e.error, "", 2000, "danger-snackbar")
    }

    this.filteredSellerCountryCode = this.dataService.formControls["country"].valueChanges.pipe(
      startWith(""),
      map((country: string | CountryCodeType) => {
        this.dataService.setCountryType("sellerCountry")

        if (typeof country == "object") {
          this.countryType = this.dataService.getCountryType()
          this.syncCountryControls("sellerCountry")
        }
        return this.dataService.getCountry(country, "sellerCountry")
      }),
    )

    this.filteredSettleCountryCode = this.dataService.formControls[
      "bank_country"
    ].valueChanges.pipe(
      startWith(""),
      map((country: string | CountryCodeType) => {
        return this.dataService.getCountry(country, "settleCountry")
      }),
    )
  }

  callDaumPostcode = (event: Event) => {
    event.stopPropagation()
    event.preventDefault()
    const closeBtn = document.getElementById("btnCloseLayer")
    const element_layer = document.getElementById("layer")

    window.sample2_execDaumPostcode(event)

    this.originalCloseFunction = closeBtn.onclick

    closeBtn.onclick = closeDaumPostcode.bind(this)

    function closeDaumPostcode() {
      element_layer.style.display = "none"

      if (!this.settleInfo.data.address1 || !this.settleInfo.data.postal_code) {
        this.dataService.setAddressError()
      }
    }
  }

  async saveSettleInfo() {
    this.isSubmitted = true
    await this.dataService.upsert()

    this.isSubmitted = false
  }

  syncCountryControls(from?: "sellerCountry" | "settleCountry") {
    switch (from) {
      case "sellerCountry":
        this.settleInfo.data.country = this.formControls["country"].value
        this.settleInfo.data.bank_country = this.formControls["country"].value
        this.formControls["bank_country"].setValue(this.formControls["country"].value)
        this.formControls["bank_country"].markAsTouched()
        break
      case "settleCountry":
        this.settleInfo.data.bank_country = this.formControls["bank_country"].value
        break
      default: {
        if (this.formControls["bank_country"].value.code) {
          this.settleInfo.data.country = this.formControls["bank_country"].value
        } else {
          this.settleInfo.data.bank_country = this.formControls["country"].value
        }
      }
    }
    this.dataService.setCountryType(from)
  }

  getErrorMsg(fieldName: string, required: boolean = false) {
    const requiredTxt = required
      ? this.translateService.instant("ALERT_CONFIRM.REQUIRED_FIELD")
      : ""
    return this.formControls[fieldName].errors && this.formControls[fieldName].errors.message
      ? this.formControls[fieldName].errors.message
      : requiredTxt
  }
}
