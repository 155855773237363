import { Component, OnInit } from "@angular/core"
import { APP_STORE_MEMBERME, PLAY_STORE_MEMBERME } from "../../constants/constants"
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap"

@Component({
  selector: "app-app-download-modal",
  templateUrl: "./app-download-modal.component.html",
  styleUrls: ["./app-download-modal.component.scss"],
})
export class AppDownloadModalComponent implements OnInit {
  constructor(public modalRef: NgbActiveModal) {}

  ngOnInit() {}

  openAppStore() {
    window.open(APP_STORE_MEMBERME)
  }

  openPlayStore() {
    window.open(PLAY_STORE_MEMBERME)
  }
}
