import { Component, OnInit } from "@angular/core"
import { FormService } from "../../../shared/services/form.service"
import { UserService } from "../../services/user.service"
import { Form } from "../../../shared/model/form"
import { ActivatedRoute, Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"

@Component({
  selector: "app-password-reset-confirm-page",
  templateUrl: "./password-reset-confirm-page.component.html",
  styleUrls: ["./password-reset-confirm-page.component.scss"],
})
export class PasswordResetConfirmPageComponent implements OnInit {
  form: Form
  uid: string
  token: string

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formService: FormService,
    private userService: UserService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.setParams()
    this.setForm()
  }

  setParams() {
    this.route.paramMap.subscribe((params) => {
      this.uid = params.get("uid")
      this.token = params.get("token")
    })
  }

  setForm() {
    this.form = null
    this.formService.getFormData("users/password/reset/confirm/").subscribe((form) => {
      form.fields["new_password"]["widget"]["attrs"]["type"] = "password"
      form.fields["re_new_password"]["widget"]["attrs"]["type"] = "password"
      this.form = form
    })
  }

  onSubmit() {
    this.form.formGroup.patchValue({
      uid: this.uid,
      token: this.token,
    })

    this.userService.resetPassword(this.form).subscribe((res) => {
      alert(this.translateService.instant("ALERT_CONFIRM.PASSWORD_RESET"))
      this.router.navigate(["/"])
    })
  }
}
