import { Injectable } from "@angular/core"
import { HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http"
import { CookieService } from "angular2-cookie/core"
import { AuthService } from "../services/auth.service"
import { catchError, mergeMap } from "rxjs/operators"
import { throwError } from "rxjs"
import { UserService } from "src/app/account/services/user.service"

@Injectable({
  providedIn: "root",
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private auth: AuthService,
    private cookieService: CookieService,
    private user: UserService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (req.url.includes("cognito-auth") || req.url.includes("amazonaws.com")) {
      return next.handle(req)
    }
    const authToken = this.auth.getAuthorizationToken()
    if (!this.isRefreshRequest(req.url)) {
      this.auth.refreshCognitoToken()
    }
    let cognitoToken = this.auth.getCognitoToken()
    if (authToken && !cognitoToken) {
      this.auth.requestSetCognitoToken()
    }
    let headers = req.headers.set("Accept-Language", this.getLangCode())
    if (authToken) {
      headers = headers.set("Authorization", `Token ${authToken}`)
    }
    if (cognitoToken) {
      if (
        this.auth.getRemainingTimeUntilIdTokenExpiration() <= 0 &&
        !this.isRefreshRequest(req.url)
      ) {
        this.auth.refreshCognitoToken(true)
        cognitoToken = this.auth.getCognitoToken()
        headers = headers.set("cognito-authorization", `${this.auth.getCognitoToken().IdToken}`)
      }
      headers = headers.set("cognito-authorization", `${cognitoToken.IdToken}`)
    }
    if (req.method === "GET") {
      headers = headers.set("Cache-Control", "no-cache").set("Pragma", "no-cache")
    }
    if (this.getGaClientId()) {
      headers = headers.set("ga-client-id", this.getGaClientId())
    }

    return next.handle(req.clone({ headers: headers })).pipe(
      catchError((error) => {
        if (cognitoToken && this.isPictorialTokenError(error)) {
          return this.user.getCognitoToken(this.auth.getAuthorizationToken()).pipe(
            mergeMap((resp: any) => {
              this.auth.setCognitoToken(resp)
              return next.handle(
                req.clone({
                  headers: req.headers.set("cognito-authorization", `${resp.IdToken}`),
                }),
              )
            }),
          )
        }
        return throwError(error)
      }),
    )
  }

  isRefreshRequest(url: string) {
    return url.includes("cognito-refresh")
  }

  sleep(ms) {
    const wakeUpTime = Date.now() + ms
    while (Date.now() < wakeUpTime) {}
  }

  private getLangCode() {
    if (this.cookieService.get("lang") === "KOR") {
      return "ko"
    }
    return "en"
  }

  private getGaClientId() {
    if (localStorage.getItem("ga_client_id")) {
      return localStorage.getItem("ga_client_id")
    }
    return null
  }

  private isPictorialTokenError(err) {
    return err.status === 401 && err.error.message === "Unauthorized"
  }
}
