import { Component, OnDestroy, ViewChild } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { MessagingComponent } from "src/app/shared/components/messaging/messaging.component"
import { SnackbarService } from "src/app/shared/services/snackbar.service"
import { TranslateService } from "@ngx-translate/core"
import { MatDialog } from "@angular/material"
import { PurchasePermission } from "src/app/creator/pages/creator-photobook-page/type"
import { PictorialDetailPageService } from "./pictorial-detail-page.service"
import { NgbCarousel, NgbModal } from "@ng-bootstrap/ng-bootstrap"
import { AuthService } from "src/app/core/services/auth.service"

@Component({
  selector: "app-pictorial-detail-page",
  templateUrl: "./pictorial-detail-page.component.html",
  styleUrls: ["./pictorial-detail-page.component.scss"],
})
export class PictorialDetailPageComponent extends MessagingComponent implements OnDestroy {
  @ViewChild("voteModal", { static: true }) voteModal: NgbCarousel

  taps = ["photo", "video"]
  pictorialId: string

  constructor(
    protected authService: AuthService,
    protected snackbarService: SnackbarService,
    protected translateService: TranslateService,
    protected dialog: MatDialog,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    public pictorialService: PictorialDetailPageService,
  ) {
    super(snackbarService, translateService, dialog)
    this.route.paramMap.subscribe((params) => {
      this.pictorialId = params.get("id")
      this.pictorialService.init(this.pictorialId)
    })
  }

  ngOnDestroy(): void {
    this.pictorialService.destroy()
  }

  goBack() {
    const notSale =
      [PurchasePermission.allFree, PurchasePermission.membershipFree].includes(
        this.pictorialService.pictorial.salesMethod,
      ) || this.pictorialService.pictorial.priceUsd == 0
    const superVoted = this.pictorialService.status.isPurchased && !notSale
    const votedToday = this.pictorialService.pictorial.isVotedToday

    if (this.pictorialService.pictorial.campaignId != undefined && !superVoted && !votedToday) {
      this.openVoteModal(this.voteModal)
    } else {
      window.history.back()
    }
  }

  private openVoteModal(content: any) {
    this.modalService.open(content, {
      ariaLabelledBy: "modal-basic-title",
      windowClass: "vote-modal",
      centered: true,
      keyboard: false,
      backdrop: "static",
    })
  }

  onTapNoContent($type: "photo" | "video") {
    if ($type === "photo") {
      this.taps.shift()
    } else {
      this.taps.pop()
    }
  }

  updateVoteAndClose(modal) {
    modal.close()
    if (this.isLoggedIn) {
      this.pictorialService.updateVote()
    } else {
      !this.router.navigate(["", { outlets: { popup: ["login"] } }], { skipLocationChange: true })
    }
  }

  closeAndHistoryBack(modal) {
    modal.close()
    window.history.back()
  }

  get covers() {
    return this.pictorialService.pictorial.__covers__
      ? this.pictorialService.pictorial.__covers__.map((cover) => cover.file.url)
      : []
  }

  get isLoggedIn() {
    return this.authService.isLoggedIn()
  }

  get isAdultLock() {
    return this.pictorialService.pictorial.adult && !this.authService.isAdult()
  }

  get PurchasePermission() {
    return PurchasePermission
  }
}
