import { Component, OnInit } from "@angular/core"
import { ArticleService } from "../../services/article.service"
import { switchMap } from "rxjs/operators"
import { ActivatedRoute, ParamMap, Router } from "@angular/router"
import { CookieService } from "angular2-cookie"

@Component({
  selector: "app-article-detail-page",
  templateUrl: "./article-detail-page.component.html",
  styleUrls: ["./article-detail-page.component.scss"],
})
export class ArticleDetailPageComponent implements OnInit {
  article$
  isKorean

  constructor(
    private route: ActivatedRoute,
    private articleService: ArticleService,
    private router: Router,
    private cookieService: CookieService,
  ) {}

  ngOnInit() {
    this.isKorean = this.cookieService.get("lang") === "KOR"
    const article_observable$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        return this.articleService.get(params.get("id"))
      }),
    )

    const observer = (article) => {
      if ("category" in article && article.title) {
        this.article$ = article
      } else {
        this.redirectToMain()
      }
    }

    // 에러가 발생했을 경우 HelpCenter main page로 리다이렉션 함.
    article_observable$.subscribe(observer, (error) => this.redirectToMain())
  }

  // language 설정에 따라 detail page에서 국가 Flag를 변경하면 그 글에 해당하는 id가 존재하지 않아 문제가 발생.
  // => 가져온 데이터가 없어서 에러가 발생하면(게시글 없음), 헬프센터 메인으로 보내주도록 설정.
  redirectToMain() {
    this.router.navigate(["/article-categories"])
  }
}
