import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from "@angular/core"
import { DomSanitizer } from "@angular/platform-browser"
import { COMMA, ENTER } from "@angular/cdk/keycodes"
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatChipInputEvent, MatDialog } from "@angular/material"
import { CookieService } from "angular2-cookie/core"
import { of } from "rxjs"

import { FormService } from "src/app/shared/services/form.service"
import { Form } from "src/app/shared/model/form"
import { PostService } from "../../services/post.service"
import { TranslateService } from "@ngx-translate/core"
import { PollService } from "src/app/board/services/poll.service"
import * as moment from "moment"
import { MessagingComponent } from "src/app/shared/components/messaging/messaging.component"
import { SnackbarService } from "src/app/shared/services/snackbar.service"
import { environment } from "src/environments/environment"
import { AuthService } from "src/app/core/services/auth.service"
import { UserFileService } from "src/app/board/services/user-file.service"
import { FroalaEditorService } from "src/app/board/services/froala-editor.service"
import { DatepickerFormatterComponent } from "src/app/shared/components/datepicker-formatter/datepicker-formatter.component"
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from "@angular/material-moment-adapter"
import { CreatorFileSizeLimitService } from "src/app/creator/services/creator-file-size-limit.service"
import { POST_VISIBILITY_MAX } from "src/app/shared/constants/constants"

const DEFAULT_ANSWERS = [
  { value: null, position: 0 },
  { value: null, position: 1 },
]

@Component({
  selector: "app-post-form-creator-card",
  templateUrl: "./post-form-creator-card.component.html",
  styleUrls: ["./post-form-creator-card.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useClass: DatepickerFormatterComponent },
  ],
})
export class PostFormCreatorCardComponent extends MessagingComponent implements OnInit {
  @ViewChild("fileInput", { static: false }) fileInput: ElementRef
  @Input() postId?: number
  @Input() currentUser // 파일크기 예외처리를 위해 추가됨
  @Input() showVisibility = true
  @Input() isThankYouPost = false
  @Input() hasAdultContent = false
  @Output() submitted = new EventEmitter<void>()
  VISIBILITY_MAX = POST_VISIBILITY_MAX

  // options for Floara wysiwyg
  public options: Object
  public editorContent: string

  form: Form
  currentFileControl
  fileInputAccept
  isDaysToVisibleChecked = false
  visible: Date = new Date()
  fileSanitized
  inProgress = false
  attachFileIdSet = new Set<string>()
  removeFileIdSet = new Set<string>()

  // tag input variables
  readonly separatorKeysCodes: number[] = [ENTER, COMMA]
  selectable = true
  removable = true
  addOnBlur = true
  tags: string[] = []
  isPollEnabled = false
  allowMultipleVote = false
  answers: any[] = DEFAULT_ANSWERS.map((x) => Object.assign({}, x))
  pollTitle: string
  endDate: Date = moment().add(1, "days").toDate()

  // 성인 컨텐츠 여부
  isAdultContent = false

  constructor(
    @Inject(MAT_DATE_FORMATS) private config: DatepickerFormatterComponent,
    private froalaEditorService: FroalaEditorService,
    private userFileService: UserFileService,
    private auth: AuthService,
    private sanitizer: DomSanitizer,
    private postService: PostService,
    private pollService: PollService,
    private creatorFileSizeLimitService: CreatorFileSizeLimitService,
    private formService: FormService,
    private cd: ChangeDetectorRef,
    private cookieService: CookieService,
    private _adapter: DateAdapter<any>,
    protected snackbarService: SnackbarService,
    protected translateService: TranslateService,
    protected dialog: MatDialog,
  ) {
    super(snackbarService, translateService, dialog)

    this.config.lang = this.cookieService.get("lang")
    this._adapter.setLocale(translateService.currentLang)
  }

  async ngOnInit() {
    await this.setOptions()
    this.setForm()
    this.isAdultContent = this.hasAdultContent
  }

  addTag(event: MatChipInputEvent): void {
    const input = event.input
    const value = event.value
    const tag = value || ""

    if (tag.trim() && this.tags.indexOf(tag) < 0 && this.tags.length <= 0) {
      this.tags.push(tag.trim())
    }

    if (input) {
      input.value = ""
    }
  }

  removeTag(tag: string): void {
    const index = this.tags.indexOf(tag)

    if (index >= 0) {
      this.tags.splice(index, 1)
    }
  }

  updatePostFileMetadata(visibility: number) {
    if (this.currentUser && this.currentUser.creator) {
      const creatorId = this.currentUser.creator
      const userId = this.currentUser.id
      return this.userFileService.updateFileMetadata({
        ids: [...this.attachFileIdSet],
        creatorId: String(creatorId),
        visibility: String(visibility),
        userId: String(userId),
      })
    }
    return of()
  }

  async setOptions() {
    const { size_mb } = await this.creatorFileSizeLimitService.get().toPromise().catch(() => ({ size_mb: 20 }))
    const fileSizeMb = size_mb ? size_mb : 20
    const requestParams = {}
    const addFileItem = this.froalaEditorService.getAddFileItemFunction(this)
    const removeFileItem = this.froalaEditorService.getRemoveFileItemFunction(this)
    const fileSizeErrorMsg = this.translateService.instant("FROALA_EDITOR.FILE_UNDER_SIZE", { size: fileSizeMb + "MB" })
    const fileSizeLimit = fileSizeMb * 1024 * 1024

    const translator = this.translateService
    const cognitoToken = this.auth.getCognitoToken()
    this.options = {
      requestHeaders: {
        "cognito-authorization": cognitoToken && cognitoToken.IdToken ? cognitoToken.IdToken : null,
      },
      key: "IB2A6A4B2pD1D1D1H5E1J4A16B3C7E7kuA-8wyyoszF4ogw==",
      placeholderText: this.translateService.instant("FROALA_EDITOR.PLACE_HOLDER"),
      charCounterCount: false,
      toolbarButtons: [
        "bold",
        "italic",
        "underline",
        "fontFamily",
        "fontSize",
        "color",
        "insertLink",
        "insertImage",
        "insertVideo",
        "emoticon",
        "insertFile",
      ],
      requestWithCORS: false,
      requestWithCredentials: false,
      // Set the image upload URL.
      imageUploadURL: environment.uploadFileUrl,
      imageUploadParams: requestParams,
      // Set request type.
      imageUploadMethod: "POST",
      // Set max image size to 20MB.
      imageMaxSize: 20 * 1024 * 1024,
      // Allow to upload PNG and JPG.
      imageAllowedTypes: ["jpeg", "jpg", "png"],

      // Set the default image width (Image's original width)
      imageDefaultWidth: 0,

      // Set the file upload URL.
      fileUploadURL: environment.uploadFileUrl,
      fileUploadParams: requestParams,
      // Set request type.
      fileUploadMethod: "POST",
      // Set max file size to 20MB.
      // 파일크기 예외처리 확인 (예외 150MB)
      fileMaxSize: fileSizeLimit,
      // Allow to upload any file.
      fileAllowedTypes: ["*"],

      // Set the file upload URL.
      videoUploadURL: environment.uploadFileUrl,
      videoUploadParams: requestParams,
      // Set request type.
      videoUploadMethod: "POST",
      // Set max file size to 10MB.
      // 파일크기 예외처리 확인 (예외 150MB)
      videoMaxSize: fileSizeLimit,
      // videoMaxSize: 20 * 1024 * 1024,
      // Allow to upload any file.
      videoAllowedTypes: ["mp4", "avi"],

      events: {
        "froalaEditor.commands.undo": () => {
        },
        "froalaEditor.image.uploaded": addFileItem,
        "froalaEditor.image.removed": removeFileItem,
        "froalaEditor.image.replaced": function(e, editor, $img, res) {
        },
        "froalaEditor.image.inserted": function(e, editor, $img) {
        },
        "froalaEditor.image.error": function(e, editor, error) {
          const $popup = editor.popups.get("image.insert")
          const $layer = $popup.find(".fr-image-progress-bar-layer")
          // Bad link.
          if (error.code === 1) {
            $layer.find("h3").text(translator.instant("FROALA_EDITOR.INVALID_LINK"))
          } else if (error.code === 2) {
            $layer.find("h3").text(translator.instant("FROALA_EDITOR.INVALID_LINK_RESPONSE"))
          } else if (error.code === 3) {
            $layer.find("h3").text(translator.instant("FROALA_EDITOR.IMAGE_UPLOAD_FAILED"))
          } else if (error.code === 4) {
            $layer.find("h3").text(translator.instant("FROALA_EDITOR.PARSING_FAILED"))
          } else if (error.code === 5) {
            $layer.find("h3").text(translator.instant("FROALA_EDITOR.FILE_UNDER_20MB"))
          } else if (error.code === 6) {
            $layer.find("h3").text(translator.instant("FROALA_EDITOR.INVALID_IMAGE_TYPE"))
          } else if (error.code === 7) {
            $layer.find("h3").text(translator.instant("FROALA_EDITOR.SAME_DOMAIN_IE9"))
          }
        },
        "froalaEditor.file.uploaded": addFileItem,
        "froalaEditor.file.removed": removeFileItem,
        "froalaEditor.file.inserted": function(e, editor, files) {
        },
        "froalaEditor.file.error": function(e, editor, error, response) {
          const $popup = editor.popups.get("file.insert")
          const $layer = $popup.find(".fr-file-progress-bar-layer")
          // Bad link.
          if (error.code === 1) {
            $layer.find("h3").text(translator.instant("FROALA_EDITOR.INVALID_LINK"))
          } else if (error.code === 2) {
            $layer.find("h3").text(translator.instant("FROALA_EDITOR.INVALID_LINK_RESPONSE"))
          } else if (error.code === 3) {
            $layer.find("h3").text(translator.instant("FROALA_EDITOR.FILE_UPLOAD_FAILED"))
          } else if (error.code === 4) {
            $layer.find("h3").text(translator.instant("FROALA_EDITOR.PARSING_FAILED"))
          } else if (error.code === 5) {
            $layer.find("h3").text(fileSizeErrorMsg)
          } else if (error.code === 6) {
            $layer.find("h3").text(translator.instant("FROALA_EDITOR.INVALID_FILE_TYPE"))
          } else if (error.code === 7) {
            $layer.find("h3").text(translator.instant("FROALA_EDITOR.SAME_DOMAIN_IE9"))
          }
        },
        "froalaEditor.video.uploaded": addFileItem,
        "froalaEditor.video.removed": removeFileItem,
        "froalaEditor.video.inserted": function(e, editor, files) {
        },
        "froalaEditor.video.error": function(e, editor, error, response) {
          const $popup = editor.popups.get("video.insert")
          const $layer = $popup.find(".fr-video-progress-bar-layer")
          // Bad link.
          if (error.code === 1) {
            $layer.find("h3").text(translator.instant("FROALA_EDITOR.INVALID_LINK"))
          } else if (error.code === 2) {
            $layer.find("h3").text(translator.instant("FROALA_EDITOR.invalid_link_response"))
          } else if (error.code === 3) {
            $layer.find("h3").text(translator.instant("FROALA_EDITOR.VIDEO_UPLOAD_FAILED"))
          } else if (error.code === 4) {
            $layer.find("h3").text(translator.instant("FROALA_EDITOR.PARSING_FAILED"))
          } else if (error.code === 5) {
            $layer.find("h3").text(fileSizeErrorMsg)
          } else if (error.code === 6) {
            $layer.find("h3").text(translator.instant("FROALA_EDITOR.INVALID_VIDEO_TYPE"))
          } else if (error.code === 7) {
            $layer.find("h3").text(translator.instant("FROALA_EDITOR.SAME_DOMAIN_IE9"))
          }
        },
      },
    }
  }

  setForm() {
    const url = this.postId ? `posts/${this.postId}/` : "posts/"
    this.form = null
    this.currentFileControl = null
    this.formService.getFormData(url).subscribe((form) => {
      this.form = form

      if (this.form.data && this.form.data.tags) {
        this.tags = this.form.data.tags
      } else {
        this.tags = []
      }
      if (
        this.form.fields &&
        this.form.fields.visibility &&
        this.form.fields.visibility.choices
      ) {
        this.formService.transformVisibilityChoices(this.form.fields.visibility.choices)
      }
      if (this.form.data && this.form.data.polls && this.form.data.polls.length) {
        const polls: any[] = this.form.data.polls
        if (polls && polls.length) {
          this.isPollEnabled = polls[0].is_enabled
          this.pollTitle = polls[0].question
          this.allowMultipleVote = polls[0].allow_multiple_vote
          this.endDate = polls[0].end_date
          this.answers = polls[0].items.sort((a: any, b: any) => a.position - b.position)
        }
      } else {
        this.isPollEnabled = false
        this.pollTitle = null
        this.allowMultipleVote = false
        this.endDate = moment().add(1, "days").toDate()
        this.answers = DEFAULT_ANSWERS.map((x) => Object.assign({}, x))
      }
      // set content for wysiwyg (temporary fix)
      this.editorContent = this.form.data.content
      this.isAdultContent = this.form.data.is_adult_content
      this.attachFileIdSet = new Set(
        this.form.data.attach_file_ids ? this.form.data.attach_file_ids : [],
      )
      this.registerFormChangeEvents()

      this.isDaysToVisibleChecked = this.form.data.opened_status == "scheduled";
      this.visible = this.form.data.visible
    })
  }

  onSubmit() {
    this.inProgress = true
    const submitCallback = () => {
      this.setForm()
      this.submitted.emit()
      this.inProgress = false
      this.messageSuccess(this.translate("CREATOR_DETAIL_PAGE.POST_SAVED"))
    }
    const formValues = this.form.formGroup.value
    this.froalaEditorService.updateFileIdSet(
      this.removeFileIdSet,
      this.attachFileIdSet,
      this.editorContent,
    )
    this.updatePostFileMetadata(formValues.visibility).subscribe((updated: any) => {
      if (updated && updated.ids && updated.ids.length) {
        this.editorContent = this.froalaEditorService.updateFileItemIdAttribute(
          this.editorContent,
          updated.ids,
        )
      }
      // temporary fix for wysiwyg
      formValues.content = this.editorContent
      formValues.media_type = "video"
      formValues.is_wysiwyg = true
      // formValues.title = 'title';
      formValues.video_embed = "temporary"
      formValues.tags = this.tags

      formValues.is_adult_content = this.isAdultContent
      formValues.attach_file_ids = this.attachFileIdSet.size ? [...this.attachFileIdSet] : []
      if (this.isDaysToVisibleChecked) {
        formValues.visible = this.visible
      }

      // file, video_index 를 빼야 api 동작. 임시 방편
      delete formValues.file
      delete formValues.video_index

      if (this.postId) {
        this.postService.update(this.postId, this.form).subscribe(
          (res: any) => {
            if (!this.isThankYouPost) {
              this.updatePolls(res, submitCallback)
            } else {
              submitCallback()
            }
          },
          (error) => {
            this.inProgress = false
          },
        )
      } else {
        this.postService.create(this.form).subscribe(
          (res: any) => {
            this.createPolls(res, submitCallback)
            // reset editor content after submission
            this.editorContent = ""
          },
          (error) => {
            this.inProgress = false
          },
        )
      }
      this.froalaEditorService.confirmAttachFiles([...this.attachFileIdSet])
      this.froalaEditorService.deleteAttachFiles([...this.removeFileIdSet])
    })
  }

  private createPolls(res: any, callback) {
    if (res.id && this.isPollEnabled && !this.isThankYouPost) {
      const poll = {
        is_enabled: this.isPollEnabled,
        question: this.pollTitle,
        allow_multiple_vote: this.allowMultipleVote,
        end_date: moment(this.endDate).format("YYYY-MM-DD"),
        post: res.id,
        items: this.answers,
      }
      this.pollService.create(res.id, poll).subscribe((_) => {
        callback()
      })
    } else {
      callback()
    }
  }

  private updatePolls(res: any, callback) {
    const poll = {
      is_enabled: this.isPollEnabled,
      question: this.pollTitle,
      allow_multiple_vote: this.allowMultipleVote,
      end_date: moment(this.endDate).format("YYYY-MM-DD"),
      post: res.id,
      items: this.answers,
    }
    if (res.polls && res.polls.length) {
      if (this.isPollEnabled && !this.isThankYouPost) {
        this.pollService.update(res.id, res.polls[0].id, poll).subscribe(
          (_) => {
            callback()
          },
          (error) => {
            console.log(error)
          },
        )
      }
    } else {
      if (this.isPollEnabled && !this.isThankYouPost) {
        this.pollService.create(res.id, poll).subscribe(
          (_) => {
            callback()
          },
          (error) => {
            console.log(error)
          },
        )
      } else {
        callback()
      }
    }
  }

  registerFormChangeEvents() {
    this.form.formGroup.get("media_type").valueChanges.subscribe(this.onMediaTypeChange.bind(this))
    this.form.formGroup.get("visibility").valueChanges.subscribe(this.onVisibilityChange.bind(this))
  }

  onMediaTypeChange(val) {
    this.form.formGroup.patchValue({
      video_embed: "",
      file: "",
    })
    this.fileSanitized = null
    this.form.formGroup.get("images").controls = []

    if (val === "audio") {
      this.fileInputAccept = "audio/*"
    } else if (val === "image") {
      this.fileInputAccept = "image/*"
    }
  }

  onDaysToVisibleChange(val) {
  }

  onVisibilityChange(val) {
    if (val === this.VISIBILITY_MAX.toString() || val === this.VISIBILITY_MAX) {
      this.isDaysToVisibleChecked = false
    }
  }

  removeItem(items: any[], current) {
    items.forEach((item, index) => {
      if (item === current) {
        items.splice(index, 1)
      }
    })
  }

  addAnswer(ord: number) {
    if (this.answers.length > 5) {
      this.messageDanger("Max 6")
    } else {
      this.answers.push({
        value: "",
        position: ord,
      })
    }
  }
}
