import { EventEmitter, Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { Router } from "@angular/router"
import { tap } from "rxjs/operators"
import { CookieService } from "angular2-cookie"
import jwt_decode from "jwt-decode"

import { CognitoJWT, CognitoUser } from "src/app/shared/types/auth.types"
import { environment } from "src/environments/environment"
import { Form } from "../../shared/model/form"
import { UserService } from "../../account/services/user.service"
import { EventService } from "./event.service"
import { fromPromise } from "rxjs/internal-compatibility"

@Injectable({
  providedIn: "root",
})
export class AuthService {
  change = new EventEmitter()

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private userService: UserService,
    private event: EventService,
    private router: Router,
  ) {
    event.onUserUpdate$.subscribe((res) => {
      this.emitChangeEvent(res)
    })
  }

  emitChangeEvent(s) {
    this.change.emit(s)
  }
  getChangeEmitter() {
    return this.change
  }

  getAuthorizationToken(): string {
    return localStorage.getItem("token")
  }

  setRefreshSemaphore() {
    localStorage.setItem("refreshSemaphore", "true")
  }

  removeRefreshSemaphore() {
    localStorage.removeItem("refreshSemaphore")
  }

  getRefreshSemaphore() {
    const semaphore = localStorage.getItem("refreshSemaphore")
    return semaphore && semaphore === "true"
  }

  getRemainingTime(expTime: any): number {
    if (isNaN(expTime)) {
      return 0
    }
    const expiration = new Date(0)
    const now = new Date()
    expiration.setUTCSeconds(expTime)
    return expiration.getTime() - now.getTime()
  }

  getCognitoUser(): CognitoUser | null {
    const token = this.getCognitoToken()
    if (token && token.IdToken) {
      return jwt_decode(token.IdToken) as CognitoUser
    }
    return null
  }

  getRemainingTimeUntilIdTokenExpiration(): number {
    const token = this.getCognitoToken()
    if (token && token.IdToken) {
      const idToken: any = jwt_decode(token.IdToken)
      return this.getRemainingTime(idToken["exp"])
    }
    return 0
  }

  refreshCognitoToken(expired = false) {
    const refreshLimit = expired ? 0 : 300000
    const token = this.getCognitoToken()
    if (token && token.IdToken) {
      const idToken: any = jwt_decode(token.IdToken)
      if (this.getRemainingTime(idToken["exp"]) <= refreshLimit && !this.getRefreshSemaphore()) {
        this.setRefreshSemaphore()
        this.userService.refreshCognitoToken(token.RefreshToken).subscribe(
          (res) => {
            this.setCognitoToken(res)
            this.removeRefreshSemaphore()
          },
          () => {},
        )
      }
    }
  }

  getCognitoToken(): CognitoJWT | undefined {
    let jsonToken
    try {
      const token = localStorage.getItem("cognito-token")
      const cognitoToken = JSON.parse(token)
      jsonToken = cognitoToken && cognitoToken.IdToken ? cognitoToken : undefined
    } catch (e) {
      console.error("getCognitoToken: invalid token")
      jsonToken = undefined
    }
    return jsonToken
  }

  setTokensAndUpdateUser(token: string): void {
    this.setAuthorizationToken(token)
    this.requestSetCognitoToken()
    this.userService.updateUser()
  }

  private setAuthorizationToken(token: string): void {
    localStorage.setItem("token", token)
  }

  setCognitoToken(tokenData: any) {
    if (tokenData && tokenData["IdToken"]) {
      localStorage.setItem("cognito-token", JSON.stringify(tokenData))
      this.cookieService.put("IdToken", tokenData["IdToken"], {
        domain: ".memberme.net",
      })
    }
  }

  requestSetCognitoToken() {
    return this.userService.getCognitoToken(this.getAuthorizationToken()).subscribe(
      (res) => {
        this.setCognitoToken(res)
      },
      () => {},
    )
  }

  private async chainLogin(loginData: any) {
    const loggedIn = await this.http
      .post(`${environment.apiUrl}users/login/`, loginData)
      .toPromise()
    const cognitoToken = await this.userService.getCognitoToken(loggedIn["token"]).toPromise()
    this.setAuthorizationToken(loggedIn["token"])
    this.setCognitoToken(cognitoToken)
    return this.userService.updateUser()
  }

  login(form: Form) {
    const data = Object.assign(form.formGroup.value, { web_client: true })
    return fromPromise(this.chainLogin(data)).pipe(
      tap(
        () => form.clearErrors(),
        (error) => form.setErrors(error),
      ),
    )
  }

  clear(): void {
    localStorage.removeItem("token")
    localStorage.removeItem("cognito-token")
    localStorage.removeItem("user")
    localStorage.removeItem("maintenanceChecked")
    this.removeRefreshSemaphore()
    this.event.onUserUpdate$.emit("out")
  }

  logout(): void {
    this.http.post(environment.apiUrl + "users/logout/", {}).subscribe(
      () => {
        this.clear()
        // redirect to landing page after logout
        this.router.navigate(["/"]).then(() => {})
      },
      (error) => {
        if (console) {
          console.log("logout error", error)
        }
        alert("Logout failed")
      },
    )
  }

  isAuthenticated(): boolean {
    const user = this.userService.getUser()
    return this.isLoggedIn() && user && user.is_verified
  }

  isLoggedIn(): boolean {
    return !!this.getAuthorizationToken()
  }

  isAdult(): boolean {
    const user = this.userService.getUser()
    return (
      (this.isLoggedIn() && user && user.verified_as_adult) ||
      this.cookieService.get("adultVerify") === "true"
    )
  }

  verifyAdult() {
    this.moveVerifyAdultPage()
  }

  moveVerifyAdultPage() {
    this.router.navigate(["", { outlets: { popup: ["users", "verify-adult"] } }], {
      skipLocationChange: true,
    })
  }

  moveLoginPage() {
    this.router.navigate(["", { outlets: { popup: ["login"] } }], { skipLocationChange: true })
  }
}
