import { NgModule } from "@angular/core"
import { RouterModule, Routes } from "@angular/router"
import { ContestRoutingDataService } from "./guards/contest-routing-data.service"
import { ContestApplyPageComponent } from "./pages/contest-apply-page/contest-apply-page.component"
import { ContestMainPageComponent } from "./pages/contest-main-page/contest-main-page.component"

import { CategoryDetailPageComponent } from "./pages/category-detail-page/category-detail-page.component"
import { ContestResolverService } from "./services/contest-resolver.service"
import { ModelContestMainPageComponent } from "./pages/model-contest-main-page/model-contest-main-page.component"
import { AsianTopModelRankingPageComponent } from "./pages/asian-top-model-ranking-page/asian-top-model-ranking-page.component"
import { environment } from "src/environments/environment"
import { ModelContestTopPageComponent } from "./pages/model-contest-top-page/model-contest-top-page.component"

const atmPath = environment.currentContestURI ? environment.currentContestURI : "atm"

const routes: Routes = [
  {
    path: "archive",
    canActivateChild: [ContestRoutingDataService],
    children: [
      {
        path: ":contest",
        pathMatch: "full",
        component: ContestMainPageComponent,
      },
      {
        path: ":contest/apply",
        component: ContestApplyPageComponent,
      },
      {
        path: ":contest/:category",
        component: CategoryDetailPageComponent,
      },
    ],
    resolve: {
      data: ContestResolverService,
    },
  },
  {
    path: atmPath,
    canActivateChild: [ContestRoutingDataService],
    children: [
      {
        path: "",
        pathMatch: "full",
        component: ModelContestMainPageComponent,
      },
      {
        path: "top",
        component: ModelContestTopPageComponent,
      },
      {
        path: "models",
        component: AsianTopModelRankingPageComponent,
      },
      {
        path: "**",
        redirectTo: "/404",
        pathMatch: "full",
      },
    ],
    resolve: {
      data: ContestResolverService,
    },
  },
  {
    path: "contest",
    canActivateChild: [ContestRoutingDataService],
    children: [
      {
        path: ":contest",
        pathMatch: "full",
        component: ModelContestMainPageComponent,
      },
      {
        path: ":contest/top",
        component: ModelContestTopPageComponent,
      },
      {
        path: ":contest/models",
        component: AsianTopModelRankingPageComponent,
      },
      {
        path: "**",
        redirectTo: "/404",
        pathMatch: "full",
      },
    ],
    resolve: {
      data: ContestResolverService,
    },
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ContestRoutingModule {}
