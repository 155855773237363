import { Pipe, PipeTransform } from "@angular/core"

// width값을 전달해주면 그에 맞춰 백엔드에 리사이징된 이미지를 요청
@Pipe({
  name: "resizeImage",
})
export class ResizeImage implements PipeTransform {
  transform(value: string, width: number): any {
    return value + "?w=" + width
  }
}
