import { Component, OnInit, Renderer2 } from "@angular/core"
import { MatIconRegistry } from "@angular/material"
import { DomSanitizer } from "@angular/platform-browser"
import { ActivatedRoute, Router } from "@angular/router"
import { ModalPageComponent } from "src/app/shared/components/modal-page/modal-page.component"
import { PreviewPostDataService } from "../../services/post-data.service.js"

@Component({
  selector: "app-explore-modal-page",
  templateUrl: "./explore-modal-page.component.html",
  styleUrls: ["./explore-modal-page.component.scss"],
})
export class ExploreModalPageComponent extends ModalPageComponent implements OnInit {
  post: any
  postIndex: number
  look: any
  subscription
  postsLength: number
  constructor(
    protected renderer: Renderer2,
    protected router: Router,
    private route: ActivatedRoute,
    protected dataService: PreviewPostDataService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
  ) {
    super(renderer, router)
    iconRegistry.addSvgIcon(
      "arrow-icon",
      sanitizer.bypassSecurityTrustResourceUrl("assets/img/icon/explore-arrow.svg"),
    )
  }

  ngOnInit() {
    this.route.params.subscribe(async (params) => {
      this.dataService.openedModal = true
      this.post = await this.dataService.getPost(params.id).toPromise()
      this.postIndex = this.dataService.posts.findIndex((i) => i.id == this.post.id)
      this.postsLength = this.dataService.posts.length
    })
  }

  onMovePost(i) {
    this.router.navigate([
      { outlets: { popup: ["preview", this.dataService.posts[this.postIndex + i].id] } },
    ])
  }

  ngOnDestroy() {
    this.dataService.openedModal = false
    this.renderer.removeClass(document.body, "modal-open")
    this.renderer.removeChild(document.body, this.modalBackdrop)
  }
}
