import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { MessagingComponent } from "../../../shared/components/messaging/messaging.component"
import { Form } from "../../../shared/model/form"
import { AuthService } from "../../../core/services/auth.service"
import { EventService } from "../../../core/services/event.service"
import { FormService } from "../../../shared/services/form.service"
import { SnackbarService } from "../../../shared/services/snackbar.service"
import { TranslateService } from "@ngx-translate/core"
import { MatDialog } from "@angular/material"
import { Model } from "../../types"
import { PictorialService } from "../../../board/services/pictorial.service"

@Component({
  selector: "app-model-meta-form",
  templateUrl: "./model-meta-form.component.html",
  styleUrls: ["./model-meta-form.component.scss"],
})
export class ModelMetaFormComponent extends MessagingComponent implements OnInit {
  @Output() submitted = new EventEmitter<any>()
  @Input() model: Model
  @Input() className = ""
  form: Form
  metaFields = {
    metaTitle: {
      type: "string",
      required: false,
      label: "meta-title",
      max_length: 8000,
      style: {
        placeholder: "meta-title",
      },
      widget: {
        name: "input",
        attrs: {
          type: "text",
          maxlength: 8000,
        },
      },
    },
    metaDescription: {
      type: "string",
      required: false,
      label: "meta-description",
      max_length: 8000,
      style: {
        placeholder: "meta-description",
      },
      widget: {
        name: "input",
        attrs: {
          type: "text",
          maxlength: 8000,
        },
      },
    },
  }
  inProgress = false

  constructor(
    public auth: AuthService,
    public event: EventService,
    private formService: FormService,
    protected snackbarService: SnackbarService,
    protected translateService: TranslateService,
    protected dialog: MatDialog,
    private pictorialService: PictorialService
  ) {
    super(snackbarService, translateService, dialog)
    event.onUserUpdate$.subscribe(this.ngOnInit.bind(this))
  }

  ngOnInit() {
    this.initForm()
  }

  initForm() {
    this.form = this.formService.getFormFromFields(this.metaFields, this.model)
  }

  onSubmit() {
    this.inProgress = true
    this.pictorialService.updateModel({id: this.model.id, ...this.form.formGroup.value}).subscribe((model) => {
      this.inProgress = false
      window.location.reload()
    })
  }
}
