import { Component, Input, OnChanges, OnInit } from "@angular/core"
import { Model } from "src/app/models/types"
import { ModelDataService } from "src/app/models/services/model-data.service"
import { Router } from "@angular/router"

@Component({
  selector: "app-model-detail-pictorials",
  templateUrl: "./model-detail-pictorials.component.html",
  styleUrls: ["./model-detail-pictorials.component.scss"],
})
export class ModelDetailPictorialsComponent implements OnInit, OnChanges {
  @Input() model: Model
  isLoading: boolean

  constructor(public modelDataService: ModelDataService, private router: Router) {
    this.isLoading = false
  }

  ngOnInit() {
    this.init()
  }

  ngOnChanges() {
    this.init()
  }

  private init() {
    this.modelDataService.init(this.model.id)
    this.isLoading = true
  }

  onScroll() {
    this.modelDataService.loadNextPage()
  }

  get infiniteScrollDisabled(): boolean {
    return false
  }

  moveToPictorials(pictorialId: string) {
    this.router.navigate(["/pictorials", pictorialId])
  }
}
