import { Component, OnInit } from "@angular/core"
import { TranslateService } from "@ngx-translate/core"
import { Form } from "../../../shared/model/form"
import { FormService } from "../../../shared/services/form.service"
import { CreatorService } from "../../services/creator.service"

@Component({
  selector: "app-creator-update-earning-visibility-page",
  templateUrl: "./creator-update-earning-visibility-page.component.html",
  styleUrls: ["./creator-update-earning-visibility-page.component.scss"],
})
export class CreatorUpdateEarningVisibilityPageComponent implements OnInit {
  form: Form

  constructor(
    private formService: FormService,
    private creatorService: CreatorService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.formService
      .getFormData("creators/me/", ["open_earning"])
      .subscribe((formData) => (this.form = formData))
  }

  onSubmit() {
    this.creatorService
      .updatePartial(this.form)
      .subscribe(() => alert(this.translateService.instant("ALERT_CONFIRM.SAVED")))
  }
}
