import { Component, OnInit } from "@angular/core"

@Component({
  selector: "app-storage-list-item",
  templateUrl: "./storage-list-item.component.html",
  styleUrls: ["./storage-list-item.component.scss"],
})
export class StorageItemComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
