import { Component, Input, OnInit } from "@angular/core"
import { Form } from "../../../shared/model/form"
import { FormService } from "../../../shared/services/form.service"
import { UserService } from "../../../account/services/user.service"
import { MembershipService } from "../../services/membership.service"
import { ActivatedRoute, Params, Router } from "@angular/router"
import { combineLatest, Observable } from "rxjs"
import { ComponentPageTitle } from "../../components/page-title/page-title"
import { map, tap } from "rxjs/operators"
import { BreakpointObserver } from "@angular/cdk/layout"
import { MatDialog } from "@angular/material"
import { SnackbarService } from "src/app/shared/services/snackbar.service"
import { TranslateService } from "@ngx-translate/core"
import { MessagingComponent } from "src/app/shared/components/messaging/messaging.component"
import { TransactionService } from "src/app/payment/services/transaction.service"
import { CookieService } from "angular2-cookie"
import { PictorialService } from "src/app/board/services/pictorial.service"
import * as moment from "moment"
import { PictorialDetailDialogComponent } from "../../components/pictorial-detail-card/pictorial-detail-card.component"
import { Util } from "src/app/shared/utils/utils"

const MIDDLE_WIDTH_BREAKPOINT = 960

@Component({
  selector: "app-buyer-list-page",
  templateUrl: "./buyer-list-page.component.html",
  styleUrls: ["./buyer-list-page.component.scss"],
})
export class BuyerListPageComponent extends MessagingComponent implements OnInit {
  @Input() params: Observable<Params>
  @Input() selectedMembership
  form: Form
  isPopup: boolean
  isScreenSmall: Observable<boolean>
  paginatedResult$
  memberCount
  displayedColumns: string[] = ["user_name", "name", "amount", "date_begin", "status"]
  private tabChanged = false
  isKorean

  page: number = 1
  pageUrl: string

  constructor(
    public _componentPageTitle: ComponentPageTitle,
    private route: ActivatedRoute,
    private router: Router,
    private formService: FormService,
    private userService: UserService,
    private membershipService: MembershipService,
    private breakpoints: BreakpointObserver,
    private transactionService: TransactionService,
    private cookieService: CookieService,
    private pictorialService: PictorialService,
    protected snackbarService: SnackbarService,
    protected translateService: TranslateService,
    protected dialog: MatDialog,
  ) {
    super(snackbarService, translateService, dialog)
    this.isScreenSmall = breakpoints
      .observe(`(max-width: ${MIDDLE_WIDTH_BREAKPOINT}px)`)
      .pipe(map((breakpoint) => breakpoint.matches))
    this.isScreenSmall.subscribe((isSmall: boolean) => {
      this.isPopup = isSmall
    })
  }

  ngOnInit() {
    this.isKorean = this.cookieService.get("lang") === "KOR"
    const creatorId = this.userService.getUser().creator
    this.route.params.subscribe(() => {
      this._componentPageTitle.setSubtitle("구매 리스트")
    })

    combineLatest(
      this.route.queryParams,
      this.formService.getFilterFormData(`payment-pictorial/?creator=${creatorId}`),
    )
      .pipe(map(([param, form]) => ({ param, form })))
      .subscribe(({ param, form }) => {
        const { fields } = form
        if (fields.status && Array.isArray(fields.status.choices)) {
          fields.status.choices.forEach((choice) => {
            switch (choice.value) {
              case "paid":
                choice.display_name = this.translateService.instant("PAYMENT_STATUS.PAID")
                break
              case "refunded":
                choice.display_name = this.translateService.instant("PAYMENT_STATUS.REFUNDED")
                break
              case "suspended":
              case "cancelled":
                choice.display_name = this.translateService.instant("PAYMENT_STATUS.CANCELED")
                break
              case "paypal_cancelled":
                choice.display_name = this.translateService.instant(
                  "PAYMENT_STATUS.CANCELED_PAYPAL",
                )
                break
            }
          })
        }

        this.form = form
        this.setupFormFields()
        this.form.formGroup.patchValue({
          creator: creatorId,
          year: moment().get("year"),
          month: moment().get("M") + 1,
        })
        if (param && param.name) {
          this.form.formGroup.patchValue({
            search: param.name,
          })
        }
        this.getPaginatedResult()
        this.form.formGroup.valueChanges.subscribe(() => {
          this.getPaginatedResult()
        })
      })
  }

  setupFormFields() {
    this.form.fields.search.style = {
      placeholder: this.translateService.instant("PICTORIAL_BUYER_PAGE.SEARCH_USER"),
    }
    this.form.fields.month.choices = Array(13)
      .fill(null)
      .map((_, index) => ({
        value: index > 0 ? index : "",
      }))
    this.form.fields.month.label = this.isKorean ? "월" : "month"
    this.form.fields.month.type = "ChoiceFilter"
    this.form.fields.month.widget.name = "select"
    this.form.fields.year.widget = {
      name: "input",
      attrs: { type: "number" },
    }
    this.form.fields.year.style = { placeholder: "연도" }
  }

  selectMembership(membership) {
    this.selectedMembership = membership
    if (this.isPopup) {
      this.openMembershipDetailDialog()
    }
  }

  openMembershipDetailDialog(): void {
    this.openDialog(
      {
        width: "100vw",
        maxWidth: "100vw",
        panelClass: "membership-detail-dialog-panel",
        data: { selectedMembership: this.selectedMembership },
      },
      (result) => {
        if (result) {
          this.getPaginatedResult()
        }
      },
      PictorialDetailDialogComponent,
    )
  }

  getPaginatedResult() {
    this.page = 1
    this.paginatedResult$ = this.transactionService
      .getListPaginated(this.form.formGroup.value)
      .pipe(
        tap((data) => {
          this.pageUrl = data["next"] || data["previous"]
          if (data["results"]) {
            if (data["results"] && data["results"].length) {
              this.selectedMembership = data["results"][0]
            }
          }
        }),
        tap((data) => {
          // 한국어 일때 status 보여지는 값 변경
          if (this.isKorean) {
            data["results"].forEach((payment) => {
              payment.status = this.form.fields.status.choices.find(
                (status) => status.value === payment.status,
              ).display_name
            })
          }
        }),
        tap(async (data) => {
          const pictorialIds = data["results"]
            .map((payment) => payment.pictorial_id)
            .filter((id) => id.length > 10)
          const pictorials: any = await this.pictorialService.getListByIds(pictorialIds).toPromise()
          data["results"] = data["results"].map((payment) => {
            const pictorial = pictorials.find((pictorial) => pictorial.id === payment.pictorial_id)
            payment.title = pictorial ? pictorial.title : ""
            return payment
          })
        }),
      )
  }

  getMemberCount() {
    if (!this.tabChanged) {
      const data: any = this.copyObject(this.form.formGroup.value)
      delete data.activated_user
      this.memberCount = this.membershipService.countMember(data).subscribe((res) => {
        this.memberCount = res
      })
    }
    this.tabChanged = false
  }

  getPaginatedResultByUrl(pageNo: number) {
    const url = Util.getPaginationUrl(this.pageUrl, pageNo)

    this.page = pageNo
    this.paginatedResult$ = this.membershipService.getListPaginatedByUrl(url).pipe(
      map((data) => {
        // 한국어 일때 status 보여지는 값 변경
        if (this.isKorean) {
          data["results"].forEach((payment) => {
            payment.status = this.form.fields.status.choices.find(
              (status) => status.value === payment.status,
            ).display_name
          })
        }
        return data
      }),
      tap(async (data) => {
        const pictorialIds = data["results"]
          .map((payment) => payment.pictorial_id)
          .filter((id) => id.length > 10)
        const pictorials: any = await this.pictorialService.getListByIds(pictorialIds).toPromise()
        data["results"] = data["results"].map((payment) => {
          const paymentPictorial = pictorials.find(
            (pictorial) => pictorial.id === payment.pictorial_id,
          )
          payment.title = paymentPictorial ? paymentPictorial.title : ""
          return payment
        })
      }),
    )
  }

  memberChanged($isChanged: boolean) {
    if ($isChanged) {
      this.getPaginatedResult()
    }
  }

  filterChanged($event: number) {
    this.tabChanged = true
    this.form.formGroup.patchValue({
      activated_user: !$event,
    })
    this.getPaginatedResult()
  }

  copyObject(objectToCopy: any) {
    return JSON.parse(JSON.stringify(objectToCopy))
  }

  searchPictorial = (pictorial) => {
    this.form.formGroup.patchValue({
      pictorial_id: pictorial.id ? pictorial.id : "",
    })
  }
}
