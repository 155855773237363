import { Component, Input, OnChanges, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { AuthService } from "src/app/core/services/auth.service"
import { Contest, ContestModel } from "../../types"

@Component({
  selector: "app-model-top-item",
  templateUrl: "./model-top-item.component.html",
  styleUrls: ["./model-top-item.component.scss"],
})
export class ModelTopItemComponent implements OnChanges, OnInit {
  @Input() model: ContestModel
  @Input() isFirst = false
  @Input() rank: number
  contest: Contest

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  ngOnInit() {}

  ngOnChanges(changes) {}

  onClick() {
    if (this.model.id) {
      this.router.navigate(["/", "models", this.model.id])
    }
  }
}
