import { User } from "src/app/account/types/user.type"

export interface Comment {
  id?: number
  content: string
  children?: number[]
  user?: User
  timesince?: string
  permissions?: string[]
  post?: number
  pictorial_id?: string
  creator?: number
  parent?: number
}

export enum CommentContentType {
  post = "post",
  comment = "comment",
}
