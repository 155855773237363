import { Component, OnInit, HostListener } from "@angular/core"
import { Router } from "@angular/router"
import { UserService } from "src/app/account/services/user.service"
import { smoothly } from "src/app/animation"
import { CreatorService } from "../../services/creator.service"
import { CookieService } from "angular2-cookie/core"

@Component({
  selector: "app-dashboard-page",
  animations: [smoothly],
  templateUrl: "./dashboard-page.component.html",
  styleUrls: ["./dashboard-page.component.scss"],
})
export class DashboardPageComponent implements OnInit {
  isContributing = false
  filterData = {
    category: "creator",
  }

  // FOR CHECKING LANGUAGE
  langFlag

  allTxtWidth
  fatkatTxtWidth
  tabUdln
  currentTab = "all"
  // for smooth transition
  pageReady = false

  fatkats
  fatkatExists
  followerExists = false
  fatkatObj = { count: 0, list: [] }
  followers
  followerObj = { count: 0, list: [] }

  constructor(
    private router: Router,
    private userService: UserService,
    private creatorService: CreatorService,
    private cookieService: CookieService,
  ) {}

  ngOnInit() {
    if (this.cookieService.get("lang") !== "KOR") {
      this.langFlag = "ENG"
    } else {
      this.langFlag = "KOR"
    }

    // 처음 실행 시, 팔로우 한 크리에이터 게시물을 호출한다.
    this.setFilterData(false)
    this.getPaginatedResults()

    setTimeout(() => {
      // this.setTabValues();
      this.pageReady = true
    }, 200)
  }

  getPaginatedResults() {
    const data = {
      is_contributing: true,
    }
    // check if the user is subscribed to any creator
    this.fatkats = this.creatorService.getListPaginated(data).subscribe((res) => {
      // check if there's any creators that the user fatkats
      this.checkRes(res)
    })

    const followerData = {
      is_following: true,
    }

    this.followers = this.creatorService.getListPaginated(followerData).subscribe((res) => {
      // check if there's any creators that the user follows
      this.checkFollowerRes(res)
    })
  }

  checkRes(result) {
    // subscribed
    if (result.count > 0) {
      this.fatkatExists = true
      this.fatkatObj = { count: result.count, list: result.results }
    } else {
      this.fatkatExists = false
    }
  }

  checkFollowerRes(result) {
    if (result.count > 0) {
      this.followerObj = { count: result.count, list: result.results }
      this.followerExists = true
    } else {
      this.followerExists = false
    }
  }

  getUser() {
    return this.userService.getUser()
  }

  setFilterData(isContributing, firstStart = false) {
    // setting filter
    this.isContributing = isContributing
    if (isContributing) {
      delete this.filterData["is_related"]
      this.filterData["is_contributing"] = true
    } else {
      delete this.filterData["is_contributing"]
      this.filterData["is_related"] = true
    }
  }

  // slider animation logic
  setTabValues() {
    // width of slider depending on highlighted tab
    this.allTxtWidth = document.getElementById("all-post-tab-txt").getBoundingClientRect().width
    this.fatkatTxtWidth = document
      .getElementById("fatkat-post-tab-txt")
      .getBoundingClientRect().width

    // reference to slider
    this.tabUdln = document.getElementById("tab-udln-id")
  }

  slideFn(contribute) {
    // clicked 팻캣전용
    if (contribute) {
      this.currentTab = "fatkat"
      // this.positionSlideToFatkatPost();
    } else if (contribute === false && contribute !== null) {
      this.currentTab = "all"
      // this.positionSlideToAllPost();
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    if (!this.tabUdln || !this.tabUdln.style || !this.tabUdln.style.width) {
      return
    }
    if (this.currentTab === "all") {
      // this.positionSlideToAllPost();
      this.tabUdln.style.width = this.allTxtWidth.toString() + "px"
    } else {
      this.tabUdln.style.width = this.fatkatTxtWidth.toString() + "px"
      // this.positionSlideToFatkatPost();
    }
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnDestroy() {
    this.fatkats.unsubscribe()
    this.followers.unsubscribe()
  }
}
