import { Injectable } from "@angular/core"
import { Form } from "../../shared/model/form"
import { environment } from "../../../environments/environment"
import { tap } from "rxjs/operators"
import { HttpClient } from "@angular/common/http"
import { BehaviorSubject } from "rxjs"
import { FormService } from "src/app/shared/services/form.service"

export interface BankAccount {
  bankName: string
  accountHolder: string
  accountNumber: string
}

export const BANK_ACCOUNT_API_URL = "bank_accounts/me/"
@Injectable({
  providedIn: "root",
})
export class BankAccountService {
  private bankAccount$: BehaviorSubject<BankAccount> = new BehaviorSubject({
    bankName: "-",
    accountHolder: "-",
    accountNumber: "-",
  })

  constructor(private http: HttpClient, private formService?: FormService) {
    this.subscribe()
  }

  update(form: Form) {
    const data = form.formGroup.value
    return this.http.put(environment.apiUrl + BANK_ACCOUNT_API_URL, data).pipe(
      tap(
        (res) => form.clearErrors(),
        (error) => form.setErrors(error),
      ),
    )
  }

  subscribe() {
    this.bankAccount$.subscribe()
  }

  next(nextValue: BankAccount) {
    this.bankAccount$.next(nextValue)
  }

  getCurrentBankAccount(): BehaviorSubject<BankAccount> {
    return this.bankAccount$
  }

  setBankAccountUsingApi() {
    this.formService.getFormData(BANK_ACCOUNT_API_URL).subscribe((form) => {
      const bankAccount = form.fields.name.choices.find((choice) => choice.value === form.data.name)

      // Global-State에 가져온 계좌 정보 등록
      this.next({
        bankName: bankAccount ? bankAccount.display_name : "-",
        accountHolder: form.data.holder || "-",
        accountNumber: form.data.number || "-",
      })
    })
  }
}
