import { Component, OnInit, NgModule } from "@angular/core"
import { MatTabsModule } from "@angular/material"
import { CookieService } from "angular2-cookie/core"

@Component({
  selector: "app-membership-cancel-page",
  templateUrl: "./membership-cancel-page.component.html",
  styleUrls: ["./membership-cancel-page.component.scss"],
})
@NgModule({
  imports: [MatTabsModule],
  declarations: [],
  bootstrap: [],
})
export class MembershipCancelPageComponent implements OnInit {
  currentTab = 1

  //
  langFlag

  constructor(private cookieService: CookieService) {}

  ngOnInit() {
    if (this.cookieService.get("lang") === "KOR") {
      this.langFlag = "KOR"
    } else {
      this.langFlag = "ENG"
    }
  }
}
