import { Component, Input, OnInit } from "@angular/core"
import { Comment } from "src/app/board/types/comment.type"
import { Post } from "src/app/board/types/post.type"
import { MessageType } from "../../types/message.type"

@Component({
  selector: "app-chat-speech-bubble",
  templateUrl: "./chat-speech-bubble.component.html",
  styleUrls: ["./chat-speech-bubble.component.scss"],
})
export class ChatSpeechBubbleComponent implements OnInit {
  @Input() isMine: boolean
  @Input() data: Post | Comment
  @Input() messageId: number
  @Input() showTail: boolean = true
  @Input() type: MessageType

  messageType = MessageType

  constructor() {}

  ngOnInit() {}
}
