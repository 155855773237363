import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core"
import { PurchasePermission } from "src/app/creator/pages/creator-photobook-page/type"
import { PictorialDetailPageService } from "../../pictorial-detail-page.service"
import { CookieService } from "angular2-cookie"
import { Util } from "src/app/shared/utils/utils"
import { MatDialog, MatDialogRef } from "@angular/material"
import { NgbModal, NgbCarouselConfig } from "@ng-bootstrap/ng-bootstrap"
import { PictorialBuyModalComponent } from "src/app/payment/pages/pictorial-buy-modal/pictorial-buy-modal.component"
import { AppDownloadModalComponent } from "src/app/shared/components/app-download-modal/app-download-modal.component"
import { APP_STORE_MEMBERME, PLAY_STORE_MEMBERME } from "src/app/shared/constants/constants"
import { BuyModalData } from "src/app/payment/pages/pictorial-buy-modal/types/modal-data.type"
import { PictorialPaymentSuccessDialogComponent } from "src/app/payment/components/pictorial-payment-success-dialog/pictorial-payment-success-dialog.component"
import { PictorialPaymentResult } from "src/app/board/types/pictorial-payment.type"
import { DynamicLinkService } from "src/app/board/services/dynamic-link.service"
import { AuthService } from "src/app/core/services/auth.service"
import { Router } from "@angular/router"

@Component({
  selector: "app-pictorial-info",
  templateUrl: "./pictorial-info.component.html",
  styleUrls: ["./pictorial-info.component.scss"],
})
export class PictorialInfoComponent implements OnInit, OnChanges {
  @Input() service: PictorialDetailPageService

  dialogRef: MatDialogRef<unknown, unknown>

  langFlag: string

  informationList
  constructor(
    config: NgbCarouselConfig,
    private modalService: NgbModal,
    private cookieService: CookieService,
    private authService: AuthService,
    private dynamicLinkService: DynamicLinkService,
    private dialog: MatDialog,
    private router: Router,
  ) {
    this.langFlag = this.cookieService.get("lang")
    config.interval = 1000000
    config.wrap = true
    config.keyboard = true
  }

  ngOnInit() {}

  onClickWatchContent() {
    if (this.isAdultLock) {
      this.authService.verifyAdult()
    } else if (!this.service.status.availableAccess) {
      this.openPaymentModal()
    } else if (this.pictorial.visibleOnWeb) {
      this.onScrollToContent()
    } else if (Util.isMobileDevice()) {
      this.dynamicLinkService.openContentsLink(
        this.pictorial.creatorId.toString(),
        this.pictorial.id,
      )
    } else {
      this.toAppDownload()
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.informationList = this.getInformationList()
  }

  private openPaymentModal() {
    if (this.isLoggedIn) {
      this.open(PictorialBuyModalComponent, {
        pictorial: this.pictorial,
        creator: this.creator,
        tier: this.tier,
        isPurchasedPictorial: this.service.status.isPurchased,
        afterPaymentAction: (result: PictorialPaymentResult) => this.onPaymentSuccess(result),
      } as BuyModalData)
    } else {
      !this.router.navigate(["", { outlets: { popup: ["login"] } }], { skipLocationChange: true })
    }
  }

  private onScrollToContent() {
    if (!this.isLoggedIn) {
      !this.router.navigate(["", { outlets: { popup: ["login"] } }], { skipLocationChange: true })
    } else {
      document.getElementsByClassName("mat-tab-group")[0].scrollIntoView()
    }
  }

  private onPaymentSuccess(result: PictorialPaymentResult) {
    this.service.fetchCreatorInfo()
    this.service.fetchPurchased()
    if (this.service.pictorial.campaignId != undefined) {
      this.service.updateSuperVote()
    }
    setTimeout(() => {
      this.dialog.open(PictorialPaymentSuccessDialogComponent, {
        data: result,
        width: "580px",
        maxWidth: "90vw",
        ariaLabel: "modal-basic-title",
        panelClass: "buy-photobook-modal",
        backdropClass: "buy-photobook-modal-backdrop",
      })
    }, 1)
  }

  private toAppDownload() {
    const os = Util.getMobileOS()
    if (os == "IOS") {
      window.open(APP_STORE_MEMBERME)
    } else if (os == "Android") {
      window.open(PLAY_STORE_MEMBERME)
    } else {
      this.modalService.open(AppDownloadModalComponent, { centered: true })
    }
  }

  private open(content, data?: Record<string, any>) {
    window.history.pushState({}, "")
    this.dialogRef = this.dialog.open(content, {
      width: "580px",
      maxWidth: "90vw",
      ariaLabel: "modal-basic-title",
      panelClass: "buy-photobook-modal",
      data,
      backdropClass: "buy-photobook-modal-backdrop",
    })
  }

  get pictorial() {
    return this.service.pictorial
  }

  get creator() {
    return this.service.creator
  }

  get tier() {
    return this.service.tier
  }

  get isAdultLock() {
    return this.pictorial.adult && !this.authService.isAdult()
  }

  get covers() {
    return this.pictorial.__covers__ ? this.pictorial.__covers__.map((cover) => cover.file.url) : []
  }

  get PurchasePermission() {
    return PurchasePermission
  }

  getInformationList() {
    return [
      {
        key: "모델",
        display: true,
        key_eng: "Model",
        value: this.pictorial.__models__
          ? this.pictorial.__models__.map((model) => model.name).join(", ")
          : "",
      },
      {
        key: "작가",
        display: true,
        key_eng: "Photographers",
        value: this.pictorial.photographers ? this.pictorial.photographers.join(", ") : "",
      },
      {
        key: "품번",
        display: true,
        key_eng: "Product number",
        value: this.pictorial.productNumber,
      },
      {
        key: "카테고리",
        display: true,
        key_eng: "Categories",
        value: this.pictorial.categories
          ? this.pictorial.categories.map((category) => category.name).join(", ")
          : "",
      },
      {
        key: "테마",
        display: true,
        key_eng: "Themes",
        value: this.pictorial.themes.join(", ") || "",
      },
      {
        key: "가격",
        key_eng: "Price",
        display: this.isIndividualSale(),
        value: this.pictorial.priceUsd ? "$" + this.pictorial.priceUsd : "Free",
      },
      {
        key: "멤버십",
        key_eng: "Membership",
        display: true,
        isHtml: true,
        value: this.membership
          ? this.membership.is_promotion_enable
            ? `<span class='strikethrough'>$${this.membership.amount_usd}/month</span> $0`
            : `$${this.membership.amount_usd}/month`
          : "",
      },
    ]
  }

  private isIndividualSale() {
    return (
      this.pictorial.salesMethod == PurchasePermission.allPaid ||
      this.pictorial.salesMethod == PurchasePermission.membershipPaid ||
      this.pictorial.availableSale
    )
  }

  private get membership() {
    if (!this.creator) {
      return null
    }
    const tier = this.tier
    const pictorialTierUsd = tier ? tier.amount_usd : 9999999
    const minTier = this.creator.tiers
      .filter((tierItem) => tierItem.joinable && tierItem.visible)
      .find((tierItem) => tierItem.amount_usd >= pictorialTierUsd)
    return minTier
  }

  get isLoggedIn() {
    return this.authService.isLoggedIn()
  }
}
