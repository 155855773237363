import { Component, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { CookieService } from "angular2-cookie"
import { UserService } from "src/app/account/services/user.service"

@Component({
  selector: "app-contest-apply-page",
  templateUrl: "./contest-apply-page.component.html",
  styleUrls: ["./contest-apply-page.component.scss"],
})
export class ContestApplyPageComponent implements OnInit {
  contest
  lang
  public editorContent: string
  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private cookieService: CookieService,
  ) {
    const lang = this.route.snapshot.data.lang
    const { description_en, description } = this.route.snapshot.data.contest
    this.editorContent = lang === "KOR" ? description : description_en
  }

  ngOnInit() {
    this.lang = this.cookieService.get("lang")
    if (!this.route.snapshot.data.contest) {
      this.router.navigateByUrl("404")
    }
    this.contest = this.route.snapshot.data.contest
  }

  goBack() {
    window.history.back()
  }

  applyCreator() {
    this.router.navigateByUrl(`/creators/create`)
  }

  applyContest() {
    const url = this.getGoogleUrl()

    if (url) {
      window.open(url)
    }
  }

  getGoogleUrl() {
    return this.lang === "KOR"
      ? "https://forms.gle/1ZH7ocWLj8b4orHX8"
      : "https://forms.gle/NuSg9BEMwj4zfYrp7"
  }
}
