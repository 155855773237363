import { Component, Input, OnInit } from "@angular/core"
// import {Router} from '@angular/router';
import { FormService } from "../../../shared/services/form.service"
import { UserService } from "../../../account/services/user.service"
import { Form } from "../../../shared/model/form"
import { CreatorService } from "../../../creator/services/creator.service"
import { ComponentPageTitle } from "../../components/page-title/page-title"
import { ActivatedRoute, Params } from "@angular/router"
import { Observable } from "rxjs"
import { TranslateService } from "@ngx-translate/core"
import { CurrencyPipe } from "src/app/shared/pipe/currency.pipe"

@Component({
  selector: "app-membership-permission-settings",
  templateUrl: "./membership-permission-settings.component.html",
  styleUrls: ["./membership-permission-settings.component.scss"],
})
export class MembershipPermissionSettingsComponent implements OnInit {
  form: Form

  creator
  tiers
  postTier
  commentTier

  submitDisabled = false
  @Input() params: Observable<Params>

  constructor(
    // private router: Router,
    public _componentPageTitle: ComponentPageTitle,
    private route: ActivatedRoute,
    private formService: FormService,
    private userService: UserService,
    private creatorService: CreatorService,
    private translateService: TranslateService,
    private currencyPipe: CurrencyPipe,
  ) {
  }

  ngOnInit() {
    this.route.params.subscribe(() => {
      this._componentPageTitle.setSubtitle("멤버 권한 설정")
    })
    // get form data used for update
    this.formService.getFormData("creators/me/").subscribe((formData) => {
      this.form = formData
    })

    // get creator for Tier info
    this.creatorService.get("me").subscribe((res) => {
      this.creator = res

      if (this.creator.tiers) {
        this.creator.tiers = this.creator.tiers.map((tier) => {
          if (!tier.amount_usd) {
            tier.amount_usd = this.currencyPipe.transform(tier.amount)
          }

          tier.amount_usd = tier.amount_usd.toLocaleString()
          return tier
        })
      }

      this.tiers = [
        { amount_usd: this.translateService.instant("MEMBERSHIP_MANAGEMENT.FOLLOWERS") },
        { amount_usd: this.translateService.instant("MEMBERSHIP_MANAGEMENT.MEMBERS") },
        ...this.creator.tiers,
      ]
      // set default values
      // tslint:disable-next-line: max-line-length
      this.postTier = this.creator.min_post
        ? this.creator.min_post
        : this.creator.min_post === 0
          ? this.tiers[1].amount_usd
          : this.tiers[0].amount_usd

      this.postTier = this.postTier.toLocaleString()
      // tslint:disable-next-line: max-line-length
      this.commentTier = this.creator.min_comment
        ? this.creator.min_comment
        : this.creator.min_comment === 0
          ? this.tiers[1].amount_usd
          : this.tiers[0].amount_usd

      this.commentTier = this.commentTier.toLocaleString()
    })
  }

  savePermissions() {
    this.submitDisabled = true
    const followers_text = this.translateService.instant("MEMBERSHIP_MANAGEMENT.FOLLOWERS")
    const members_text = this.translateService.instant("MEMBERSHIP_MANAGEMENT.MEMBERS")

    this.form.formGroup.value.min_post =
      this.postTier === followers_text ? null : this.postTier === members_text ? 0 : this.postTier
    this.form.formGroup.value.min_comment =
      this.commentTier === followers_text ? null : this.commentTier === members_text ? 0 : this.commentTier

    const minComment = this.form.formGroup.value.min_comment
    const minPost = this.form.formGroup.value.min_post

    if (minComment) {
      this.form.formGroup.value.min_comment = minComment.replaceAll(",", "")
    }

    if (minPost) {
      this.form.formGroup.value.min_post = minPost.replaceAll(",", "")
    }

    this.creatorService.update(this.form).subscribe(
      (res) => {
        this.submitDisabled = false
        alert(this.translateService.instant("ALERT_CONFIRM.SAVED"))
      },
      (error) => {
        this.submitDisabled = false
      },
    )
  }
}
