export const globalStateKey = "state"

export enum Breakpoint {
  "xxs" = "0px",
  "xs" = "360px",
  "sm" = "428px",
  "md" = "712px",
  "lg" = "1200px",
}
export const PLAY_STORE_MEMBERME =
  "https://play.google.com/store/apps/details?id=com.fatkat.memberme"
export const APP_STORE_MEMBERME =
  "https://apps.apple.com/kr/app/memberme-%EB%A9%A4%EB%B2%84%EB%AF%B8/id1481547544"
export const LEEHEE_ZAP_URL = "https://zep.us/play/8g5oP9"
export const POST_VISIBILITY_MAX = 99999999
