import { Component } from "@angular/core"

@Component({
  selector: "app-explore",
  templateUrl: "./explore-page.component.html",
  styleUrls: ["./explore-page.component.scss"],
})
export class ExplorePageComponent {
  navLinks = [
    { path: "/preview/posts", label: "PREVIEW.POSTS" },
    { path: "/preview/contents", label: "PREVIEW.CONTENTS" },
  ]
}
