import { COMMA, ENTER } from "@angular/cdk/keycodes"
import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core"
import { FormControl } from "@angular/forms"
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from "@angular/material/autocomplete"
import { MatChipInputEvent } from "@angular/material/chips"
import { BehaviorSubject, Observable } from "rxjs"
import { map, startWith } from "rxjs/operators"
import { cloneDeep, difference } from "lodash"
import { PhotobookAutocomplete } from "../../pages/creator-photobook-page/type"

@Component({
  selector: "app-photobook-autocomplete",
  templateUrl: "./photobook-autocomplete.component.html",
  styleUrls: ["./photobook-autocomplete.component.scss"],
})
export class PhotobookAutocompleteComponent implements OnInit, AfterViewInit {
  @ViewChild(MatAutocompleteTrigger, {
    static: false,
  })
  autocomplete: MatAutocompleteTrigger
  selectable = true
  removable = true
  openedAutoSelect = false
  separatorKeysCodes: number[] = [ENTER, COMMA]

  inputControl = new FormControl()
  filteredList: Observable<Array<string | PhotobookAutocomplete>>
  isLoading = true
  @Input() list: string[]
  @Input() placeholder?: string
  @Input() allItems: BehaviorSubject<PhotobookAutocomplete[]>
  @ViewChild("autoCompleteInput", { static: false }) autoCompleteInput: ElementRef<HTMLInputElement>

  constructor() {}

  ngOnInit() {
    this.filteredList = this.inputControl.valueChanges.pipe(
      startWith(null),
      map((inputValue: string | null) => {
        const t = this._filter(inputValue)
        return t
      }),
    )

    this.allItems.subscribe((res) => {
      this.inputControl.setValue("")
    })
  }

  ngAfterViewInit() {
    this.autoCompleteInput.nativeElement.addEventListener("click", () => {
      this.autocomplete.openPanel()
    })
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || "").trim()

    // Add our fruit
    if (value) {
      this.list.push(value)
    }

    this.allItems.value.forEach((item) => {
      if (!item.type) {
        if (item.themes) {
          item.themes.push(value)
        } else if (item.photoGraphers) {
          item.photoGraphers.push(value)
        }
      }
    })

    // Clear the input value
    event.input.value = ""
    this.inputControl.setValue(null)
  }

  remove(target: string): void {
    const index = this.list.indexOf(target)

    if (index >= 0) {
      this.list.splice(index, 1)

      this.inputControl.setValue("")
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (!this.list.includes(event.option.viewValue)) {
      this.list.push(event.option.viewValue)
    }

    this.autoCompleteInput.nativeElement.value = ""
    this.inputControl.setValue(null)
  }

  private _filter(value: string): PhotobookAutocomplete[] {
    const s = cloneDeep(this.allItems.value)

    return s.map((item) => {
      if (item.themes) {
        item.themes = difference(item.themes, this.list)
      } else if (item.photoGraphers) {
        item.photoGraphers = difference(item.photoGraphers, this.list)
      }
      return item
    })
  }
}
