import { NgModule } from "@angular/core"
import {
  MatButtonModule,
  MatChipsModule,
  MatIconModule,
  MatInputModule,
  MatTabsModule,
} from "@angular/material"
import { InfiniteScrollModule } from "ngx-infinite-scroll"
import { BoardModule } from "../board/board.module"
import { SharedModule } from "../shared/shared.module"
import { ExploreRoutingModule } from "./explore-routing.module"
import { ExplorePageComponent } from "./pages/explore-page/explore-page.component"
import { ExploreModalPageComponent } from "./pages/explore-modal-page/explore-modal-page.component"
import { AdultToggleButtonComponent } from "./components/adult-toggle-button/adult-toggle-button.component"
import { PostsTabComponent } from "./components/posts-tab/posts-tab.component"
import { ContentsTabComponent } from "./components/contents-tab/contents-tab.component"
import { ExploreTabItemComponent } from "./components/explore-tab-item/explore-tab-item.component"

@NgModule({
  imports: [
    ExploreRoutingModule,
    SharedModule,
    MatInputModule,
    BoardModule,
    InfiniteScrollModule,
    MatIconModule,
    MatChipsModule,
    MatButtonModule,
    MatTabsModule,
    BoardModule,
  ],
  declarations: [
    ExplorePageComponent,
    ExploreModalPageComponent,
    AdultToggleButtonComponent,
    PostsTabComponent,
    ContentsTabComponent,
    ExploreTabItemComponent,
  ],
  exports: [ExploreTabItemComponent],
})
export class ExploreModule {}
