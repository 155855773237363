import { Component, OnInit } from "@angular/core"

import { CookieService } from "angular2-cookie/core"
import { Location } from "@angular/common"

@Component({
  selector: "app-content-policy-page",
  templateUrl: "./content-policy-page.component.html",
  styleUrls: ["./content-policy-page.component.scss"],
})
export class ContentPolicyPageComponent implements OnInit {
  //
  langFlag

  constructor(private cookieService: CookieService, private location: Location) {}

  ngOnInit() {
    if (this.cookieService.get("lang") !== "KOR") {
      this.langFlag = "ENG"
    } else {
      this.langFlag = "KOR"
    }

    // langFlag for memberme app - 앱의 웹뷰에서 쿠키가 동작이 안될 때
    const path = this.location.path()
    const split_url = path.split(/[?&]/) // '?' 또는 '&'을 기준으로 스플릿
    // lang 값이 있을때만...
    if (path.includes("lang")) {
      if (split_url.includes("lang=KOR")) {
        this.langFlag = "KOR"
      } else {
        this.langFlag = "ENG"
      }
    }
  }
}
