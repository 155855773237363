import { Injectable } from "@angular/core"
import { PostService } from "../../board/services/post.service.js"
import { environment } from "src/environments/environment"
import { HttpClient } from "@angular/common/http"
import { AuthService } from "../../core/services/auth.service.js"
import { Post, PostCategory } from "src/app/board/types/post.type.js"

import { tap } from "rxjs/operators"

import { Pagination } from "src/app/shared/types/pagination.type.js"
import { GetPostParams } from "src/app/explore/types/ajax.type.js"
import { PostFilter, ExplorePost } from "src/app/explore/types/post.type.js"
import { Observable } from "rxjs"

@Injectable({ providedIn: "root" })
export class PostDataService {
  posts: Post[]
  nextUrl: string
  inDashboard: boolean
  postLoaded = true
  loadingMore = true
  openedModal = false
  langFlag: string
  filterData: PostFilter = {
    visibility: 0,
    category: PostCategory.creator,
    has_adult_content: false,
  }
  isAdultUser: boolean
  isFirstLoad = true
  isFreePost: boolean = true

  private imgSrcRegExp = /<img[^>]*src=[\"']?([^>\"']+)[\"']?[^>]*>/

  constructor(
    protected postService: PostService,
    protected http: HttpClient,
    protected authService: AuthService,
  ) {
    this.delayedPaginate()
    this.authService.getChangeEmitter().subscribe(() => {
      this.checkAdultUser()
      this.getPaginatedResult()
    })
  }

  private delayedPaginate() {
    this.checkAdultUser()
    setTimeout(() => {
      if (this.isFirstLoad) {
        this.getPaginatedResult()
      }
    }, 300)
  }

  getPost(id: number) {
    const cachedPost = this.posts && this.posts.find((post) => post.id === id)

    if (cachedPost) {
      return new Observable((observer) => {
        observer.next(cachedPost)
        observer.complete()
      })
    }

    return this.postService
      .get(id)
      .pipe(tap((res) => (this.posts ? this.posts.push(res) : (this.posts = [res]))))
  }

  getListPaginated(data: GetPostParams) {
    if (this.isFreePost) {
      return this.http.get<Pagination<Post>>(`${environment.apiUrl}posts/?is_web=true`, {
        params: data,
      })
    }
  }

  getPaginatedResult() {
    this.posts = undefined
    this.postLoaded = false
    this.isFirstLoad = false
    this.getListPaginated(this.filterData).subscribe((res) => {
      this.nextUrl = this.getNextURL(res["next"])
      this.posts = res["results"].map(this.parsePost).filter(this.hasThumbnail)
      this.postLoaded = true
      this.loadingMore = !!this.nextUrl
      if (this.loadingMore) {
        this.getPaginatedResultByUrl()
      }
    })
    return null
  }

  getPaginatedResultByUrl() {
    if (this.openedModal) {
      return
    }
    this.postLoaded = false

    this.postService.getListPaginatedByUrl(this.nextUrl).subscribe((res) => {
      this.nextUrl = this.getNextURL(res["next"])
      this.posts = this.posts.concat(res["results"].map(this.parsePost).filter(this.hasThumbnail))
      this.postLoaded = true
      this.loadingMore = !!this.nextUrl
    })
  }

  removeDuplicatePosts() {
    if (this.posts && this.posts.length < 40) {
      const duplicationCheckArr = []
      this.posts = this.posts.filter((post) => {
        if (duplicationCheckArr.includes(post.id)) {
          return false
        }
        duplicationCheckArr.push(post.id)
        return true
      })
    }
  }

  parsePost = (post: Post): ExplorePost => {
    return post.is_adult_content && !this.isAdultUser
      ? { ...post, isBlur: true }
      : { ...post, exploreThumbnail: this.parseThumbnail(post) }
  }

  getNextURL(url) {
    if (url && !url.startsWith(environment.apiUrl) && !url.startsWith("https")) {
      return url.replace("http", "https")
    }
    return url
  }

  parseThumbnail(post: Post) {
    if (post.thumbnail) {
      return post.thumbnail
    }

    if (!post.content) {
      return null
    }

    const src = this.getSingleImgSrc(post)

    if (src) {
      return src
    }

    const youtubeId = this.getYoutubeId(post.content)

    if (youtubeId) {
      return `https://img.youtube.com/vi/${youtubeId}/hqdefault.jpg`
    }

    const hasVimeo = post.content.includes("vimeo.com/")
    if (hasVimeo) {
      return null
      // return "/assets/img/icon/img-thumbnail-default.png"
    }

    return null
  }

  parseThumbnails(post: Post): string[] {
    if (post.thumbnails) {
      return post.thumbnails
    }
    if (!post.content) {
      return null
    }

    const src = this.getMultipleImgSrc(post)
    if (src) {
      return src
    }

    const youtubeId = this.getYoutubeId(post.content)

    if (youtubeId) {
      return [`https://img.youtube.com/vi/${youtubeId}/hqdefault.jpg`]
    }

    const hasVimeo = post.content.includes("vimeo.com/")
    if (hasVimeo) {
      return null
      // return "/assets/img/icon/img-thumbnail-default.png"
    }

    return null
  }

  getSingleImgSrc(post: any): string {
    if (post.images && post.images.length) {
      return post.images[0].thumbnail && post.images[0].image
    }

    const res = this.imgSrcRegExp.exec(post.content)
    if (res) {
      return res[1]
    }
  }

  getMultipleImgSrc(post: any): string[] {
    if (post.images && post.images.length) {
      return post.images.map((image) => image.thumbnail && image.image)
    }

    const res = this.imgSrcRegExp.exec(post.content)
    if (res) {
      return res
    }
  }

  getYoutubeId(url: string) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
    const match = url.match(regExp)

    if (match && match[2].length === 11) {
      return match[2]
    } else {
      return null
    }
  }

  hasThumbnail(post) {
    return post.isBlur || !!post.exploreThumbnail
  }

  private checkAdultUser() {
    this.isAdultUser = this.authService.isAdult()
  }

  postMessageRead(id: number) {
    return this.postService.postMessageRead(id)
  }
}
