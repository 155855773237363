import { Component, OnInit } from "@angular/core"
import { UserService } from "src/app/account/services/user.service"

@Component({
  selector: "app-creator-update-thank-you-page",
  templateUrl: "./creator-update-thank-you-page.component.html",
  styleUrls: ["./creator-update-thank-you-page.component.scss"],
})
export class CreatorUpdateThankYouPageComponent implements OnInit {
  currentUser

  constructor(private userService: UserService) {}

  ngOnInit() {
    this.currentUser = this.getUser()
  }

  getUser() {
    return this.userService.getUser()
  }

  onSubmit() {}
}
