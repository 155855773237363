import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { NgbModule } from "@ng-bootstrap/ng-bootstrap"
import { PaginationComponent } from "./components/pagination/pagination.component"
import { DynamicFieldComponent } from "./components/dynamic-field/dynamic-field.component"
import { RouterModule } from "@angular/router"
import { ModalPageComponent } from "./components/modal-page/modal-page.component"
import { DynamicAttrsDirective } from "./directives/dynamic-attrs.directive"
import { ResponsiveEmbedComponent } from "./components/responsive-embed/responsive-embed.component"
import { ScrapeButtonComponent } from "./components/scrape-button/scrape-button.component"
import { NumberOnlyDirective } from "./directives/number-only.directive"
import { TranslateModule } from "@ngx-translate/core"
import {
  MatButtonModule,
  MatDialogModule,
  MatPaginatorModule,
  MatAutocompleteModule,
  MatSnackBarModule,
  MatIconModule,
  MatProgressSpinnerModule,
  MatBadgeModule,
  MatSelectModule,
} from "@angular/material"
import { ConfirmationDialogComponent } from "./components/confirmation-dialog/confirmation-dialog.component"
import { SnackbarService } from "./services/snackbar.service"
import { MessagingComponent } from "./components/messaging/messaging.component"
import { AutocompleteTabDirective } from "./directives/autocomplete-tab.directive"
import { CircularProgressIndicatorComponent } from "./components/circular-progress-indicator/circular-progress-indicator.component"
import { ApplicationInstallInducerComponent } from "./components/application-install-inducer/application-install-inducer.component"
import { CurrencyPipe } from "./pipe/currency.pipe"
import { ScrollTopButtonComponent } from "./components/scroll-top-button/scroll-top-button.component"
import { InfiniteScrollModule } from "ngx-infinite-scroll"
import { ShortNumberPipe } from "./pipe/short-number.pipe"
import { SafeUrlPipe } from "./pipe/safe-url.pipe"
import { LockCoverComponent } from "./components/lock-cover/lock-cover.component"
import { PhotobookAdultCoverComponent } from "./components/photobook-adult-cover/photobook-adult-cover.component"
import { ResizeImage } from "./pipe/resize-image.pipe"
import { FileSizePipe } from "./pipe/file-size.pipe"
import { CreatorListRecommendedComponent } from "./components/creator-list-recommended/creator-list-recommended.component"

import { SendmeGnbNotiComponent } from "./components/sendme-gnb-noti/sendme-gnb-noti.component"
import { CountryRestrictionModalComponent } from "./components/country-restriction-modal/country-restriction-modal.component"
import { SendmeMailBtnComponent } from "./components/sendme-mail-btn/sendme-mail-btn.component"
import { AngularSvgIconModule } from "angular-svg-icon"
import { ContentBlurWrapperComponent } from "./components/content-blur-wrapper/content-blur-wrapper.component"
import { ModelContestApplyBtnComponent } from "src/app/shared/components/model-contest-apply-btn/model-contest-apply-btn.component"
import { BunnynetIframeComponent } from "src/app/shared/components/bunnynet-iframe/bunnynet-iframe.component"
import { AppDownloadModalComponent } from "./components/app-download-modal/app-download-modal.component"
import { SelectboxComponent } from "./components/selectbox/selectbox.component"
import { CheckboxComponent } from "./components/checkbox/checkbox.component"
import { RecommendedModelListComponent } from "./components/recommended-model-list/recommended-model-list.component"

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    TranslateModule,
    NgbModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: "never" }),
    MatPaginatorModule,
    MatDialogModule,
    MatButtonModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatIconModule,
    MatProgressSpinnerModule,
    InfiniteScrollModule,
    MatBadgeModule,
    AngularSvgIconModule,
    MatSelectModule,
  ],
  declarations: [
    PaginationComponent,
    DynamicFieldComponent,
    ModalPageComponent,
    MessagingComponent,
    DynamicAttrsDirective,
    AutocompleteTabDirective,
    ResponsiveEmbedComponent,
    ScrapeButtonComponent,
    NumberOnlyDirective,
    ConfirmationDialogComponent,
    CircularProgressIndicatorComponent,
    ApplicationInstallInducerComponent,
    CurrencyPipe,
    ScrollTopButtonComponent,
    ShortNumberPipe,
    SafeUrlPipe,
    LockCoverComponent,
    PhotobookAdultCoverComponent,
    ResizeImage,
    FileSizePipe,
    CreatorListRecommendedComponent,
    SendmeMailBtnComponent,
    SendmeGnbNotiComponent,
    CountryRestrictionModalComponent,
    ContentBlurWrapperComponent,
    ModelContestApplyBtnComponent,
    BunnynetIframeComponent,
    AppDownloadModalComponent,
    SelectboxComponent,
    CheckboxComponent,
    RecommendedModelListComponent,
  ],
  entryComponents: [
    ConfirmationDialogComponent,
    CountryRestrictionModalComponent,
    AppDownloadModalComponent,
  ],
  exports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    NgbModule,
    PaginationComponent,
    DynamicFieldComponent,
    DynamicAttrsDirective,
    NumberOnlyDirective,
    ResponsiveEmbedComponent,
    ScrapeButtonComponent,
    AutocompleteTabDirective,
    CircularProgressIndicatorComponent,
    ApplicationInstallInducerComponent,
    CurrencyPipe,
    ShortNumberPipe,
    ScrollTopButtonComponent,
    InfiniteScrollModule,
    SafeUrlPipe,
    LockCoverComponent,
    PhotobookAdultCoverComponent,
    ResizeImage,
    FileSizePipe,
    MatIconModule,
    CreatorListRecommendedComponent,
    SendmeMailBtnComponent,
    SendmeGnbNotiComponent,
    ContentBlurWrapperComponent,
    ModelContestApplyBtnComponent,
    BunnynetIframeComponent,
    SelectboxComponent,
    CheckboxComponent,
    RecommendedModelListComponent,
  ],
  providers: [SnackbarService, CurrencyPipe, ShortNumberPipe, FileSizePipe],
})
export class SharedModule {}
