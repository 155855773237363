import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core"
import { UserService } from "src/app/account/services/user.service"
import { AuthService } from "src/app/core/services/auth.service"
import { ActivatedRoute, Router } from "@angular/router"
import { PurchasePermission } from "src/app/creator/pages/creator-photobook-page/type"
import { CountryRestrictionModalService } from "src/app/shared/services/country-restiction-modal.service"
import { CookieService } from "angular2-cookie/core"
import { Util } from "src/app/shared/utils/utils"

@Component({
  selector: "app-pictorial-card",
  templateUrl: "./pictorial-card.component.html",
  styleUrls: ["./pictorial-card.component.scss"],
})
export class PictorialCardComponent implements OnInit, AfterViewInit {
  @Input() pictorial
  @Input() isAdultCreator: boolean
  @Input() isVerifiedCreator: boolean
  @Input() hiddenName: boolean = false
  @Input() isPaid: boolean = false
  @Input() sendMeId: number = -1
  @Input() specificRegionExcluded: boolean = false
  @ViewChild("img", { static: false }) img

  isMobile: boolean
  imgLoading = true
  isMine: boolean

  isBlur: boolean
  sendmeFree: boolean = false
  sendmePay: boolean = false
  langFlag: string

  navigate = []

  constructor(
    private userService: UserService,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private restrictCountryService: CountryRestrictionModalService,
    private cookieService: CookieService,
  ) {}

  ngOnInit() {
    const me = this.userService.getUser()
    this.isMobile = Util.isMobileDevice()
    this.isMine = me && this.pictorial.creatorId == me.creator
    this.langFlag = this.cookieService.get("lang") === "KOR" ? "KOR" : "ENG"
    if (!this.pictorial.thumbnailUri) {
      this.pictorial.thumbnailUri =
        this.pictorial.__covers__ &&
        this.pictorial.__covers__[0] &&
        this.pictorial.__covers__[0].file.url
    }
    this.setBlur()
    this.setSendmeFreeContent()
    this.setSendmePayContent()
    this.authService.getChangeEmitter().subscribe(() => {
      this.setBlur()
    })
  }

  setBlur() {
    this.isBlur =
      (!this.isMine && this.isAdultCreator && !this.authService.isAdult()) ||
      (this.pictorial.adult && !this.authService.isAdult())
  }

  setSendmeFreeContent() {
    if (this.pictorial.salesMethod == PurchasePermission.allFree && this.sendMeId > -1) {
      this.sendmeFree = true
    }
  }

  setSendmePayContent() {
    const { salesMethod } = this.pictorial

    if (
      (salesMethod == PurchasePermission.allPaid ||
        salesMethod == PurchasePermission.membershipPaid) &&
      !this.isPaid &&
      this.sendMeId > -1
    ) {
      this.sendmePay = true
    }
  }

  onClick() {
    if (this.specificRegionExcluded && this.restrictCountryService.isRestricted()) {
      this.restrictCountryService.openRestrictModal()
      return
    }
    if (this.sendMeId !== -1) {
      this.router.navigate(["/sendme/inbox/read/", this.sendMeId])
      return
    }

    if (this.isBlur) {
      this.authService.verifyAdult()
    } else {
      this.router.navigate(
        ["./pictorials", this.pictorial.id],
        this.isMine ? { relativeTo: this.route } : {},
      )
    }
  }

  ngAfterViewInit() {
    const endLoading = () => {
      this.imgLoading = false
      this.cdr.detectChanges()
      this.img.nativeElement.removeEventListener("load", endLoading)
    }
    if (this.img) {
      this.img.nativeElement.addEventListener("load", endLoading)
    } else {
      this.imgLoading = false
      this.cdr.detectChanges()
    }
  }
}
