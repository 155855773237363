import { Injectable } from "@angular/core"
import { ActivatedRouteSnapshot, CanActivateChild, Router } from "@angular/router"
import { CookieService } from "angular2-cookie"
import { Pagination } from "src/app/shared/types/pagination.type"

import { ContestService } from "../services/contest.service"
import { Contest } from "../types"
import { environment } from "src/environments/environment"

@Injectable({
  providedIn: "root",
})
export class ContestRoutingDataService implements CanActivateChild {
  lang
  contest

  constructor(
    private contestService: ContestService,
    private router: Router,
    private cookieService: CookieService,
  ) {
    this.lang = this.cookieService.get("lang")
  }

  async canActivateChild(next: ActivatedRouteSnapshot) {
    const contestUrl = this.getContestUrl(next)
    if (!this.contest) {
      if (await this.findContestByUrl(contestUrl, next)) {
        return true
      }
    } else {
      this.setContestAndNextData(next)
      return true
    }
    return this.router.navigateByUrl("404", { skipLocationChange: true, replaceUrl: true })
  }

  private getContestUrl(next: ActivatedRouteSnapshot) {
    const url = next.url.length > 0 ? next.url.shift().path : next.params.contest
    return !url || url == "models" ? environment.currentContestURI : url
  }

  async findContestByUrl(url: string, next: any) {
    let response: Pagination<Contest>

    response = await this.contestService.getContestList().toPromise()

    while (response.next) {
      const nextContest = this.findContestInPagination(url, response)
      if (nextContest) {
        this.setContestAndNextData(next, nextContest)
        return true
      }

      response = await this.contestService.getContestListByUrl(response.next).toPromise()
    }

    const contest = this.findContestInPagination(url, response)
    if (contest) {
      this.setContestAndNextData(next, contest)
      return true
    }
    return this.router.navigate(["404"], { skipLocationChange: true, replaceUrl: true })
  }

  findContestInPagination(url: string, response: Pagination<Contest>) {
    const { results } = response
    const contest = results.find((item) => item.url === url)
    if (contest && contest.is_activated) {
      return contest
    }
  }

  setContestAndNextData(next: ActivatedRouteSnapshot, contest?: Contest) {
    if (!contest) {
      contest = this.contest
    } else {
      this.contest = contest
    }
    next.data = {
      ...next.data,
      contest,
      lang: this.lang,
    }
  }
}
