import { Injectable } from "@angular/core"
import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http"
import { map } from "rxjs/operators"
import _ from "lodash"
import { environment } from "src/environments/environment"
import { UserFile } from "../types/post.type"
import { Pagination } from "src/app/shared/types/pagination.type"
import { Pictorial, PictorialFileUrlReq, PictorialFileUrlRes, PictorialListItemWithThumbnail } from "../types/pictorial.type"
import { GetPictorialPhotosParams } from "../types/ajax.type"
import * as moment from "moment"
import { Comment } from "../types/comment.type"
import { Observable } from "rxjs"

@Injectable({
  providedIn: "root",
})
export class PictorialService {
  constructor(private http: HttpClient) {}

  get(id: string) {
    return this.http.get<Pictorial>(`${environment.pictorialApiUrl}pictorials/${id}`)
  }

  getFileList(id, path) {
    return this.http.get(`${environment.pictorialApiUrl}pictorials/${id}/${path}`)
  }

  getList(data) {
    return this.findMany(data).pipe(
      map((res) => ({
        ...res,
        items: res.items.map((pictorial) => {
          const [firstCover] = pictorial.__covers__
          return {
            ...pictorial,
            thumbnailUri: firstCover ? firstCover.file.url : "",
          } as PictorialListItemWithThumbnail
        }),
      })),
    )
  }

  findMany(data: any) {
    return this.http.get<Pagination<Pictorial>>(`${environment.pictorialApiUrl}pictorials`, {
      params: data,
    })
  }

  getListByIds(ids: string[]) {
    const url = `${environment.pictorialApiUrl}pictorials/by-ids`
    return this.http.post<Pictorial[]>(url, ids)
  }

  getCategoryList(id) {
    const url = `${environment.pictorialApiUrl}categories`
    return this.http.get(url, { params: { creatorId: id, listType: "TREE" } })
  }

  getModelList(id) {
    const url = `${environment.pictorialApiUrl}models`
    return this.http.get(url, { params: { creatorId: id, withPagination: "false" } })
  }

  getModel(id) {
    const url = `${environment.pictorialApiUrl}models/${id}`
    return this.http.get(url)
  }

  getThemeList(creatorId?: number, name?: string, isMain?: boolean) {
    const url = `${environment.pictorialApiUrl}themes`
    return this.http.get(url, {
      params: {
        ...(creatorId && { creatorId: _.toString(creatorId) }),
        ...(name && { name: name }),
        ...(isMain !== undefined && { isMain: _.toString(isMain) }),
      },
    })
  }

  getPhotographerList(creatorId?: number, name?: string) {
    const url = `${environment.pictorialApiUrl}photographers`
    return this.http.get(url, {
      params: {
        ...(creatorId && { creatorId: _.toString(creatorId) }),
        ...(name && { name: name }),
      },
    })
  }

  getPictorialPhotos(params: GetPictorialPhotosParams) {
    // [TODO] 작업당시에는 photos에 요청하면 401 에러가 발생했다. covers 로 작업했음.
    const url = `${environment.pictorialApiUrl}pictorials/${params.id}/photos`
    delete params.id
    return this.http.get<UserFile[]>(url, { params })
  }

  getPictorialVideos(params: GetPictorialPhotosParams) {
    const url = `${environment.pictorialApiUrl}pictorials/${params.id}/videos`
    delete params.id
    return this.http.get<UserFile[]>(url, { params })
  }

  create(id) {
    const url = `${environment.pictorialApiUrl}pictorials`
    const data = {
      title: "Untitled",
      release: false,
      availableSale: false,
      individualSale: false,
      modelIds: [],
      photographers: [],
      categoryId: null,
      themes: [],
      creatorId: id,
      tierId: null,
      visibility: 0,
      price: 0,
      productNumber: null,
      releaseDate: null,
      closeDate: null,
      order: 0,
      adult: true,
    }
    return this.http.post(url, data)
  }

  createPictorialFileUploadUrls(id: string, body: PictorialFileUrlReq) {
    const url = `${environment.pictorialApiUrl}pictorials/${id}/upload-urls`
    return this.http.post<PictorialFileUrlRes[]>(url, body)
  }

  uploadToPreSignUrl(url: string, file: File): Observable<any> {
    const headers = new HttpHeaders({ "Content-Type": file.type })
    const req = new HttpRequest("PUT", url, file, { headers: headers, reportProgress: false })
    return this.http.request(req)
  }

  createFile(id, path, body) {
    const url = `${environment.pictorialApiUrl}pictorials/${id}/${path}`
    return this.http.post(url, body)
  }

  createCategory(body) {
    const url = `${environment.pictorialApiUrl}categories`
    return this.http.post(url, body)
  }

  createModel(body) {
    const url = `${environment.pictorialApiUrl}models`
    return this.http.post(url, body)
  }

  uploadModelProfile(id, body) {
    const url = `${environment.pictorialApiUrl}models/${id}/profile`
    return this.http.post(url, body)
  }

  update(id, data, withItems = true) {
    const url = `${environment.pictorialApiUrl}pictorials/${id}`
    return this.http.put(url, data, { params: { "with-items": withItems.toString() } })
  }

  updateCategory(body) {
    const url = `${environment.pictorialApiUrl}categories/${body.id}`
    return this.http.put(url, body)
  }

  updateCategoryList(body) {
    const url = `${environment.pictorialApiUrl}categories`
    return this.http.put(url, body)
  }

  updateModel(body) {
    const url = `${environment.pictorialApiUrl}models/${body.id}`
    return this.http.put(url, body)
  }

  updateModelList(body) {
    const url = `${environment.pictorialApiUrl}models`
    return this.http.put(url, body)
  }

  delete(id) {
    const url = `${environment.pictorialApiUrl}pictorials/${id}`
    return this.http.delete(url)
  }

  deleteCategory(id) {
    const url = `${environment.pictorialApiUrl}categories/${id}`
    return this.http.delete(url)
  }

  deleteModel(id) {
    const url = `${environment.pictorialApiUrl}models/${id}`
    return this.http.delete(url)
  }

  postLike(id: string) {
    const url = `${environment.pictorialApiUrl}pictorials/${id}/like`
    return this.http.post(url, {})
  }

  deleteLike(id: string) {
    const url = `${environment.pictorialApiUrl}pictorials/${id}/like`
    return this.http.delete(url)
  }

  postVote(id: string, campaignId: string, voteWeight?: number) {
    const url = `${environment.pictorialApiUrl}pictorials/${id}/votes`
    const today = moment().format("YYYY-MM-DD")
    return this.http.put(url, {
      votedAt: today,
      voteWeight: voteWeight ? voteWeight : 1,
      campaignId: campaignId,
    })
  }

  getComments(params: { pictorialId: string; parentId?: number; userId?: string }) {
    const url = `${environment.apiUrl}pictorial-comments/`
    return this.http.get<Pagination<any>>(url, {
      params: {
        page_size: "100",
        pictorial_id: params.pictorialId,
        ...(params.parentId && { parent: params.parentId + "" }),
        ...(params.userId && { user: params.userId }),
      },
    })
  }
  postComment(data: {
    pictorialId: string
    creatorId: number
    content: string
    parentId?: number
  }) {
    const url = `${environment.apiUrl}pictorial-comments/`
    return this.http.post<Comment>(url, {
      pictorial_id: data.pictorialId,
      creator: data.creatorId,
      content: data.content,
      parent: data.parentId,
    })
  }
  deleteComment(commentId: number) {
    const url = `${environment.apiUrl}pictorial-comments/${commentId}/`
    return this.http.delete(url)
  }
}
