import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core"
import { MatDialog } from "@angular/material"
import { PictorialService } from "../../services/pictorial.service"
import { UserFile } from "../../types/post.type"
import { EXTERNAL_VIDEO_PROVIDER_BUNNY_NET } from "src/app/shared/constants/file"

@Component({
  selector: "app-photobook-contents-tap",
  templateUrl: "./photobook-contents-tap.component.html",
  styleUrls: ["./photobook-contents-tap.component.scss"],
})
export class PhotobookContentsTapComponent implements OnInit {
  @ViewChild("container", { static: false }) listContainer: ElementRef<HTMLUListElement>
  @Input() isVisible: boolean
  @Input() isAppOnly: boolean
  @Input() id: string
  @Input() type: "photo" | "video"
  @Output() noContents = new EventEmitter<string>()
  contents: UserFile[]
  isContentsVisible = false
  isOverFlowed = false
  eventEmitter = new EventEmitter()
  videoProvider = {
    EXTERNAL_VIDEO_PROVIDER_BUNNY_NET: EXTERNAL_VIDEO_PROVIDER_BUNNY_NET,
  }

  constructor(private pictorialService: PictorialService, public dialog: MatDialog) {}

  ngOnInit() {
    // app only인 경우에는 contents를 가져오지 않기 위함.
    if (!this.isAppOnly) {
      if (this.type === "photo") {
        this.getPhotos()
      } else if (this.type === "video") {
        this.getVideos()
      }

      this.eventEmitter.subscribe(() => {
        setTimeout(() => {
          this.checkOverFlowed()
        }, 0)
      })
    }
  }

  toggleContentsVisible() {
    this.isContentsVisible = !this.isContentsVisible
    if (this.isContentsVisible) {
      this.isOverFlowed = false
    } else {
      this.checkOverFlowed()
    }
  }

  getVideos() {
    this.pictorialService.getPictorialVideos({ id: this.id }).subscribe((res: UserFile[]) => {
      this.contents = res

      if (this.contents.length === 0) {
        this.noContents.emit(this.type)
      }
    })
  }

  getPhotos() {
    this.pictorialService
      .getPictorialPhotos({
        id: this.id,
        widthPagination: false,
      })
      .subscribe((res: UserFile[]) => {
        this.contents = res

        if (this.contents.length === 0) {
          this.noContents.emit(this.type)
        }
      })
  }

  checkOverFlowed() {
    if (this.listContainer) {
      const container = this.listContainer.nativeElement
      this.isOverFlowed = container.scrollHeight > container.clientHeight
    }
  }
}
