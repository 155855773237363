import { PurchasePermission } from "src/app/creator/pages/creator-photobook-page/type"

export enum PictorialFileType {
  photos = "photos",
  videos = "videos",
  covers = "covers",
}

export interface PictorialFileUrlReq {
  count: number
  fileType: PictorialFileType
}

export interface PictorialFileUrlRes {
  id: string
  url: string
}

export interface CampaignPictorial {
  campaignId: number
  pictorialId: string
  pictorial: Pictorial
}

export interface Pictorial {
  title: string
  release: boolean
  availableSale: boolean
  individualSale: boolean
  categoryId: string
  themes?: string[]
  creatorId: number
  tierId?: number
  visibility?: number
  price?: number
  productNumber: string
  releaseDate: string
  closeDate: string
  order: number
  adult: boolean
  id: string
  free?: boolean
  openTo?: string
  categories: Category[]
  createdAt: string
  updatedAt?: string
  description?: string
  isCampaignBlocked?: boolean
  campaignId?: string
  isLiked?: boolean
  likeCount?: number
  isVotedToday?: boolean
  voteCount?: number
  photographers?: string[]
  photosCount?: number
  originPriceUsd?: number
  priceUsd?: number
  discountRatePercent?: number
  salesMethod: PurchasePermission
  videosCount?: number
  visibleOnApp: boolean
  visibleOnWeb: boolean
  __covers__: Covers[]
  __models__: Models[]
  thumbnailUri?: string
}

export interface PictorialListItemWithThumbnail extends Pictorial {
  thumbnailUri: string
}

export interface Covers {
  id: string
  order: number
  file: File
}

export interface File {
  id: string
  url: string
}

export interface Models {
  id: string
  creatorId: number
  name: string
  modelCreatorId?: number
  membermeUri?: string
  birthday: Date | null
  height: number
  weight: null
  bwh: string
  order: number
}

export interface Category {
  id: string
  creatorId: number
  name: string
  release: boolean
  order: number
}
