import { Component, OnInit, Renderer2 } from "@angular/core"
import { ModalPageComponent } from "../../../shared/components/modal-page/modal-page.component"
import { ActivatedRoute, ParamMap, Router } from "@angular/router"
import { FormService } from "../../../shared/services/form.service"
import { Form } from "../../../shared/model/form"
import { RewardService } from "../../services/reward.service"
import { switchMap } from "rxjs/operators"
import { TranslateService } from "@ngx-translate/core"

@Component({
  selector: "app-reward-form-page",
  templateUrl: "./reward-form-page.component.html",
  styleUrls: ["./reward-form-page.component.scss"],
})
export class RewardFormPageComponent extends ModalPageComponent implements OnInit {
  form: Form
  rewardId

  constructor(
    renderer: Renderer2,
    router: Router,
    private route: ActivatedRoute,
    private formService: FormService,
    private rewardService: RewardService,
    private translateService: TranslateService,
  ) {
    super(renderer, router)
  }

  ngOnInit() {
    this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) => {
          this.rewardId = params.get("id")
          const url = this.rewardId ? `rewards/${this.rewardId}/` : "rewards/"
          return this.formService.getFormData(url)
        }),
      )
      .subscribe(
        (formData) => {
          this.form = formData
          this.translateForm()
        },
        () => {
          this.router.navigate(["", { outlets: { popup: null } }]).then(() => {
            this.router.navigate(["/404"], { replaceUrl: true })
          })
        },
      )
  }

  translateForm() {
    const { fields } = this.form
    if (fields.name) {
      fields.name.help_text = this.translateService.instant("REWARD_FORM.DISCLAIMER")
    }
    if (fields.delivery_schedule && Array.isArray(fields.delivery_schedule.choices)) {
      fields.delivery_schedule.choices.forEach((choice) => {
        switch (choice.value) {
          case "onetime":
            choice.display_name = this.translateService.instant("REWARD_FORM.ONE_TIME")
            break
          case "monthly":
            choice.display_name = this.translateService.instant("REWARD_FORM.MONTHLY")
            break
        }
      })
    }

    if (fields.is_managed_reward) {
      fields.is_managed_reward.label = this.translateService.instant("REWARD_FORM.MANAGED_BENEFIT")
    }
  }

  onSubmit() {
    let reward$
    if (this.rewardId) {
      reward$ = this.rewardService.update(this.rewardId, this.form)
    } else {
      reward$ = this.rewardService.create(this.form)
    }
    reward$.subscribe((res) => {
      this.router.navigate([{ outlets: { popup: null } }])
    })
  }
}
