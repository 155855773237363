import { Component } from "@angular/core"
import { SnackbarService } from "../../services/snackbar.service"
import { TranslateService } from "@ngx-translate/core"
import { ConfirmationDialogComponent } from "src/app/shared/components/confirmation-dialog/confirmation-dialog.component"
import { MatDialog, MatDialogConfig } from "@angular/material"

@Component({
  template: "",
})
export class MessagingComponent {
  constructor(
    protected snackbarService: SnackbarService,
    protected translateService: TranslateService,
    protected dialog: MatDialog,
  ) {
    translateService.setDefaultLang("en")
  }

  public openDialog(
    config: MatDialogConfig,
    afterClose: Function,
    componentOrTemplateRef: any = ConfirmationDialogComponent,
  ): void {
    const dialogRef = this.dialog.open(componentOrTemplateRef, config)
    dialogRef.afterClosed().subscribe((result: any) => afterClose(result))
  }

  public translate(key) {
    return this.translateService.instant(key)
  }

  public messageSuccess(message: string, action = "ok", duration = 2000) {
    this.snackbarService.open(message, action, duration, "primary-snackbar")
  }

  public messageDanger(message: string, action = "ok", duration = 2000) {
    this.snackbarService.open(message, action, duration, "danger-snackbar")
  }

  public messageWarn(message: string, action = "ok", duration = 2000) {
    this.snackbarService.open(message, action, duration, "warn-snackbar")
  }

  public messageInfo(message: string, action = "ok", duration = 2000) {
    this.snackbarService.open(message, action, duration, "info-snackbar")
  }
}
