import { Component, EventEmitter, OnInit } from "@angular/core"

@Component({
  selector: "app-creator-update-profile-page",
  templateUrl: "./creator-update-profile-page.component.html",
  styleUrls: ["./creator-update-profile-page.component.scss"],
})
export class CreatorUpdateProfilePageComponent implements OnInit {
  submitEventEmitter: EventEmitter<undefined> = new EventEmitter()

  constructor() {}

  ngOnInit() {}
}
