import { Component, HostListener, Inject } from "@angular/core"
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material"
import { PostDetailDialogComponent } from "src/app/board/components/post-detail-dialog/post-detail-dialog.component"
import { Pictorial } from "src/app/board/types/pictorial.type"
import { BuyModalData } from "./types/modal-data.type"
import { MessageType } from "src/app/sendme/types/message.type"
import { Creator, MembershipTier } from "src/app/creator/types"
import { PurchasePermission } from "src/app/creator/pages/creator-photobook-page/type"
import { Router } from "@angular/router"
import { StripeMethodDialog } from "../../components/stripe-method-dialog/stripe-method-dialog"
import { environment } from "src/environments/environment"

@Component({
  selector: "app-pictorial-buy-modal",
  templateUrl: "./pictorial-buy-modal.component.html",
  styleUrls: ["./pictorial-buy-modal.component.scss"],
})
export class PictorialBuyModalComponent {
  @HostListener("document:keydown.escape", ["$event"]) onKeydown() {
    window.history.back()
  }

  @HostListener("window:click", ["$event"]) onPopState(event: any) {
    if (event.target.className && event.target.className.includes("backdrop")) {
      window.history.back()
    }
  }

  pictorial: Pictorial
  creator: Creator
  onClickPay: (modal: any) => void
  isPurchasedPictorial: boolean

  needBuyPictorial: boolean
  needChangeMembership: boolean
  needJoinMembership: boolean
  cannotPurchase: boolean

  paymentTier?: MembershipTier

  allowPurchase: boolean = true

  contentCount?: string

  constructor(
    public stripeMethodDialog: StripeMethodDialog,
    public dialogRef: MatDialogRef<PostDetailDialogComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: BuyModalData,
  ) {
    this.pictorial = data["pictorial"]
    this.onClickPay = data["onClickPay"] ? data["onClickPay"] : this.paymentPictorial
    this.creator = data["creator"]
    this.isPurchasedPictorial = data["isPurchasedPictorial"]

    if (this.pictorial) {
      this.setPictorialPaymentInfo()
    } else {
      this.setMembershipPaymentInfo()
    }

    dialogRef.addPanelClass("pictorial-buy-modal")
  }

  private setPictorialPaymentInfo() {
    const showContentCountCreator = environment.showContentCountCreators.find(
      (creator) => creator.id == this.creator.id,
    )
    this.contentCount = showContentCountCreator
      ? showContentCountCreator.count.toLocaleString()
      : null

    let isSalesPictorial = this.pictorial.availableSale
    let allowedToMembers = !!this.pictorial.tierId

    if (this.pictorial.salesMethod) {
      isSalesPictorial = [PurchasePermission.allPaid, PurchasePermission.membershipPaid].includes(
        this.pictorial.salesMethod,
      )
      allowedToMembers = [
        PurchasePermission.membershipPaid,
        PurchasePermission.membershipFree,
      ].includes(this.pictorial.salesMethod)
    }

    this.needBuyPictorial = isSalesPictorial && !this.isPurchasedPictorial

    if (allowedToMembers) {
      const tier = this.data["tier"]
      const currentTier = this.creator.membership && this.creator.membership.tier
      const currentTierUsd = currentTier ? currentTier.amount_usd : 0
      const pictorialTierUsd = tier ? tier.amount_usd : 9999999
      const minTier = this.creator.tiers
        .filter((tier) => tier.joinable && tier.visible)
        .find((tier) => tier.amount_usd >= pictorialTierUsd)
      this.paymentTier = minTier

      if (currentTier && currentTierUsd < pictorialTierUsd) {
        this.needChangeMembership = true
      }

      if (!currentTier) {
        this.needJoinMembership = true
        this.allowPurchase = !this.needBuyPictorial // 화보, 멤버십 동시 구매시에만 체크박스 생김.
      }

      if (!this.paymentTier && (this.needChangeMembership || this.needJoinMembership)) {
        this.cannotPurchase = true
      }
    }
  }

  private setMembershipPaymentInfo() {
    this.paymentTier = this.data["tier"]
    this.needJoinMembership = true
  }

  async paymentPictorial() {
    this.dialogRef.close()
    const paymentSucceed = await this.stripeMethodDialog.open({
      creatorId: this.needJoinMembership ? this.paymentTier.creator : this.pictorial.creatorId,
      tierId: this.needJoinMembership ? this.paymentTier.id : undefined,
      pictorialId: this.needBuyPictorial ? this.pictorial.id : undefined,
      type: this.pictorial ? "pictorial" : "membership",
    })

    if (paymentSucceed) {
      if (this.data.afterPaymentAction) {
        this.data.afterPaymentAction({
          membershipTier: this.needJoinMembership ? this.paymentTier : undefined,
          pictorial: this.pictorial,
          creator: this.creator,
        })
      }
    }
  }

  close() {
    window.history.back()
    this.dialogRef.close()
  }

  toMembershipPage() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(["/creators", this.pictorial.creatorId, "contribute"]),
    )
    window.open(url, "_blank")
  }
}
