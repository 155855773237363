import { Injectable } from "@angular/core"
import { Form } from "../../shared/model/form"
import { environment } from "../../../environments/environment"
import { tap } from "rxjs/operators"
import { HttpClient } from "@angular/common/http"
import { MembershipTier } from "../types"

@Injectable({
  providedIn: "root",
})
export class TierService {
  constructor(private http: HttpClient) {
  }

  get(id: number) {
    return this.http.get<MembershipTier>(environment.apiUrl + "tiers/" + id + "/")
  }

  getPromotionTier() {
    return this.http.get<MembershipTier[]>(environment.apiUrl + "tiers/promotions/")
  }

  create(form: Form) {
    const data = form.formGroup.value
    return this.http.post(environment.apiUrl + "tiers/", data).pipe(
      tap(
        (res) => form.clearErrors(),
        (error) => form.setErrors(error),
      ),
    )
  }

  update(id, form: Form) {
    const data = form.formGroup.value
    return this.http.put(environment.apiUrl + "tiers/" + id + "/", data).pipe(
      tap(
        (res) => form.clearErrors(),
        (error) => form.setErrors(error),
      ),
    )
  }

  delete(id) {
    return this.http.delete(environment.apiUrl + "tiers/" + id + "/")
  }
}
