import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { CookieService } from "angular2-cookie"
import { ArticleService } from "../../services/article.service"

enum Category {
  creator = "faq_creator",
  contributor = "faq_contributor",
  notice = "notice",
  manual = "manual",
  reference = "reference",
}

@Component({
  selector: "app-article-list-page",
  templateUrl: "./article-list-page.component.html",
  styleUrls: ["./article-list-page.component.scss"],
})
export class ArticleListPageComponent implements OnInit {
  // paginatedResult$;
  paginatedResult
  nextUrl
  articles

  isKorean

  constructor(
    private route: ActivatedRoute,
    private articleService: ArticleService,
    private cookieService: CookieService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.isKorean = this.cookieService.get("lang") === "KOR"
    this.route.paramMap.subscribe((res) => {
      if (res.keys[0] === "category") {
        this.articleService
          .getListPaginated({ category: res.get("category") })
          .subscribe((result) => {
            this.translateCategory(result, res.get("category"))
            this.paginatedResult = result
            this.nextUrl = result["next"]
            this.articles = result["results"]
          })
      } else if (res.keys[0] === "search") {
        this.articleService.getListPaginated({ search: res.get("search") }).subscribe((result) => {
          this.translateCategory(result, res.get("category"))
          this.paginatedResult = result
          this.nextUrl = result["next"]
          this.articles = result["results"]
        })
      }
    })
  }

  setPaginatedResultNext(next) {
    this.articleService.getListPaginatedByUrl(next).subscribe((res) => {
      this.nextUrl = res["next"]
      this.articles = this.articles.concat(res["results"])
    })
  }

  translateCategory(paginatedResult: any, category) {
    switch (category) {
      case Category.creator:
        paginatedResult.category_display = this.translateService.instant("HELP_CENTER.FAQ_CREATOR")
        break
      case Category.contributor:
        paginatedResult.category_display = this.translateService.instant("HELP_CENTER.FAQ_MEMBER")
        break
      case Category.notice:
        paginatedResult.category_display = this.translateService.instant("HELP_CENTER.NOTICE")
        break
      case Category.manual:
        paginatedResult.category_display = this.translateService.instant("HELP_CENTER.MANUAL")
        break
      case Category.reference:
        paginatedResult.category_display = this.translateService.instant("HELP_CENTER.REFERENCE")
        break
    }
  }
}
