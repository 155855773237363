import { Component, DoCheck, HostListener, OnInit, ViewChild } from "@angular/core"
import { ActivatedRoute, ParamMap, Router } from "@angular/router"
import { BreakpointObserver } from "@angular/cdk/layout"
import { map, switchMap } from "rxjs/operators"
import { Observable } from "rxjs"
import { UserService } from "src/app/account/services/user.service"
import { PostListCardComponent } from "src/app/board/components/post-list-card/post-list-card.component"
import { FormService } from "src/app/shared/services/form.service"
import { AuthService } from "src/app/core/services/auth.service"
import { smoothly } from "src/app/animation"
import { CreatorService } from "../../services/creator.service"
import { SnackbarService } from "src/app/shared/services/snackbar.service"
import { TranslateService } from "@ngx-translate/core"
import { MatDialog, MatIconRegistry } from "@angular/material"
import { MessagingComponent } from "src/app/shared/components/messaging/messaging.component"
import { PostService } from "src/app/board/services/post.service"
import { PostDetailDialogComponent } from "src/app/board/components/post-detail-dialog/post-detail-dialog.component"
import { PictorialService } from "src/app/board/services/pictorial.service"
import { environment } from "src/environments/environment"
import { CookieService } from "angular2-cookie"
import { CurrencyPipe } from "src/app/shared/pipe/currency.pipe"
import { Pictorial } from "src/app/board/types/pictorial.type"
import { LEEHEE_ZAP_URL } from "src/app/shared/constants/constants"
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser"
import { Creator, CreatorPictorialInformation } from "../../types"
import { ContestPictorialRegistryService } from "src/app/creator/services/contest-pictorial-registry.service"
import { CreatorDetailPageContentsService } from "./creator-detail-page-contents.service"
import { CreatorDetailPageCreatorService } from "./creator-detail-page-creator.service"

@Component({
  selector: "app-creator-detail-page",
  animations: [smoothly],
  templateUrl: "./creator-detail-page.component.html",
  styleUrls: ["./creator-detail-page.component.scss"],
})
export class CreatorDetailPageComponent extends MessagingComponent implements OnInit, DoCheck {
  @ViewChild("postListCreatorRecent", { static: false })
  postListCreatorRecent: PostListCardComponent
  @ViewChild("postListCreator", { static: false }) postListCreator: PostListCardComponent
  @ViewChild("postListContributor", { static: false }) postListContributor: PostListCardComponent
  leeheeZepUrl: SafeResourceUrl

  introDiv
  postDiv
  fanDiv
  membershipDiv

  mainWidth
  postWidth
  fanWidth
  membershipWidth

  isAppOnly
  isScreenSmall: Observable<boolean>
  isCollapsedPanel = false
  hasTagParam = false
  openedCategoryInMobile = false

  pid
  cid

  currentTab = "main"

  tag

  currentUser

  creatorPictorialInformation

  constructor(
    private router: Router,
    public auth: AuthService,
    private route: ActivatedRoute,
    private formService: FormService,
    private userService: UserService,
    private breakpoints: BreakpointObserver,
    protected snackbarService: SnackbarService,
    protected translateService: TranslateService,
    private creatorService: CreatorService,
    private postService: PostService,
    protected dialog: MatDialog,
    private pictorialService: PictorialService,
    private cookieService: CookieService,
    private currencyPipe: CurrencyPipe,
    private sanitizer: DomSanitizer,
    private iconRegistry: MatIconRegistry,
    private contestRegistryService: ContestPictorialRegistryService,
    protected detailPagePictorialService: CreatorDetailPageContentsService,
    protected detailPageCreatorService: CreatorDetailPageCreatorService,
  ) {
    super(snackbarService, translateService, dialog)
    this.isScreenSmall = breakpoints
      .observe(`(max-width: 1024px)`)
      .pipe(map((breakpoint) => breakpoint.matches))
    this.isScreenSmall.subscribe((isSmall: boolean) => {
      this.isCollapsedPanel = isSmall
    })
    this.leeheeZepUrl = this.sanitizer.bypassSecurityTrustResourceUrl(LEEHEE_ZAP_URL)
    this.iconRegistry.addSvgIcon(
      "leehee-meta-card",
      sanitizer.bypassSecurityTrustResourceUrl("assets/img/leehee-meta-card.svg"),
    )
  }

  ngOnInit() {
    this.contestRegistryService.init()
    this.route.queryParams.subscribe((params) => {
      this.tag = params.tag
    })
    this.detailPageCreatorService.creator$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        this.currentUser = this.getUser()
        this.pid = params.get("pid")
        this.cid = params.get("id")
        return this.creatorService.get(params.get("id"))
      }),
    )

    this.detailPageCreatorService.creator$.subscribe(
      (creator: Creator) => {
        this.isAppOnly = creator.is_app_only
        this.detailPageCreatorService.isLeeheeCreator = environment.leeheeCreatorIds.includes(
          creator.id,
        )

        this.detailPageCreatorService.creator = Object.assign(
          creator,
          this.isOfficialAccount(creator) ? { count_followers: "-" } : undefined,
        )

        if (this.tag && ("" + this.tag).trim()) {
          setTimeout(() => this.menuClicked("post", false), 300)
          this.hasTagParam = true
        } else {
          this.menuClicked("main", false)
        }
        if (this.pid && (this.isMine() || !creator.is_app_only)) {
          this.getPostResult(this.pid)
        }
        this.detailPageCreatorService.getCreator(creator, this.tag)
        this.gotToModelTab()
        this.creatorService.getPictorialInformation(creator.id).subscribe((res: CreatorPictorialInformation) => {
          this.creatorPictorialInformation = res
        })
      },
      () => {
        // 토큰이 expire 됐을 경우 데이터 다시 받아와야 함
        this.auth.clear()
        if (this.resetCounter < 1) {
          this.detailPageCreatorService.resetCounter++
          this.ngOnInit()
        }
      },
    )
  }

  private gotToModelTab() {
    this.route.url.pipe().subscribe((url) => {
      if (url[1] && url[1].path === "models") {
        this.menuClicked("model", false)
        const modelId = url[2] ? url[2].path : null
        this.goToModelPage(modelId)
      }
    })
  }

  private goToModelPage(modelId: string) {
    if (modelId) {
      this.pictorialService
        .getModel(modelId)
        .pipe()
        .subscribe((res: any) => {
          const model = Object.assign(res, { query: { modelIds: [res.id] } })
          this.onClickSideListItem(model)
        })
    }
  }

  // tslint:disable-next-line: use-life-cycle-interface
  ngDoCheck() {
    if (!this.introDiv) {
      this.introDiv = document.getElementById("main-tab-id")

      if (this.introDiv) {
        const postTabCont = document.getElementById("post-tab-cont")
        const fanTabCont = (this.fanWidth = document.getElementById("fan-tab-cont"))
        const membershipTabCont = document.getElementById("membership-tab-cont")
        const mainTabCont = document.getElementById("main-tab-cont")
        this.postDiv = document.getElementById("post-tab-id")
        this.fanDiv = document.getElementById("fan-tab-id")
        this.membershipDiv = document.getElementById("membership-tab-id")

        // width of tabs
        this.mainWidth = mainTabCont ? mainTabCont.getBoundingClientRect().width : this.mainWidth
        this.postWidth = postTabCont ? postTabCont.getBoundingClientRect().width : this.postWidth
        if (!this.creator.is_fanpage_active) {
          this.fanWidth = 0
        } else {
          this.fanWidth = fanTabCont ? fanTabCont.getBoundingClientRect().width : this.fanWidth
        }
        this.membershipWidth = membershipTabCont
          ? membershipTabCont.getBoundingClientRect().width
          : this.membershipWidth

        const x = this.introDiv.getBoundingClientRect().left
        const wi = this.introDiv.getBoundingClientRect().width

        document.getElementById("udln-id").style.display = "block"
        document.getElementById("udln-id").style.left = x.toString() + "px"
        document.getElementById("udln-id").style.width = wi.toString() + "px"
        // document.getElementById('udln-id').style.backgroundColor = '#2563d6'
      }
    }
  }

  getPostResult(pid) {
    this.postService.get(pid).subscribe((res) => {
      this.openPostDetailDialog(res)
    })
  }

  openPostDetailDialog(data): void {
    data.visibility = Math.round(Number(data.visibility / 10)) / 100
    this.openDialog(
      {
        width: "700px",
        maxWidth: "700px",
        maxHeight: "100vh",
        panelClass: "post-detail-dialog-panel",
        data: { post: data },
      },
      () => {},
      PostDetailDialogComponent,
    )
  }

  // 멤버미 공식 계정 여부 체크
  isOfficialAccount(creator): boolean {
    creator = creator ? creator : this.creator
    return environment.officialAccounts.includes(creator.id)
  }

  getUser() {
    return this.userService.getUser()
  }

  isMine() {
    return this.getUser() && this.creator.id === this.getUser().creator
  }

  // menu slide animation logic
  menuClicked(dest, scrollToTop = true) {
    const udlnEl = document.getElementById("udln-id")

    let xMove
    if (this.currentTab.toString() === "main") {
      if (dest === "post") {
        xMove = this.mainWidth
        udlnEl.style.transform = "translateX(" + xMove.toString() + "px)"
        udlnEl.style.width = this.postDiv.getBoundingClientRect().width.toString() + "px"
      } else if (dest === "fan") {
        xMove = this.mainWidth + this.postWidth
        udlnEl.style.transform = "translateX(" + xMove.toString() + "px)"
        udlnEl.style.width = this.fanDiv.getBoundingClientRect().width.toString() + "px"
      } else if (dest === "membership") {
        this.scrollToMembership()
        xMove = this.mainWidth + this.postWidth + this.fanWidth
        udlnEl.style.transform = "translateX(" + xMove.toString() + "px)"
        udlnEl.style.width = this.membershipDiv.getBoundingClientRect().width.toString() + "px"
      }
    } else if (this.currentTab.toString() === "post") {
      if (dest === "main") {
        udlnEl.style.transform = "translateX(0px)"
        udlnEl.style.width = this.introDiv.getBoundingClientRect().width.toString() + "px"
      } else if (dest === "fan") {
        xMove = this.postWidth + this.mainWidth
        udlnEl.style.transform = "translateX(" + xMove.toString() + "px)"
        udlnEl.style.width = this.fanDiv.getBoundingClientRect().width.toString() + "px"
      } else if (dest === "membership") {
        this.scrollToMembership()
        xMove = this.postWidth + this.mainWidth + this.fanWidth
        udlnEl.style.transform = "translateX(" + xMove.toString() + "px)"
        udlnEl.style.width = this.membershipDiv.getBoundingClientRect().width.toString() + "px"
      }
    } else if (this.currentTab.toString() === "fan") {
      if (dest === "main") {
        udlnEl.style.transform = "translateX(0px)"
        udlnEl.style.width = this.introDiv.getBoundingClientRect().width.toString() + "px"
      } else if (dest === "post") {
        xMove = this.mainWidth
        udlnEl.style.transform = "translateX(" + xMove.toString() + "px)"
        udlnEl.style.width = this.postDiv.getBoundingClientRect().width.toString() + "px"
      } else if (dest === "membership") {
        this.scrollToMembership()
        xMove = this.postWidth + this.mainWidth + this.fanWidth
        udlnEl.style.transform = "translateX(" + xMove.toString() + "px)"
        udlnEl.style.width = this.membershipDiv.getBoundingClientRect().width.toString() + "px"
      }
    } else {
      if (dest === "main") {
        udlnEl.style.transform = "translateX(0px)"
        udlnEl.style.width = this.introDiv.getBoundingClientRect().width.toString() + "px"
      } else if (dest === "post") {
        xMove = this.mainWidth
        udlnEl.style.transform = "translateX(" + xMove.toString() + "px)"
        udlnEl.style.width = this.postDiv.getBoundingClientRect().width.toString() + "px"
      } else if (dest === "fan") {
        xMove = this.mainWidth + this.postWidth
        udlnEl.style.transform = "translateX(" + xMove.toString() + "px)"
        udlnEl.style.width = this.fanDiv.getBoundingClientRect().width.toString() + "px"
      }
    }
    // set current tab string
    this.currentTab = dest

    // scroll to top
    if (dest !== "membership" && scrollToTop) {
      window.scrollTo(0, 0)
    }

    if (
      ((this.creator.creator_type == "photo" || this.creator.creator_type == "sns_and_photo") &&
        dest == "main") ||
      dest == "model"
    ) {
      this.detailPagePictorialService.init(this.creator)
    }
  }

  // set under bar after window resize
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    if (this.creator.creator_type == "photo" || this.creator.creator_type == "sns_and_photo") {
      return
    }
    this.mainWidth = document.getElementById("main-tab-cont").getBoundingClientRect().width
    this.postWidth = document.getElementById("post-tab-cont").getBoundingClientRect().width
    if (this.creator.is_fanpage_active) {
      this.fanWidth = 0
    } else {
      this.fanWidth = document.getElementById("fan-tab-cont").getBoundingClientRect().width
    }
    this.membershipWidth = document
      .getElementById("membership-tab-cont")
      .getBoundingClientRect().width

    if (this.currentTab === "main") {
      this.setSliderValuesOnResize(
        this.introDiv.getBoundingClientRect().left,
        this.introDiv.getBoundingClientRect().width,
      )
    } else if (this.currentTab === "post" || this.currentTab === "model") {
      this.setSliderValuesOnResize(
        this.postDiv.getBoundingClientRect().left - this.mainWidth,
        this.postDiv.getBoundingClientRect().width,
      )
    } else if (this.currentTab === "fan") {
      this.setSliderValuesOnResize(
        this.fanDiv.getBoundingClientRect().left - this.postWidth - this.mainWidth,
        this.fanDiv.getBoundingClientRect().width,
      )
    } else {
      this.setSliderValuesOnResize(
        this.membershipDiv.getBoundingClientRect().left -
          this.fanWidth -
          this.postWidth -
          this.mainWidth,
        this.membershipDiv.getBoundingClientRect().width,
      )
    }
  }

  // utility function for setting left/width of slider on window resize
  setSliderValuesOnResize(x, wi) {
    document.getElementById("udln-id").style.left = x.toString() + "px"
    document.getElementById("udln-id").style.width = wi.toString() + "px"
  }

  scrollToMembership() {
    setTimeout(() => {
      document.getElementById("tier-list-id").scrollIntoView({ behavior: "smooth", block: "start" })
    }, 200)
  }

  // fix navigation at the top when scrolling
  @HostListener("window:scroll", [])
  onWindowScroll() {
    const bgContainerId = document.getElementById("bg-container-id")
    const navbarId = document.getElementById("navbar-id")
    const creatorPicId = document.getElementById("creator-pic-id")
    if (bgContainerId && bgContainerId.getBoundingClientRect().top < 0) {
      navbarId && navbarId.classList.add("nav-sticker")
      creatorPicId && creatorPicId.classList.add("pic-hide")
    } else {
      navbarId && navbarId.classList.remove("nav-sticker")
      creatorPicId && creatorPicId.classList.remove("pic-hide")
    }
  }

  filterTagChanged($event: any) {
    this.menuClicked("post")
    if ($event && ("" + $event).trim()) {
      this.hasTagParam = true
      this.form.formGroup.patchValue({
        tag: $event,
      })
    }
  }

  onClickBecomeMember() {
    // 성인 멤버쉽 && 성인 인증 X
    if (this.creatorService.currentCreator.has_adult_content && !this.auth.isAdult()) {
      this.auth.verifyAdult()
    } else {
      this.router.navigate(["/creators", this.creatorService.currentCreator.id, "contribute"])
    }
  }

  onClickSideListItem(select) {
    this.detailPagePictorialService.setFilter(select)
  }

  onClickNewPictorial() {
    this.pictorialService.create(this.creator.id).subscribe((res: Pictorial) => {
      this.router.navigate(["pictorials", res.id], { relativeTo: this.route })
    })
  }

  onClickNewModel() {
    this.router.navigate(["creators", "contents", "models"], {
      // queryParams: { "new-model": true },
    })
  }

  onScroll() {
    if (this.meta && this.meta.currentPage < this.meta.totalPages) {
      this.detailPagePictorialService.getPictorialsResult()
    }
  }

  get meta() {
    return this.detailPagePictorialService.meta
  }
  get viewAll() {
    return this.detailPagePictorialService.viewAll
  }
  get selectedListItem() {
    return this.detailPagePictorialService.selectedFilter
  }
  get pictorials() {
    return this.detailPagePictorialService.pictorials
  }
  get models() {
    return this.detailPagePictorialService.models
  }
  get categories() {
    return this.detailPagePictorialService.categories
  }
  get themes() {
    return this.detailPagePictorialService.themes
  }
  get mainThemes() {
    return this.detailPagePictorialService.mainThemes
  }
  get modelCreator() {
    return this.detailPagePictorialService.modelCreator
  }
  get query() {
    return this.detailPagePictorialService.query
  }

  get editorContent() {
    return this.detailPageCreatorService.editorContent
  }
  get postPermission() {
    return this.detailPageCreatorService.postPermission
  }
  get commentPermission() {
    return this.detailPageCreatorService.commentPermission
  }
  get creator$() {
    return this.detailPageCreatorService.creator$
  }
  get creator() {
    return this.detailPageCreatorService.creator
  }
  get form() {
    return this.detailPageCreatorService.form
  }
  get creatorLoaded() {
    return this.detailPageCreatorService.creatorLoaded
  }
  get isFatkatPartner() {
    return this.detailPageCreatorService.isFatkatPartner
  }
  get isBlockedUser() {
    return this.detailPageCreatorService.isBlockedUser
  }
  get notPaidUser() {
    return this.detailPageCreatorService.notPaidUser
  }
  get resetCounter() {
    return this.detailPageCreatorService.resetCounter
  }
  get isLeeheeCreator() {
    return this.detailPageCreatorService.isLeeheeCreator
  }
}
