import { Injectable } from "@angular/core"
import { environment } from "../../../environments/environment"
import { HttpClient } from "@angular/common/http"
import { map } from "rxjs/operators"
import { Pagination } from "src/app/shared/types/pagination.type"

@Injectable({
  providedIn: "root",
})
export class ContentThemeService {
  constructor(private http: HttpClient) {}

  getList() {
    return this.http
      .get<Pagination<{ theme: string }>>(`${environment.apiUrl}preview-themes/`)
      .pipe<string[]>(
        map((res) => {
          const previewThemes = res["results"]
          return previewThemes.map((previewTheme) => previewTheme.theme)
        }),
      )
  }
}
