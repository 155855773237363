import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core"
import { Message } from "../../types/message.type"
import * as moment from "moment"
import { CookieService } from "angular2-cookie"
import { ActivatedRoute, Router } from "@angular/router"
import { PostService } from "src/app/board/services/post.service"
import { SnackbarService } from "src/app/shared/services/snackbar.service"
import { Util } from "src/app/shared/utils/utils"
import { Post } from "src/app/board/types/post.type"
import { CountryRestrictionModalService } from "src/app/shared/services/country-restiction-modal.service"

@Component({
  selector: "app-message-list-item",
  templateUrl: "./message-list-item.component.html",
  styleUrls: ["./message-list-item.component.scss", "./creator-sent-message-list-item.scss"],
})
export class MessageListItemComponent implements OnInit {
  @Input() isRemoveMode: boolean
  @Input() onCheckboxChange: (id: number) => void
  @Input() message: Message
  // 페이지를 이동할 때, 체크박스가 체크되어있는지 확인하기 위해 사용
  @Input() isRowSelected: boolean = false
  @Input() productSentByMe: Post

  created: moment.Moment
  isKorean: boolean

  messageCreatedByMe: boolean = false
  parsedContent: string

  constructor(
    private cookieService: CookieService,
    private postService: PostService,
    private router: Router,
    private route: ActivatedRoute,
    private snackbarService: SnackbarService,
    private restrictCountryService: CountryRestrictionModalService,
  ) {
    this.isKorean = this.cookieService.get("lang") === "KOR"
  }

  ngOnInit() {
    const defaultContent = "멤버십 가입이후 확인 가능합니다"
    const messageCreatedByMe = (this.messageCreatedByMe = Boolean(this.productSentByMe))

    let lastCommentContent = null
    let postContent = null

    if (this.message && this.message.last_comment) {
      lastCommentContent = this.message.last_comment.content
    }

    if (this.message && this.message.post) {
      postContent = this.message.post.content
    }

    const userReceivedMessageContent = lastCommentContent || postContent || defaultContent
    const creatorSentMessageContent = this.productSentByMe ? this.productSentByMe.content : null

    this.created = messageCreatedByMe
      ? moment(this.productSentByMe.created)
      : moment(this.message.created)

    this.parsedContent = this.parseContent(creatorSentMessageContent || userReceivedMessageContent)
  }

  parseContent(content: string) {
    return new DOMParser().parseFromString(content, "text/html").body.textContent
  }

  onDeleteChecked($event: PointerEvent) {
    $event.stopPropagation()
    this.onCheckboxChange(this.message.id)
    this.isRowSelected = !this.isRowSelected
  }

  isNotReadReceivedToday() {
    const today = moment()
    const copy = this.created.clone()
    return copy.add(1, "days").isAfter(today) && !this.message.is_read
  }

  async updateImportant($event: MouseEvent) {
    $event.stopPropagation()
    try {
      await this.postService
        .updateLike(this.message.post.id, !this.message.post.is_liked)
        .toPromise()
      this.message.post.is_liked = !this.message.post.is_liked
    } catch (error) {
      this.showErrorSnackbar(error)
    }
  }

  moveToRoom() {
    if (this.messageCreatedByMe) {
      this.router.navigate([this.productSentByMe.id], { relativeTo: this.route })
      return
    }

    if (this.message.specific_region_excluded && this.restrictCountryService.isRestricted()) {
      this.restrictCountryService.openRestrictModal()
      return
    }

    this.router.navigate([this.message.id], { relativeTo: this.route })
  }

  showErrorSnackbar(error: any) {
    this.snackbarService.open(Util.getErrorTxt(error.error), "", 2000, "danger-snackbar")
  }
}
