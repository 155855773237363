import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core"
import { FilterItem } from "./types/sendme-filter.type"

@Component({
  selector: "app-storage-content-filter",
  templateUrl: "./storage-content-filter.component.html",
  styleUrls: ["./storage-content-filter.component.scss"],
})
export class StorageContentFilterComponent implements OnChanges {
  @Input() filters: FilterItem[] = []
  @Input() divider: boolean = true
  @Input() filterType: string
  @Input() class: string
  @Input() selected: string
  @Output() selectedChange: EventEmitter<FilterItem> = new EventEmitter()

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filters) {
      this.filters = changes.filters.currentValue.filter((filter) => !!filter.value)
    }
  }

  onClick(value: string) {
    this.selectedChange.emit({ label: this.filterType, value })
  }
}
