import { Component, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { Contest } from "../../types"
import { CountryRestrictionModalService } from "src/app/shared/services/country-restiction-modal.service"

@Component({
  selector: "app-contest-main-page",
  templateUrl: "./contest-main-page.component.html",
  styleUrls: ["./contest-main-page.component.scss"],
})
export class ContestMainPageComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private restrictCountryService: CountryRestrictionModalService,
  ) {}

  lang: string

  contest: Contest
  isFetchingIp: boolean = false

  ngOnInit() {
    this.route.data.subscribe((t) => {
      if (!this.isActivated(t)) {
        this.router.navigateByUrl("404")
      }

      this.setContest()
    })

    this.restrictCountryService.isFetchingIpEvent.subscribe((isFetchingIp: boolean) => {
      this.isFetchingIp = isFetchingIp
    })
  }

  isActivated(t) {
    return this.route.snapshot.data.contest && this.route.snapshot.data.contest.is_activated
  }

  setContest() {
    this.lang = this.route.snapshot.data.lang
    this.contest = this.route.snapshot.data.contest
  }
}
