import { Component, Input } from "@angular/core"
import { PaginationMeta } from "src/app/shared/types/pagination.type"
import { ModelContestDataService } from "../../services/model-contest-data.service"

@Component({
  selector: "app-model-contest-top-container",
  templateUrl: "./model-contest-top-container.component.html",
  styleUrls: ["./model-contest-top-container.component.scss"],
})
export class ModelContestTopContainerComponent {
  @Input() title?: string
  @Input() subtitle?: string

  leftItems: any[] = []
  isLoaded = false
  meta: PaginationMeta
  firstItem: any

  constructor(modelContestDataService: ModelContestDataService) {
    modelContestDataService.modelList$.subscribe((items) => {
      this.firstItem = items[0]
      this.leftItems = items.slice(1).reverse()
      this.isLoaded = true
    })
  }

  ngOnInit() {}
}
