import { Component, Input, OnInit } from "@angular/core"

@Component({
  selector: "app-photobook-toggle-button",
  templateUrl: "./photobook-toggle-button.component.html",
  styleUrls: ["./photobook-toggle-button.component.scss"],
})
export class PhotobookToggleButtonComponent implements OnInit {
  @Input()
  checked: boolean
  @Input()
  id: string
  @Input()
  onClick: (key: string, value: boolean) => {}

  constructor() {}

  ngOnInit() {}

  changeStatus(e) {
    this.onClick(e.target.name, e.target.checked)
  }
}
