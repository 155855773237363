import { Component, Input, OnInit } from "@angular/core"
import { CommentService } from "../../services/comment.service"
import { UserBlockService } from "../../services/userblock.service"
import { UserService } from "../../../account/services/user.service"
import { Router } from "@angular/router"

import { CookieService } from "angular2-cookie/core"
import { TranslateService } from "@ngx-translate/core"
import { User } from "src/app/account/types/user.type"

@Component({
  selector: "app-comment-detail",
  templateUrl: "./comment-detail.component.html",
  styleUrls: ["./comment-detail.component.scss", "./sendme-comment-detail.component.scss"],
})
export class CommentDetailComponent implements OnInit {
  @Input() comment
  @Input() canReplyOnComment
  @Input() isBlockedUser: any
  @Input() isReply: boolean
  @Input() isCommentEnabled: boolean
  @Input() formInputClassName: string

  comments: any
  isReplyFormShown = false
  user: User
  isHidden = false
  isBlocked = false
  loading

  //
  langFlag

  constructor(
    private router: Router,
    private userService: UserService,
    private commentService: CommentService,
    private userBlockService: UserBlockService,
    private cookieService: CookieService,
    private translateService: TranslateService,
  ) {
    this.comments = []
  }

  ngOnInit() {
    if (this.cookieService.get("lang") !== "KOR") {
      this.langFlag = "ENG"
    } else {
      this.langFlag = "KOR"
    }
    this.setIsBlocked()
    this.user = this.userService.getUser()
  }

  showReplyForm() {
    this.loading = true
    this.commentService.getList({ parent: this.comment.id }).subscribe((comments) => {
      this.loading = false
      this.comments = comments
      // API에서 반대 방향으로 오기 때문에 reverse 해줌
      this.comments = this.comments.slice().reverse()
    })
    this.isReplyFormShown = true
  }

  hideReplyForm() {
    this.comments = []
    this.isReplyFormShown = false
  }

  addComment(comment) {
    this.comments.push(comment)
  }

  deleteComment() {
    if (confirm(this.translateService.instant("ALERT_CONFIRM.DELETE_COMMENT_CONFIRM"))) {
      this.commentService.delete(this.comment.id).subscribe(() => (this.isHidden = true))
    }
  }

  setIsBlocked() {
    if (!this.comment.content) {
      this.isBlocked = true
    }
  }

  doAction(action) {
    const actions = {
      delete: this.deleteComment.bind(this),
    }

    // console.log(actions[action]);

    actions[action]()
  }

  blockUser() {
    if (confirm(this.translateService.instant("ALERT_CONFIRM.REPORT_CONFIRM"))) {
      this.userBlockService.create(this.comment.user.id).subscribe(
        (result) => {
          this.isHidden = true
          alert(this.translateService.instant("ALERT_CONFIRM.REPORT_SUCCESS"))
        },
        (error) => {
          console.log(error)
          if (error.error.non_field_errors) {
            alert(this.translateService.instant("ALERT_CONFIRM.ALREADY_BLOCKED"))
          }
        },
      )
    }
  }

  navigateToCreator() {
    if (this.comment.user["creator_url"]) {
      this.router.navigate(["/", this.comment.user["creator_url"]])
    }
  }

  // used to determine to show pointer cursor or not
  checkCreator() {
    if (this.comment.user["creator_url"]) {
      return true
    }
    return false
  }

  checkMyComment() {
    if (this.user) {
      if (this.comment.user.id == this.user.id) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }
}
