import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { environment } from "src/environments/environment"
import { MediaMetadata } from "src/app/shared/types/file.types"

@Injectable({
  providedIn: "root",
})
export class UserFileService {
  servicePath = "user-files"

  constructor(private http: HttpClient) {}

  deleteFiles(ids: string[]) {
    return this.http.request("DELETE", `${environment.pictorialApiUrl}${this.servicePath}`, {
      body: ids,
    })
  }

  updateFileMetadata(metaData: MediaMetadata) {
    return this.http.put(`${environment.pictorialApiUrl}${this.servicePath}/meta`, metaData)
  }

  confirmFiles(ids: string[]) {
    return this.http.put(`${environment.pictorialApiUrl}${this.servicePath}/confirm`, ids)
  }
}
