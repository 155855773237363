import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"

@Component({
  selector: "app-pagination",
  templateUrl: "./pagination.component.html",
  styleUrls: ["./pagination.component.scss"],
})
export class PaginationComponent implements OnInit {
  @Output() pageChanged = new EventEmitter<string>()
  @Input() pages: any[]

  constructor() {}

  ngOnInit() {
    // 1번 page 안보이는 버그 fix. 백엔드에서 PAGE_SIZE 고칠까 했는데 일단 12로 놔두기로.
    if (this.pages[0].page_number === 1 && this.pages[this.pages.length - 1].page_number === 12) {
      // 아이폰5/SE 에서 1~10 까지 밖에 안보임
      this.pages.pop()
      this.pages.pop()
    }
  }

  changePage(url) {
    // window.scroll(0, 0);
    this.pageChanged.emit(url)
  }
}
