import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"

import { Pagination } from "src/app/shared/types/pagination.type"
import { environment } from "src/environments/environment"
import { DeletePostMessageResp, GetMessageParams } from "../types/ajax.type"
import { Message } from "../types/message.type"
import { BlockedMessage } from "../types/block.type"
import { GetParams } from "src/app/shared/types/ajax.type"

interface MessageChangedEvent {
  id: number
  is_read?: boolean
}

interface CreateMessageBody {
  post: number
  message_type: "post" | "pictorial"
  target_type?: "member" | "tag" | "follower"
  visibility: 0
  specific_region_excluded: boolean
  tier_ids?: number[]
  send: boolean
}

@Injectable({
  providedIn: "root",
})
export class PostMessagesService {
  constructor(private http: HttpClient) {}

  getMessageItems(params: GetMessageParams) {
    return this.http.get<Pagination<Message>>(`${environment.apiUrl}postmessages/`, { params })
  }
  getMessageFromId(id: number) {
    return this.http.get<Message>(`${environment.apiUrl}postmessages/${id}`)
  }
  getMessagesFromIds(ids: number[]) {
    return this.http.post<Message[]>(`${environment.apiUrl}postmessages/by-post/`, ids)
  }

  deleteMessages(ids: number[]) {
    return this.http.post<DeletePostMessageResp>(`${environment.apiUrl}postmessages/delete/`, ids)
  }

  getBlockedItems(params: GetParams) {
    return this.http.get<Pagination<BlockedMessage>>(`${environment.apiUrl}postmessage-block/`, {
      params,
    })
  }

  postMessageBlock(id: number) {
    return this.http.post<BlockedMessage>(`${environment.apiUrl}postmessage-block/`, {
      blocked_creator: id,
    })
  }
  deleteMessageBlock(id: number) {
    return this.http.delete<void>(`${environment.apiUrl}postmessage-block/${id}`)
  }
  getNextMessageItems(url: string) {
    return this.http.get<Pagination<Message>>(url)
  }

  createMessage(message: CreateMessageBody) {
    return this.http.post<Message>(`${environment.apiUrl}postmessage-manager/`, message)
  }
}
