import { Injectable } from "@angular/core"
import { environment } from "../../../environments/environment"
import { HttpClient } from "@angular/common/http"
import { Form } from "../../shared/model/form"
import { tap } from "rxjs/operators"

@Injectable({
  providedIn: "root",
})
export class GoalService {
  constructor(private http: HttpClient) {}

  get(id, data) {
    return this.http.get(`${environment.apiUrl}goals/${id}/`, { params: data })
  }

  getTuple(data) {
    return this.http.get(`${environment.apiUrl}goals/tuple/`, { params: data })
  }

  getSummary(creatorId, year, month) {
    return this.http.get(`${environment.apiUrl}goals/`, {
      params: {
        creator: creatorId,
        year: year,
        month: month,
      },
    })
  }

  create(form: Form) {
    const data = form.formGroup.value
    return this.http.post(`${environment.apiUrl}goals/`, data).pipe(
      tap(
        (res) => form.clearErrors(),
        (error) => form.setErrors(error),
      ),
    )
  }

  update(id, form: Form) {
    const data = form.formGroup.value
    return this.http.put(`${environment.apiUrl}goals/${id}/`, data).pipe(
      tap(
        (res) => form.clearErrors(),
        (error) => form.setErrors(error),
      ),
    )
  }
}
