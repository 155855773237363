import { Injectable } from "@angular/core"
import { Pictorial } from "src/app/board/types/pictorial.type"
import { CreatorService } from "src/app/creator/services/creator.service"
import { Creator, MembershipTier } from "src/app/creator/types"

interface TierDropdownItem extends MembershipTier {
  value: number
  viewValue: string
}

@Injectable()
export class SendmeCreateService {
  constructor(private creatorService: CreatorService) {}

  init() {
    return this.creatorService.get("me").subscribe((res: any) => {
      this.creator = res
      this.tierList = res.tiers
        ? res.tiers.map((tier) => ({ ...tier, value: tier.id, viewValue: tier.name }))
        : []
    })
  }

  creator: Creator
  tierList: TierDropdownItem[]

  sendmeType?: "post" | "pictorial" = null
  isFree?: boolean = null

  title: string = ""
  content: string = ""

  target: "membership" | "tag" = "membership"
  selectedTagIds: number[] = []
  selectedMembershipIds: number[] = []

  priceUsd: number
  specificRegionExcluded: boolean

  selectedContent: Pictorial
}
